<template>
  <v-ons-page>
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <component :is="mod.comp" :related="mod.relatedModule" @select="handleSelect" @detail="handleDetailOpen" />

    <information :comp="mod.comp" :detail="detail" @on-close="handleDetailClose" />
  </v-ons-page>
</template>

<script>
import RelatedPurchases from "@/views/common/list/related_approval/RelatedPurchases";
import RelatedContract from "@/views/common/list/related_approval/RelatedContract";
import Info from "@/views/common/list/related_approval/Info";

const defaultDetail = { show: false, title: "", data: [] };

export default {
  name: "RelatedApproval",
  components: {
    purchases: RelatedPurchases,
    contract: RelatedContract,
    information: Info
  },
  data() {
    return {
      detail: Object.assign({}, defaultDetail)
    };
  },
  computed: {
    module() {
      return this.toolbarInfo.param.moduleCode;
    },
    mod() {
      let c = { comp: "", relatedModule: "" };
      switch (this.module) {
        case "reimbursement":
          c = { comp: "purchases", relatedModule: "purchase" };
          break;
        case "payment":
          c = { comp: "contract", relatedModule: "contract-supplier" };
          break;
        case "collection-debt":
        case "collection-report":
          c = { comp: "contract", relatedModule: "contract-customer" };
          break;
      }
      return c;
    }
  },
  methods: {
    handleSelect(item, data) {
      this.$store.commit("navigator/setParam", {
        selectedApproval: {
          id: item.id,
          code: item.code,
          currency: item.currency_code,
          data: data,
          module: this.mod.relatedModule,
          type: item.type ? item.type.code : null,
          contract_name: item.contract_name ? item.contract_name : null,
          supplier_account: item.supplier_account ? item.supplier_account : null
        }
      });
      this.$store.commit("navigator/pop");
    },
    handleDetailOpen(item, obj) {
      this.detail = {
        show: true,
        title: item.code,
        item: item,
        data: obj
      };
    },
    handleDetailClose() {
      this.detail = Object.assign({}, defaultDetail);
    }
  }
};
</script>

<style></style>
