<template>
  <v-ons-card v-if="data">
    <div class="content p-2 space-y-2">
      <d-text :label="$t('common.app_code')" :value="data.code" />
      <d-text :label="$t('common.company')" :value="company ? company : '-'" />
      <d-text :label="$t('common.department')" :value="department ? department : '-'" />
      <d-items
        :data="obj.purchase_goods"
        :label="$t('app.purc.goods_detail')"
        :history="'purchase_goods' in history ? history.purchase_goods : null"
        @tap="handleTapDetail"
      />
      <d-text
        :label="$t('app.purc.total_estimated')"
        :value="currency + ' ' + Round(obj.total_estimated, currency)"
        :history="'total_estimated' in history ? history.total_estimated : null"
      />
      <hr />
      <d-list :label="$t('app.forward_finance')" :items="finances" itemKey="name" />
    </div>
  </v-ons-card>
</template>

<script>
import PurchaseDetail from "@/views/Approval/component/purchase/PurchaseDetail";
import buffer from "@/mixins/buffer";
import history_detail from "@/mixins/history_detail";
import { fetchUserDetail as getUser } from "@/api/user";
import { Round } from "../../../../utils/number";

export default {
  name: "Purchase",
  props: ["data", "history"],
  mixins: [buffer, history_detail],
  computed: {
    obj() {
      return this.data.data;
    },
    companyId() {
      return this.obj?.purchase_goods ? this.obj.purchase_goods[0].company_id : null;
    },
    departmentId() {
      return this.obj?.purchase_goods ? this.obj.purchase_goods[0].department_id : null;
    },
    pages() {
      return [
        {
          component: PurchaseDetail
        }
      ];
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(n, o) {
        if (n != o) {
          this.fetchCurrency(this.obj.currency_id);
          if (this.companyId) {
            this.fetchCompany(this.companyId);
          }
          if (this.departmentId) {
            this.fetchDepartment(this.departmentId);
          }
          if (this.obj.user_finances) {
            this.obj.user_finances.forEach((user) => {
              this.fetchUserDetail(user);
            });
          }
        }
      }
    }
  },
  data() {
    return {
      currency: "",
      finances: []
    };
  },
  methods: {
    Round,
    handleTapDetail(item, key) {
      this.push(this.pages[0].component, item, this.$t("app.purc.goods_detail") + " (" + (key + 1) + ")");
    },
    fetchUserDetail(id) {
      this.finances = [];
      getUser(id).then((response) => {
        this.finances.push(response);
      });
    },
    push(page, item, name) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: name,
              data: item
            }
          };
        }
      });
    }
  }
};
</script>

<style></style>
