<template>
  <v-ons-page @init="onInitPage">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <search-filter @on-search="onSearch" @on-reset="onReset" />
    <div class="mt-14">
      <div class="no-result" v-if="picList.length === 0">{{ $t("common.empty") }}</div>
      <v-ons-list v-else>
        <form-select-icon
          v-for="(obj, key) in picList"
          :key="key"
          :label="obj.name"
          :sublabel="obj.desc"
          :badge="obj.is_authorizer ? $t('common.company_authorizer') : null"
          icon="circle"
          iconClass="text-gray-300"
          modifier="none"
          @tap="onSelectPic(obj.id)"
        />
      </v-ons-list>
    </div>
    <infinite-loading @infinite="getListData" />
  </v-ons-page>
</template>

<script>
import { mapMutations } from "vuex";
import { fetchUserPic } from "@/api/company";
import SearchFilter from "@/components/SearchFilter";

const defaultFilter = { page: 1, itemsPerPage: 10, name: "" };
export default {
  name: "PicList",
  components: { SearchFilter },
  data: () => ({
    picList: [],
    picId: null,
    filter: Object.assign({}, defaultFilter)
  }),
  methods: {
    ...mapMutations("navigator", ["pop", "setParam", "clearParam"]),
    onSelectPic(id) {
      let selectedPic = this.picList.filter((pic) => pic.id === id)[0];
      let param = { key: this.picId, value: selectedPic };
      this.setParam({ pic: param });
      this.pop();
    },
    onInitPage() {
      let param = this.toolbarInfo.param;
      let picId = param.picId;
      if (picId) {
        this.picId = picId;
      }
    },
    getListData($state) {
      fetchUserPic(this.filter).then((response) => {
        let data = [];
        data = response["hydra:member"].map((item) => {
          return {
            name: item.name,
            id: item.id,
            desc: item.department.name,
            department_id: item.department.id,
            is_authorizer: item.is_authorizer,
            initial_name: item.initial_name
          };
        });

        this.picList.push(...data);

        if (data.length) {
          this.filter.page += 1;
          $state && $state.loaded();
        } else {
          $state && $state.complete();
        }
      });
    },
    onSearch(filter) {
      this.picList = [];
      this.filter.page = 1;
      this.filter.name = filter.term;
      this.getListData();
    },
    onReset() {
      this.picList = [];
      this.filter = Object.assign({}, defaultFilter);
      this.getListData();
    }
  }
};
</script>

<style scoped></style>
