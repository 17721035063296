<template>
  <div>
    <div>
      <span class="text-gray-500 mr-3">{{ $t("app.sc.item.enter_content") }}</span>
      <span>{{ item.content }}</span>
    </div>
    <div>
      <span class="text-gray-500 mr-3">{{ $t("app.sc.item.unit_price") }}</span>
      <span>{{ currency }} {{ item.unit_price }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RelatedServices",
  props: ["item", "currency"]
};
</script>

<style></style>
