<template>
  <div class="space-y-6 mb-16">
    <v-ons-list>
      <form-input kind="text" :label="$t('app.sc.item.name')" :placeholder="$t('app.sc.item.enter_name')" :required="true" v-model="form.name" />

      <form-calendar
        :label="$t('app.sc.item.start_date')"
        :placeholder="$t('common.select_date')"
        :required="true"
        mode="single"
        @on-change="handleStartDateSelect"
        :value="form.start_date"
      />

      <form-textarea :label="$t('app.sc.item.terms')" :placeholder="$t('app.sc.item.enter_terms')" v-model="form.term" />

      <v-ons-list-item class="">
        <div class="list-item__title required">
          <span class="ml-1">{{ $t("app.sc.item.national_amount_hr") }}</span>
        </div>
        <div class="list-item__subtitle">
          <div class="flex items-center">
            <span class="mr-4 text-orange-500"> ({{ currency ? currency.code : null }}) </span>
            <v-ons-input v-model.number="form.amount" type="number" float :placeholder="$t('app.sc.item.enter_national_amount')" />
          </div>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <v-ons-list>
      <form-textarea :label="$t('common.notes')" :placeholder="$t('common.enter_notes')" v-model="form.note" />
    </v-ons-list>

    <bottom-submit :label="$t('common.next')" @click.native="handleSubmit" classes="bg-blue-500" />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "formhr",
  props: ["param"],
  data() {
    return {
      form: {
        name: null,
        start_date: null,
        term: "",
        amount: null,
        note: ""
      }
    };
  },
  computed: {
    currency() {
      return this.param.currencyObj ? this.param.currencyObj : "-";
    },
    index() {
      return this.param.item ? this.param.item.index : null;
    }
  },
  created() {
    if (this.param.item.total > 0) {
      this.form = this.param.item;
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      start_date: {
        required
      },
      amount: {
        required
      }
    }
  },
  methods: {
    handleStartDateSelect(val) {
      this.form.start_date = val;
    },
    handleSubmit() {
      this.isFormValid().then((valid) => {
        if (valid) {
          this.form.total = this.form.amount;
          this.form.index = this.index;

          this.$store.commit("navigator/setParam", { contractItems: this.form });
          this.$store.commit("navigator/pop");
        }
      });
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.name.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.item.goods"), options);
        return false;
      }
      if (this.$v.form.start_date.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.item.date"), options);
        return false;
      }
      if (this.$v.form.amount.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.item.amount"), options);
        return false;
      }

      return true;
    }
  }
};
</script>

<style></style>
