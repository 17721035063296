import request from "@/utils/request";

export function fetchCompanies(params) {
  return request({
    url: "/companies/bank_accounts",
    method: "get",
    params: params
  });
}

export function fetchList(params) {
  return request({
    url: "/company_bank_accounts",
    method: "get",
    params: params
  });
}

export function fetchDetail(id) {
  return request({
    url: "/company_bank_accounts/" + id,
    method: "get"
  });
}

export function fetchListByCompany(companyId, params) {
  return request({
    url: "/company_bank_accounts_list/" + companyId,
    method: "get",
    params: params
  });
}

export function submit(data) {
  return request({
    url: "/buffer/company-bank-account",
    method: "post",
    data: data
  });
}

export function submitEdit(id, data) {
  return request({
    url: "/buffer/company-bank-account/" + id + "/edit",
    method: "post",
    data: data
  });
}

export function submitDelete(id, data) {
  return request({
    url: "/buffer/company-bank-account/" + id + "/delete",
    method: "post",
    data: data
  });
}

export function fetchCompanyBankDetail(id) {
  return request({
    url: `/company_bank_accounts/${id}`,
    method: "get"
  });
}
