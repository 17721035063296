<template>
  <Modal :showing="isVisible" :cancelable="false" @on-close="$emit('close')" @on-confirm="handleConfirm">
    <ons-list v-show="currency" class="pl-0">
      <div v-for="(item, key) in item" :key="key" class="p-2" :class="{ 'bg-gray-100 rounded-lg': selected && selected.index === item.index }">
        <form-select
          :label="$t('app.pay.payment') + ' (' + item.index + ')'"
          @click="handleSelect(item)"
          :value="(currency ? currency.code : '') + ' ' + item.total"
        />
      </div>
    </ons-list>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
export default {
  name: "FileLinkSelection",
  components: { Modal },
  props: {
    show: Boolean,
    item: Array,
    currency: Object
  },
  data() {
    return {
      text: "",
      selected: null
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.show;
      },
      set(bool) {
        this.$emit("close", bool);
      }
    }
  },
  methods: {
    handleSelect(value) {
      this.selected = value;
    },
    handleConfirm() {
      this.$emit("confirm", this.selected);
    }
  }
};
</script>

<style scoped></style>
