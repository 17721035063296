<template>
  <v-ons-page @show="handleShow" @init="handleInit">
    <custom-toolbar v-bind="toolbarInfo">
      <v-ons-toolbar-button slot="right" id="info-button" v-if="activeTab == 0" @click="$refs.form.handleReset()">
        <span class="mr-2 text-black">{{ $t("common.reset") }}</span>
      </v-ons-toolbar-button>
    </custom-toolbar>
    <NavTop :tabs="defaultTab" @change-tab="handleChangeTab" />

    <div class="pt-6 mb-28">
      <component
        :is="module"
        ref="form"
        v-show="activeTab === 0"
        :show="form.show"
        :init="form.init"
        :param="toolbarInfo.param"
        @reloaded="form.show = false"
        @initialized="form.init = false"
      />
      <application-data v-show="activeTab === 1" :load="data.load" :module="module" @loaded="data.load = false" />
    </div>
  </v-ons-page>
</template>

<script>
import NavTop from "@/components/NavTop";
import reimbursement from "@/views/Application/Reimbursement/ReimbursementForm";
import payment from "@/views/Application/Payment/PaymentForm";
import purchase from "@/views/Application/Purchase/PurchaseForm";
import supplier from "@/views/Application/SupplierContract/SupplierForm";
import customer from "@/views/Application/CustomerContract/customerContractForm";
import seal from "@/views/Application/CorporateSeal/CorporateSealForm";
import debt from "@/views/Application/CollectionReport/debt/debtForm";
import report from "@/views/Application/CollectionReport/report/reportForm";
import sales from "@/views/Application/CollectionReport/sales/salesForm";
import ApplicationData from "@/views/Application/component/ApplicationData";

import { fetchAll as fetchExpenses } from "@/api/expense_account";

export default {
  name: "Application",
  components: {
    NavTop,
    reimbursement,
    payment,
    purchase,
    "contract-supplier": supplier,
    "contract-customer": customer,
    "corporate-seal": seal,
    "collection-debt": debt,
    "collection-report": report,
    "collection-sales": sales,
    ApplicationData
  },
  data() {
    return {
      activeTab: 0,
      form: {
        show: false,
        init: false,
        load: false
      },
      data: {
        show: false,
        init: false,
        load: false
      }
    };
  },
  computed: {
    defaultTab() {
      return [this.$t("common.submit"), this.$t("common.view_data")];
    },
    module() {
      return this.toolbarInfo.param ? this.toolbarInfo.param.module : "";
    }
  },
  methods: {
    handleShow() {
      if (this.$store.getters.isSubmit) {
        //reload page
        this.data.load = true;
        this.$store.commit("navigator/resetSubmit");
      }
      this.activeTab === 0 ? (this.form.show = true) : (this.data.show = true);
    },
    handleInit() {
      this.activeTab === 0 ? (this.form.init = true) : (this.data.init = true);
      this.handleGetContentType();
    },
    handleChangeTab(index) {
      this.activeTab = index;
      this.activeTab === 0 ? (this.form.load = true) : (this.data.load = true);
    },
    //to get all user expenses
    async handleGetContentType() {
      let expenses = this.$store.getters.expenses;
      let datalang = this.$store.getters.datalang;

      if (expenses.length > 0 && datalang == this.$i18n.locale) {
        return;
      } else {
        let res = await fetchExpenses({
          moduleCode: "reimbursement"
          // companyId: this.$store.getters.profile.company.id
        });
        this.$store.commit("user/SET_EXPENSES", res["hydra:member"]);
        this.$store.commit("user/SET_DATALANG", this.$i18n.locale);
      }
    }
  }
};
</script>

<style scoped></style>
