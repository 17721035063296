import Vue from "vue";

Vue.component("Icon", require("@/components/Icon").default);
Vue.component("Logo", require("@/components/Logo").default);
Vue.component("Uploading", require("@/components/Uploading").default);
Vue.component("Loading", require("@/components/Loading").default);
Vue.component("LoadingSmall", require("@/components/LoadingSmall").default);
Vue.component("BottomSubmit", require("@/components/BottomSubmit").default);

Vue.component("btn-default", require("@/components/button/default").default);

Vue.component("d-text", require("@/components/detail/d-text").default);
Vue.component("d-process", require("@/components/detail/d-process").default);
Vue.component("d-image", require("@/components/detail/d-image").default);
Vue.component("d-list", require("@/components/detail/d-list").default);
Vue.component("d-items", require("@/components/detail/d-items").default);
Vue.component("d-subtitle", require("@/components/detail/d-subtitle").default);
Vue.component("d-item-content", require("@/components/detail/d-item-content").default);
Vue.component("d-item-related", require("@/components/detail/d-item-related").default);
Vue.component("d-info", require("@/components/detail/d-info").default);
Vue.component("d-highlight", require("@/components/detail/d-highlight").default);
Vue.component("d-total", require("@/components/detail/d-total").default);

Vue.component("l-file", require("@/components/list/list-file").default);

Vue.component("user-circle", require("@/components/UserCircle").default);
Vue.component("form-button", require("@/components/form/form-button").default);
Vue.component("form-select", require("@/components/form/form-select").default);
Vue.component("form-select-user", require("@/components/form/form-select-user").default);
Vue.component("form-select-remove", require("@/components/form/form-select-remove").default);
Vue.component("form-upload", require("@/components/form/form-upload").default);
Vue.component("form-uploader", require("@/components/form/form-uploader").default);
Vue.component("form-input", require("@/components/form/form-input").default);
Vue.component("form-input-search", require("@/components/form/form-input-search").default);
Vue.component("form-input-mask", require("@/components/form/form-input-mask").default);
Vue.component("form-calendar", require("@/components/form/form-calendar").default);
Vue.component("form-select-icon", require("@/components/form/form-select-icon").default);
Vue.component("form-textarea", require("@/components/form/form-textarea").default);
Vue.component("form-multipleselect", require("@/components/form/form-multipleselect").default);
