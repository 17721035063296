<template>
  <v-ons-page @init="handleInit">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <search-filter @on-search="handleSearch" @on-reset="handleReset" />

    <loading-small v-show="loading" />
    <v-ons-list v-show="!loading" class="mt-14">
      <form-select-icon
        v-for="(item, key) in list"
        :key="key"
        :label="item.name"
        :sublabel="item.module.name ? item.module.name : ''"
        icon="circle"
        iconClass="text-gray-300"
        modifier="none"
        @tap="handleSelectAccount(item)"
      />
    </v-ons-list>
  </v-ons-page>
</template>

<script>
import SearchFilter from "@/components/SearchFilter";
import { fetchAll } from "@/api/expense_account";

const defaultFilter = { name: "", companyId: null };
export default {
  name: "ExpenseAccountList",
  components: { SearchFilter },
  data() {
    return {
      loading: true,
      list: [],
      filter: Object.assign({}, defaultFilter)
    };
  },
  methods: {
    handleInit() {
      if (this.toolbarInfo.param && this.toolbarInfo.param.company) {
        this.filter.companyId = this.toolbarInfo.param.company;
        this.getData();
      } else {
        this.loading = false;
      }
    },
    getData() {
      fetchAll(this.filter).then((response) => {
        this.list = response["hydra:member"];
        this.loading = false;
      });
    },
    handleSelectAccount(item) {
      this.$store.commit("navigator/setParam", { selectedAccount: item });
      this.$store.commit("navigator/pop");
    },
    handleSearch(filter) {
      this.filter.name = filter.term;
      this.getData();
    },
    handleReset() {
      this.filter.name = null;
      this.getData();
    }
  }
};
</script>

<style></style>
