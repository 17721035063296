<template>
  <div class="space-y-6 mb-16">
    <v-ons-list>
      <form-input kind="text" :label="$t('app.sc.item.name')" :placeholder="$t('app.sc.item.enter_name')" :required="true" v-model="form.name" />

      <form-input
        :label="$t('app.sc.item.type_brand')"
        :placeholder="$t('app.sc.item.enter_type_brand')"
        kind="text"
        :required="true"
        v-model="form.brand"
      />
    </v-ons-list>

    <v-ons-list>
      <form-input
        :label="$t('app.sc.item.quantity')"
        :placeholder="$t('app.sc.item.enter_quantity')"
        :required="true"
        v-model.number="form.quantity"
      />

      <v-ons-list-item>
        <div class="list-item__title required">
          <span class="ml-1">{{ $t("app.sc.item.budget_tax") }}</span>
        </div>
        <div class="list-item__subtitle">
          <div class="flex items-center">
            <span class="mr-4 text-orange-500"> ({{ currency ? currency.code : null }}) </span>

            <v-ons-input v-model.number="form.amount" type="number" float maxlength="20" :placeholder="$t('app.sc.item.enter_budget')" />
          </div>
        </div>
      </v-ons-list-item>
      <v-ons-list-item>
        <span class="list-item__title required">
          {{ $t("app.sc.item.unit_price") }}
        </span>
        <span class="list-item__subtitle">
          <div class="flex items-center">
            <span class="mr-4 text-orange-500"> ({{ currency ? currency.code : null }}) </span>

            <v-ons-input v-model.number="form.unit_price" type="number" float :placeholder="$t('app.sc.item.unit_price')" />
          </div>
        </span>
      </v-ons-list-item>

      <form-input :label="$t('app.sc.frequency')" :placeholder="$t('app.sc.select_frequency')" :required="true" v-model.number="form.frequency" />
    </v-ons-list>

    <v-ons-list>
      <form-textarea :label="$t('common.notes')" :placeholder="$t('common.enter_notes')" v-model="form.note" />
    </v-ons-list>

    <bottom-submit :label="$t('common.next')" @click.native="handleSubmit" classes="bg-blue-500" />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "goods",
  props: ["param"],
  data() {
    return {
      form: {
        name: null,
        brand: null,
        quantity: null,
        amount: null,
        unit_price: null,
        frequency: null,
        note: ""
      }
    };
  },
  computed: {
    currency() {
      return this.param.currencyObj ? this.param.currencyObj : "-";
    },
    index() {
      return this.param.item ? this.param.item.index : null;
    }
  },
  created() {
    if (this.param.item.amount > 0 || this.param.item.total > 0) {
      this.form = Object.assign({}, this.param.item);
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      brand: {
        required
      },
      quantity: {
        required
      },
      amount: {
        required
      },
      unit_price: {
        required
      },
      frequency: {
        required
      }
    }
  },
  methods: {
    handleSubmit() {
      this.isFormValid().then((valid) => {
        if (valid) {
          this.form.total = this.form.quantity * this.form.unit_price;
          this.form.index = this.index;

          this.$store.commit("navigator/setParam", { contractItems: this.form });
          this.$store.commit("navigator/pop");
        }
      });
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.name.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.item.goods"), options);
        return false;
      }
      if (this.$v.form.brand.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.item.type"), options);
        return false;
      }
      if (this.$v.form.quantity.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.item.quantity"), options);
        return false;
      }
      if (this.$v.form.amount.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.item.amount"), options);
        return false;
      }
      if (this.$v.form.unit_price.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.item.unit"), options);
        return false;
      }
      if (this.$v.form.frequency.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.item.frequency"), options);
        return false;
      }

      return true;
    }
  }
};
</script>

<style></style>
