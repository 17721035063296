export const stat = {
  select_one: "Please select one below",
  projection: "Payment projection",
  projection_info: "Payment projection statistic",
  invoice: "Invoice management",
  invoice_info: "Invoice management setting",
  bank: "Company bank account",
  bank_info: "Company bank account setting",
  expense: "Expense Account Management",
  expense_info: "Expense account setting",
  export: "Export",
  export_info: "Export payment/reciept report/statistic",
  document_search: "Application & File Search",
  register: "Register",

  proj: {
    filter: "Filter",
    passed: "Passed",
    today: "Today",
    upto: "Up to today",
    one: "Tomorrow +1 Week",
    two: "Within 8-14 days",
    three: "Within 15-30 days",
    pending: "Pending payment"
  },
  inv: {
    reimbursement: "Reimbursement",
    payment: "Payment",
    info: "Unobtained invoice list",
    selected: "Selected",
    items: "item(s)",
    select: "Please select",
    ok: "OK",
    upload: "Upload",
    einvoice: "E-Invoices",
    einvoice_info: "You can upload up to 10 e-invoices",
    paper: "Payment notice etc",
    paper_info: "You can upload up to 10 payment notices",
    alert: {
      upload: "Please upload e-invoice or application paper"
    }
  },
  bank_: {
    account: "Company bank account",
    name: "Bank name",
    enter_name: "Enter bank name",
    branch: "Branch",
    enter_branch: "Enter branch",
    no: "Account no",
    enter_no: "Enter account no.",
    type: "Type",
    enter_type: "Enter type",
    alert: {
      name: "Please enter bank name",
      branch: "Please enter branch",
      no: "Please enter account no.",
      type: "Please enter type"
    }
  },
  expense_: {
    account: "Expense account",
    reimbursement: "Reimbursement",
    payment: "Payment",
    create: "Create expense account",
    edit: "Edit expense account",
    delete: "Delete expense account",
    name: "Name",
    name_en: "Name (EN)",
    name_jp: "Name (JP)",
    name_zh: "Name (ZH)",
    enter_name: "Enter expense type name",
    transport: "Transportation category",
    transport_info: "Check if a tranportation type",
    alert: {
      name_en: "Please enter expense type name (EN)",
      name_jp: "Please enter expense type name (JP)",
      name_zh: "Please enter expense type name (ZH)"
    }
  },
  export_: {
    export: "Export",
    type: "Export type",
    select_type: "Select type",
    file_type: "File Type",
    select_file_type: "File file type",
    period: "Period",
    select_period: "Select period",
    alert: {
      type: "Please select type",
      period: "Please select period"
    },
    date_type: "Date type",
    date_type_: {
      completion: "Completion Date",
      payment: "Payment Date"
    }
  },
  search_: {
    desc: "Search for application or document",
    app: "Application",
    doc: "File",
    change: "Change"
  }
};
