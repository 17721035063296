import request from "@/utils/request";

export function fetchList(params) {
  return request({
    url: "/invoice_managements",
    method: "get",
    params: params
  });
}

export function upload(data) {
  return request({
    url: "/invoice_managements",
    method: "post",
    data: data
  });
}

export function approve(data) {
  return request({
    url: "/invoice_management_approve",
    method: "post",
    data: data
  });
}

export function register(data) {
  return request({
    url: "/invoice_management_show",
    method: "post",
    data: data
  });
}
