<template>
  <ons-list-item>
    <div class="left" v-if="icon">
      <div class="approval-icon bg-transparent">
        <Icon :name="icon" :classes="iconClass" :size="iconSize" />
      </div>
    </div>
    <div class="center py-3">
      <div class="list-item__title">
        <div class="text-xs text-gray-500">{{ label }}</div>
      </div>
      <div class="list-item__subtitle">
        <div class="text-base text-black">{{ sublabel }}</div>
      </div>
    </div>
  </ons-list-item>
</template>

<script>
export default {
  name: "d-info",
  props: {
    icon: {
      type: String,
      default: null
    },
    size: {
      type: Number,
      default: 18
    },
    classes: {
      type: String,
      default: ""
    },
    label: {
      type: String
    },
    sublabel: {
      type: String
    }
  }
};
</script>

<style></style>
