<template>
  <div class="space-y-2">
    <d-text :label="$t('app.sc.item.name')" :value="item.name" />
    <d-text :label="$t('app.sc.item.type_brand')" :value="item.brand" />
    <hr />
    <d-text :label="$t('app.sc.item.quantity')" :value="item.quantity.toString()" />
    <d-text :label="$t('app.sc.item.budget_tax')" :value="budget" />
    <d-text :label="$t('app.sc.item.unit_price')" :value="unitPrice" />
    <d-text :label="$t('app.sc.frequency')" :value="item.frequency.toString()" />
    <hr />
    <d-text :label="$t('common.notes')" :value="item.note" />
  </div>
</template>

<script>
import { Round } from "../../../../utils/number";

export default {
  name: "SupplierContractItemGoods",
  props: ["data"],
  computed: {
    item() {
      return this.data.item;
    },
    currency() {
      return this.data.currency;
    },
    budget() {
      return this.currency + " " + (this.item.amount ? Round(this.item.amount, this.currency) : "-");
    },
    unitPrice() {
      return this.currency + " " + (this.item.unit_price ? Round(this.item.unit_price, this.currency) : "-");
    }
  },
  methods: {
    handleInit() {}
  }
};
</script>

<style></style>
