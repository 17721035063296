export const home = {
  header: {
    application: "アプリ"
  },
  menu: {
    reimbursements: "精算申請",
    payment: "支払申請",
    collection_report: "債権回収",
    purchase: "購買申請",
    supplier_contract: "仕入・ベンダー契約申請",
    customer_contract: "顧客契約申請",
    corporate_seal: "証書・捺印申請",
    stamp_statistic: "捺印統計",
    sales: "Sales",
    sales_registration: "Sales/custom info registration"
  },
  top_menu: {
    pending: "承認待ち",
    done: "承認済み",
    initiated: "申請済",
    forwarded: "転送受取"
  },
  navigation: {
    home: "ホーム",
    statistic: "財務統計",
    admin: "ユーザー管理"
  }
};
