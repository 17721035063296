<template>
  <div class="px-2 py-1 space-y-2">
    <div class="bg-white px-3 py-2 rounded-md shadow-sm text-left">
      <div class="mb-2 flex justify-between space-y-2 items-center">
        <div>
          <span :class="'capitalize mr-2 ' + color"> {{ title }}</span>
          <span class="text-blue-500 text-sm">{{ code }}</span>
        </div>
        <div>
          <Icon name="chevron-right" class="text-gray-400" />
        </div>
      </div>
      <div class="leading-6 text-xs">
        <div v-for="(item, key) in datas" :key="key" class="flex justify-between" :class="{ 'border-b border-gray-100': key != dataCount - 1 }">
          <div class="text-gray-400 truncate">
            {{ item.key }}
          </div>
          <div class="w-2/3 truncate text-right">{{ item.value }}</div>
        </div>
      </div>
      <div class="mt-2 flex justify-between">
        <div class="approval-user-info">
          <div class="thumb p-0">
            {{ applicant.name.charAt(0) }}
          </div>
          <span class="text-sm">{{ applicant.name }}</span>
        </div>

        <status-badge :status="process.status" :process="processName" />
      </div>
    </div>
  </div>
</template>

<script>
import StatusBadge from "@/components/StatusBadge";
export default {
  name: "CardList",
  components: { StatusBadge },
  props: {
    code: {
      Type: String
    },
    process: {
      Type: Object
    },
    datas: {
      Type: Array
    },
    title: {
      Type: String
    },
    applicant: {
      Type: Object
    },
    color: {
      Type: String,
      default: ""
    },
    activeStatus: {
      Type: String
    }
  },
  computed: {
    dataCount: function() {
      return Object.keys(this.datas).length;
    },
    processName: function() {
      if (this.activeStatus) {
        return this.activeStatus;
      }

      return null;
    }
  }
};
</script>

<style></style>
