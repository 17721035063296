<template>
  <div>
    <ons-list>
      <v-ons-list-header />
      <form-select
        :label="$t('common.currency')"
        modifier="quiet"
        :required="true"
        :placeholder="$t('common.select_currency')"
        :value="currencyCode"
      />
      <form-select
        :required="true"
        :label="$t('common.company')"
        modifier="quiet"
        :placeholder="$t('common.select_company')"
        :value="local.company ? local.company.name : null"
      />
      <form-select
        :required="true"
        :label="$t('common.department')"
        modifier="quiet"
        :placeholder="$t('common.select_department')"
        :value="local.department ? local.department.name : null"
      />
      <form-select
        :required="true"
        :label="$t('app.pay.estimated_payment_date')"
        modifier="quiet"
        :placeholder="$t('common.select')"
        :value="local.estimatedPaymentDate ? moment(local.estimatedPaymentDate).format('YYYY-MM-DD') : ''"
      />
      <form-select
        :label="$t('common.related_approval')"
        modifier="quiet"
        :placeholder="$t('common.select')"
        :value="local.relatedApproval ? local.relatedApproval.code : null"
      />
    </ons-list>

    <ons-list class="mt-6">
      <v-ons-list-header> {{ $t("app.pay.payment") }} </v-ons-list-header>
      <form-select
        v-for="(item, key) in local.items"
        :key="key"
        :label="$t('app.pay.payment') + ' (' + ++key + ')'"
        @click="HandleDetailClick(item)"
        :value="currencyCode + ' ' + Round(item.total, currencyCode)"
      />

      <v-ons-list-item>
        <div class="center" style="font-size: 13px; color: rgba(0, 0, 0, 0.6)">
          {{ $t("app.pay.add_more_info") }}
        </div>
      </v-ons-list-item>
    </ons-list>
    <v-ons-list>
      <v-ons-list-item class="p-0">
        <div class="center flex justify-center items-center p-0">
          <v-ons-button modifier="quiet" style="justify-content: center" @click="handleAddItem">
            <ons-icon icon="md-plus"> </ons-icon>
            {{ $t("common.add_more") }}
          </v-ons-button>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <br />
    <v-ons-list>
      <v-ons-list-item>
        <div class="left">{{ $t("common.total") }}</div>
        <div class="center" style="margin-left: 10px">
          {{ currencyCode }}
          <span class="text-orange ml-10">
            {{ Round(formObj.total, currencyCode) }}
          </span>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <br />
    <div style="color: #828282; padding: 5px 15px; text-align: left">
      <span style="font-size: 12px">
        {{ $t("app.pay.e_invoice_info", { number: param.title }) }}
      </span>
    </div>

    <ons-list>
      <form-uploader
        :label="$t('app.pay.e_invoice')"
        :sublabel="$t('app.pay.e_invoice_sublabel')"
        :multiple="true"
        :documentType="FILE_TYPE.INVOICE"
        :existing="formObj.e_invoices"
        :canDelete="false"
        @on-success="onUpload($event, FILE_TYPE.INVOICE)"
      />
    </ons-list>

    <ons-list>
      <form-uploader
        :label="$t('app.pay.application_paper')"
        :sublabel="$t('app.pay.application_paper_sublabel')"
        :multiple="true"
        :documentType="FILE_TYPE.APP_PAPER"
        :existing="formObj.application_papers"
        :canDelete="false"
        @on-success="onUpload($event, FILE_TYPE.APP_PAPER)"
      />
    </ons-list>
    <br />
    <v-ons-list>
      <v-ons-list-header> {{ $t("app.invoice_later") }} </v-ons-list-header>
      <form-input :placeholder="$t('common.enter_amount')" v-model.number="formObj.invoice_amount" />
      <form-textarea :label="$t('app.pay.supplier_name')" :placeholder="$t('app.pay.enter_supplier_name')" v-model="formObj.invoice_note" />
    </v-ons-list>

    <!-- hide input by treasury in application -->
    <br />
    <v-ons-list>
      <v-ons-list-header> {{ $t("app.input_by_treasury") }} </v-ons-list-header>
      <form-select
        :label="$t('app.payment_account')"
        :placeholder="$t('app.select_payment_account')"
        @click="push(page[0].component, page[0].label, { company: local.company.id })"
        :value="formObj.account_id ? formObj.account_id.bank_name + ' - ' + formObj.account_id.branch : null"
      />

      <form-calendar
        :label="$t('app.payment_date')"
        mode="single"
        :placeholder="$t('common.select')"
        @on-change="handleDateSelect('payment_date', $event)"
        :value="formObj.payment_date ? moment(formObj.payment_date).format('YYYY-MM-DD') : ''"
      />

      <form-uploader
        :label="$t('app.deal_slip')"
        :sublabel="$t('app.deal_slip_sublabel')"
        :multiple="true"
        :documentType="FILE_TYPE.DEAL_SLIP"
        :existing="formObj.deal_slips"
        :canDelete="false"
        @on-success="onUpload($event, FILE_TYPE.DEAL_SLIP)"
      />
    </v-ons-list>
    <br />
    <v-ons-card class="flat p-0">
      <v-ons-list>
        <v-ons-list-header>
          {{ $t("app.pay.tax") }}
        </v-ons-list-header>
        <form-textarea :label="$t('app.pay.enter_tax')" :placeholder="$t('common.enter_notes')" v-model="formObj.note" />
      </v-ons-list>
    </v-ons-card>

    <br />
    <v-ons-list>
      <form-select-user :label="$t('app.forward_document')" :users="formObj.user_finances ? formObj.user_finances : []" :editable="false" />
    </v-ons-list>

    <bottom-submit @click.native="handleSubmit" />
  </div>
</template>

<script>
import _ from "lodash";
import ApplicationEdit from "@/mixins/application_edit";
//api
import { fetchCompanyDetail as fetch_company } from "@/api/company";
import { fetchDepartmentDetail as fetch_department } from "@/api/department";
import { fetchRelatedContractDetail as fetch_related_approval } from "@/api/related_approval";
//helper
import { getRelatedApproval as fetch_appoval_item } from "@/utils/related";
//comps
import BankAccountList from "@/views/common/list/BankAccountList";

export default {
  name: "PaymentEdit",
  mixins: [ApplicationEdit],
  data() {
    return {
      local: {
        moduleCode: "payment",
        items: [],
        company: null,
        department: null,
        relatedApproval: null,
        relatedApprovalItem: null
      }
    };
  },
  computed: {
    page() {
      return [
        {
          component: BankAccountList,
          label: this.$t("common.select")
        }
      ];
    },
    currencyCode() {
      return this.formObj.currency_id ? this.formObj.currency_id.code : null;
    }
  },
  watch: {
    formObj: {
      deep: true,
      handler(n) {
        //set form
        let form = {
          transaction_items: n.transaction_items,
          currency_id: n.currency_id ? n.currency_id.id : null,
          total: n.total,
          note: n.note,
          e_invoices: n.e_invoices.length > 0 ? n.e_invoices.map((item) => item.id) : [],
          application_papers: n.application_papers.length > 0 ? n.application_papers.map((item) => item.id) : [],
          invoice_amount: n.invoice_amount ? parseInt(n.invoice_amount) : 0,
          invoice_note: n.invoice_note,
          account_id: n.account_id ? n.account_id.id : null,
          payment_date: n.payment_date,
          deal_slips: n.deal_slips.length > 0 ? n.deal_slips.map((item) => item.id) : [],
          user_finances: n.user_finances.length > 0 ? n.user_finances.map((item) => item.id) : []
        };
        this.form = Object.assign({}, form);
      }
    },
    "local.items": function(n) {
      if (n) {
        this.formObj.total = n.reduce((sum, obj) => sum + obj.total, 0);
      }
    }
  },
  methods: {
    setDefaultLocalData() {
      this.formObj.transaction_items = this.formObj.transaction_items.map((item, key) => ({
        ...item,
        total: this.calculateItemTotal(item.contents),
        index: key
      }));

      this.defaultForm = _.cloneDeep(this.formObj);
      this.local.items = this.formObj.transaction_items;

      if (this.local.items.length > 0) {
        if (this.local.items[0].company_id) {
          fetch_company(this.local.items[0].company_id).then((r) => (this.local.company = r));
        }
        if (this.local.items[0].department_id) {
          fetch_department(this.local.items[0].department_id).then((r) => (this.local.department = r));
        }
        if (this.local.items[0].payment_information.estimated_payment_date) {
          this.local.estimatedPaymentDate = this.local.items[0].payment_information.estimated_payment_date;
        }

        if (this.local.items[0].related_approval_id) {
          fetch_related_approval(this.local.items[0].related_approval_id)
            .then((r) => (this.local.relatedApproval = r))
            .then(async (r) => {
              this.local.relatedApprovalItem = await fetch_appoval_item(this.local.moduleCode, r.id);
            });
        }
      }
    },
    calculateItemTotal(arr) {
      if (!arr) {
        return 0;
      }

      let total = 0;
      arr.forEach((item) => {
        total = total + item.amount;
      });
      return total;
    },
    onUpload(file, type) {
      if (type == this.FILE_TYPE.DEAL_SLIP) {
        this.formObj.deal_slips = file;
      }
      if (type == this.FILE_TYPE.APP_PAPER) {
        this.formObj.application_papers = file;
      }
      if (type == this.FILE_TYPE.INVOICE) {
        this.formObj.e_invoices = file;
      }
    },
    handleShow() {
      let param = this.$store.getters["navigator/getParam"];
      let paymentDetail = param?.paymentDetail;
      let selectedAccount = param.selectedAccount;

      if (selectedAccount) {
        this.formObj.account_id = selectedAccount;
      }

      if (paymentDetail) {
        this.local.items = this.local.items.map((obj) => {
          if (obj.index === paymentDetail.index) {
            return { ...paymentDetail };
          } else {
            return obj;
          }
        });

        this.formObj.transaction_items = this.local.items.filter((item) => item.total > 0);
      }
    },
    handleAddItem() {
      this.local.items = [
        ...this.local.items,
        {
          index: this.local.items.length,
          total: 0,
          company_id: this.local.company.id,
          department_id: this.local.department.id,
          related_approval_id: this.local.relatedApproval ? this.local.relatedApproval.id : null,
          related_items: this.local.relatedApprovalItem,
          estimated_payment_date: this.local.estimatedPaymentDate
        }
      ];
    }
  }
};
</script>

<style></style>
