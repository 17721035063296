<template>
  <bottom-sheet-action :showing="show" :title="title" @on-close="$emit('cancel')" @on-save="handleSave">
    <form-input
      kind="text"
      :required="true"
      :label="$t('app.sc.item.content')"
      :placeholder="$t('app.sc.item.enter_content')"
      v-model="form.content"
    />
    <form-input
      :required="true"
      :label="$t('app.sc.item.unit_price')"
      :placeholder="$t('app.sc.item.enter_unit_price')"
      v-model.number="form.unit_price"
    />
  </bottom-sheet-action>
</template>

<script>
import BottomSheetAction from "@/components/BottomSheetAction";
import { required } from "vuelidate/lib/validators";

const defaultForm = { content: "", unit_price: null };
export default {
  name: "RelatedGoodsForm",
  props: ["data", "title", "show"],
  components: { BottomSheetAction },
  data() {
    return {
      form: Object.assign({}, defaultForm)
    };
  },
  watch: {
    data(n) {
      this.form = Object.assign({}, n);
    }
  },
  validations: {
    form: {
      content: {
        required
      },
      unit_price: {
        required
      }
    }
  },
  methods: {
    async validate() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.content.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.item.content"), options);
        return false;
      }
      if (this.$v.form.unit_price.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.item.unit"), options);
        return false;
      }

      return true;
    },
    handleSave() {
      this.validate().then((valid) => {
        if (valid) {
          this.$emit("save", this.form);
        }
      });
    }
  }
};
</script>

<style></style>
