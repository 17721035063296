<template>
  <div class="my-3 py-4 bg-blue-900">
    <div class=" flex items-center px-4 text-white">
      <div>
        <Icon name="info" classes="h-6 w-6" />
      </div>
      <div class="flex ml-4 text-base">
        <div>{{ label }}</div>
        <div class="ml-2 font-bold" :class="{ 'border-b-2 border-red-500': history }">{{ value }}</div>
      </div>
    </div>
    <div v-if="history" class="text-xs flex px-14 text-gray-300">
      <div class="rounded flex items-center ">
        <Icon name="edit" classes="text-gray-300 mr-1" :size="12" />
        <span class="mr-1">{{ history.user.name }}, </span>
        <span class="italic">{{ history.date ? moment(history.date).format("YYYY/MM/DD") : "" }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "d-highlight",
  props: {
    label: {
      type: String
    },
    value: {
      type: [String, Number]
    },
    history: {
      type: [Object, String]
    }
  }
};
</script>

<style scoped></style>
