import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueOnsen from "vue-onsenui";
import Calendar from "vue-mobile-calendar";
import Photoswipe from "vue-pswipe";

import WeuiDesignVue from "weui-design-vue";
import "weui-design-vue/lib/weui-design-vue.css";

import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
import "./assets/css/custom.css";
// import "./assets/css/control.css";
import "./assets/css/uploader.css";
import "./assets/css/style.css";
import "./assets/css/weui.css";
import "./assets/css/tailwind.css";
import "./assets/css/global.css";

import CustomToolbar from "./components/CustomToolbar.vue";
import Vuelidate from "vuelidate";
import moment from "moment";
import i18n from "./locale";
import InfiniteLoading from "vue-infinite-loading";

Vue.use(require("vue-moment"));
Vue.use(Vuelidate);
Vue.use(require("vue-inputmask").default);
// import AppNavigator from './AppNavigator.vue';

Vue.use(WeuiDesignVue);

//local component
require("./components");
require("dotenv").config();

Vue.use(VueOnsen);
Vue.use(Calendar);
Vue.use(Photoswipe);
Vue.use(InfiniteLoading, {
  props: {
    spinner: "spiral"
  },
  slots: {
    noMore: "",
    noResults: ""
  }
});

Vue.config.productionTip = false;
Vue.component("custom-toolbar", CustomToolbar); // Common toolbar

Vue.prototype.moment = moment;

new Vue({
  i18n,
  router,
  store,
  beforeCreate() {
    Vue.prototype.md = this.$ons.platform.isAndroid();

    // if (window.location.search.match(/iphonex/i)) {
    //   document.documentElement.setAttribute("onsflag-iphonex-portrait", "");
    //   document.documentElement.setAttribute("onsflag-iphonex-landscape", "");
    // }
    this.$ons.disableAutoStyling();
  },
  render: (h) => h(App)
}).$mount("#app");
