import axios from "axios";
import store from "../store";
import { getToken } from "@/utils/auth";
import i18n from "../locale";
import Login from "@/views/Security/Login";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 360000
});

//request
service.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers = {
        Accept: "application/json, text/plain, */*",
        Authorization: getToken(),
        "X-Locale": i18n.locale
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//response
service.interceptors.response.use(
  (response) => {
    if (response.status > 204) {
      console.log("response.status");
      return Promise.reject("error");
    } else {
      return response.data;
    }
  },
  (error) => {
    if (error.response.status === 401) {
      let res = error.response.data;
      store.commit("user/SET_ERROR", res);
      store.dispatch("user/LogOut").then(() => {
        store.commit("navigator/reset", Login);
      });
    } else {
      window.$vm.$ons.notification.toast(error.response.data.message, { timeout: 3000 });
    }
    return Promise.reject(error);
  }
);

export default service;
