<template>
  <v-ons-card v-if="data">
    <div class="content p-2 space-y-2">
      <d-text :label="$t('common.app_code')" :value="data.code" />
      <d-text :label="$t('app.col.related')" :value="relatedApproval ? relatedApproval.stamp_no : '-'" />
      <d-text :label="$t('common.company')" :value="company" />
      <d-text :label="$t('common.department')" :value="department" />
      <d-text :label="$t('app.col.customer')" :value="data.data.customer_name" />
      <hr />
      <d-text :label="$t('app.col.report.collected')" :value="currency + ' ' + Round(data.data.amount, currency)" />
      <d-text
        :label="$t('app.col.report.reciept')"
        :value="bankAccountObj ? bankAccountObj.bank_name + ' ' + bankAccountObj.branch + ' / ' + bankAccountObj.type : '-'"
      />
      <d-text :label="$t('app.col.report.reciept_date')" :value="obj.receipt_date ? moment(obj.receipt_date).format('YYYY/MM/DD') : '-'" />
      <hr />
      <form-uploader classes="p-0" :label="$t('app.deal_slip')" :existing="dealSlips" :disabled="true" />
      <d-text :label="$t('common.notes')" :value="obj.notes" />
      <weui-gallery :visible="previewShow" :src="previewImg" :canDelete="false" @onClick="previewShow = false"></weui-gallery>
      <hr />
      <d-list :label="$t('app.forward_finance')" :items="finances" itemKey="name" />
    </div>
  </v-ons-card>
</template>

<script>
import { fetchDetail as getFile } from "@/api/file";
import { fetchRelatedContractDetail } from "@/api/related_approval";
import buffer from "@/mixins/buffer";
import { fetchUserDetail as getUser } from "@/api/user";
import { Round } from "../../../../utils/number";

export default {
  name: "CollectionReport",
  props: ["data"],
  mixins: [buffer],
  data() {
    return {
      dealSlips: [],
      relatedApproval: null,
      finances: []
    };
  },
  computed: {
    obj() {
      return this.data.data;
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(n, o) {
        if (n != o) {
          this.fetchCurrency(this.obj.currency_id);
          this.fetchCompany(this.obj.company_id);
          this.fetchDepartment(this.obj.department_id);
          this.fetchBankAccount(this.obj.receipt_account_id);

          this.fetchDealSlips();
          this.fetchContractDetail(this.obj.related_approval_id);
          if (this.obj.user_finances) {
            this.obj.user_finances.forEach((user) => {
              this.fetchUserDetail(user);
            });
          }
        }
      }
    }
  },
  methods: {
    Round,
    fetchDealSlips() {
      this.contractPlanFiles = [];
      this.obj.deal_slip_files.forEach((item) => {
        getFile(item).then((f) => {
          this.dealSlips.push({ ...f, size: f.size_string });
        });
      });
    },
    fetchContractDetail(id) {
      fetchRelatedContractDetail(id).then((response) => {
        this.relatedApproval = response;
      });
    },
    fetchUserDetail(id) {
      this.finances = [];
      getUser(id).then((response) => {
        this.finances.push(response);
      });
    }
  }
};
</script>

<style scoped></style>
