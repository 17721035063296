export const common = {
  yes: "はい",
  no: "-",
  back: "戻る",
  cancel: "キャンセル",
  add_more: "追加",
  submit: "提出",
  next: "次に進む",
  view_data: "データを見る",
  process: "承認フロー",
  applicant: "Applicant",
  //app
  currency: "通貨",
  select_currency: "通貨の選択",
  amount: "金額",
  enter_amount: "数字を入力",
  total: "合計金額",
  date: "日付",
  select_date: "日付の選択",
  destination: "費用明細",
  enter_destination: "精算明細の追加",
  notes: "備考",
  enter_notes: "備考の入力",
  company: "社名",
  select_company: "社名選択",
  department: "部門",
  select_department: "部門の選択",
  related_approval: "関連承認済申請",
  related_approval_info: "関連承認済申請情報",
  select: "選択",
  enter_number: "支払通貨",
  content: "内容",
  //other
  select_finance: "財務担当の選択",
  //payment
  bank_name: "銀行名",
  enter_bank_name: "銀行名の入力",
  branch_name: "支店名",
  enter_branch_name: "支店番号",
  colleague: "同僚",
  search: "検索",
  filter: "フィルター",
  change: "変化する",
  sort: "並べ替え",
  ascending: "昇順",
  descending: "降順",
  reset: "リセット",
  greater_than: "以上＞",
  less_than: "以下＜",
  equal_to: "一致＝",
  bypass: "システム保存（承認フローに回さない）ショートカット",
  remember_approvers: "今回設定したフロー上の人を記憶",
  uploaded_at: "アップロード日時",
  confirm: "選択",
  vendor: "ベンダー",
  enter_vendor: "ベンダー名を入力してください",
  ok: "完了",
  empty: "現在データがありません",
  add: "追加",
  save: "保存",
  select_languange: "言語を選択してください",
  alert: {
    currency: "通貨を選択してください",
    approver: "承認者を選択してください",
    company: "社名を選択してください",
    department: "部門を選択してください"
  },
  notif: {
    submit: "申請は提出されました",
    approve: "申請は承認されました",
    reject: "申請は承認されませんでした",
    alert: "警告",
    confirm: "確認",
    decide: "決定しますか",
    saved: "保存済み",
    email: "レポートがメールボックスに送信されますのでご確認ください"
  },
  create: {
    user: "ユーザーの作成",
    company: "会社の作成",
    background: "グループの作成",
    reimbursement: "精算申請",
    payment: "支払申請",
    purchase: "購買申請",
    "collection-debt": "債権回収",
    "collection-report": "入金報告",
    "collection-sales": "Create Sales/Custom Info Registration",
    "contract-supplier": "仕入・ベンダー契約締結",
    "contract-customer": "顧客契約締結",
    "corporate-seal": "証書・捺印申請",
    "expense-account": "費目",
    "bank-account": "自社口座情報管理の作成"
  },
  edit: {
    user: "ユーザーの編集",
    company: "会社の編集",
    background: "グループの編集",
    reimbursement: "精算申請",
    payment: "支払申請",
    purchase: "購買申請",
    "collection-debt": "債権回収",
    "collection-report": "入金報告",
    "contract-supplier": "仕入・ベンダー契約締結",
    "contract-customer": "顧客契約締結",
    "corporate-seal": "証書・捺印申請",
    "expense-account": "費目",
    "bank-account": "自社口座情報管理の編集"
  },
  delete: {
    user: "ユーザーの削除",
    "bank-account": "自社口座情報管理の削除",
    "expense-account": "費目の削除"
  },
  waiting: "{type}の対応待ち",
  app_code: "申請番号",
  type: "タイプ",
  approve: "承認",
  reject: "不承認",
  approved: "フロー完成",
  rejected: "却下",
  withdrawn: "撤回",
  onprocess: "申請中",
  detail: "詳細",
  requested_by: "申請者： ",
  company_authorizer: "会社責任者",
  sort_: {
    by: "並べ替え",
    app_code: "申請番号",
    created_at: "申請日時",
    updated_at: "更新日時",
    ascending: "昇順",
    descending: "降順",
    reset: "リセット"
  },
  app_detail_: {
    more: "オプション",
    more_opt: "オプション",
    submit_again: "この以前の記録を利用して再提出へ",
    cancel: "戻る"
  }
};
