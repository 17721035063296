<template>
  <div>
    <div v-if="approvers.length > 1">
      <div class="approval-process">
        <div class="relative">
          <div class="thumb p-0">
            <Icon name="users-fill" classes="h-6 w-6" />
          </div>
          <div v-if="indicator.icon !== null" :class="`status ${indicator.class}`">
            <Icon :name="indicator.icon" size="12" />
          </div>
        </div>
        <div class="ml-4 w-full">
          <div class="font-16 text-dark-gray">{{ item.master_process.process.name }}</div>
          <div class="text-black flex justify-between">
            <div>{{ approvers.length }} People Approval (at least one approved)</div>
          </div>
        </div>
      </div>
      <div class="approval-process mb-4">
        <div class="relative">
          <div class="thumb p-0" style="background-color: transparent !important;border: none !important"></div>
        </div>
        <div class="ml-4 flex items-center w-full">
          <div class="flex flex-col items-center" v-for="(approver, k) in approvers" :key="k">
            <div class="bg-blue text-white rounded-md  h-8 w-8 flex items-center justify-center">{{ approver.user_approver.name.charAt(0) }}</div>
            <div
              class="text-xs text-dark-gray truncate"
              :title="approver.user_approver.name"
              style="max-width: 50px;min-width: 50px;font-size: 11px;"
            >
              {{ approver.user_approver.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProcessCardView v-if="item.approver" :item="item" :approver="item.approver" />
    <ProcessCardView v-else-if="!item.approver && approvers.length === 1" :item="item" :approver="approver" />
    <ProcessLog v-for="(log, k) in item.approval_logs" :key="k" :log="log" />
  </div>
</template>

<script>
import ProcessCardView from "@/components/ProcessCardView.vue";
import ProcessLog from "@/components/ProcessLog";

export default {
  name: "ProcessThumbMultiple",
  props: ["approvers", "status", "item"],
  computed: {
    approver: function() {
      if (this.approvers.length == 1) {
        return this.approvers[0].user_approver;
      }
      return null;
    },
    indicator: function() {
      // if (this.item.status === 1) {
      //   return {
      //     class: "bg-green",
      //     icon: "check"
      //   };
      // }
      if (this.item.active) {
        return {
          class: "bg-orange",
          icon: "dots"
        };
      }
      if (this.item.active === false && this.item.status == 0) {
        return {
          class: "bg-gray",
          icon: "dots"
        };
      }
      if (this.item.status === 2) {
        return {
          class: "bg-rose-500",
          icon: "close"
        };
      }

      return {
        class: null,
        icon: null
      };
    }
  },
  components: { ProcessCardView, ProcessLog }
};
</script>

<style></style>
