<template>
  <div>
    <Loading v-show="loading" />
    <ons-list>
      <ons-list-header />
      <form-select
        :label="$t('common.currency')"
        :required="true"
        @click="cshow.currency = true"
        :placeholder="$t('common.select_currency')"
        :value="currencyCode"
      />

      <form-select
        :required="true"
        :label="$t('common.company')"
        @click="onClickComponent(COMPONENT.COMPANY)"
        :placeholder="$t('common.select_company')"
        :value="component.company ? component.company.name : null"
      />

      <form-select
        :required="true"
        v-if="component.company !== null"
        :label="$t('common.department')"
        @click="onClickComponent(COMPONENT.DEPARTMENT, { companyId: component.company.id })"
        :placeholder="$t('common.select_department')"
        :value="component.department ? component.department.name : null"
      />
    </ons-list>

    <ons-list v-show="common.currency" class="mt-6">
      <ons-list-header>{{ $t("app.purc.goods_detail") }}</ons-list-header>
      <form-select
        v-for="(item, key) in purchases"
        :key="key"
        :label="$t('app.purc.goods_detail') + '(' + item.index + ')'"
        @click="purchaseDetailClick(item)"
        :value="currencyCode + ' ' + Round(item.budget, currencyCode)"
      />

      <v-ons-list-item>
        <div class="center list-item__center text-gray-500 text-xs">
          {{ $t("app.purc.add_more_info") }}
        </div>
      </v-ons-list-item>
    </ons-list>
    <v-ons-list v-show="common.currency">
      <v-ons-list-item class="p-0">
        <div class="center flex justify-center items-center p-0">
          <v-ons-button modifier="quiet" style="justify-content: center" @click="addPurchase">
            <ons-icon icon="md-plus"> </ons-icon>
            {{ $t("common.add_more") }}
          </v-ons-button>
        </div>
      </v-ons-list-item>
    </v-ons-list>
    <br />
    <v-ons-list>
      <v-ons-list-item class="py-4">
        <div class="left">
          {{ $t("app.purc.total_estimated") }}
          {{ currencyCode ? "(" + currencyCode + ")" : "" }}
        </div>
        <div class="center" style="margin-left: 10px">
          <span class="text-orange"> {{ Round(totalEstimated, currencyCode) }}</span>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <br />
    <v-ons-list>
      <form-select-user
        :label="$t('app.forward_document')"
        :users="component.finance ? component.finance : []"
        @remove="handleRemoveFinance"
        @add="onClickComponent(COMPONENT.FINANCE, { default: component.finance })"
      />
    </v-ons-list>

    <process-card module-code="purchase">
      <hr />
      <v-ons-list class="bg-transparent mb-5">
        <v-ons-list-item>
          <label class="center">{{ $t("common.remember_approvers") }}</label>
          <div class="right">
            <v-ons-switch v-model="form.save_approvers"> </v-ons-switch>
          </div>
        </v-ons-list-item>
      </v-ons-list>
    </process-card>

    <bottom-submit @submit="handleSubmit" :loading="submitting">
      <template v-slot:additional>
        <div class="flex items-center p-2 space-x-4 w-full text-sm">
          <label class="left">
            <v-ons-checkbox :input-id="'checkbox-email'" v-model="form.send_notification_first_approval"> </v-ons-checkbox>
          </label>
          <label class="center" :for="'checkbox-email'"> {{ $t("app.send_notification_approver") }} </label>
        </div>
      </template>
    </bottom-submit>

    <currency-select :showing="cshow.currency" @on-close="cshow.currency = false" @on-select="onCommonSelect($event, COMMON.CURRENCY)" />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import { submitPurchaseBuffer } from "@/api/purchase";
import CurrencySelect from "@/views/common/select/CurrencySelect";
import ProcessCard from "../../common/ProcessCard.vue";
import application from "@/mixins/application";

const defaultForm = {
  currency_id: null,
  total_estimated: null,
  total_approved: 0,
  purchase_goods: [],
  user_finances: []
};
const defaultItem = [
  {
    index: 1,
    estimated_unit_price: 0,
    qty: 0,
    budget: 0,
    company_id: null,
    department_id: null
  }
];
export default {
  name: "PurchaseForm",
  components: {
    CurrencySelect,
    ProcessCard
  },
  mixins: [application],
  props: ["show", "init", "param"],
  computed: {
    approver() {
      return this.$store.getters.approvers;
    },
    totalEstimated() {
      // return this.purchases.reduce((sum, obj) => sum + obj.estimated_unit_price * obj.qty, 0);
      return this.purchases.reduce((sum, obj) => sum + obj.budget, 0);
    },
    companyId() {
      return this.component.company ? this.component.company.id : null;
    },
    departmentId() {
      return this.component.department ? this.component.department.id : null;
    }
  },
  watch: {
    show: function(newVal) {
      if (newVal) {
        this.onShow();
      }
    },
    init: function(newVal) {
      if (newVal) {
        this.onInit("purchase");
      }
    },
    totalEstimated(newVal) {
      this.form.purchase.total_estimated = newVal;
    },
    "common.currency": function(n) {
      if (n) {
        this.form.purchase.currency_id = n.id;
      }
    },
    "component.finance": function(n) {
      if (n) {
        this.form.purchase.user_finances = n.map((item) => item.id);
      }
    },
    "component.company": function(n) {
      if (n) {
        console.log(n);
        this.purchases = this.purchases.map((item) => ({ ...item, company_id: n.id }));
        this.form.purchase.purchase_goods = this.purchases;
      }
    },
    "component.department": function(n) {
      if (n) {
        this.purchases = this.purchases.map((item) => ({ ...item, department_id: n.id }));
        this.form.purchase.purchase_goods = this.purchases;
      }
    }
  },
  data: () => ({
    moduleCode: "purchase",
    tempMaster: ["form", "purchases", "common", "component"],
    form: {
      send_notification_first_approval: true,
      purchase: Object.assign({}, defaultForm),
      save_approvers: true,
      approvals: []
    },
    loading: true,
    submitting: false,
    purchases: defaultItem
  }),
  validations: {
    form: {
      purchase: {
        currency_id: {
          required
        }
      }
    }
  },
  methods: {
    ...mapMutations("navigator", ["setParam", "clearParam", "pop"]),
    onShow() {
      let param = this.$store.getters["navigator/getParam"];
      let purchaseDetail = param?.purchaseDetail;
      let selectedFinance = param.selectedFinance;
      let selectedCompany = param.selectedContractParty;
      let selectedDepartment = param.selectedDepartment;

      if (selectedFinance) {
        this.component[this.COMPONENT.FINANCE] = selectedFinance;
        this.form.purchase.user_finances = selectedFinance.map((item) => item.id);
      }

      if (selectedCompany) {
        this.component[this.COMPONENT.COMPANY] = selectedCompany;
        this.component[this.COMPONENT.DEPARTMENT] = null;
      }
      if (selectedDepartment) {
        this.component[this.COMPONENT.DEPARTMENT] = selectedDepartment;
      }

      if (purchaseDetail) {
        this.purchases = this.purchases.map((obj) => {
          if (obj.index === purchaseDetail.index) {
            return { ...purchaseDetail };
          } else {
            return obj;
          }
        });

        this.form.purchase.purchase_goods = this.purchases;
      }
      this.clearParam();
      this.$emit("reloaded");
      this.loading = false;
    },
    // onInit() {
    //   if (this.param, this.form.purchase) {
    //     this.form.purchase = Object.assign({}, this.param.data);
    //     this.loadData();
    //   }
    // },
    addPurchase() {
      this.purchases = [
        ...this.purchases,
        { index: this.purchases.length + 1, estimated_unit_price: 0, qty: 0, budget: 0, company_id: this.companyId, department_id: this.departmentId }
      ];
    },
    purchaseDetailClick(item) {
      let options = { title: this.$t("common.notif.alert") };

      if (null == item.company_id) {
        this.$ons.notification.alert(this.$t("common.alert.company"), options);
        return;
      }
      if (null == item.department_id) {
        this.$ons.notification.alert(this.$t("common.alert.department"), options);
        return;
      }

      this.onClickDetail(item);
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.purchase.currency_id.$invalid) {
        await this.$ons.notification.alert(this.$t("common.alert.currency"), options);
        return false;
      }
      if (null == this.companyId) {
        await this.$ons.notification.alert(this.$t("common.alert.company"), options);
        return;
      }
      if (null == this.departmentId) {
        await this.$ons.notification.alert(this.$t("common.alert.department"), options);
        return;
      }
      if (this.totalEstimated === 0) {
        await this.$ons.notification.alert(this.$t("app.purc.alert.item"), options);
        return false;
      }
      if (!this.approverIsValid) {
        await this.$ons.notification.alert(this.$t("common.alert.approver"), options);
        return false;
      }
      return true;
    },
    handleSubmit() {
      this.isFormValid().then((valid) => {
        if (valid) {
          this.submitting = true;
          submitPurchaseBuffer(this.form)
            .then((res) => {
              this.$ons.notification.alert({
                title: res.code ? res.code : this.$t("common.notif.saved"),
                messageHTML: ` ${this.$t("common.notif.submit")}`
              });
              this.populateDefaultTemp();
              this.pop();
            })
            .then(() => {
              this.submitting = false;
            });
        }
      });
    },
    updateFormCommon(type) {
      if (type === this.COMMON.CURRENCY) {
        this.form.purchase.currency_id = this.common.currency.id;
      }
    },
    loadData() {
      if (this.form.purchase.currency_id) {
        this.loadApi(this.COMMON.CURRENCY, this.form.purchase.currency_id);
      }

      if (this.form.purchase.user_finances) {
        this.form.purchase.user_finances.forEach((item) => {
          this.loadApi(this.COMPONENT.FINANCE, item);
        });
      }

      if (this.form.purchase.purchase_goods) {
        this.component[this.COMPONENT.COMPANY] = this.loadApi(this.COMPONENT.COMPANY, this.form.purchase.purchase_goods[0].company_id);
        this.component[this.COMPONENT.DEPARTMENT] = this.loadApi(this.COMPONENT.DEPARTMENT, this.form.purchase.purchase_goods[0].department_id);
        this.purchases = this.form.purchase.purchase_goods.map((obj, index) => {
          let total = obj.budget;

          return {
            ...obj,
            delivery_date: this.moment(obj.delivery_date).format("YYYY-MM-DD"),
            index: index + 1,
            total: total
          };
        });
      }
    },
    handleReset() {
      this.$ons.notification.confirm(this.$t("common.notif.decide"), { title: this.$t("common.notif.confirm") }).then((response) => {
        if (response === 1) {
          this.form.purchase = Object.assign({}, defaultForm);
          this.purchases = defaultItem;
          this.component[this.COMPONENT.COMPANY] = null;
          this.component[this.COMPONENT.DEPARTMENT] = null;

          if (this.common.currency) {
            this.form.purchase.currency_id = this.common.currency.id;
          }

          if (this.companyId) {
            this.purchases[0].company_id = this.companyId;
          }
          if (this.departmentId) {
            this.purchases[0].department_id = this.departmentId;
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.vux-uploader_del {
  position: absolute;
  right: 0;
  bottom: 50px !important;
  left: 0;
  background-color: #0d0d0d;
  color: #fff;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-family: weui;
}
</style>
