<template>
  <div>
    <v-ons-list-item tappable v-for="(obj, key) in content" :key="key" :class="{ 'pl-0': obj.related_approval_item_content_id > 0 }">
      <div class="left list-item__left mr-2 p-0" v-show="obj.related_approval_item_content_id > 0">
        <div
          class="h-full m-0 p-0 w-1.5"
          :class="{
            'bg-gray-300': !obj.edited,
            'bg-red-400': obj.edited
          }"
        ></div>
      </div>
      <div class="center list-item__center">
        <span class="list-item__title">
          {{ obj.expense.name }}
          <span style="color: #157afb">
            {{ selectedCurrency ? selectedCurrency.code : null }}
            {{ Round(obj.amount, selectedCurrency ? selectedCurrency.code : null) }}
          </span>
        </span>
        <span v-show="obj.expense.is_transportation" class="list-item__subtitle text-gray-600">
          {{ obj.destination }}, {{ obj.date ? moment(obj.date).format("YYYY/MM/DD") : "" }}
          <br />
          <div class="flex items-center" v-if="obj.from_to && obj.from_to.length > 1">
            {{ obj.from_to.split(",")[0] }} <Icon name="arrow-right" classes="h-6 w-6 px-1" /> {{ obj.from_to.split(",")[1] }}
          </div>
        </span>
        <span class="list-item__subtitle">
          {{ obj.notes }}
        </span>
      </div>
      <div class="right">
        <v-ons-button modifier="quiet" @click="onContentDelete(key)">
          <Icon name="trash" stroke-width="1.5" classes="icon list-item__thumbnail" />
        </v-ons-button>
        <v-ons-button modifier="quiet" @click="onContentEdit(obj, key)">
          <Icon name="edit" classes="icon list-item__thumbnail" />
        </v-ons-button>
      </div>
    </v-ons-list-item>
  </div>
</template>

<script>
import { Round } from "../../../../utils/number";
export default {
  name: "ContentList",
  props: ["content", "selectedCurrency"],
  methods: {
    Round,
    onContentDelete(index) {
      this.$emit("delete", index);
    },
    onContentEdit(item, index) {
      this.$emit("edit", item, index);
    }
  }
};
</script>

<style></style>
