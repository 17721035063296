<template>
  <v-ons-page @show="onShow" @init="onInit">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <div>
      <v-ons-list>
        <v-ons-list-header>
          {{ $t("app.input_by_treasury") }}
        </v-ons-list-header>
        <form-select
          :label="$t('app.payment_account')"
          :placeholder="$t('app.select_payment_account')"
          @click="
            push(page[0].component, page[0].label, {
              company: ApplicantsCompany
            })
          "
          :value="selectedAccount ? selectedAccount.bank_name + ' - ' + selectedAccount.branch : null"
        />

        <form-calendar
          :label="$t('app.payment_date')"
          mode="single"
          @on-change="handlePaymentDateSelect"
          :placeholder="$t('common.select')"
          :value="form.payment_date"
        />

        <form-upload
          :label="$t('app.deal_slip')"
          :sublabel="$t('app.deal_slip_sublabel')"
          documentType="deal-slip"
          :limit="1"
          :files="dealSlipList"
          @on-uploading="uploading = true"
          @on-success="handleUploadDealSlip"
        />
      </v-ons-list>
      <bottom-submit @click.native="onSubmit" classes="bg-blue-500" :loading="loading" />
    </div>
  </v-ons-page>
</template>

<script>
import BankAccountList from "@/views/common/list/BankAccountList";
import { approve as submitForm } from "@/api/approval";
import { fetchDetail as getPaymentAccount } from "@/api/bank_account";
import { fetchDetail as getFile } from "@/api/file";

const defaultForm = { payment_date: null, account_id: null, deal_slips: [] };
export default {
  name: "TreasuryForm",
  computed: {
    buffer() {
      return this.toolbarInfo.param ? this.toolbarInfo.param.buffer : null;
    },
    data() {
      return this.buffer ? this.buffer.data : null;
    },
    approvalId() {
      return this.toolbarInfo.param ? this.toolbarInfo.param.approval : null;
    },
    ApplicantsCompany() {
      return this.toolbarInfo.param ? this.toolbarInfo.param.buffer.data.transaction_items[0].company_id : null;
    },
    page() {
      return [
        {
          component: BankAccountList,
          label: this.$t("common.select")
        }
      ];
    }
  },
  data() {
    return {
      loading: false,
      form: Object.assign({}, defaultForm),
      selectedAccount: null,
      uploading: false,
      dealSlipList: []
    };
  },
  watch: {
    dealSlipList: function(n) {
      if (n) {
        this.form.deal_slips = n.map((file) => file.data.id);
      }
    }
  },
  methods: {
    onInit() {
      this.form = Object.assign({}, this.data);
      if (this.data.account_id) {
        this.fetchAccount(this.data.account_id);
      }
      if (this.data.payment_date) {
        this.fetchPaymentDate(this.data.payment_date);
      }
      if (this.data.deal_slips.length > 0) {
        this.fetchDealSlips(this.data.deal_slips);
      }
    },
    onShow() {
      let param = this.$store.getters["navigator/getParam"];
      let selectedAccount = param.selectedAccount;

      if (selectedAccount) {
        this.selectedAccount = selectedAccount;
        this.form.account_id = selectedAccount.id;
      }
      this.$store.commit("navigator/clearParam");
    },
    fetchDealSlips(list) {
      this.dealSlipList = [];
      list.forEach((item) => {
        getFile(item).then((response) => {
          let data = {
            url: response.url,
            data: response
          };
          this.dealSlipList.push(data);
        });
      });
    },
    fetchPaymentDate(date) {
      this.form.payment_date = this.moment(date).format("YYYY-MM-DD");
    },
    fetchAccount(id) {
      getPaymentAccount(id).then((response) => {
        this.selectedAccount = response;
      });
    },
    handlePaymentDateSelect(val) {
      this.form.payment_date = val;
    },
    handleUploadDealSlip(file) {
      this.uploading = false;
      this.dealSlipList = file;
    },
    async onSubmit() {
      this.loading = true;
      await submitForm(this.approvalId, {
        approve: null,
        data: this.form
      }).then(() => {
        this.$ons.notification.toast(this.$t("common.notif.saved"), {
          timeout: 2000,
          animation: "fall"
        });
        this.$store.commit("navigator/popSubmit");
      });

      this.loading = false;
    },
    push(page, key, param) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style></style>
