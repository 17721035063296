export const app = {
  application_number: "申請番号",
  invoice_later: "後日入手予定の発票金額",
  input_by_treasury: "出納用記載項目",
  deal_slip: "入金伝票",
  deal_slip_sublabel: "入金伝票をアップロードしてください",
  payment_account: "支払口座",
  select_payment_account: "支払口座の選択",
  payment_date: "支払完了日",
  forward_finance: "財務担当者に転送",
  select_finance: "財務担当者を選択",
  page_application: "添付請求書等枚数",
  enter_page_application: "添付頁数を入力",
  item: "項目",
  quantity: "数量",
  enter_quantity: "数量の入力",
  forward_document: "財務担当者に転送",
  estimated_approval_date: "承認予定期限",
  send_notification_approver: "次の承認者に通知メールを送信する",
  withdraw: "申請撤回 ",
  withdraw_alert: "申請を取り消しますか？ ",
  stepback: "差し戻し ",
  stepback_alert: "申請を差し戻しますか？ ",
  is_drawdown: "Automatic drawdown",

  account: {
    name: "銀行口座名義",
    number: "銀行口座番号",
    bank: "銀行名、BIC CODE",
    branch: "支店名、口座種類"
  },

  reimb: {
    reimbursement: "精算申請",
    reimbursement_detail: "精算明細",
    add_more_info: "精算を追加する場合は、追加タブをタップしてください",
    e_invoice_info:
      "請求書やインボイスの原紙はＡ４の紙（表面のみ）にサイズを揃えて貼り付けた後、出納担当者に提出してください。一枚目右上に上の申請番号を記載してください。{number}",
    e_invoice: "電子発票",
    e_invoice_sublabel: "発票のOFD・PDFファイル、写真を10件までアップロードできます",
    application_paper: "請求書等",
    application_paper_sublabel: "請求書等のファイル、写真を10件までアップロードできます",
    //detail
    reimbursement_content: "精算内容",
    expense_detail: "費用明細",
    select_expense: "費用科目の選択",
    destination: "訪問顧客名・目的地",
    enter_destination: "顧客名・目的地を入力",
    from: "出発点",
    enter_from: "場所を入力",
    to: "到着点",
    enter_to: "場所を入力",
    supplier_name: "入手予定の発票発行元",
    enter_supplier_name: "発票発行元名称を入力",
    tax: "発票入手不能金額",
    tax2: "発票入手不能金額",
    enter_tax: "発票入手不能の金額と理由を入力",
    with_supplier_account: "もし今回の入金口座が通常の給与受取口座と異なる口座の場合、記載してください",
    alert: {
      item: "１件以上の支払を選択してください",
      page: "用紙枚数を入力してください",
      finance: "財務の選択",
      content: "事業名を1以件上選択してください"
    }
  },
  pay: {
    payment: "支払申請",
    payment_detail: "支払明細",
    add_more_info: "支払申請を追加する場合は、下の追加タブをタップしてください",
    e_invoice_info:
      "請求書やインボイスの原紙はＡ４の紙（表面のみ）にサイズを揃えて貼り付けた後、出納担当者に提出してください。一枚目右上に上の申請番号を記載してください{number}",
    e_invoice: "電子発票",
    e_invoice_sublabel: "発票のOFD・PDFファイル、写真を10件までアップロードできます",
    application_paper: "請求書等",
    application_paper_sublabel: "請求書等のファイル、写真を10件までアップロードできます",
    supplier_name: "入手予定の発票発行元",
    enter_supplier_name: "発票発行元名称を入力",
    tax: "発票入手不能金額",
    tax2: "発票入手不能金額",
    enter_tax: "発票入手不能の金額と理由を入力",
    month: "対象月",
    enter_month: "対象月の入力",
    estimated_payment_date: "希望支払日",
    recipient: "支払先",
    enter_recipient: "支払先口座名義",
    recipient_account: "支払先口座番号",
    enter_recipient_account: "口座番号の入力",
    payment_content: "費目明細",
    expense_detail: "支払明細",
    select_expense: "精算内容の選択",
    alert: {
      item: "1件以上の支払を選択してください",
      finance: "財務の選択",
      month: "月の入力",
      date: "支払予測日を選択してください",
      page: "用紙枚数を入力してください",
      recipient: "支払先の名称",
      account_no: "口座番号を入力してください",
      bank: "銀行名を入力してください",
      branch: "支店名を入力してください",
      content: "事業名を1以件上選択してください"
    }
  },
  purc: {
    purchase: "購買申請",
    goods_detail: "物品明細",
    add_more_info: "明細を追加する場合は、下の追加タブをタップしてください",
    total_estimated: "総支払額",
    delivery_date: "引渡日時",
    delivery_place: "送付先（場所）",
    enter_delivery_place: "送付先（場所）を入力",
    goods_name: "物品名称",
    enter_goods_name: "物品名の入力",
    recurrent: "次回以降承認済物品として登録",
    type_brand: "規格、ブランド",
    enter_type_brand: "規格、ブランドを入力",
    budget_tax: "予算金額（税込）",
    enter_budget: "金額の入力",
    estimated_unit_price: "想定単価",
    upload_photo: "参考ファイル、写真をアップロード",
    upload_photo_desc: "参考ファイル、写真を10件までアップロードできます",
    source: "購買元または参考リンク",
    enter_source: "リンクの入力",
    arrange_by: "手配者",
    by_me: "自分で購入後精算",
    by_ga: "総務が購入し、精算",
    alert: {
      item: "1件以上の購買を選択してください",
      delivery_date: "配達日を選択してください",
      delivery_place: "配達場所を選択してください",
      goods: "物品名称を入力してください",
      type: "規格、ブランドを入力してください",
      quantity: "数量を入力してください",
      budget: "予算金額（税込）を入力してください",
      arrange: "手配者を選択してください"
    }
  },
  cc: {
    contract_party: "自社社名選択",
    select_party: "会社の選択",
    contract_type: "契約の種類",
    select_type: "商品販売、サービス売上、その他売上から選択",
    contract_period: "契約期間満期",
    contract_fullname: "顧客名全称",
    enter_fullname: "顧客名の入力",
    items: "項目",
    add_more_info: "項目を追加する場合は、下の追加タブをタップしてください",
    plan: "契約書案",
    plan_info: "契約書案のアップロード",
    stamp: "捺印番号",
    signed: "捺印済契約書",
    signed_info: "捺印済契約書のアップロード",
    item: {
      name: "商品名称",
      enter_name: "名称の入力",
      brand: "規格、ブランド",
      enter_brand: "規格、ブランドの入力",
      qty: "数量",
      freq: "販売回数",
      enter_freq: "回数の入力",
      content: "事業名",
      enter_content: "名称の入力",
      type1: "大項目",
      enter_type1: "大項目の入力",
      type2: "小項目",
      enter_type2: "小項目の入力",
      unit: "単価",
      enter_unit: "単価の入力",
      term: "前払比率",
      enter_term: "比率の入力",
      amount_tax: "単価（税込）",
      enter_amount_tax: "単価の入力",
      ratio: "前受比率",
      enter_ratio: "比率の入力"
    },
    alert: {
      party: "契約主体を選択してください",
      type: "契約種類を選択してください",
      fullname: "契約名全称を入力してください",
      plan: "契約書案をアップロードしてください",
      items: "一項目以上を入力してください",
      item: {
        name: "物品名称を入力してください",
        brand: "規格、ブランドを入力してください",
        qty: "数量を入力してください",
        amount: "金額を入力してください",
        unit: "単価を入力してください",
        content: "事業名を入力してください",
        term: "前払比率を入力してください",
        amount_tax: "金額（税込）を入力してください",
        freq: "購入回数を入力してください"
      }
    }
  },
  sc: {
    our_contract_party: "自社社名選択",
    select_contract_party: "会社の選択",
    contract_type: "契約の種類",
    expense_account: "費用科目",
    select_account: "費用科目の選択",
    contract_fullname: "仕入先・ベンダー名全称",
    employee_fullname: "人員氏名",
    enter_contract_fullname: "全称の入力",
    add_more_info: "項目を追加する場合は、下の追加タブをタップしてください",
    upload_contract_plan: "契約書案をアップロード",
    select_file: "ファイルの選択",
    after_approval_before_sign: "承認済捺印待ち",
    stamp: "捺印番号",
    upload_signed_contract: "捺印済契約書をアップロード",
    supplier_fullname: "捺印必要部数、捺印箇所",
    frequency: "支払頻度",
    select_frequency: "頻度の選択",
    payment_deadline: "支払期限",
    enter_payment_deadline: "支払期限を記載",
    supplier_account_detail: "支払先口座情報",
    employee_account_detail: "報酬支払口座情報　",
    supplier_name: "銀行口座名義",
    enter_name: "名前の入力",
    number: "銀行口座番号",
    contract_period: "契約期間満期",
    auto_renew: "契約自動更新の有無",
    enter_account_number: "口座番号の入力",
    bank: "銀行、BIC CODE",
    enter_bank: "銀行名、BIC CODEの入力",
    branch: "支店、口座種類等",
    enter_branch: "支店名、口座種類等の入力",
    item: {
      name: "名前",
      enter_name: "名前の入力",
      type_brand: "規格、ブランド",
      enter_type_brand: "規格、ブランドの入力",
      quantity: "数量",
      enter_quantity: "数量の選択",
      budget_tax: "予算金額（税込）",
      enter_budget: "金額の入力",
      unit_price: "単価",
      enter_unit_price: "単価の入力",
      times: "購入回数",
      enter_times: "回数の入力",
      content: "事業名",
      enter_content: "名称の入力",
      type1: "大項目",
      enter_type1: "大項目の入力",
      type2: "小項目",
      enter_type2: "小項目の入力",
      terms: "前払比率",
      enter_terms: "比率の入力",
      national_amount: "額面金額",
      national_amount_hr: "額面金額　",
      enter_national_amount: "金額の入力",
      start_date: "開始日"
    },
    alert: {
      type: "契約種類を選択してください",
      fullname: "契約名全称を入力してください",
      plan: "契約書案をアップロードしてください",
      items: "一項目以上を入力してください",
      supplier: "契約先全称択してください",
      contract_period: "契約期間満期の入力",
      item: {
        content: "事業名を入力してください",
        unit: "単価を入力してください",
        date: "開始日択してください",
        amount: "金額を入力してください",
        goods: "物品名称を入力してください",
        type: "規格、ブランドを入力してください",
        quantity: "数量を入力してください",
        budget: "金額（税込）を入力してください",
        frequency: "購入回数を入力してください"
      }
    }
  },
  col: {
    select_one: "以下から一つを選択してください",
    _debt: "債権回収",
    _report: "入金報告",
    related: "関連承認済契約",
    select_related: "関連承認済契約の選択",
    customer: "顧客名",
    enter_customer: "名前の入力",
    debt: {
      uncollected: "未入金金額",
      enter_uncollected: "金額の入力",
      payment_date: "契約上入金日付",
      situation: "状況",
      enter_situation: "状況の入力",
      estimated_date: "入金想定日"
    },
    report: {
      collected: "入金金額",
      enter_collected: "入金額の入力",
      reciept: "入金口座",
      select_reciept: "番号の入力",
      reciept_date: "入金日付"
    },
    sales: {
      amount: "Sales (delivery) amount",
      amount_enter: "Enter Sales (delivery) amount",
      evidence_type: "Evidence type",
      evidence_type_select: "Select evidence type",
      delivery_date: "Sales (delivery) date",
      evidence: "Evidence",
      evidence_upload: "Please upload evidence and related waybill, Packing list, or bill of lading"
    },
    alert: {
      customer: "顧客名を入力してください",
      uncollected: "未入金金額を入力してください",
      payment_date: "入金日付を選択してください",
      collected: "入金金額を入力してください",
      reciept: "入金口座の番号を入力してください",
      evidence: "Please select evidence type"
    }
  },
  stamp: {
    usage: "用途種類",
    select_usage: "種類の選択",
    purpose: "捺印用途",
    enter_purpose: "用途の入力",
    select_purpose: "用途の選択（複数可）",
    fullname: "提出先名称と目的",
    enter_fullname: "名称と目的の入力",
    // no1: "捺印文件名全称、部数①",
    // no2: "捺印文件名全称、部数②",
    // no3: "捺印文件名全称、部数③",
    stamp_docs: "必要資料名指定",
    stamp_docs_detail: "名称、部数、捺印・サイン箇所、印鑑の種類、原本有無等入力",
    add_more_stamp_docs_info: "項目を追加する場合は、下の追加タブをタップしてください",
    enter_notes: "電子営業許可証授権の場合、授権の対象、授権期間、電話番号、身分証番号を入力",
    enter_detail: "詳細の入力",
    party: "我々契約主体",
    select_party: "契約主体の選択",
    type: "印鑑の種類の指定",
    sign_on: "サイン箇所の指定",
    plan: "捺印予定書面のアップロード",
    plan_info: "捺印予定書面をアップロードしてください",
    stamp: "捺印番号",
    sign: "捺印済書面",
    sign_info: "捺印済書面をアップロードしてください",
    alert: {
      usage: "用途種類を選択してください",
      purpose: "捺印用途を選択してください",
      fullname: "捺印先全称を入力してください",
      party: "契約主体を選択してください",
      plan: "捺印予定書面をアップロードしてください"
    },
    agreement: {
      one: "印鑑を借用する場合、捺印後全ての用途に写真を撮影することを確認します",
      two: "過去印鑑を借用した際、用途を報告していない捺印はありません",
      three: "I confirm borrowing deed or stamp should return during the day, unless the government or the bank collects it."
    },
    purposes: {
      borrow_stamp: "証書・印鑑の借用",
      borrow_id: "身分証・パスポート原本の借用",
      use_electronic_id: "身分証・証書電子データの使用",
      use_photocopy_id: "身分証・証書コピーの使用",
      authorize_deed: "電子営業許可証の授権",
      signature: "サイン",
      without_stamp: "捺印署名不要外部手続"
    }
  }
};
