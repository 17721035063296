<template>
  <div>
    <div class="font-16 text-dark-gray">{{ label }}</div>
    <div class="flex flex-wrap">
      <div v-for="(item, key) in items" :key="'items_' + key" class="flex items-center bg-gray-200 text-black rounded-md text-xs px-2 py-1 mt-1 mr-2">
        {{ item[itemKey] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "d-list",
  props: {
    label: {
      type: String
    },
    items: {
      type: Array
    },
    itemKey: {
      type: String
    }
  }
};
</script>

<style scoped></style>
