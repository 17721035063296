<template>
  <div>
    <Loading v-show="loading" />
    <v-ons-list>
      <v-ons-list-header />
      <form-select
        :label="$t('app.sc.our_contract_party')"
        :required="true"
        @click="push(page[1].component, page[1].label)"
        :placeholder="$t('app.sc.select_contract_party')"
        :value="companyObj ? companyObj.name : null"
      />

      <form-select
        v-if="companyObj !== null"
        :label="$t('common.department')"
        :required="false"
        @click="
          push(page[3].component, page[3].label, {
            companyId: companyObj.id
          })
        "
        :placeholder="$t('common.select_department')"
        :value="departmentObj ? departmentObj.name : null"
      />

      <form-calendar
        :required="false"
        :label="$t('app.estimated_approval_date')"
        mode="single"
        @on-change="handleEstimatedApprovalDeadline"
        :placeholder="$t('common.select_date')"
        :value="form.contract.estimated_approval_date ? moment(form.contract.estimated_approval_date).format('YYYY-MM-DD') : null"
      />
    </v-ons-list>

    <br />
    <ons-list>
      <form-select
        :label="$t('app.sc.contract_type')"
        :required="true"
        @click="contractSelectShow = true"
        :placeholder="$t('common.select')"
        :value="contractTypeObj ? contractTypeObj.name : null"
      />

      <form-select
        :label="$t('common.currency')"
        :required="true"
        @click="cshow.currency = true"
        :placeholder="$t('common.select_currency')"
        :value="common.currency ? common.currency.code : null"
      />

      <form-select
        :label="$t('app.sc.expense_account')"
        :required="false"
        @click="
          push(page[2].component, page[2].label, {
            company: companyObj ? companyObj.id : null
          })
        "
        :placeholder="$t('app.sc.select_account')"
        :value="expenseAccountObj ? expenseAccountObj.name : null"
      />

      <form-input
        kind="text"
        :required="true"
        :label="contractTypeObj && contractTypeObj.code === 'hr' ? $t('app.sc.employee_fullname') : $t('app.sc.contract_fullname')"
        :placeholder="$t('app.sc.enter_contract_fullname')"
        v-model="form.contract.contract_full_name"
      />

      <form-input
        kind="text"
        :label="$t('app.sc.supplier_fullname')"
        :placeholder="$t('app.sc.enter_name')"
        v-model="form.contract.supplier_full_name"
      />

      <div>
        <ons-list-item modifier="chevron" tappable class="border-b-0" @click="supplierAccountShow = true">
          <div class="center required">
            <span class="list-item__title ml-1">
              {{ contractTypeObj && contractTypeObj.code === "hr" ? $t("app.sc.employee_account_detail") : $t("app.sc.supplier_account_detail") }}
            </span>
            <!-- <span v-show="!isSupplierAccountFilled" class="list-item__subtitle">
              &nbsp;
            </span> -->
          </div>
        </ons-list-item>
        <ons-list-item v-show="isSupplierAccountFilled">
          <div class="center pt-0">
            <div class="w-full">
              <div class=" flex text-sm">
                <div class="w-1/2 text-gray-400">{{ $t("app.account.name") }}</div>
                <div>{{ form.contract.supplier_account.name }}</div>
              </div>
              <div class=" flex text-sm">
                <div class="w-1/2 text-gray-400">{{ $t("app.account.number") }}</div>
                <div>{{ form.contract.supplier_account.no }}</div>
              </div>
              <div class=" flex text-sm">
                <div class="w-1/2 text-gray-400">{{ $t("app.account.bank") }}</div>
                <div>{{ form.contract.supplier_account.bank_name }}</div>
              </div>
              <div class=" flex text-sm">
                <div class="w-1/2 text-gray-400">{{ $t("app.account.branch") }}</div>
                <div>{{ form.contract.supplier_account.branch_name }}</div>
              </div>
            </div>
          </div>
        </ons-list-item>
      </div>

      <form-input-mask
        kind="text"
        :required="false"
        :label="$t('app.sc.contract_period')"
        :placeholder="'YYYY-MM-DD'"
        :mask="{ mask: '9999-99-99', placeholder: 'YYYY-MM-DD' }"
        v-model="form.contract.contract_period"
      />

      <v-ons-list-item>
        <label class="center">
          {{ $t("app.sc.auto_renew") }}
        </label>
        <div class="right">
          <v-ons-switch v-model="form.contract.auto_renewal"> </v-ons-switch>
        </div>
      </v-ons-list-item>
    </ons-list>

    <br />
    <ons-list v-show="common.currency && contractTypeObj">
      <ons-list-header>{{ $t("app.item") }}</ons-list-header>
      <form-select
        v-for="purchase in items"
        :key="purchase.index"
        @click="onClickItem(purchase)"
        :label="$t('app.item') + ' (' + purchase.index + ')'"
        :value="(common.currency ? common.currency.code : '') + ' ' + Round(purchase.total, common.currency ? common.currency.code : null)"
      />
      <v-ons-list-item>
        <div class="center" style="font-size: 13px; color: rgba(0, 0, 0, 0.6)">
          {{ $t("app.sc.add_more_info") }}
        </div>
      </v-ons-list-item>
    </ons-list>
    <v-ons-list v-show="common.currency && contractTypeObj" class="mb-6">
      <v-ons-list-item class="p-0">
        <div class="center flex justify-center items-center p-0">
          <v-ons-button modifier="quiet" style="justify-content: center" @click="addPayment">
            <ons-icon icon="md-plus"> </ons-icon>
            {{ $t("common.add_more") }}
          </v-ons-button>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <br />
    <v-ons-list class="border-b border ">
      <v-ons-list-item>
        <div class="left">{{ $t("common.total") }}</div>
        <div class="center text-xl" style="margin-left: 10px">
          {{ common.currency ? common.currency.code : "" }}
          <span class="text-orange ml-2 font-bold">
            {{ Round(form.contract.amount, common.currency ? common.currency.code : null) }}
          </span>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <v-ons-list>
      <form-uploader
        :label="$t('app.sc.upload_contract_plan')"
        :sublabel="$t('app.sc.select_file')"
        :multiple="true"
        :required="false"
        :documentType="FILE_TYPE.CONTRACT_PLAN"
        :existing="this.files[FILE_TYPE.CONTRACT_PLAN]"
        @on-success="onUpload($event, FILE_TYPE.CONTRACT_PLAN)"
      />
    </v-ons-list>

    <v-ons-list class="mt-6">
      <v-ons-list-header>
        {{ $t("app.sc.after_approval_before_sign") }}
      </v-ons-list-header>
      <v-ons-list-item modifier="nodivider">
        <v-ons-col width="30%">
          <label>{{ $t("app.sc.stamp") }}</label>
        </v-ons-col>
        <v-ons-col style="margin-left: 10px"> {{ nextStampNo }} </v-ons-col>
      </v-ons-list-item>

      <!-- <form-uploader
        :label="$t('app.sc.upload_signed_contract')"
        :sublabel="$t('app.sc.select_file')"
        :multiple="true"
        :documentType="FILE_TYPE.CONTRACT_SIGNED"
        :existing="this.files[FILE_TYPE.CONTRACT_SIGNED]"
        @on-success="onUpload($event, FILE_TYPE.CONTRACT_SIGNED)"
      /> -->
    </v-ons-list>

    <v-ons-list class="mt-6">
      <form-select
        :label="$t('app.sc.frequency')"
        :placeholder="$t('app.sc.select_frequency')"
        @click="frequencySelectShow = true"
        :value="frequencyObj ? frequencyObj.name : null"
      />

      <form-input
        kind="text"
        :required="false"
        :label="$t('app.sc.payment_deadline')"
        :placeholder="$t('app.sc.payment_deadline')"
        v-model="form.contract.payment_deadline_text"
      />
    </v-ons-list>

    <br />
    <v-ons-list>
      <form-select-user
        :label="$t('app.forward_document')"
        :users="component.finance ? component.finance : []"
        @remove="handleRemoveFinance"
        @add="onClickComponent(COMPONENT.FINANCE, { default: component.finance })"
      />
    </v-ons-list>

    <process-card :required="false" :module-code="moduleCode">
      <hr />
      <v-ons-list class="bg-transparent mb-5">
        <v-ons-list-item>
          <label class="center">{{ $t("common.remember_approvers") }}</label>
          <div class="right">
            <v-ons-switch v-model="form.save_approvers"> </v-ons-switch>
          </div>
        </v-ons-list-item>
      </v-ons-list>
    </process-card>

    <bottom-submit @submit="handleSubmit" :loading="submitting">
      <template v-slot:additional>
        <div class="flex items-center p-2 space-x-4 w-full text-sm">
          <label class="left">
            <v-ons-checkbox :input-id="'checkbox-email'" v-model="form.send_notification_first_approval"> </v-ons-checkbox>
          </label>
          <label class="center" :for="'checkbox-email'"> {{ $t("app.send_notification_approver") }} </label>
        </div>
      </template>
    </bottom-submit>

    <SupplierAccount
      :title="contractTypeObj && contractTypeObj.code === 'hr' ? $t('app.sc.employee_account_detail') : $t('app.sc.supplier_account_detail')"
      :showing="supplierAccountShow"
      @save="handleSaveSupplierAccount"
      @on-close="supplierAccountShow = false"
    />

    <currency-select :showing="cshow.currency" @on-close="cshow.currency = false" @on-select="onCommonSelect($event, COMMON.CURRENCY)" />
    <ContractTypeSelect :showing="contractSelectShow" @on-close="contractSelectShow = false" @on-select="handleContractSelect" />
    <FrequencySelect :showing="frequencySelectShow" @on-close="frequencySelectShow = false" @on-select="handleFrequencySelect" />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { submitConctractSupplierBuffer } from "@/api/contractSupplier";
import ProcessCard from "@/views/common/ProcessCard";

import ContractPartyList from "@/views/common/list/ContractPartyList";
import DepartmentList from "@/views/common/list/DepartmentList";
import ExpenseAccountList from "@/views/common/list/ExpenseAccountList";

import CurrencySelect from "@/views/common/select/CurrencySelect";
import ContractTypeSelect from "@/views/common/select/ContractTypeSelect";
import FrequencySelect from "@/views/common/select/FrequencySelect";

import SupplierAccount from "@/views/Application/SupplierContract/component/SupplierAccount";
import DetailForm from "@/views/Application/SupplierContract/DetailForm";

const PURCHASE_GOODS_TYPE = "goods";
const SERVICE_TYPE = "services";
const HR_TYPE = "hr";
const OTHER_TYPE = "other";

import application from "@/mixins/application";

const defaultForm = {
  company_id: null,
  department_id: null,
  currency_id: null,
  contract_item_type_id: null,
  expense_account_id: null,
  contract_full_name: null,
  supplier_full_name: "",
  contract_frequency_id: null,
  payment_deadline_text: null,
  supplier_account: {
    name: null,
    no: null,
    bank_name: null,
    branch_name: null
  },
  contract_plan_files: [],
  signed_contract_files: [],
  contract_period: null,
  auto_renewal: false,
  estimated_approval_date: null,
  user_finances: [],
  amount: 0
};

export default {
  name: "SupplierForm",
  components: {
    CurrencySelect,
    ContractTypeSelect,
    FrequencySelect,
    SupplierAccount,
    ProcessCard
  },
  props: ["show", "init"],
  mixins: [application],
  computed: {
    approver() {
      return this.$store.getters.approvers;
    },
    isSupplierAccountFilled: function() {
      return (
        this.form.contract.supplier_account.name &&
        this.form.contract.supplier_account.no &&
        this.form.contract.supplier_account.bank_name &&
        this.form.contract.supplier_account.branch_name
      );
    },
    page() {
      return [
        {
          component: DetailForm,
          label: this.$t("app.item")
        },
        {
          component: ContractPartyList,
          label: this.$t("app.sc.select_contract_party")
        },
        {
          component: ExpenseAccountList,
          label: this.$t("app.sc.select_account")
        },
        {
          component: DepartmentList,
          label: this.$t("common.select_department")
        }
      ];
    }
  },
  watch: {
    show: function(newVal) {
      if (newVal) {
        this.onShow();
      }
    },
    init: function(newVal) {
      if (newVal) {
        this.onInit("contract");
      }
    },
    companyObj: {
      handler(n) {
        if (n) {
          this.form.contract.company_id = n.id;
        }
      },
      deep: true
    },
    departmentObj: {
      handler(n) {
        if (n) {
          this.form.contract.department_id = n.id;
        }
      },
      deep: true
    },
    "common.currency": {
      handler(n) {
        if (n) {
          this.form.contract.currency_id = n.id;
        }
      },
      deep: true
    },
    "form.contract.contract_period": {
      handler(n) {
        if (n == "") {
          this.form.contract.contract_period = null;
        }
      }
    },
    items: function(n) {
      if (n) {
        this.form.contract.amount = n.reduce((sum, obj) => sum + obj.total, 0);
      }
    },
    "component.finance": function(n) {
      if (n) {
        this.form.contract.user_finances = n.map((item) => item.id);
      }
    }
  },
  data: () => ({
    moduleCode: "contract-supplier",
    tempMaster: [
      "form",
      "items",
      "companyObj",
      "departmentObj",
      "contractTypeObj",
      "expenseAccountObj",
      "frequencyObj",
      "common",
      "files",
      "component"
    ],
    defaultTemp: ["common", "companyObj", "departmentObj"],
    form: {
      send_notification_first_approval: true,
      contract: Object.assign({}, defaultForm),
      save_approvers: true,
      approvals: []
    },
    processes: [],
    frequencySelectShow: false,
    contractSelectShow: false,
    supplierAccountShow: false,

    companyObj: undefined,
    departmentObj: undefined,
    contractTypeObj: undefined,
    expenseAccountObj: undefined,
    frequencyObj: undefined,

    loading: true,
    submitting: false,

    items: [
      {
        index: 1,
        total: 0
      }
    ],
    defaultItems: [
      {
        index: 1,
        total: 0
      }
    ]
  }),
  validations: {
    form: {
      contract: {
        currency_id: {
          required
        },
        company_id: {
          required
        },
        contract_item_type_id: {
          required
        },
        contract_full_name: {
          required
        }
      }
    }
  },
  methods: {
    onShow() {
      let param = this.$store.getters.param;

      let selectedContractParty = param.selectedContractParty;
      let selectedAccount = param.selectedAccount;
      let selectedDepartment = param.selectedDepartment;
      let contractItems = param.contractItems;
      let selectedFinance = param.selectedFinance;

      if (selectedFinance) {
        this.component[this.COMPONENT.FINANCE] = selectedFinance;
        this.form.contract.user_finances = selectedFinance.map((item) => item.id);
      }

      if (selectedDepartment) {
        this.departmentObj = selectedDepartment;
        this.form.contract.department_id = selectedDepartment.id;
      }
      if (selectedAccount) {
        this.expenseAccountObj = selectedAccount;
        this.form.contract.expense_account_id = selectedAccount.id;
      }
      if (selectedContractParty) {
        if (this.companyObj && this.companyObj.id != selectedContractParty.id) {
          this.resetCompanyRelation();
        }
        this.companyObj = selectedContractParty;
        this.form.contract.company_id = selectedContractParty.id;
      }

      if (contractItems) {
        this.items = this.items.map((obj) => {
          if (obj.index === contractItems.index) {
            return { ...contractItems };
          } else {
            return obj;
          }
        });

        this.populateItem();
      }

      this.$store.commit("navigator/clearParam");
      this.loading = false;
      this.$emit("reloaded");
    },
    loadData() {
      if (this.form.contract.user_finances) {
        this.form.contract.user_finances.forEach((item) => {
          this.loadApi(this.COMPONENT.FINANCE, item);
        });
      }
    },
    resetCompanyRelation() {
      this.expenseAccountObj = null;
      this.form.expense_account_id = null;
      this.departmentObj = null;
      this.form.contract.department_id = null;
    },
    onClickItem(item) {
      this.push(this.page[0].component, this.$t("app.item") + " (" + item.index + ")", {
        item: item,
        currencyObj: this.common.currency,
        contractType: this.contractTypeObj
      });
    },
    addPayment() {
      this.items = [...this.items, { index: this.items.length + 1, total: 0 }];
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.contract.company_id.$invalid) {
        await this.$ons.notification.alert(this.$t("common.alert.company"), options);
        return false;
      }
      if (this.$v.form.contract.contract_item_type_id.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.type"), options);
        return false;
      }
      if (this.$v.form.contract.currency_id.$invalid) {
        await this.$ons.notification.alert(this.$t("common.select_currency"), options);
        return false;
      }
      if (this.$v.form.contract.contract_full_name.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.fullname"), options);
        return false;
      }
      if (!this.isSupplierAccountFilled) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.supplier"), options);
        return false;
      }
      if (this.form.contract.contract_period != null && !this.$moment(this.form.contract.contract_period)._isValid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.contract_period"), options);
        return false;
      }

      if (
        this.form.contract.item_goods === undefined &&
        this.form.contract.item_services === undefined &&
        this.form.contract.item_hr === undefined &&
        this.form.contract.item_other === undefined
      ) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.items"), options);
        return false;
      }

      if (!this.approverIsValid) {
        await this.$ons.notification.alert(this.$t("common.alert.approver"), options);
        return false;
      }

      return true;
    },
    populateItem() {
      if (this.contractTypeObj?.code.toLowerCase() === PURCHASE_GOODS_TYPE) {
        this.form.contract.item_goods = this.items.map((item) => ({
          name: item.name,
          brand: item.brand,
          quantity: item.quantity,
          unit_price: item.unit_price,
          amount: item.amount,
          frequency: item.frequency,
          note: item.note
        }));
      }
      if (this.contractTypeObj?.code.toLowerCase() === SERVICE_TYPE) {
        this.form.contract.item_services = this.items.map((item) => ({
          content: item.content,
          type1: item.type1,
          type2: item.type2,
          unit_price: item.unit_price,
          start_date: item.start_date,
          term: item.term,
          note: item.note
        }));
      }
      if (this.contractTypeObj?.code.toLowerCase() === HR_TYPE) {
        this.form.contract.item_hr = this.items.map((item) => ({
          name: item.name,
          start_date: item.start_date,
          term: item.term,
          amount: item.amount,
          note: item.note
        }));
      }
      if (this.contractTypeObj?.code.toLowerCase() === OTHER_TYPE) {
        this.form.contract.item_other = this.items.map((item) => ({
          content: item.content,
          type1: item.type1,
          type2: item.type2,
          amount: item.amount,
          note: item.note
        }));
      }
    },
    handleSubmit() {
      this.isFormValid().then((valid) => {
        //remove approver if null
        this.form.approvals = this.form.approvals.filter((item) => item.approver_id !== null);

        if (valid) {
          this.submitting = true;
          submitConctractSupplierBuffer(this.form)
            .then((res) => {
              this.$ons.notification.alert({
                title: res.code ? res.code : this.$t("common.notif.saved"),
                messageHTML: ` ${this.$t("common.notif.submit")}`
              });
              this.populateDefaultTemp();
              this.$store.commit("navigator/pop");
            })
            .then(() => {
              this.submitting = false;
            });
        }
      });
    },
    updateFormCommon(type) {
      if (type === this.COMMON.CURRENCY) {
        this.form.contract.currency_id = this.common.currency.id;
      }
    },
    handleContractSelect(val) {
      this.contractSelectShow = false;
      this.contractTypeObj = val;
      this.form.contract.contract_item_type_id = this.contractTypeObj.id;
    },
    handleFrequencySelect(val) {
      this.frequencySelectShow = false;
      this.frequencyObj = val;
      this.form.contract.contract_frequency_id = this.frequencyObj.id;
    },
    handleEstimatedApprovalDeadline(val) {
      this.form.contract.estimated_approval_date = val;
    },
    handleSaveSupplierAccount(supplierAccountForm) {
      this.form.contract.supplier_account = supplierAccountForm;
      this.supplierAccountShow = false;
    },
    updateFormFile(type) {
      if (type === this.FILE_TYPE.CONTRACT_PLAN) {
        this.form.contract.contract_plan_files = this.files[this.FILE_TYPE.CONTRACT_PLAN].map((file) => file.id);
      }
      if (type === this.FILE_TYPE.CONTRACT_SIGNED) {
        this.form.contract.signed_contract_files = this.files[this.FILE_TYPE.CONTRACT_SIGNED].map((file) => file.id);
      }
    },
    handleReset() {
      this.$ons.notification.confirm(this.$t("common.notif.decide"), { title: this.$t("common.notif.confirm") }).then((response) => {
        if (response === 1) {
          this.form.contract = Object.assign({}, defaultForm);
          this.contractTypeObj = null;
          this.expenseAccountObj = null;
          this.frequencyObj = null;
          this.companyObj = null;
          this.departmentObj = null;
          this.items = this.defaultItems;
          this.files = {};
          if (this.common.currency) {
            this.form.contract.currency_id = this.common.currency.id;
          }
        }
      });
    }
  }
};
</script>

<style scoped></style>
