<template>
  <div>
    <v-ons-list-item
      tappable
      v-for="(item, key) in data"
      :key="key"
      class="p-0"
    >
      <div class="left list-item__left mr-2 p-0">
        <div
          class="h-full m-0 p-0 w-1.5"
          :class="{
            'bg-gray-300': !item.edited,
            'bg-red-400': item.edited
          }"
        ></div>
      </div>
      <div class="center list-item__center">
        <div class="w-full">
          <div class="mb-1.5">
            {{ $t("app.purc.goods_detail") }} ({{ key + 1 }})
          </div>
          <div class="space-y-1 text-sm">
            <div>
              <div class="flex">
                <div class="text-gray-400 mr-3">
                  {{ $t("app.purc.goods_name") }}
                </div>
                <div>{{ item.goods_name }}</div>
              </div>
              <div class="flex">
                <div class="text-gray-400 mr-3">
                  {{ $t("app.purc.budget_tax") }}
                </div>
                <div class="text-blue-500">
                  {{ item.currency }} {{ item.budget }}
                </div>
              </div>
              <div class="flex">
                <div class="text-gray-400 mr-3">
                  {{ $t("app.purc.estimated_unit_price") }}
                </div>
                <div class="text-blue-500">
                  {{ item.currency }} {{ item.unit_price }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="right">
        <v-ons-button modifier="quiet" @click="onDelete(key)">
          <Icon name="trash" stroke-width="1.5" classes="icon list-item__thumbnail" />
        </v-ons-button>
        <v-ons-button modifier="quiet" @click="onEdit(item, key)">
          <Icon name="edit" classes="icon list-item__thumbnail" />
        </v-ons-button>
      </div> -->
    </v-ons-list-item>
  </div>
</template>

<script>
export default {
  name: "RelatedList",
  props: ["data"],
  methods: {
    onDelete(index) {
      this.$emit("delete", index);
    },
    onEdit(item, index) {
      this.$emit("edit", item, index);
    }
  }
};
</script>

<style></style>
