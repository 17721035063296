<template>
  <div class="approval-process">
    <div class="relative">
      <div class="thumb p-0">{{ initial }}</div>
      <div v-if="status == 'done'" class="status bg-green">
        <Icon name="check" size="12" />
      </div>
      <div v-if="status == 'process'" class="status bg-orange">
        <Icon name="dots" size="12" />
      </div>
      <div v-if="status == 'waiting'" class="status bg-gray">
        <Icon name="dots" size="12" />
      </div>
      <div v-if="status == 'rejected'" class="status bg-rose-500">
        <Icon name="close" size="12" />
      </div>
    </div>
    <div class="ml-4 w-full">
      <div class="font-16 text-gray-400">{{ process }}</div>
      <div class="text-black flex justify-between">
        <div>{{ name }}</div>
        <div>{{ approved_at ? moment(approved_at).format("YYYY/MM/DD HH:mm") : "" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProcessThumb",
  props: ["status", "initial", "name", "process", "approved_at"]
};
</script>

<style></style>
