<template>
  <BottomSheet :title="data.title" :showing="data.show" @on-close="$emit('on-close')">
    <div class="content p-4 text-sm">
      <!-- <d-text :label="$t('common.related_approval')" :value="relatedApproval ? relatedApproval.code : '-'" /> -->
      <d-text :label="$t('app.page_application')" :value="item.page_number ? item.page_number.toString() : '-'" />
      <!-- <d-text :label="$t('common.company')" :value="company ? company : '-'" />
      <d-text :label="$t('common.department')" :value="department ? department : '-'" /> -->

      <hr />
      <d-subtitle> {{ $t("common.content") }}</d-subtitle>

      <d-item-content v-for="(item, key) in item.contents" :key="'content_' + key" :data="item" :currency="currency" class="mb-2">
        <div class="font-16 text-gray-500">
          <span v-if="item.destination">{{ $t("app.reimb.destination") }} : {{ item.destination }},</span>
          {{ item.date ? moment(item.date).format("YYYY/MM/DD") : "" }}
        </div>
        <div class="flex items-center text-gray-500" v-if="item.from_to && item.from_to.length > 1">
          {{ item.from_to.split(",")[0] }} <Icon name="arrow-right" classes="h-6 w-6 px-1" /> {{ item.from_to.split(",")[1] }}
        </div>
      </d-item-content>

      <hr />
      <d-subtitle> {{ $t("common.related_approval_info") }}</d-subtitle>
      <div v-if="noRelations" class="text-gray-400">{{ $t("common.empty") }}</div>
      <d-item-related
        v-else
        v-for="(item, key) in item.related_items"
        :key="'related_' + key"
        :title="$t('app.purc.goods_detail') + ' (' + (key + 1) + ')'"
        :data="item"
      />
    </div>
  </BottomSheet>
</template>

<script>
import BottomSheet from "@/components/BottomSheet";
// import { fetchDetail as getRelatedApproval } from "@/api/purchase";
// import buffer from "@/mixins/buffer";

export default {
  name: "ReimbursementDetail",
  props: {
    data: {
      type: Object
    },
    currency: {
      type: String
    }
  },
  components: { BottomSheet },
  data() {
    return {
      relatedApproval: null
    };
  },
  computed: {
    item() {
      return this.data.data;
    },
    show() {
      return this.data.show;
    },
    noRelations() {
      return this.item.related_items === null || this.item.related_items.length === 0;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // this.fetchCurrency(this.item.currency_id);
      // this.fetchCompany(this.item.company_id);
      // this.fetchDepartment(this.item.department_id);
      // if (this.item.related_approval_id) {
      //   this.fetchRelatedApproval(this.item.related_approval_id);
      // }
      // this.loading = false;
    }
    // async fetchRelatedApproval(id) {
    //   await getRelatedApproval(id).then((response) => {
    //     this.relatedApproval = response;
    //   });
    // }
  }
};
</script>

<style></style>
