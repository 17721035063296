import request from "@/utils/request";

export function fetchData(params) {
  return request({
    url: "/backgrounds",
    method: "get",
    params: params
  });
}

export function fetchBackgroundDetail(background_id) {
  return request({
    url: `/backgrounds/${background_id}`,
    method: "get"
  });
}

export function fetchPic(params) {
  return request({
    url: "/pic_types",
    method: "get",
    params: params
  });
}

export function fetchPicById(id) {
  return request({
    url: "/pic_types/" + id,
    method: "get"
  });
}

export function fetchPicUsers(params) {
  return request({
    url: "/users/pic",
    method: "get",
    params: params
  });
}

export function createBackground(params) {
  return request({
    url: "/buffer/backgrounds",
    method: "post",
    data: params
  });
}

export function updateBackground(params, backgroundId) {
  return request({
    url: `/buffer/backgrounds/${backgroundId}/edit`,
    method: "post",
    data: params
  });
}

export function fetchBackgroundPic(companyId) {
  return request({
    url: "/backgrounds/setting/" + companyId,
    method: "get"
  });
}
