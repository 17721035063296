<template>
  <v-ons-action-sheet :visible="showing" @posthide="handleClose" cancelable class="action-sheet-form">
    <div class="action-sheet-title">
      <div class="left" @click="handleClose">
        <Icon name="chevron-down" classes="h-6 w-6" />
      </div>
      <div>{{ title }}</div>
    </div>

    <div style="max-height:80vh;overflow: auto;padding-bottom:28px;">
      <slot />
    </div>
    <ons-row class="border-t flex items-center justify-center py-4">
      <v-ons-button @click="handleSave" modifier="quiet">
        {{ $t("common.save") }}
      </v-ons-button>
    </ons-row>
  </v-ons-action-sheet>
</template>

<script>
export default {
  name: "BottomSheetAction",
  props: ["title", "showing"],
  methods: {
    handleClose() {
      this.$emit("on-close");
    },
    handleSave() {
      this.$emit("on-save");
    }
  }
};
</script>

<style></style>
