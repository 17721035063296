<template>
  <div class="space-y-6 mb-16">
    <v-ons-list>
      <v-ons-list-header />
      <v-ons-list>
        <form-textarea
          :label="$t('app.cc.item.content')"
          :placeholder="$t('app.cc.item.enter_content')"
          :required="true"
          v-model="item_services.content"
        />
      </v-ons-list>
    </v-ons-list>

    <v-ons-list>
      <form-input kind="text" :label="$t('app.cc.item.type1')" :placeholder="$t('app.cc.item.enter_type1')" v-model="item_services.type1" />
      <form-input kind="text" :label="$t('app.cc.item.type2')" :placeholder="$t('app.cc.item.enter_type2')" v-model="item_services.type2" />
    </v-ons-list>

    <v-ons-list>
      <form-input
        :label="$t('app.cc.item.unit')"
        :placeholder="$t('app.cc.item.enter_unit')"
        :required="true"
        v-model.number="item_services.unit_price"
      />

      <form-input
        :label="$t('app.cc.item.freq')"
        :placeholder="$t('app.cc.item.enter_freq')"
        :required="true"
        v-model.number="item_services.frequency"
      />
    </v-ons-list>

    <v-ons-list>
      <form-textarea :label="$t('app.cc.item.term')" :required="true" :placeholder="$t('app.cc.item.enter_term')" v-model="item_services.term" />
      <form-textarea :label="$t('common.notes')" :placeholder="$t('common.enter_notes')" v-model="item_services.note" />
    </v-ons-list>

    <bottom-submit :label="$t('common.next')" @click.native="handleSubmit" classes="bg-blue-500" />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "customerDetailServices",
  props: ["param"],
  mounted() {
    if (this.param.item.item) {
      this.item_services = this.param.item.item;
    } else if (this.param.item) {
      this.item_services = this.param.item;
    }
  },
  watch: {
    item_services: {
      deep: true,
      handler(n) {
        n.total = n.unit_price;
      }
    }
  },
  data() {
    return {
      item_services: {
        content: "",
        type1: "",
        type2: "",
        unit_price: null,
        frequency: null,
        term: "",
        note: ""
      }
    };
  },
  validations: {
    item_services: {
      content: {
        required
      },
      unit_price: {
        required
      },
      frequency: {
        required
      }
    }
  },
  methods: {
    handleSubmit() {
      this.isFormValid().then((valid) => {
        if (valid) {
          this.$store.commit("app/SET_DATA", {
            index: this.param.item.index,
            total: this.item_services.unit_price,
            name: "item_services",
            item: this.item_services
          });

          this.$store.commit("navigator/pop");
        }
      });
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.item_services.content.$invalid) {
        await this.$ons.notification.alert(this.$t("app.cc.alert.item.content"), options);
        return false;
      }
      if (this.$v.item_services.unit_price.$invalid) {
        await this.$ons.notification.alert(this.$t("app.cc.alert.item.unit"), options);
        return false;
      }
      if (this.$v.item_services.frequency.$invalid) {
        await this.$ons.notification.alert(this.$t("app.cc.alert.item.freq"), options);
        return false;
      }

      return true;
    }
  }
};
</script>

<style scoped></style>
