<template>
  <v-ons-page @init="handleInit">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <v-ons-card>
      <div class="content p-2 space-y-2">
        <d-text :label="$t('app.purc.delivery_date')" :value="data.delivery_date ? moment(data.delivery_date).format('YYYY/MM/DD') : '-'" />
        <d-text :label="$t('app.purc.delivery_place')" :value="data.delivery_place" />
        <hr />
        <d-text :label="$t('app.purc.goods_name')" :value="data.goods_name" />
        <div class="py-2">
          <div class="font-16 text-dark-gray">{{ $t("app.purc.recurrent") }}</div>
          <div class="right">
            <v-ons-switch input-id="switch1" v-model="data.is_recurrent" disabled="true"> </v-ons-switch>
          </div>
        </div>
        <d-text :label="$t('app.purc.type_brand')" :value="data.brand" />
        <d-text :label="$t('app.quantity')" :value="data.qty.toString()" />
        <d-text :label="$t('app.purc.budget_tax')" :value="data.budget.toString()" />
        <d-text :label="$t('app.purc.estimated_unit_price')" :value="data.estimated_unit_price.toString()" />
        <d-text :label="$t('common.notes')" :value="data.notes" />
        <hr />
        <form-uploader classes="p-0" :label="$t('app.purc.upload_photo')" :existing="photoList" :disabled="true" :history="null" />
        <!-- <div class="font-16 text-dark-gray">{{ $t("app.purc.upload_photo") }}</div>
        <l-file v-for="(item, key) in photoList" :key="'photo_' + key" :show_action="false" :file="item" @on-click="preview" /> -->

        <d-text :label="$t('app.purc.source')" :value="data.source" />
        <d-text
          :label="$t('app.purc.arrange_by')"
          :value="data.arrange_by ? (data.arrange_by === 'me' ? $t('app.purc.by_me') : $t('app.purc.by_ga')) : '-'"
        />
        <weui-gallery :visible="previewShow" :src="previewImg" :canDelete="false" @onClick="previewShow = false"></weui-gallery>
      </div>
    </v-ons-card>
  </v-ons-page>
</template>

<script>
import { fetchDetail as getFile } from "@/api/file";
import buffer from "@/mixins/buffer";

export default {
  name: "PurchaseDetail",
  mixins: [buffer],
  data() {
    return {
      loading: false,
      photoList: []
    };
  },
  computed: {
    data() {
      return this.toolbarInfo.data;
    }
  },
  methods: {
    handleInit() {
      this.fetchPhotos();
    },
    async fetchPhotos() {
      this.photoList = [];
      await this.data.purchase_goods_photo.forEach((item) => {
        getFile(item).then((f) => {
          this.photoList.push({ ...f, size: f.size_string, is_edited: false });
        });
      });
    }
  }
};
</script>

<style></style>
