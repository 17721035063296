<template>
  <v-ons-page>
    <div class="bg-white flex flex-col h-full justify-between">
      <div class="border-b-8 border-blue-600">
        <img :src="require('@/assets/images/bg1.png')" alt="allastarsea" style="height: 40vh;width: 100%;object-fit: cover;" />
      </div>
      <div class="md:w-96 px-10 m-auto mt-16 w-full">
        <div>
          <div class="border-b border-gray-400 flex items-center w-full">
            <span class="approval-icon bg-transparent">
              <Icon name="mail" classes="text-gray-400" size="24" />
            </span>
            <input type="text" class="px-3 py-2 rounded-md text-input h-12 w-full" v-model="loginForm.username" placeholder="邮箱地址" />
          </div>
          <div class="border-b border-gray-400 flex items-center w-full relative">
            <span class="approval-icon bg-transparent">
              <Icon name="lock" classes="text-gray-400" size="24" />
            </span>
            <input type="password" class="px-3 py-2 rounded-md text-input h-12 w-9/12" v-model="loginForm.password" placeholder="密码" />
            <div class="absolute right-0 border-l pl-3" @click="push(page[0].component, page[0].label)">
              <span class="text-blue-500 text-sm">忘记？</span>
            </div>
          </div>
        </div>
        <div class="mt-8">
          <div class="bg-white bottom-0">
            <ons-button class="bg-blue-500 button rounded-md text-center w-full py-1.5" :disabled="loading" @click="handleLogin">
              {{ loading ? "登录中" : "登录" }}
            </ons-button>
          </div>
        </div>
      </div>
      <div class="px-4 text-left mb-5">
        <div style="font-size:0.6rem">
          <a href="https://beian.miit.gov.cn/" target="_blank" class="text-blue-500">
            沪公网备 31010502006668 号 <br />
            沪ICP备2023007206号-1
          </a>
          <span class="">ALLASTARSEA版权</span>
        </div>
        <div style="font-size:0.5rem" class="text-gray-500">
          <div>
            主办单位：星翌霜信息科技（上海）有限公司
          </div>
          <div>
            对外办公时间 周一至周五 地址 上海市，广东省珠海市，江苏省苏州市
          </div>
          <div>
            电话：86-2168413688（上海），86-7562991525（珠海），86-51268204329（苏州）
          </div>
          <div>
            建议使用IE9.0,1024×768以上分辨率浏览本网站
          </div>
        </div>
      </div>
    </div>
  </v-ons-page>
</template>

<script>
import AppSplitterVue from "@/AppSplitter.vue";
import ResetPassword from "@/views/Security/ResetPassword";
import { fetchData } from "@/api/profile";

export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        username: "",
        password: ""
      },
      loading: false,
      page: [
        {
          component: ResetPassword,
          label: "重设密码"
        }
      ]
    };
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$store
        .dispatch("user/Login", this.loginForm)
        .then(() => {
          this.loading = false;
          this.setProfile();
          this.$store.commit("tabbar/resetIndex");
          this.$store.commit("navigator/reset", AppSplitterVue);
        })
        .catch(() => {
          let err = this.$store.getters.loginError;
          if (!err.locked) {
            this.$dialog.show({
              type: "ios",
              content: `邮箱地址或密码无效 (${err.failed_attempt})`,
              showCancel: false,
              okText: "知道了"
            });
          } else {
            this.$dialog.show({
              type: "ios",
              content: "Your account has been locked, please check your email to reset your password.",
              showCancel: false,
              okText: "知道了"
            });
          }

          this.loading = false;
        });
    },
    async setProfile() {
      await fetchData().then((response) => {
        this.$store.commit("user/SET_PROFILE", response);
        this.$store.commit("user/SET_ROLES", response.roles["hydra:member"]);
      });
    },
    push(page, key) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: "返回",
              title: key
            }
          };
        }
      });
    }
  }
};
</script>
