import i18n from "@/locale";
import { Round } from "./number";
import store from "@/store/index";

export function mapInfo(module, info, data) {
  if (module === "contract-customer") {
    return [
      { key: i18n.tc("common.total"), value: info.currency_code + " " + Round(data.amount ? data.amount : info.total, info.currency_code) },
      { key: i18n.tc("app.cc.stamp"), value: info.stamp_no },
      { key: i18n.tc("app.cc.contract_fullname"), value: data.contract_full_name }
    ];
  }
  if (module === "contract-supplier") {
    return [
      {
        key: i18n.tc("common.total"),
        value: info.currency_code + " " + Round(data.amount ? data.amount : info.amount, info.currency_code)
      },
      { key: i18n.tc("app.cc.stamp"), value: info.stamp_no },
      { key: i18n.tc("app.sc.contract_fullname"), value: data.contract_full_name }
    ];
  }

  if (module === "reimbursement") {
    let expenses = store.getters.expenses;
    let item = data?.transaction_items.map((item) =>
      item.contents.map((c) => {
        let expense = expenses.find((e) => e.id === c.content_type_id);
        if (expense) {
          return {
            key: expense.name,
            value: info.currency_code + " " + Round(c.amount, info.currency_code)
          };
        }
      })
    );

    let flatItem = [];
    for (let i = 0; i < item.length; i++) {
      flatItem = flatItem.concat(item[i]);
    }

    let r = flatItem.length > 0 ? flatItem.sort((a, b) => b.value - a.value) : [];

    //max item should only 2
    let res = [];
    for (let i = 0; i <= 1; i++) {
      if (r[i]) {
        res[i] = r[i];
      }
    }

    let t = [
      {
        key: i18n.tc("common.total") + ` (${flatItem.length}) `,
        value: info.currency_code + " " + Round(data.total ? data.total : info.total, info.currency_code)
      }
    ];

    return res.concat(t);
  }

  if (module === "payment") {
    let recipient = null;
    if (data) {
      recipient = data.transaction_items.map((item) => {
        let sum = 0;
        item.contents.forEach((val) => (sum += val.amount));
        return {
          key: item.payment_recipient_account.name,
          value: sum
        };
      });
    }

    let r = recipient !== null ? recipient.sort((a, b) => b.value - a.value) : [];
    let t = [
      {
        key: i18n.tc("common.total") + ` (${r.length}) `,
        value: info.currency_code + " " + Round(data.total ? data.total : info.total, info.currency_code)
      }
    ];

    r = r.map((item) => ({
      key: item.key,
      value: info.currency_code + " " + Round(item.value, info.currency_code)
    }));

    let res = [];
    for (let i = 0; i <= 1; i++) {
      if (r[i]) {
        res[i] = r[i];
      }
    }

    return res.concat(t);
  }

  if (module === "purchase") {
    return [
      { key: i18n.tc("app.item"), value: info.total_goods },
      { key: i18n.tc("common.amount"), value: Round(data.total_estimated) }
    ];
  }

  if (module === "collection-debt" || module === "collection-report" || module === "collection-sales") {
    return [
      { key: i18n.tc("app.col.customer"), value: info.customer_name },
      { key: i18n.tc("common.amount"), value: info.currency.code + " " + Round(info.amount, info.currency_code) }
    ];
  }

  if (module === "corporate-seal") {
    let purpose = null;
    if (data?.purpose) {
      let trans = data.purpose.split(",").map((item) => i18n.tc(item));
      purpose = {
        key: i18n.tc("app.stamp.purpose"),
        value: trans.join(", ")
      };
    }

    return [
      purpose,
      // { key: i18n.tc("app.stamp.usage"), value: info.type_name.name[i18n.locale] },
      { key: i18n.tc("app.stamp.stamp"), value: info.stamp_no },
      { key: i18n.tc("app.stamp.fullname"), value: data.stamped_party_full_name }
    ];
  }

  if (module === "user") {
    return [
      { key: i18n.tc("admin.user.name"), value: info.name },
      { key: i18n.tc("admin.user.company"), value: info.company + " - " + info.department },
      { key: i18n.tc("admin.user.location"), value: info.location }
    ];
  }

  if (module === "expense-account") {
    return [
      { key: i18n.tc("common.company"), value: info.company },
      { key: i18n.tc("stat.expense_.name"), value: info.expense_account_name }
    ];
  }

  if (module === "background") {
    return [
      { key: i18n.tc("admin.background.group_name"), value: info.name },
      { key: i18n.tc("admin.background.company"), value: info.company }
    ];
  }

  if (module === "company") {
    return [{ key: i18n.tc("admin.company.company"), value: info.name }];
  }

  if (module === "bank-account") {
    return [
      { key: i18n.tc("common.company"), value: info.company },
      { key: i18n.tc("stat.bank_.name"), value: info.bank_name }
    ];
  }
}
