<template>
  <BottomSheetAction
    :title="$t('app.stamp.select_purpose')"
    :showing="showing"
    @on-close="$emit('on-close')"
    @on-save="$emit('on-save', selectedList)"
  >
    <v-ons-list>
      <v-ons-list-item v-for="(item, key) in List" :key="key" tappable>
        <label class="left">
          <v-ons-checkbox :input-id="'purpose_list-' + key" :value="item" v-model="selectedList"> </v-ons-checkbox>
        </label>
        <label :for="'purpose_list-' + key" class="center">
          {{ $t(item) }}
        </label>
      </v-ons-list-item>
    </v-ons-list>
  </BottomSheetAction>
</template>

<script>
import BottomSheetAction from "@/components/BottomSheetAction";

export default {
  name: "PurposeSelect",
  props: ["showing", "selection"],
  components: {
    BottomSheetAction
  },
  data() {
    return {
      List: [
        "app.stamp.purposes.borrow_stamp",
        "app.stamp.purposes.borrow_id",
        "app.stamp.purposes.use_electronic_id",
        "app.stamp.purposes.use_photocopy_id",
        "app.stamp.purposes.authorize_deed",
        "app.stamp.purposes.signature",
        "app.stamp.purposes.without_stamp"
      ],
      selectedList: this.selection
    };
  },
  watch: {
    showing: function(n) {
      if (n) {
        this.selectedList = this.selection;
      }
    }
  }
};
</script>

<style></style>
