<template>
  <v-ons-page @init="handleInit" @show="handleShow">
    <Loading v-show="loading" />
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>

    <div>
      <ons-list>
        <v-ons-list-header />
        <form-select
          :label="$t('stat.export_.type')"
          :placeholder="$t('stat.export_.select_type')"
          @click="moduleSelectShow = true"
          :value="selectedModule ? selectedModule.text : null"
        />

        <form-select
          :label="$t('common.company')"
          :placeholder="$t('common.select_company')"
          @click="push(page[0].component, page[0].label)"
          :value="selectedCompany ? selectedCompany.name : null"
        />

        <form-select
          :label="$t('stat.export_.date_type')"
          :placeholder="$t('common.select')"
          @click="dateTypeShow = true"
          :value="selectedDateType ? selectedDateType.text : null"
        />

        <form-calendar
          :label="$t('stat.export_.period')"
          :placeholder="$t('stat.export_.select_period')"
          mode="during"
          @on-change="handlePeriodSelect"
          :value="selectedPeriod"
        />
        <form-select
          :label="$t('stat.export_.file_type')"
          :placeholder="$t('stat.export_.select_file_type')"
          @click="fileTypeShow = true"
          :value="selectedFileType ? selectedFileType : null"
        />
      </ons-list>

      <form-button :loading="submitting" classes="py-6" @click.native="handleExport">
        <icon name="export" :size="22" classes="mr-2" />
        {{ submitting ? "Loading..." : $t("stat.export_.export") }}
      </form-button>
    </div>

    <module-select :showing="moduleSelectShow" @on-close="moduleSelectShow = false" @on-select="handleModuleSelect" />
    <file-type-select :showing="fileTypeShow" :value="selectedFileType" @on-close="fileTypeShow = false" @on-select="handleFileTypeSelect" />
    <date-type-select :showing="dateTypeShow" @on-close="dateTypeShow = false" @on-select="handleDateTypeSelect" />
  </v-ons-page>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import moduleSelect from "@/views/Statistic/Export/components/moduleSelect";
import fileTypeSelect from "@/views/Statistic/Export/components/fileTypeSelect";
import dateTypeSelect from "@/views/Statistic/Export/components/dateTypeSelect";
import CompanyList from "@/views/common/list/ContractPartyList";
import { SubmitExport } from "@/api/export";

export default {
  name: "Export",
  components: { moduleSelect, dateTypeSelect, fileTypeSelect },
  data() {
    return {
      loading: true,
      submitting: false,
      filter: {
        moduleCode: null,
        period: null,
        dateType: "completion",
        companyId: null,
        fileType: "pdf"
      },
      moduleSelectShow: false,
      fileTypeShow: false,
      dateTypeShow: false,
      selectedPeriod: "",
      selectedModule: "",
      selectedFileType: "pdf",
      selectedDateType: "",
      selectedCompany: null
    };
  },
  validations: {
    filter: {
      moduleCode: {
        required
      },
      period: {
        required
      },
      companyId: {
        required
      },
      fileType: {
        required
      }
    }
  },
  computed: {
    page() {
      return [
        {
          component: CompanyList,
          label: this.$t("common.select_company")
        }
      ];
    }
  },
  methods: {
    handleInit() {
      this.loading = false;
      this.selectedDateType = Object.assign(
        {},
        {
          value: "completion",
          text: this.$t("stat.export_.date_type_.completion")
        }
      );
    },
    handleShow() {
      let param = this.$store.getters["navigator/getParam"];
      let selectedCompany = param.selectedContractParty;
      if (selectedCompany) {
        this.selectedCompany = selectedCompany;
        this.filter.companyId = selectedCompany.id;
      }

      this.$store.commit("navigator/clearParam");
    },
    handleModuleSelect(val) {
      this.selectedModule = val;
      this.filter.moduleCode = this.selectedModule.value;
      this.moduleSelectShow = false;
    },
    handleFileTypeSelect(val) {
      this.selectedFileType = val;
      this.filter.fileType = this.selectedFileType;
      this.fileTypeShow = false;
    },
    handleDateTypeSelect(val) {
      this.selectedDateType = val;
      this.filter.dateType = this.selectedDateType.value;
      this.dateTypeShow = false;
    },
    handlePeriodSelect(val) {
      this.selectedPeriod = val.join(" to ");
      this.filter.period = val.join("to");
    },
    async isFilterValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.filter.moduleCode.$invalid) {
        await this.$ons.notification.alert(this.$t("stat.export_.alert.type"), options);
        return false;
      }
      if (this.$v.filter.companyId.$invalid) {
        await this.$ons.notification.alert(this.$t("common.alert.company"), options);
        return false;
      }
      if (this.$v.filter.period.$invalid) {
        await this.$ons.notification.alert(this.$t("stat.export_.alert.period"), options);
        return false;
      }
      if (this.$v.filter.fileType.$invalid) {
        await this.$ons.notification.alert(this.$t("stat.export_.alert.file_type"), options);
        return false;
      }

      return true;
    },
    handleExport() {
      this.isFilterValid().then((valid) => {
        if (valid) {
          this.submitting = true;
          SubmitExport(this.filter).then(() => {
            this.$ons.notification.toast(this.$t("common.notif.email"), {
              timeout: 2500,
              animation: "fall"
            });
            this.$store.commit("navigator/pop");
          });

          this.submitting = false;
        }
      });
    },
    push(page, key, param) {
      this.$store.commit("navigator/options", {
        animation: "lift-ios"
      });
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped></style>
