<template>
  <v-ons-page>
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>

    <customer-detail-purchase v-if="isPurchase" :param="parameter" />
    <customer-detail-services v-if="isServices" :param="parameter" />
    <customer-detail-other v-if="isOther" :param="parameter" />
  </v-ons-page>
</template>

<script>
import CustomerDetailPurchase from "@/views/Application/CustomerContract/component/customerDetailPurchase";
import CustomerDetailServices from "@/views/Application/CustomerContract/component/customerDetailServices";
import CustomerDetailOther from "@/views/Application/CustomerContract/component/customerDetailOther";

export default {
  name: "customerDetailForm",
  components: {
    CustomerDetailOther,
    CustomerDetailServices,
    CustomerDetailPurchase
  },
  data() {
    return {
      loading: true,
      form: {}
    };
  },
  computed: {
    isPurchase: function() {
      return this.toolbarInfo.param.contractTypeObj.code === "goods";
    },
    isServices: function() {
      return this.toolbarInfo.param.contractTypeObj.code === "services";
    },
    isOther: function() {
      return this.toolbarInfo.param.contractTypeObj.code === "other";
    },
    parameter: function() {
      return this.toolbarInfo.param;
    }
  }
};
</script>

<style scoped></style>
