<template>
  <div>
    <div>
      <span class="text-gray-500 mr-3">{{ $t("app.sc.item.name") }}</span>
      <span>{{ item.name }}</span>
    </div>
    <div>
      <span class="text-gray-500 mr-3">{{ $t("app.sc.item.budget_tax") }}</span>
      <span>{{ currency }} {{ item.amount }}</span>
    </div>
    <div>
      <span class="text-gray-500 mr-3">{{ $t("app.sc.item.unit_price") }}</span>
      <span>{{ currency }} {{ item.unit_price }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RelatedGoods",
  props: ["item", "currency"]
};
</script>

<style></style>
