<template>
  <ons-bottom-toolbar class="z-10" style="height:59px !important">
    <div class="bg-white shadow w-full bottom-0 fixed  border-t border-gray-100 p-2 ">
      <div class="flex mb-2">
        <div v-if="canSendNotif" class="flex items-center px-2 space-x-4 w-full text-sm">
          <label class="left">
            <v-ons-checkbox :input-id="'checkbox-email'" v-model="notificationEmail"> </v-ons-checkbox>
          </label>
          <label class="center" :for="'checkbox-email'">
            {{ $t("app.send_notification_approver") }}
          </label>
        </div>
      </div>
      <div class="flex justify-center justify-between">
        <!-- <div>
        <div
          v-if="treasury"
          class="bg-white outline-none flex h-full items-center px-3 rounded-md"
          @click="push(page[0], code, { buffer: buffer, approval: approval })"
        >
          <Icon name="edit" size="24" stroke="1.5" classes="text-gray-500" />
        </div>
      </div> -->
        <div>
          <div
            class="bg-white outline-none flex h-full items-center px-3 rounded-md"
            v-if="editableModules.includes(module)"
            @click="push(page[1], code, { buffer: buffer, approval: approval, module: module, history: history })"
          >
            <Icon name="edit" size="24" stroke="1.5" classes="text-gray-500" />
          </div>
        </div>
        <div>
          <v-ons-button
            v-show="canStepback"
            class="btn-primary outline text-white mr-2 button rounded-md"
            style="padding: 3px 22px"
            @click="$emit('stepback')"
          >
            <div class="flex items-center">
              <Icon name="reply" classes="mr-1" />
              <div>{{ $t("app.stepback") }}</div>
            </div>
          </v-ons-button>
          <v-ons-button class="btn-primary outline text-white mr-2 button rounded-md" style="padding: 3px 22px" @click="$emit('reject')">
            {{ $t("common.reject") }}
          </v-ons-button>
          <v-ons-button class="btn-primary text-white button rounded-md" style="padding: 3px 22px" @click="$emit('approve', notificationEmail)">
            {{ $t("common.approve") }}
          </v-ons-button>
        </div>
      </div>
    </div>
  </ons-bottom-toolbar>
</template>

<script>
import TreasuryForm from "@/views/Approval/form/TreasuryForm";
import ApplicationEdit from "@/views/ApplicationEdit";

export default {
  name: "Action",
  props: ["process", "code", "buffer", "approval", "history", "canStepback", "canSendNotif", "sendNotif"],
  data() {
    return {
      page: [TreasuryForm, ApplicationEdit],
      editableModules: ["reimbursement", "payment", "purchase", "contract-supplier", "contract-customer", "corporate-seal"],
      notificationEmail: this.sendNotif
    };
  },
  watch: {
    sendNotif: {
      handler(n) {
        this.notificationEmail = n;
      },
      deep: true
    }
  },
  computed: {
    role() {
      return this.process.process.selected_roles;
    },
    module() {
      return this.process.module.code;
    },
    treasury() {
      return this.role.includes("ROLE_TREASURY") && (this.module === "payment" || this.module === "reimbursement");
    }
  },
  methods: {
    push(page, key, param) {
      this.$store.commit("navigator/options", {
        animation: "lift-ios"
      });

      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style></style>
