<template>
  <div class="space-y-2">
    <d-text :label="$t('app.sc.item.name')" :value="item.name ? item.name : '-'" />
    <d-text :label="$t('app.sc.item.start_date')" :value="startDate" />
    <d-text :label="$t('app.sc.item.terms')" :value="item.term ? item.term : '-'" />
    <hr />
    <d-text :label="$t('app.sc.item.national_amount')" :value="amount" />
    <hr />
    <d-text :label="$t('common.notes')" :value="item.note ? item.note : '-'" />
  </div>
</template>

<script>
export default {
  name: "SupplierContractItemHr",
  props: ["data"],
  computed: {
    item() {
      return this.data.item;
    },
    currency() {
      return this.data.currency;
    },
    amount() {
      return this.currency + " " + (this.item.amount ? this.item.amount : "0");
    },
    startDate() {
      return this.item.start_date ? this.moment(this.item.start_date).format("YYYY/MM/DD") : "-";
    }
  }
};
</script>

<style></style>
