<template>
  <div class="bg-white shadow-sm flex py-2 px-2 rounded-md space-x-2 text-sm" @click="handleDetail">
    <div class="w-full">
      <div class="flex justify-between items-center">
        <div class="approval-user-info">
          <div class="thumb p-0">
            {{ item.applicant_name.charAt(0) }}
          </div>
          <span class="text-sm">{{ item.applicant_name }}</span>
        </div>
        <Icon name="chevron-right" classes="text-gray-500" size="18" v-if="allowView" />
      </div>
      <div class="flex flex-col mt-1 text-left">
        <div class="leading-6 text-xs  border-gray-100">
          <div class="flex justify-between">
            <div class="text-gray-400">
              {{ $t("app.cc.stamp") }}
            </div>
            <div>{{ item.stamp_no }}</div>
          </div>
        </div>
        <!-- <div class="leading-6 text-xs">
          <div class="flex justify-between">
            <div class="text-gray-400">
              {{ $t("app.cc.contract_type") }}
            </div>
            <div>{{ item.type }}</div>
          </div>
        </div> -->
        <div class="leading-6 text-xs border-t border-gray-100" v-for="(item, key) in additionals" :key="`additional_${key}`">
          <div class="flex justify-between">
            <div class="text-gray-400 truncate">
              {{ item.key }}
            </div>
            <div class="w-2/3 truncate text-right">{{ item.value }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cards",
  props: ["item", "allowView"],
  methods: {
    handleDetail() {
      if (this.allowView) {
        this.$emit("detail", this.item.buffer);
      }
    }
  },
  computed: {
    additionals() {
      return this.item && this.item.buffer.additionalInfo ? this.item.buffer.additionalInfo : [];
    }
  }
};
</script>

<style></style>
