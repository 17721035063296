<template>
  <BottomSheet :title="$t('stat.export_.date_type')" :showing="showing" @on-close="$emit('on-close')">
    <v-ons-list class="pb-16">
      <v-ons-list-item v-for="(item, key) in list" :key="key" tappable @click="handleChange(item)">
        <label class="left">
          <v-ons-checkbox :input-id="'date-type-select-' + key" :value="item.value" v-model="selectedOption"> </v-ons-checkbox>
        </label>
        <label :for="'date-type-select-' + key" class="center">
          {{ item.text }}
        </label>
      </v-ons-list-item>
    </v-ons-list>
  </BottomSheet>
</template>

<script>
import BottomSheet from "@/components/BottomSheet";

export default {
  name: "dateTypeSelect",
  props: {
    showing: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BottomSheet
  },
  watch: {
    showing: function(newVal) {
      if (newVal) {
        this.selectedOption = [];
      }
    }
  },
  data() {
    return {
      selectedOption: []
    };
  },
  computed: {
    list() {
      return [
        {
          value: "completion",
          text: this.$t("stat.export_.date_type_.completion")
        },
        {
          value: "payment",
          text: this.$t("stat.export_.date_type_.payment")
        }
      ];
    }
  },
  methods: {
    handleChange(val) {
      this.$emit("on-select", val);
    }
  }
};
</script>

<style></style>
