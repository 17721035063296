<template>
  <v-ons-page :style="swipePosition" :key="componentKey">
    <custom-toolbar :style="swipeTheme" modifier="white-content">
      {{ title }}
      <template v-slot:right>
        <div class="flex h-full items-center justify-center">
          <button class="bg-gray-100 focus:outline-none py-2 px-2 rounded-xl flex items-center text-xs" @click="showBottomMenu = true">
            <img :src="require('@/assets/icon/' + lang.icon)" alt="" class="mr-2 h-4 w-4" />
            {{ lang.text }}
          </button>
        </div>
      </template>
    </custom-toolbar>

    <v-ons-action-sheet :visible.sync="showBottomMenu" cancelable class="action-sheet-form">
      <div class="action-sheet-title">
        <div class="left" @click="showBottomMenu = false">
          <Icon name="chevron-down" classes="h-6 w-6" />
        </div>
        <div>{{ $t("common.select_languange") }}</div>
      </div>
      <v-ons-list class="pb-16">
        <v-ons-list-item v-for="(item, key) in languages" :key="key" tappable>
          <label class="left">
            <v-ons-radio :input-id="'radio-' + key" :value="item.value" v-model="$i18n.locale" @change="onLangChange(item.value)" />
          </label>
          <label :for="'radio-' + key" class="center">
            <img :src="require('@/assets/icon/' + item.icon)" alt="" class="mr-2 h-4 w-4" />
            {{ item.text }}
          </label>
        </v-ons-list-item>
      </v-ons-list>
    </v-ons-action-sheet>

    <v-ons-tabbar position="0" swipeable :on-swipe="onSwipe" :tabbar-style="swipeTheme" :tabs="tabs" :index.sync="index" />
  </v-ons-page>
</template>

<script>
import Home from "./views/Home/Home";
import Admin from "./views/Admin/Admin";
import Statistic from "./views/Statistic";
import { getLang, setLang } from "@/utils/lang";

// RGB colors
const white = [255, 255, 255];

export default {
  data() {
    return {
      showBottomMenu: false,
      languages: [
        { text: "English", value: "en", icon: "united-kingdom.svg" },
        { text: "日本語", value: "ja", icon: "japan.svg" },
        { text: "中文", value: "zh", icon: "china.svg" }
      ],
      colors: white,
      animationOptions: {},
      topPosition: 0,
      tabs: [],
      componentKey: 0
    };
  },
  beforeMount() {
    this.setTabs();
    this.setTitle(getLang());
  },
  watch: {
    roles() {
      this.setTabs();
    },
    profile: {
      deep: true,
      handler() {
        this.setTabs();
      }
    },
    lang() {
      this.setTabs();
      this.forceRerender();
    }
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    onLangChange(val) {
      this.showBottomMenu = false;
      this.$i18n.locale = val;
      this.setTitle(val);
      setLang(val);
      this.$store.commit("app/SET_LANG", val);
    },
    onSwipe(index, animationOptions) {
      // Apply the same transition as ons-tabbar
      this.animationOptions = animationOptions;
    },
    setTitle(lang) {
      if (lang === "en") {
        document.title = "Waterflow";
      }
      if (lang === "ja") {
        document.title = "若水霜霜";
      }
      if (lang === "zh") {
        document.title = "若水霜霜";
      }
    },
    setTabs() {
      let p = this.profile;
      let ALLOW_STATISTIC = p && (p.is_authorizer || p.is_finance || p.is_treasury);

      if (ALLOW_STATISTIC) {
        this.tabs = [
          {
            label: this.$t("home.navigation.home"),
            icon: "ion-ios-home",
            page: Home,
            theme: white
          },
          {
            label: this.$t("home.navigation.statistic"),
            icon: "ion-ios-journal",
            page: Statistic,
            theme: white
          },
          {
            label: this.$t("home.navigation.admin"),
            icon: "ion-ios-cog",
            page: Admin,
            theme: white
          }
        ];
      } else {
        this.tabs = [
          {
            label: this.$t("home.navigation.home"),
            icon: "ion-ios-home",
            page: Home,
            theme: white
          },
          {
            label: this.$t("home.navigation.admin"),
            icon: "ion-ios-cog",
            page: Admin,
            theme: white
          }
        ];
      }
    }
  },
  computed: {
    roles() {
      return this.$store.getters.roles;
    },
    profile() {
      return this.$store.getters.profile;
    },
    lang: function() {
      let lang = this.languages.filter((item) => item.value === this.$i18n.locale);

      return {
        icon: lang[0].icon,
        text: lang[0].text
      };
    },
    index: {
      get() {
        return this.$store.state.tabbar.index;
      },
      set(newValue) {
        this.$store.commit("tabbar/set", newValue);
      }
    },
    title() {
      return this.tabs[this.index] !== undefined ? this.tabs[this.index].label : "";
    },
    swipeTheme() {
      return (
        this.md && {
          backgroundColor: `rgb(${this.colors.join(",")})`,
          transition: `all ${this.animationOptions.duration || 0}s ${this.animationOptions.timing || ""}`
        }
      );
    },
    swipePosition() {
      return (
        this.md && {
          top: this.topPosition + "px",
          transition: `all ${this.animationOptions.duration || 0}s ${this.animationOptions.timing || ""}`
        }
      );
    }
  }
};
</script>

<style></style>
