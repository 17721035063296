<template>
  <BottomSheet
    :class="{ 'z-low': zlow, 'z-behind': zbehind }"
    :title="$t('common.filter')"
    :showing="showing"
    @on-close="$emit('on-close')"
    classes="pb-0"
    :min="90"
  >
    <!-- <loading-small class="test" /> -->
    <div class="overflow-y-auto  pb-20">
      <v-ons-list class="">
        <form-select-user
          :label="$t('common.applicant')"
          :users="component.users ? component.users : []"
          @remove="handleRemoveUser"
          @add="onClickComponent(COMPONENT.USER, { default: component.users })"
        />

        <form-select
          :label="$t('app.stamp.party')"
          @click="onClickComponent(COMPONENT.COMPANY)"
          :placeholder="$t('common.select_company')"
          :value="component.company ? component.company.name : null"
        />

        <form-select
          :label="$t('common.currency')"
          @click="handleCurrencyClick"
          :placeholder="$t('common.select_currency')"
          :value="filter.currency"
        />
        <form-input :label="$t('common.amount') + (' (' + filter.currency + ')')" :placeholder="$t('common.enter_amount')" v-model.number="amount">
          <div :class="`grid grid-cols-${options.length} py-1.5 space-x-1 text-center w-full`">
            <div
              v-for="(item, key) in options"
              :key="'amount_' + key"
              :class="{ 'border-blue-500 text-blue-500': item.value === selectedOption }"
              @click="selectedOption = item.value"
              class="bg-gray-50 border py-1 rounded"
            >
              {{ item.text }}
            </div>
          </div>
        </form-input>
      </v-ons-list>
      <v-ons-list class="pt-3">
        <v-ons-list-header> {{ $t("stat.export_.period") }} </v-ons-list-header>
        <v-ons-list-item>
          <span class="list-item__title"> {{ $t("stat.export_.period") }} </span>
          <div :class="`grid grid-cols-${periods.length} py-1.5 space-x-1 text-center w-full`">
            <div
              v-for="(item, key) in periods"
              :key="'period_' + key"
              @click="periodType = item"
              class="bg-gray-50 border py-1 rounded"
              :class="{ 'border-blue-500 text-blue-500': null !== periodType && item.value === periodType.value }"
            >
              {{ item.text }}
            </div>
          </div>
        </v-ons-list-item>

        <v-ons-list-item v-if="periodType">
          <span class="list-item__title"> {{ periodType.text }} </span>
          <div :class="`grid grid-cols-${conditionals.length} py-1.5 space-x-1 text-center w-full`">
            <div
              v-for="(item, key) in conditionals"
              :key="'conditional_' + key"
              :class="{ 'border-blue-500 text-blue-500': null !== conditionalType && item.value === conditionalType.value }"
              @click="conditionalType = item"
              class="bg-gray-50 border py-1 rounded"
            >
              {{ item.text }}
            </div>
          </div>
        </v-ons-list-item>
        <form-calendar
          v-if="null !== conditionalType"
          :bold="true"
          :label="$t('stat.export_.period')"
          :placeholder="$t('stat.export_.select_period')"
          :mode="null !== conditionalType && conditionalType.value === 'between' ? 'during' : 'single'"
          @on-change="handlePeriodSelect"
          :value="period"
        />
      </v-ons-list>
    </div>
    <div class="fixed bottom-0 w-full bg-white flex items-center px-6">
      <ons-row class="flex items-center justify-center py-4">
        <v-ons-button modifier="quiet" class="w-full text-center rounded" @click="onReset">
          {{ this.$t("common.sort_.reset") }}
        </v-ons-button>
      </ons-row>
      <ons-row class="flex items-center justify-center py-4">
        <v-ons-button class="w-full text-center rounded" @click="onSubmit">
          {{ $t("common.search") }}
        </v-ons-button>
      </ons-row>
    </div>
    <CurrencySelect :showing="showCurrency" @on-close="handleCurrencyClose" @on-select="handleCurrencySelect" />
  </BottomSheet>
</template>
<script>
import { COMPONENT } from "@/utils/constant";
import BottomSheet from "@/components/BottomSheet";

import CompanyList from "@/views/common/list/ContractPartyList";
import CurrencySelect from "@/views/common/select/CurrencySelect";
import UserListMultipleSelect from "../../../common/select/UserListMultipleSelect.vue";

const defaultComponent = {
  company: null,
  users: []
};
const defaultFilter = {
  "contract_party.id": null,
  "applicant.id": [],
  currency: "CNY"
};
export default {
  name: "StampFilterForm",
  props: ["showing"],
  components: {
    BottomSheet,
    CurrencySelect
  },
  data() {
    return {
      zlow: false,
      zbehind: false,
      COMPONENT: COMPONENT,
      component: Object.assign({}, defaultComponent),
      filter: Object.assign({}, defaultFilter),
      showCurrency: false,
      selectedCurrency: null,
      amount: 0,
      selectedOption: "gte",
      periodType: null,
      conditionalType: null,
      period: null,
      rangePeriod: { from: null, to: null }
    };
  },
  computed: {
    componentPage() {
      return [
        {
          name: this.COMPONENT.COMPANY,
          component: CompanyList,
          label: this.$t("common.select_company")
        },
        {
          name: this.COMPONENT.USER,
          component: UserListMultipleSelect,
          label: this.$t("common.applicant")
        }
      ];
    },
    options() {
      return [
        {
          value: "lte",
          text: this.$t("common.less_than")
        },
        {
          value: "gte",
          text: this.$t("common.greater_than")
        }
      ];
    },
    periods() {
      return [
        {
          value: "buffer.completion_date",
          text: "Completion Date"
        },
        {
          value: "contract_period_till",
          text: "Contract Date"
        }
      ];
    },
    conditionals() {
      return [
        {
          value: "before",
          text: this.$t("common.less_than")
        },
        {
          value: "after",
          text: this.$t("common.greater_than")
        },
        {
          value: "between",
          text: "Between"
        }
      ];
    },
    periodPayloadName() {
      return null !== this.periodType && null !== this.conditionalType && null !== this.period
        ? this.periodType.value + "[" + this.conditionalType.value + "]"
        : null;
    },
    amountPayloadName() {
      return `amount[${this.selectedOption}]`;
    }
  },
  watch: {
    "$store.getters.param.selectedContractParty": function(n) {
      if (n) {
        this.zlow = false;
        this.component.company = n;
        this.filter["contract_party.id"] = n.id;
      }
    },
    "$store.getters.param.selectedUsers": function(n) {
      if (n) {
        this.zlow = false;
        this.component.users = n;
        this.filter["applicant.id"] = n.map((user) => user.id);
      }
    },
    conditionalType: function(n) {
      if (n) {
        this.period = null;
      }
    },
    "component.users": {
      deep: true,
      handler(n) {
        this.filter["applicant.id"] = n.map((user) => user.id);
      }
    },
    "$store.getters.isPop": function(n) {
      if (n) {
        this.zlow = false;
      }
    }
  },
  methods: {
    onClickComponent(name, param) {
      this.zlow = true;
      let page = this.componentPage.filter((item) => item.name === name);
      if (page && page[0]) {
        this.push(page[0].component, page[0].label, param);
      }
    },
    onReset() {
      this.filter = Object.assign({}, defaultFilter);
      this.component = Object.assign({}, defaultComponent);

      this.selectedCurrency = null;
      this.amount = 0;
      this.selectedOption = "gte";
      this.periodType = null;
      this.conditionalType = null;
      this.period = null;

      this.$emit("on-filter", null);
    },
    onSubmit() {
      let payload = Object.assign({}, this.filter);
      if (this.rangePeriod && null !== this.conditionalType && this.conditionalType.value == "between") {
        payload[`${this.periodType.value}[after]`] = this.rangePeriod.from;
        payload[`${this.periodType.value}[before]`] = this.rangePeriod.to;
      } else if (this.periodPayloadName && this.period) {
        payload[this.periodPayloadName] = this.period;
      }

      if (this.amountPayloadName) {
        payload[this.amountPayloadName] = this.amount;
      }

      this.$emit("on-filter", payload);
    },
    handleRemoveUser(id) {
      this.component.users = this.component.users.filter((user) => user.id !== id);
    },
    handleCurrencyClick() {
      this.zbehind = true;
      this.showCurrency = true;
    },
    handleCurrencySelect(val) {
      this.selectedCurrency = val;
      this.filter.currency = val.code;
      this.handleCurrencyClose();
    },
    handleCurrencyClose() {
      this.zbehind = false;
      this.showCurrency = false;
    },
    handlePeriodSelect(value) {
      if (null !== this.conditionalType && this.conditionalType.value === "between") {
        if (value[0] && value[1]) {
          this.rangePeriod.from = value[0];
          this.rangePeriod.to = value[1];
          this.period = value.join("~");
        }
      } else {
        this.period = value;
      }
    },
    push(page, key, param) {
      this.$store.commit("navigator/options", {
        animation: "none"
      });
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.cancel"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped>
.list-item /deep/ .list-item__title {
  font-weight: 700 !important;
}
.z-low {
  z-index: 0 !important;
}
.z-behind {
  z-index: 200 !important;
}
</style>
