<template>
  <v-ons-page @show="handleShow">
    <v-ons-progress-bar indeterminate v-if="loading"></v-ons-progress-bar>

    <v-ons-card class="flat">
      <div style="text-align: center; margin-bottom: 20px">
        <div class="profile-thumb">{{ profile.initial_name }}</div>
        <div>{{ profile.name }}</div>
      </div>
    </v-ons-card>

    <section v-show="!loading">
      <v-ons-list>
        <v-ons-list-item>
          <div class="left">
            <div class="approval-icon bg-transparent">
              <Icon name="sitemap" classes="text-green" size="32" stroke="2" />
            </div>
          </div>

          <div class="center" @click="push(pages[0].component, pages[0].label)">
            <span class="list-item__title">
              {{ $t("admin.menu.my_organization") }}
            </span>
            <span class="list-item__subtitle">{{ profile.my_organization_count }} {{ $t("common.colleague") }}</span>
          </div>

          <div class="right" @click="push(pages[3].component, pages[3].edit, { companyId: profile.company.id })">
            <v-ons-button modifier="quiet">
              <Icon name="edit" classes="icon list-item__thumbnail" />
            </v-ons-button>
          </div>
        </v-ons-list-item>

        <v-ons-list-item tappable @click="push(pages[1].component, pages[1].label)">
          <div class="left">
            <div class="approval-icon bg-transparent">
              <Icon name="users" classes="text-green" size="32" />
            </div>
          </div>
          <div class="center">
            <span class="list-item__title">
              {{ $t("admin.menu.external_companies") }}
            </span>
            <span class="list-item__subtitle">{{ profile.external_company_count }} {{ $t("common.company") }}</span>
          </div>
        </v-ons-list-item>
      </v-ons-list>

      <v-ons-list>
        <v-ons-list-header />
        <v-ons-list-item v-if="isHoshinoCompanyAuthorizer" modifier="chevron" tappable @click="push(pages[2].component, pages[2].label)">
          <div class="left">
            <Logo name="background" size="18" />
          </div>
          <div class="center">{{ $t("admin.menu.background") }}</div>
        </v-ons-list-item>

        <v-ons-list-item modifier="chevron" v-if="isHoshino" tappable @click="push(pages[3].component, pages[3].create)">
          <div class="left">
            <Logo name="company" size="18" />
          </div>
          <div class="center">{{ $t("admin.menu.create_company") }}</div>
        </v-ons-list-item>

        <v-ons-list-item
          modifier="chevron"
          tappable
          v-if="isClient"
          @click="
            push(pages[3].component, pages[3].edit, {
              companyId: profile.company.id
            })
          "
        >
          <div class="left">
            <Logo name="company" size="18" />
          </div>
          <div class="center">{{ $t("admin.company.edit_company") }}</div>
        </v-ons-list-item>

        <v-ons-list-item modifier="chevron" tappable @click="push(pages[4].component, pages[4].label)">
          <div class="left">
            <Logo name="user" size="18" />
          </div>
          <div class="center">{{ $t("admin.menu.create_user") }}</div>
        </v-ons-list-item>
      </v-ons-list>

      <v-ons-list>
        <v-ons-list-header></v-ons-list-header>
        <v-ons-list-item tappable @click="handleLogOut">
          <div class="left">
            <div class="approval-icon bg-transparent">
              <Icon name="off-duo" classes="text-dark-gray" size="24" />
            </div>
          </div>
          <div class="center">{{ $t("admin.menu.log_out") }}</div>
        </v-ons-list-item>
      </v-ons-list>
    </section>
  </v-ons-page>
</template>

<script>
import MyOrganization from "./MyOrganization";
import ExternalCompanies from "./ExternalCompanies";
import Background from "./Background";
import CompanyForm from "./form/CompanyForm";
import UserForm from "./form/UserForm";
import Login from "@/views/Security/Login";
import { fetchData } from "@/api/profile";

export default {
  name: "Admin",
  data() {
    return {
      profile: {},
      loading: true
    };
  },
  computed: {
    isHoshino() {
      // return !this.profile.is_customer;
      // all allastarsea group
      return this.profile.is_allastarsea_group_member;
    },
    isCompanyAuthorizer() {
      let role = this.$store.getters.roles;
      return role.includes("ROLE_COMPANY_AUTHORIZER");
    },
    isHoshinoCompanyAuthorizer() {
      let role = this.$store.getters.roles;
      return role.includes("ROLE_COMPANY_AUTHORIZER") && this.isHoshino;
    },
    isClient() {
      return !this.isHoshino;
    },
    pages() {
      return [
        {
          component: MyOrganization,
          label: this.$t("admin.menu.my_organization")
        },
        {
          component: ExternalCompanies,
          label: this.$t("admin.menu.external_companies")
        },
        {
          component: Background,
          label: this.$t("admin.menu.background")
        },
        {
          component: CompanyForm,
          create: this.$t("admin.menu.create_company"),
          edit: this.$t("admin.company.edit_company")
        },
        {
          component: UserForm,
          label: this.$t("admin.menu.create_user")
        }
      ];
    }
  },
  methods: {
    handleShow() {
      this.setProfile();
      this.getProfileData();
    },
    getProfileData() {
      this.profile = this.$store.getters.profile;
      this.loading = false;
    },
    handleLogOut() {
      this.$ons.notification.confirm(this.$t("common.notif.decide"), { title: this.$t("common.notif.confirm") }).then((response) => {
        if (response === 1) {
          this.$store.dispatch("user/LogOut").then(() => {
            this.$store.commit("navigator/reset", Login);
          });
        }
      });
    },
    async setProfile() {
      await fetchData().then((response) => {
        this.$store.commit("user/SET_PROFILE", response);
        this.$store.commit("user/SET_ROLES", response.roles["hydra:member"]);
      });
    },
    push(page, key, param) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>
