<template>
  <v-ons-page>
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <search-filter @on-search="onSearch" @on-reset="onReset" />
    <div class="mt-14">
      <div class="no-result" v-if="companyList.length === 0">{{ $t("common.empty") }}</div>
      <v-ons-list v-else>
        <form-select-icon
          v-for="(obj, key) in companyList"
          :key="key"
          :label="obj.name"
          :sublabel="''"
          icon="circle"
          iconClass="text-gray-300"
          modifier="none"
          @tap="onCompanySelect(obj.id)"
        />
      </v-ons-list>
    </div>
    <infinite-loading @infinite="getData" :identifier="infiniteId" />
  </v-ons-page>
</template>

<script>
import { mapMutations } from "vuex";
import { fetchCompanyData as fetchList, fetchCompanyBackground as fetchRelated } from "@/api/company";
import SearchFilter from "../../../components/SearchFilter.vue";

const defaultFilter = { page: 1, itemsPerPage: 10, name: "" };
export default {
  components: { SearchFilter },
  name: "CompanyList",
  data: () => ({
    companyList: [],
    filter: Object.assign({}, defaultFilter),
    infiniteId: new Date()
  }),
  computed: {
    index() {
      return this.toolbarInfo.param ? this.toolbarInfo.param.key : undefined;
    },
    excluded() {
      return this.toolbarInfo.param ? this.toolbarInfo.param.exclude : undefined;
    },
    hasBackground() {
      return this.toolbarInfo.param ? this.toolbarInfo.param.hasBackground : undefined;
    },
    isHoshino() {
      return this.toolbarInfo.param ? this.toolbarInfo.param.isHoshino : undefined;
    }
  },
  methods: {
    ...mapMutations("navigator", ["pop", "setParam"]),
    getData($state) {
      if (this.hasBackground !== undefined) {
        this.filter.has_background = this.hasBackground;
      }

      let fetchFunction = fetchList;
      if (this.isHoshino != undefined) {
        if (this.isHoshino === false) {
          fetchFunction = fetchRelated;
        }
      }

      // let fetchFunction = this.isHoshino ? fetchList : fetchRelated;
      fetchFunction(this.filter).then((response) => {
        let data = response["hydra:member"].map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });

        if (this.excluded) {
          data = data.filter((item) => {
            let f = this.excluded.filter((obj) => (obj.id ? obj.id === item.id : false));
            return f.length === 0; // if false then remove
          });
        }

        this.companyList = [...this.companyList, ...data];

        if (data.length) {
          this.filter.page += 1;
          $state && $state.loaded();
        } else {
          $state && $state.complete();
        }
      });
    },
    onSearch(filter) {
      this.companyList = [];
      this.filter.page = 1;
      this.filter.name = filter.term;
      this.forceRerender();
    },
    onReset() {
      this.companyList = [];
      this.filter = Object.assign({}, defaultFilter);
      this.forceRerender();
    },
    onCompanySelect(id) {
      let selectedCompany = this.companyList.filter((company) => company.id === id)[0];
      if (this.index !== undefined) {
        this.setParam({ selectedCompany: { index: this.index, selected: selectedCompany } });
      } else {
        this.setParam({ selectedCompany: selectedCompany });
      }
      this.pop();
    },
    forceRerender() {
      this.infiniteId += 1;
    }
  }
};
</script>

<style scoped></style>
