export const app = {
  application_number: "Application Number",
  invoice_later: "Invoices obtaining not yet but later",
  input_by_treasury: "Input by treasury",
  deal_slip: "Deal slip",
  deal_slip_sublabel: "Please upload deal slip",
  payment_account: "Payment Account",
  select_payment_account: "Select payment account",
  payment_date: "Payment date",
  forward_finance: "Forward to finance",
  select_finance: "Select finance",
  page_application: "Page of application",
  enter_page_application: "Enter number page of application",
  item: "Item",
  quantity: "Quantity",
  enter_quantity: "Enter quantity",
  forward_document: "Forward document",
  estimated_approval_date: "Estimated approval deadline",
  send_notification_approver: "Send notification email to the next approver",
  withdraw: "Withdraw",
  withdraw_alert: "Are you sure to withdraw your application? ",
  stepback: "Step Back",
  stepback_alert: "Are you sure to step back to the previous approver? ",
  is_drawdown: "Automatic drawdown",

  account: {
    name: "Recipient name",
    number: "Account number",
    bank: "Bank name, BIC CODE",
    branch: "Branch name, Account type"
  },

  reimb: {
    reimbursement: "Reimbursement",
    reimbursement_detail: "Reimbursement detail",
    add_more_info: "To add reimbursement, please click add more button below",
    e_invoice_info:
      "Please submit original invoice or reimbursement voucher by A4 paper (paper surface use only). On the front page, please write down the application number {number}",
    e_invoice: "E-invoice",
    e_invoice_sublabel: "You can upload up to 10 invoices",
    application_paper: "Payment notice etc",
    application_paper_sublabel: "You can upload up to 10 payment notices",
    //detail
    reimbursement_content: "Reimbursement content",
    expense_detail: "Expense detail",
    select_expense: "Select expense",
    destination: "Destination",
    enter_destination: "Enter destination",
    from: "From",
    enter_from: "Enter departing place",
    to: "To",
    enter_to: "Enter arrival place",
    supplier_name: "Supplier name",
    enter_supplier_name: "Enter supplier name",
    tax: "No tax invoice",
    tax2: "No tax invoice",
    enter_tax: "Enter amount and reason if we cannot obtain taxable invoice",
    with_supplier_account: "If this time recipient account is different from your usual salary recipient account, please input",
    alert: {
      item: "Please add at least 1 reimbursement",
      page: "Please enter page of application",
      finance: "Please select finance",
      content: "Please create at least 1 content"
    }
  },
  pay: {
    payment: "Payment",
    payment_detail: "Payment Detail",
    add_more_info: "To add more payment application, please click the add more button below",
    e_invoice_info:
      "Please submit original invoice or reimbursement voucher by A4 paper (paper surface use only). On the front page, please write down the application number {number}",
    e_invoice: "E-invoice",
    e_invoice_sublabel: "You can upload up to 10 invoices",
    application_paper: "Payment notice etc",
    application_paper_sublabel: "You can upload up to 10 payment notices",
    supplier_name: "Supplier name",
    enter_supplier_name: "Enter supplier name",
    tax: "No tax invoice",
    tax2: "No tax invoice",
    enter_tax: "Enter amount and reason if we cannot obtain taxable invoice",
    month: "Month",
    enter_month: "Enter month",
    estimated_payment_date: "Estimated payment date",
    recipient: "Recipient",
    enter_recipient: "Enter name of recipient",
    recipient_account: "Account Number",
    enter_recipient_account: "Enter account number",
    payment_content: "Payment content list",
    expense_detail: "Expense detail",
    select_expense: "Select expense",
    alert: {
      item: "Please add at least 1 payment",
      finance: "Please select finance",
      month: "Please enter month",
      date: "Please select estimated payment date",
      page: "Please enter page of application",
      recipient: "Please enter recipient name",
      account_no: "Please enter account number",
      bank: "Please enter bank name",
      branch: "Please enter branch name",
      content: "Please create at least 1 content"
    }
  },
  purc: {
    purchase: "Purchase",
    goods_detail: "Goods detail",
    add_more_info: "To add more good detail, please click the add more button below",
    total_estimated: "Total Estimated",
    delivery_date: "Delivery date",
    delivery_place: "Delivery place",
    enter_delivery_place: "Enter delivery place",
    goods_name: "Goods name",
    enter_goods_name: "Enter goods name",
    recurrent: "Recurrent",
    type_brand: "Type/Brand",
    enter_type_brand: "Enter Type/Brand",
    budget_tax: "Budget (Tax Inclusive)",
    enter_budget: "Enter budget",
    estimated_unit_price: "Estimated Unit Price",
    upload_photo: "Upload files or photoes for reference",
    upload_photo_desc: "You can upload up to 10 files or photos",
    source: "Source (Link Reference)",
    enter_source: "Enter source",
    arrange_by: "Arrange By",
    by_me: "By Me",
    by_ga: "By General Affar",
    alert: {
      item: "Please add at least 1 purchase",
      delivery_date: "Please select delivery date",
      delivery_place: "Please select delivery place",
      goods: "Please enter goods name",
      type: "Please enter type,brand",
      quantity: "Please enter quantity",
      budget: "Please enter budget (tax inclusive)",
      arrange: "Please select arrange by"
    }
  },
  cc: {
    contract_party: "Contract Party",
    select_party: "Select contract party",
    contract_type: "Contract Type",
    select_type: "Select contract type",
    contract_period: "Contract period till",
    contract_fullname: "Customer fullname",
    enter_fullname: "Enter customer full name",
    items: "Item",
    add_more_info: "To add more item, please click add more button below",
    plan: "Contract plan",
    plan_info: "Please upload contract plan",
    stamp: "Stamp No.",
    signed: "Signed contract",
    signed_info: "Please upload signed contract",
    item: {
      name: "Name",
      enter_name: "Enter goods name",
      brand: "Type, Brand",
      enter_brand: "Enter Type, Brand",
      qty: "Quantity",
      freq: "Frequency",
      enter_freq: "Enter frequency",
      content: "Content",
      enter_content: "Enter content",
      type1: "Type 1",
      enter_type1: "Enter Type 1",
      type2: "Type 2",
      enter_type2: "Enter Type 2",
      unit: "Unit price",
      enter_unit: "Enter unit price",
      term: "Terms",
      enter_term: "Enter terms",
      amount_tax: "Amount (Tax Inclusive)",
      enter_amount_tax: "Enter amount (tax inclusive)",
      ratio: "Ratio in advance payment",
      enter_ratio: "Enter ratio in advance payment"
    },
    alert: {
      party: "Please select our contract party",
      type: "Please select contract type",
      fullname: "Please enter contract fullname",
      plan: "Please upload document plan",
      items: "Please enter at least 1 item",
      item: {
        name: "Please enter goods name",
        brand: "Please enter Type, Brand",
        qty: "Please enter quantity",
        amount: "Please enter amount",
        unit: "Please enter unit price",
        content: "Please enter contents",
        term: "Please enter terms",
        amount_tax: "Please enter amount (tax inclusive)",
        freq: "Please enter frequency"
      }
    }
  },
  sc: {
    our_contract_party: "Our contract party",
    select_contract_party: "Select contract party",
    contract_type: "Contract Type",
    expense_account: "Expense account",
    select_account: "Select account",
    contract_fullname: "Supplier fullname",
    employee_fullname: "Employee fullname",
    enter_contract_fullname: "Enter supplier fullname",
    add_more_info: "To add more item, please click the add more button below",
    upload_contract_plan: "Upload contract plan",
    select_file: "Select file",
    after_approval_before_sign: "After approval before sign",
    stamp: "Stamp",
    upload_signed_contract: "Upload signed contract",
    supplier_fullname: "How many versions and where to sign",
    frequency: "Frequency",
    select_frequency: "Select frequency",
    payment_deadline: "Payment deadline",
    enter_payment_deadline: "Describe Payment deadline",
    supplier_account_detail: "Supplier account information",
    employee_account_detail: "Employee account information",
    supplier_name: "Name",
    enter_name: "Enter account name",
    contract_period: "Contract period till",
    auto_renew: "Automatically renew the contract ?",
    number: "Account Number",
    enter_account_number: "Enter account number",
    bank: "Bank, BIC Code",
    enter_bank: "Enter bank name, BIC Code",
    branch: "Branch, Account type",
    enter_branch: "Enter branch name, account type",
    item: {
      name: "Name",
      enter_name: "Enter name",
      type_brand: "Type/Brand",
      enter_type_brand: "Enter Type/Brand",
      quantity: "Quantity",
      enter_quantity: "Enter quantity",
      budget_tax: "Budget (Tax Inclusive)",
      enter_budget: "Enter Budget",
      unit_price: "Unit price",
      enter_unit_price: "Enter unit price",
      times: "Times",
      enter_times: "Enter number",
      content: "Content",
      enter_content: "Enter content",
      type1: "Type (1)",
      enter_type1: "Enter Type(1)",
      type2: "Type (2)",
      enter_type2: "Enter Type(2)",
      terms: "Terms",
      enter_terms: "Enter terms",
      national_amount: "National amount",
      national_amount_hr: "National amount",
      enter_national_amount: "Enter national amount",
      start_date: "Start date"
    },
    alert: {
      type: "Please select contract type",
      fullname: "Please enter contract fullname",
      plan: "Please upload document plan",
      items: "Please enter at least 1 item",
      supplier: "Please enter supplier account",
      contract_period: "Please enter the contract period",
      item: {
        content: "Please enter content",
        unit: "Please enter unit price",
        date: "Please select start date",
        amount: "Please enter amount",
        goods: "Please enter goods name",
        type: "Please enter type/brand",
        quantity: "Please enter quantity",
        budget: "Please enter amount (tax inclusive)",
        frequency: "Please select frequency"
      }
    }
  },
  col: {
    select_one: "Please select one below",
    _debt: "Collection for debt",
    _report: "Collection report",
    related: "Related approval",
    select_related: "Select related approval",
    customer: "Customer name",
    enter_customer: "Enter customer name",
    debt: {
      uncollected: "Uncollected amount",
      enter_uncollected: "Enter uncollected amount",
      payment_date: "Payment date on the contract",
      situation: "Situation",
      enter_situation: "Enter real situation",
      estimated_date: "Estimated collection date"
    },
    report: {
      collected: "Collected amount",
      enter_collected: "Enter collected amount",
      reciept: "Reciept our account",
      select_reciept: "Select account",
      reciept_date: "Reciept date"
    },
    sales: {
      amount: "Sales (delivery) amount",
      amount_enter: "Enter Sales (delivery) amount",
      evidence_type: "Evidence type",
      evidence_type_select: "Select evidence type",
      delivery_date: "Sales (delivery) date",
      evidence: "Evidence",
      evidence_upload: "Please upload evidence and related waybill, Packing list, or bill of lading"
    },
    alert: {
      customer: "Please enter customer name",
      uncollected: "Please enter uncollected amount",
      payment_date: "Please enter payment date",
      collected: "Please enter collected amount",
      reciept: "Please select reciept our account",
      evidence: "Please select evidence type"
    }
  },
  stamp: {
    usage: "Type of usage",
    select_usage: "Select type of usage",
    purpose: "Purpose",
    enter_purpose: "Enter purpose",
    select_purpose: "Select purpose (plural OK)",
    fullname: "Submission to and for the purpose of",
    enter_fullname: "Recipient party name and purpose",
    // no1: "Stamped document fullname, Number (1)",
    // no2: "Stamped document fullname, Number (2)",
    // no3: "Stamped document fullname, Number (3)",
    stamp_docs: "Necessary documents ",
    stamp_docs_detail: "Enter detail (Name, number, the place to sign or stamp, which stamp, necessity of original etc)",
    add_more_stamp_docs_info: "To add more, please click add more button below",
    enter_notes: "In case of e-deed, objective, terms, name and phone no",
    enter_detail: "Enter detail",
    party: "Contract Party",
    select_party: "Select contract party",
    type: "Stamped type, (if required)",
    sign_on: "Where to sign on, (if required)",
    plan: "Document plan",
    plan_info: "Please upload document plan",
    stamp: "Stamp No.",
    sign: "Stamped /signed documents",
    sign_info: "Please upload stamped / signed documents",
    alert: {
      usage: "Please select type of usage",
      purpose: "Please enter purpose",
      fullname: "Please enter stamp party fullname",
      party: "Please select contract party",
      plan: "Please upload document plan"
    },
    agreement: {
      one: "I confirm when I borrow corporate seals I should report  to this system all the photoes that I used.",
      two: "I confirm there is no other usage I missed to report when I had corporate seals in my hand.",
      three: "I confirm borrowing deed or stamp should return during the day, unless the government or the bank collects it."
    },
    purposes: {
      borrow_stamp: "Borrow Deed or stamp",
      borrow_id: "Borrow ID original",
      use_electronic_id: "Use ID or deed electronic data",
      use_photocopy_id: "Use ID or deed photocopy",
      authorize_deed: "Authorize e-deed",
      signature: "Signature",
      without_stamp: "Procedure without stamp etc"
    }
  }
};
