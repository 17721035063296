<template>
  <div class="w-full z-50" :class="{ fixed: fix }">
    <div class="bg-white flex text-sm relative border-b border-gray-100">
      <div
        class="absolute ease-in-out transition-transform duration-200 bottom-0 left-0 w-1/2 flex justify-center"
        :style="{ transform: `translateX(calc(100% * ${activeTab}))` }"
      >
        <span class="bg-blue-500 h-0.5 w-1/6" />
        <!-- <span
          class="bg-gradient-to-br from-green-500 h-1.5 rounded-full to-green-300 w-1/6"
        /> -->
      </div>
      <div
        v-for="(item, index) in tabs"
        :key="index"
        class="flex justify-center flex-1"
        :class="index == activeTab ? 'font-medium ' : 'text-gray-400'"
        @click="handleChange(index)"
      >
        <span class="mb-2">{{ item }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavTop",
  props: {
    tabs: {
      type: Array
    },
    fix: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      activeTab: 0
    };
  },
  methods: {
    handleChange(index) {
      let val = parseInt(index);
      this.activeTab = val;
      this.$emit("change-tab", val);
    }
  }
};
</script>

<style></style>
