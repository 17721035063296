<template>
  <div>
    <d-highlight
      v-if="estimatedApprovalDate"
      :label="$t('app.estimated_approval_date')"
      :value="estimatedApprovalDate ? moment(estimatedApprovalDate).format('YYYY/MM/DD') : '-'"
      :history="history ? ('estimated_approval_date' in history ? history.estimated_approval_date : null) : null"
    />
    <v-ons-card v-if="data">
      <div class="content p-2 space-y-2">
        <d-text :label="$t('common.app_code')" :value="data.code" />
        <d-text :label="$t('app.sc.our_contract_party')" :value="company" />
        <d-text :label="$t('common.department')" :value="department" />
        <!-- <d-text
        :label="$t('app.estimated_approval_date')"
        :value="obj.estimated_approval_date ? moment(obj.estimated_approval_date).format('DD/MM/YYYY') : '-'"
      /> -->
        <hr />
        <d-text :label="$t('app.sc.contract_type')" :value="contractType ? contractType.name : '-'" />
        <d-text :label="$t('app.sc.expense_account')" :value="expenseAccountObj ? expenseAccountObj.name : '-'" />
        <d-text
          :label="contractType && contractType.code === 'hr' ? $t('app.sc.employee_fullname') : $t('app.sc.contract_fullname')"
          :value="obj.contract_full_name"
          :history="history ? ('contract_full_name' in history ? history.contract_full_name : null) : null"
        />

        <d-text
          :label="$t('app.sc.supplier_fullname')"
          :value="obj.supplier_full_name ? obj.supplier_full_name : '-'"
          :history="'supplier_full_name' in history ? history.supplier_full_name : null"
        />

        <div>
          <d-subtitle>
            {{ contractType && contractType.code === "hr" ? $t("app.sc.employee_account_detail") : $t("app.sc.supplier_account_detail") }}
          </d-subtitle>
          <ul>
            <li class="flex justify-between">
              <span class="font-16 text-dark-gray">{{ $t("app.account.name") }}</span>
              <span>{{ data.data.supplier_account.name }}</span>
            </li>
            <li class="flex justify-between">
              <span class="font-16 text-dark-gray">{{ $t("app.account.number") }}</span>
              <span>{{ data.data.supplier_account.no }}</span>
            </li>
            <li class="flex justify-between">
              <span class="font-16 text-dark-gray">{{ $t("app.account.bank") }}</span>
              <span>{{ data.data.supplier_account.bank_name }}</span>
            </li>
            <li class="flex justify-between">
              <span class="font-16 text-dark-gray">{{ $t("app.account.branch") }}</span>
              <span>{{ data.data.supplier_account.branch_name }}</span>
            </li>
          </ul>
        </div>

        <d-text
          :label="'Contract period'"
          :value="obj.contract_period ? moment(obj.contract_period).format('YYYY/MM/DD') : '-'"
          :history="'contract_period' in history ? history.contract_period : null"
        />

        <div class="pt-2 space-y-1">
          <div class="flex items-center ">
            <div class="checkbox mr-2" :class="[obj.auto_renewal ? 'bg-green' : 'bg-gray-400']">
              <Icon :name="obj.auto_renewal ? 'check' : 'close'" size="12" classes="text-white" />
            </div>
            <div class="flex items-center justify-between w-full">
              <div :class="{ 'text-red-500': 'auto_renewal' in history }">
                {{ $t("app.sc.auto_renew") }}
              </div>
              <div v-if="'auto_renewal' in history">
                <div class="p-1 rounded flex items-center " style="font-size:10px">
                  <Icon name="edit" classes="text-gray-500 mr-1" :size="12" />
                  <span class="mr-1">{{ history.auto_renewal.user.name }} </span>
                  <span class="italic">{{ history.auto_renewal.date ? moment(history.auto_renewal.date).format("YYYY/MM/DD") : "" }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <d-items :data="items" :label="$t('app.item')" @tap="handleDetailOpen" :history="itemType in history ? history[itemType] : null" />

        <d-total
          v-if="obj.amount"
          :label="$t('common.total')"
          :currency="currency"
          :amount="obj.amount"
          :history="'total' in history ? history.amount : null"
        />

        <!-- <d-image :images="contractPlanFiles" :label="$t('app.sc.upload_contract_plan')" /> -->

        <form-uploader
          classes="p-0"
          :label="$t('app.sc.upload_contract_plan')"
          :existing="contractPlanFiles"
          :disabled="true"
          :history="'contract_plan_files' in history ? history.contract_plan_files : null"
        />

        <hr />
        <d-text :label="$t('app.sc.stamp')" :value="data.stamp_no" />
        <!-- <d-image :images="signedContractFiles" :label="$t('app.sc.upload_signed_contract')" /> -->

        <form-uploader
          classes="p-0"
          :label="$t('app.sc.upload_signed_contract')"
          :existing="signedContractFiles"
          :disabled="true"
          :history="'signed_contract_files' in history ? history.signed_contract_files : null"
        />

        <hr />
        <d-text :label="$t('app.sc.frequency')" :value="frequency" />
        <!-- <d-text :label="$t('app.sc.payment_deadline')" :value="obj.payment_deadline ? moment(obj.payment_deadline).format('DD/MM/YYYY') : '-'" /> -->
        <d-text
          :label="$t('app.sc.payment_deadline')"
          :value="obj.payment_deadline_text"
          :history="'payment_deadline_text' in history ? history.payment_deadline_text : null"
        />
        <hr />
        <d-list :label="$t('app.forward_finance')" :items="finances" itemKey="name" />
      </div>

      <detail :data="this.detail" @on-close="handleDetailClose" />
      <weui-gallery :visible="previewShow" :src="previewImg" :canDelete="false" @onClick="previewShow = false"></weui-gallery>
    </v-ons-card>
  </div>
</template>

<script>
import { getContractTypeById } from "@/api/contractSupplier";
import { getContractFrequencyById } from "@/api/contractSupplier";
import { fetchDetail as getFile } from "@/api/file";
import Detail from "@/views/Approval/component/supplier_contract/Detail";
import buffer from "@/mixins/buffer";
import history_detail from "@/mixins/history_detail";
import { fetchUserDetail as getUser } from "@/api/user";

const defaultDetail = {
  show: false,
  code: "",
  title: "",
  item: null,
  currency: ""
};

const itemConst = [
  {
    code: "hr",
    data: "item_hr",
    total: "amount"
  },
  {
    code: "goods",
    data: "item_goods",
    total: "amount"
  },
  {
    code: "services",
    data: "item_services",
    total: "unit_price"
  },
  {
    code: "other",
    data: "item_other",
    total: "amount"
  }
];

export default {
  name: "SupplierContract",
  props: ["data", "history"],
  mixins: [buffer, history_detail],
  components: { Detail },
  data() {
    return {
      contractType: null,
      frequency: "-",
      contractPlanFiles: [],
      signedContractFiles: [],
      detail: Object.assign({}, defaultDetail),
      finances: []
    };
  },
  computed: {
    obj() {
      return this.data?.data;
    },

    estimatedApprovalDate() {
      return this.obj?.estimated_approval_date;
    },
    items() {
      if (this.contractType) {
        if (this.contractType.code === "goods") {
          return this.obj.item_goods;
        } else if (this.contractType.code === "services") {
          return this.obj.item_services;
        } else if (this.contractType.code === "hr") {
          return this.obj.item_hr;
        } else if (this.contractType.code === "other") {
          return this.obj.item_other;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    itemType() {
      let f = itemConst.find((i) => i.code === this.contractType.code);
      return f?.data;
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(n, o) {
        if (n != o) {
          this.fetchCurrency(this.data.data.currency_id);
          this.fetchCompany(this.data.data.company_id);
          this.fetchDepartment(this.data.data.department_id);
          this.fetchExpenseAccount(this.data.data.expense_account_id);

          this.fetchContractType(this.data.data.contract_item_type_id);
          this.fetchContractFrequency(this.data.data.contract_frequency_id);
          this.fetchContractPlainFiles();
          this.fetchSignedContractFiles();
          if (this.obj.user_finances) {
            this.obj.user_finances.forEach((user) => {
              this.fetchUserDetail(user);
            });
          }
        }
      }
    }
  },
  methods: {
    handleDetailOpen(item, key) {
      this.detail = {
        ...{
          show: true,
          title: this.$t("app.item") + " (" + (key + 1) + ")",
          code: this.contractType ? this.contractType.code : "",
          item: item,
          currency: this.currency
        }
      };
    },
    handleDetailClose() {
      this.detail = Object.assign({}, defaultDetail);
    },
    fetchContractType(id) {
      getContractTypeById(id).then((response) => {
        this.contractType = response;
      });
    },
    fetchContractFrequency(id) {
      if (id) {
        getContractFrequencyById(id).then((response) => {
          this.frequency = response.name;
        });
      }
    },
    fetchContractPlainFiles() {
      this.contractPlanFiles = [];
      this.obj.contract_plan_files.forEach((item) => {
        getFile(item).then((f) => {
          this.contractPlanFiles.push({ ...f, size: f.size_string, is_edited: this.isFileEdited(f.id, "contract_plan_files") });
        });
      });
    },
    fetchSignedContractFiles() {
      this.signedContractFiles = [];
      this.obj.signed_contract_files.forEach((item) => {
        getFile(item).then((f) => {
          this.signedContractFiles.push({ ...f, size: f.size_string, is_edited: this.isFileEdited(f.id, "signed_contract_files") });
        });
      });
    },
    fetchUserDetail(id) {
      this.finances = [];
      getUser(id).then((response) => {
        this.finances.push(response);
      });
    }
  }
};
</script>

<style scoped></style>
