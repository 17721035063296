<template>
  <v-ons-page>
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>

    <goods v-if="isGoods" :param="param" />
    <services v-if="isService" :param="param" />
    <formhr v-if="isHr" :param="param" />
    <other v-if="isOther" :param="param" />
  </v-ons-page>
</template>

<script>
import goods from "@/views/Application/SupplierContract/component/goods";
import services from "@/views/Application/SupplierContract/component/services";
import formhr from "@/views/Application/SupplierContract/component/formhr";
import other from "@/views/Application/SupplierContract/component/other";

export default {
  name: "DetailForm",
  components: {
    goods,
    services,
    formhr,
    other
  },
  computed: {
    isGoods() {
      return this.toolbarInfo.param.contractType.code === "goods";
    },
    isService() {
      return this.toolbarInfo.param.contractType.code === "services";
    },
    isHr() {
      return this.toolbarInfo.param.contractType.code === "hr";
    },
    isOther() {
      return this.toolbarInfo.param.contractType.code === "other";
    },
    param() {
      return this.toolbarInfo.param;
    }
  }
};
</script>

<style></style>
