<template>
  <v-ons-page @show="fetchList">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>

    <v-ons-progress-bar indeterminate v-if="loading"></v-ons-progress-bar>

    <search-filter />

    <div class="no-result" v-show="listCount === 0">{{ $t("common.empty") }}</div>

    <v-ons-list class="mt-14" v-show="listCount > 0">
      <v-ons-list-item v-for="(item, key) in listData" :key="key">
        <div class="left">
          <div class="user-thumb">{{ item.initial_name }}</div>
        </div>
        <div class="center" @click="navigate(1, item.id)">
          <span class="list-item__title">{{ item.name }}</span>
          <span class="list-item__subtitle">
            {{ item.department ? item.department.name : "" }}
          </span>
        </div>
        <div class="right">
          <v-ons-button modifier="quiet" @click="navigate(0, item.id)">
            <Icon name="edit" classes="icon list-item__thumbnail" />
          </v-ons-button>
        </div>
      </v-ons-list-item>
    </v-ons-list>
  </v-ons-page>
</template>

<script>
import UserForm from "./form/UserForm";
import UserDetail from "@/views/Admin/detail/UserDetail";
import { fetchEmployees } from "@/api/company";
import SearchFilter from "@/components/SearchFilter";

export default {
  name: "Employee",
  components: { SearchFilter },
  data() {
    return {
      listData: {},
      listCount: 0,
      loading: true
    };
  },
  computed: {
    pages() {
      return [
        {
          component: UserForm,
          label: this.$t("admin.user.edit_user")
        },
        {
          component: UserDetail,
          label: ""
        }
      ];
    }
  },
  methods: {
    navigate(index, userId) {
      this.push(this.pages[index].component, this.pages[index].label, { userId: userId });
    },
    fetchList() {
      fetchEmployees(this.toolbarInfo.param.userId).then((response) => {
        this.listData = response["hydra:member"];
        this.listCount = response["hydra:totalItems"];
        this.loading = false;
      });
    },
    push(page, key, param) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style></style>
