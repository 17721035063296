<template>
  <ons-bottom-toolbar class="fixed z-50">
    <div class="bg-white bottom-0 p-2 w-full">
      <slot name="additional" />
      <v-ons-button class="shadow-md w-10/12" :class="classes" :disabled="disabled || loading" @click="$emit('submit')">
        <span v-if="loading" class="flex justify-center items-center">
          <img :src="require('@/assets/images/spinner.svg')" style="max-width:32px" alt="" />
        </span>
        <span v-else>{{ label ? label : $t("common.submit") }}</span>
      </v-ons-button>
    </div>
  </ons-bottom-toolbar>
</template>

<script>
export default {
  name: "BottomSubmit",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    classes: {
      type: String,
      default: "bg-blue-500 active:bg-blue-700"
    }
  }
};
</script>

<style></style>
