<template>
  <div class="bg-gray-50 flex items-center pt-10 py-2 px-4 border-b justify-between">
    <div @click="$emit('show')" class="text-left text-xs">
      <div v-if="filter.companyName">
        <div class="font-medium flex items-center">{{ filter.companyName }} <Icon name="arrow-right" class="px-1" /> {{ filter.vendor }}</div>
      </div>
      <div class="flex items-center">
        <div>
          <span> {{ filter.condition }} {{ filter.currency }} {{ filter.inputAmount ? filter.inputAmount : 0 }}</span>
          <span v-if="filter.completionDate">, {{ filter.completionDate }}</span>
        </div>
      </div>
    </div>

    <div class="text-right text-sm font-medium text-blue" @click="$emit('action')" v-if="showAction">
      <!-- {{ $t("stat.search_.change") }} -->
      {{ showSelect ? "Cancel" : "Select file" }}
    </div>
    <div class="text-right text-sm font-medium text-blue" @click="$emit('show')" v-else>
      {{ $t("stat.search_.change") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterDescription",
  props: {
    filter: Object,
    showAction: Boolean,
    showSelect: Boolean
  }
};
</script>
