<template>
  <v-ons-list-item>
    <span class="list-item__title" :class="{ required: required }">
      {{ label }}
    </span>
    <span class="list-item__subtitle">
      <label class="center">
        <textarea
          class="textarea w-full"
          cols="40"
          rows="4"
          :placeholder="placeholder"
          :value="value"
          @input="handleInput"
        />
      </label>
    </span>
  </v-ons-list-item>
</template>

<script>
export default {
  name: "form-textarea",
  props: {
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    value: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleInput(val) {
      this.$emit("input", val.target.value);
    }
  }
};
</script>

<style scoped></style>
