<template>
  <v-ons-dialog :cancelable="cancelable" :visible="showing" @posthide="handleClose" class="dialog-selection">
    <div v-if="cancelable" class="flex p-2" @click="handleClose">
      <Icon name="close" classes="h-6 w-6" />
    </div>

    <div class="p-3">
      <div class="h-80 overflow-y-auto">
        <slot />
      </div>
    </div>
    <div class="flex items-center justify-center p-2">
      <button class="flex focus:outline-none items-center px-16 py-3" @click="handleConfirm">
        {{ $t("common.notif.confirm") }}
      </button>
    </div>
  </v-ons-dialog>
</template>
<script>
export default {
  name: "Modal",
  props: {
    title: {
      type: String
    },
    showing: {
      type: Boolean
    },
    cancelable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleClose() {
      this.$emit("on-close");
    },
    handleConfirm() {
      this.$emit("on-confirm");
    }
  }
};
</script>

<style scoped>
.dialog-selection /deep/ .dialog {
  width: 90% !important;
}
.dialog-selection /deep/ .dialog-container {
  background-color: white !important;
}
</style>
