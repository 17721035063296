const app = {
  namespaced: true,
  state: {
    data: {},
    processes: [],
    approvers: [],
    lang: "ja"
  },

  getters: {
    getLang(state) {
      return state.lang;
    }
  },

  mutations: {
    SET_DATA: (state, data) => {
      state.data = data;
    },
    SET_PROCESSES: (state, processes) => {
      state.processes = processes;
    },
    SET_APPROVERS: (state, approvers) => {
      state.approvers = approvers;
    },
    SET_LANG: (state, lang) => {
      state.lang = lang;
    }
  },

  actions: {
    SetData({ commit }, data) {
      return new Promise((resolve) => {
        commit("SET_DATA", data);
        resolve();
      });
    }
  }
};

export default app;
