import _ from "lodash";

export default {
  name: "HistoryDetailMixins",
  methods: {
    isFileEdited(id, objName) {
      let ret = false;
      if (objName in this.history) {
        ret = !!_.find(this.history[objName].value, ["id", id]);
      }

      return ret;
    }
  }
};
