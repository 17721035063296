<template>
  <v-ons-action-sheet :visible.sync="isBottomMenuVisible" cancelable class="action-sheet-form">
    <div class="action-sheet-title">
      <div class="left" @click="onCloseBottomMenu">
        <Icon name="chevron-down" classes="h-6 w-6" />
      </div>
      <div>{{ $t("common.content") }}</div>
    </div>
    <v-ons-list>
      <v-ons-list-item>
        <span class="list-item__title">
          {{ $t("app.pay.expense_detail") }}
        </span>
        <span class="list-item__subtitle">
          <select @change="onExpenseChange" v-model="contentForm.expense" class="select-input w-full">
            <option :value="null" disabled selected>
              {{ $t("app.pay.select_expense") }}
            </option>
            <option :key="expense.id" v-for="expense in expenseList" :value="expense">
              {{ expense.name }}
            </option>
          </select>
        </span>
      </v-ons-list-item>
      <v-ons-list-item>
        <span class="list-item__title">
          {{ $t("common.amount") }}
          ({{ selectedCurrency ? selectedCurrency.code : null }})
        </span>
        <span class="list-item__subtitle">
          <v-ons-input v-model.number="contentForm.amount" type="number" float maxlength="20" :placeholder="$t('common.enter_amount')"> </v-ons-input>
        </span>
      </v-ons-list-item>
      <v-ons-list-item v-show="contentForm.expense ? contentForm.expense.is_transportation : false">
        <div class="left">{{ $t("common.date") }}</div>
        <label class="center">
          <v-ons-input v-model="contentForm.date" style="width: 80%" type="date" float maxlength="20" :placeholder="$t('common.select_date')">
          </v-ons-input>
        </label>
      </v-ons-list-item>
      <!-- <v-ons-list-item
        :modifier="md ? 'nodivider' : ''"
        v-show="
          contentForm.expense ? contentForm.expense.is_transportation : false
        "
      >
        <div class="left">{{ $t("common.destination") }}</div>
        <label class="center">
          <v-ons-input
            v-model="contentForm.destination"
            style="width: 80%"
            type="text"
            float
            maxlength="20"
            :placeholder="$t('common.enter_destination')"
          >
          </v-ons-input>
        </label>
      </v-ons-list-item> -->
      <v-ons-list-item>
        <span class="list-item__title">{{ $t("common.notes") }}</span>
        <span class="list-item__subtitle">
          <label class="center">
            <textarea v-model="contentForm.notes" class="textarea" cols="40" rows="5" :placeholder="$t('common.enter_notes')" style="width: 95%">
            </textarea>
          </label>
        </span>
      </v-ons-list-item>
      <ons-row class="border-t flex items-center justify-center py-4">
        <v-ons-button @click="onSubmitContent" modifier="quiet">
          {{ $t("common.submit") }}
        </v-ons-button>
      </ons-row>
    </v-ons-list>
  </v-ons-action-sheet>
</template>

<script>
export default {
  name: "PaymentContent",
  model: {
    prop: "showBottomMenu",
    event: "onVisibleChange"
  },
  props: {
    contentForm: Object,
    showBottomMenu: Boolean,
    selectedCurrency: Object,
    expenseList: Array
  },
  methods: {
    onSubmitContent() {
      this.$emit("onSubmitContent");
    },
    onExpenseChange() {
      this.$emit("onExpenseChange");
    },
    onCloseBottomMenu() {
      this.$emit("closeBottomMenu");
    }
  },
  computed: {
    isBottomMenuVisible: {
      get() {
        return this.showBottomMenu;
      },
      set(isVisible) {
        this.$emit("closeBottomMenu", isVisible);
      }
    }
  }
};
</script>

<style scoped></style>
