export const home = {
  header: {
    application: "功能"
  },
  menu: {
    reimbursements: "报销申请",
    payment: "付款申请",
    collection_report: "债权回收",
    purchase: "购买申请",
    supplier_contract: "购入・供应商合同签订",
    customer_contract: "客户合同签订",
    corporate_seal: "证件资料盖章申请",
    stamp_statistic: "盖章统计",
    sales: "Sales",
    sales_registration: "Sales/custom info registration"
  },
  top_menu: {
    pending: "等待审批",
    done: "审批完成",
    initiated: "已申请",
    forwarded: "收到转送"
  },
  navigation: {
    home: "主页",
    statistic: "财务统计",
    admin: "使用者管理"
  }
};
