export const admin = {
  menu: {
    my_organization: "自社",
    external_companies: "外部顧客",
    background: "バックグラウンド（詳細）",
    create_company: "企業の追加",
    create_user: "ユーザーの追加",
    log_out: "ログアウト"
  },
  background: {
    add_group: "顧客グループの追加",
    edit_group: "グループの編集",
    group_name: "グループ名",
    enter_group_name: "グループ名の入力",
    company: "会社名",
    select_company: "会社の選択",
    pic_help: "星霜担当者の登録",
    finance: "財務担当",
    select_finance: "財務担当の選択",
    treasury: "出納担当",
    select_treasury: "出納担当の選択",
    hr: "人事担当",
    select_hr: "人事担当の選択",
    ga: "総務担当",
    select_ga: "総務担当の選択",
    select_pic: "星霜担当者の選択"
  },
  company: {
    create_company: "会社・部門・地点情報登録",
    edit_company: "会社の編集",
    company: "会社",
    enter_company: "会社名の入力",
    location: "地点",
    enter_location: "地点の入力",
    department: "部門",
    enter_department: "部門の選択"
  },
  user: {
    create_user: "ユーザーの登録",
    edit_user: "ユーザーの編集",
    delete_user: "ユーザーの削除",
    company_info: "会社情報",
    company: "会社",
    select_company: "会社の選択",
    department: "部門",
    select_department: "部門の選択",
    location: "地点",
    select_location: "地点の選択",
    join_date: "入職日",
    select_join_date: "入職日の選択",
    personal_info: "個人情報",
    name: "名前",
    enter_name: "名前の入力",
    email: "メール",
    enter_email: "メールアドレスを入力",
    company_auth: "会社責任者",
    company_finance: "Company Finance / Treasury",
    company_treasury: "Company Internal Auditor",
    company_legal: "Company Legal",
    role: "User role",
    setting: "設定"
  }
};
