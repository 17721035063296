<template>
  <v-ons-page @show="onShow" @init="onInit">
    <v-ons-progress-bar indeterminate v-if="loading"></v-ons-progress-bar>
    <div class="md:px-4 px-2 bg-white overflow-hidden relative">
      <img :src="require('@/assets/images/bg2.png')" alt="allastarsea" class="img-homepage rounded-lg" />
      <span class="absolute inset-0 bg-gradient-to-b from-transparent to-white"></span>
    </div>

    <v-ons-card class="flat py-4">
      <v-ons-row style="text-align: center">
        <v-ons-col>
          <div class="application-icon p-0 bg-white position-relative" @click="push(pages[0], $t('home.top_menu.pending'))">
            <Icon name="pending" classes="text-yellow-500" size="32" />
            <div v-if="approvalCount > 0" class="notification">
              {{ approvalCount }}
            </div>
          </div>
          <div class="text-sm">{{ $t("home.top_menu.pending") }}</div>
        </v-ons-col>
        <v-ons-col>
          <div class="application-icon p-0 bg-white position-relative" @click="push(pages[0], $t('home.top_menu.done'), { function: 'done' })">
            <Icon name="approved" classes="text-green-500" size="32" />
            <div class="notification"></div>
          </div>
          <div class="text-sm">{{ $t("home.top_menu.done") }}</div>
        </v-ons-col>
        <v-ons-col>
          <div
            class="application-icon p-0 bg-whit position-relative"
            @click="push(pages[1], $t('home.top_menu.initiated'), { function: 'initiated' })"
          >
            <Icon name="initiated" classes="text-blue-500" size="32" />
            <div class="notification"></div>
          </div>
          <div class="text-sm">{{ $t("home.top_menu.initiated") }}</div>
        </v-ons-col>
        <v-ons-col>
          <div
            class="application-icon p-0 bg-whit position-relative"
            @click="push(pages[1], $t('home.top_menu.forwarded'), { function: 'forwarded' })"
          >
            <Icon name="paper-plane" classes="text-blue-500" size="32" />
            <div class="notification"></div>
          </div>
          <div class="text-sm">{{ $t("home.top_menu.forwarded") }}</div>
        </v-ons-col>
      </v-ons-row>
    </v-ons-card>
    <br />
    <v-ons-card class="flat p-4">
      <div class="title">{{ $t("home.header.application") }}</div>
      <v-ons-row style="text-align: center">
        <v-ons-col>
          <ons-button modifier="quiet" class="bg-transparent" @click="push(pages[2], $t('app.reimb.reimbursement'), { module: 'reimbursement' })">
            <div class="application-icon bg-blue-500">
              <Icon name="wallet-duo" classes="text-white" size="26" />
            </div>
          </ons-button>
          <div class="text-logo-small">
            {{ $t("home.menu.reimbursements") }}
          </div>
        </v-ons-col>
        <v-ons-col>
          <ons-button modifier="quiet" class="bg-transparent" @click="push(pages[2], $t('home.menu.payment'), { module: 'payment' })">
            <div class="application-icon bg-purple-500">
              <Icon name="credit-card-duo" classes="text-white" size="26" />
            </div>
          </ons-button>
          <div class="text-logo-small">{{ $t("home.menu.payment") }}</div>
        </v-ons-col>
        <v-ons-col>
          <ons-button modifier="quiet" class="bg-transparent" @click="push(pages[2], $t('home.menu.purchase'), { module: 'purchase' })">
            <div class="application-icon bg-yellow-500">
              <Icon name="check-edit-duo" classes="text-white" size="26" />
            </div>
          </ons-button>
          <div class="text-logo-small">{{ $t("home.menu.purchase") }}</div>
        </v-ons-col>
        <v-ons-col>
          <ons-button modifier="quiet" class="bg-transparent" @click="push(pages[3], $t('home.menu.sales'))">
            <div class="application-icon bg-green-500">
              <Icon name="book-duo" classes="text-white" size="26" />
            </div>
          </ons-button>
          <i18n tag="div" class="text-logo-small" path="home.menu.sales">
            <br />
          </i18n>
        </v-ons-col>
      </v-ons-row>

      <v-ons-row style="text-align: center">
        <v-ons-col>
          <ons-button
            modifier="quiet"
            class="bg-transparent"
            @click="push(pages[2], $t('home.menu.supplier_contract'), { module: 'contract-supplier' })"
          >
            <div class="application-icon bg-rose-500">
              <Icon name="cube-duo" classes="text-white" size="26" />
            </div>
          </ons-button>
          <i18n tag="div" class="text-logo-small" path="home.menu.supplier_contract">
            <br />
          </i18n>
        </v-ons-col>
        <v-ons-col>
          <ons-button
            modifier="quiet"
            class="bg-transparent"
            @click="push(pages[2], $t('home.menu.customer_contract'), { module: 'contract-customer' })"
          >
            <div class="application-icon bg-rose-500">
              <Icon name="user-2-duo" classes="text-white" size="26" />
            </div>
          </ons-button>
          <i18n tag="div" class="text-logo-small" path="home.menu.customer_contract">
            <br />
          </i18n>
        </v-ons-col>
        <v-ons-col>
          <ons-button modifier="quiet" class="bg-transparent" @click="push(pages[2], $t('home.menu.corporate_seal'), { module: 'corporate-seal' })">
            <div class="application-icon bg-rose-500">
              <Icon name="stamp-duo" classes="text-white" size="26" />
            </div>
          </ons-button>
          <i18n tag="div" class="text-logo-small" path="home.menu.corporate_seal">
            <br />
          </i18n>
        </v-ons-col>
        <v-ons-col>
          <div v-if="isAllowStampStatistic">
            <ons-button modifier="quiet" class="bg-transparent" @click="push(pages[4], $t('home.menu.stamp_statistic'))">
              <div class="application-icon bg-green-500">
                <Icon name="chart-bar-duo" classes="text-white" size="28" />
              </div>
            </ons-button>
            <i18n tag="div" class="text-logo-small" path="home.menu.stamp_statistic">
              <br />
            </i18n>
          </div>
        </v-ons-col>
      </v-ons-row>
    </v-ons-card>
  </v-ons-page>
</template>

<script>
import { fetchList } from "@/api/approval";
import Pending from "@/views/Approval/Pending";
import Done from "@/views/Approval/Done";

// import SupplierForm from "@/views/Home/form/SupplierForm";

// import ReimbursementForm from "@/views/Application/Reimbursement";
// import PaymentForm from "@/views/Application/Payment";
// import PurchaseForm from "@/views/Application/Purchase";
import CollectionReport from "@/views/Application/CollectionReport";
// import SupplierContract from "@/views/Application/SupplierContract";
// import CustomerContract from "@/views/Application/CustomerContract";
// import CorporateSeal from "@/views/Application/CorporateSeal";
import StampStatistic from "@/views/Application/StampStatistic";

import Application from "@/views/Application/index";

export default {
  name: "Home",
  data() {
    return {
      loading: true,
      approvalCount: 0,
      pages: [Pending, Done, Application, CollectionReport, StampStatistic]
    };
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
    isAllowStampStatistic() {
      return this.profile && (this.profile.is_authorizer || this.profile.is_legal || this.profile.is_treasury);
    }
  },
  methods: {
    onShow() {
      this.$store.commit("app/SET_PROCESSES", []);
      this.$store.commit("app/SET_APPROVERS", []);
      this.getApprovalData();
    },
    onInit() {
      this.getApprovalData();
    },
    getApprovalData() {
      fetchList({ itemsPerPage: 99999 }).then((response) => {
        this.approvalCount = response["hydra:member"].length;
        this.loading = false;
      });
    },
    push(page, key, param) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>
