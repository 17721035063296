<template>
  <div>
    <div v-if="process">
      <span v-if="status >= 3" class="bg-rose-100 px-2 py-0.5 rounded rounded-md text-rose-500 text-xs">
        {{ process }}
      </span>
      <span v-if="status === 2" class="bg-green-100 px-2 py-0.5 rounded rounded-md text-green-500 text-xs">
        {{ process }}
      </span>
      <span v-if="status === 1" class="bg-green-100 px-2 py-0.5 rounded rounded-md text-green-500 text-xs">
        {{ process }}
      </span>
      <span v-if="status === 0" class="bg-yellow-100 px-2 py-0.5 rounded rounded-md text-yellow-500 text-xs">
        {{ process }}
      </span>
    </div>
    <div v-else>
      <span v-if="status == 3" class="bg-rose-100 px-2 py-0.5 rounded rounded-md text-rose-500 text-xs">
        {{ $t("common.rejected") }}
      </span>
      <span v-if="status == 10" class="bg-rose-100 px-2 py-0.5 rounded rounded-md text-rose-500 text-xs">
        {{ $t("common.withdrawn") }}
      </span>
      <span v-if="status === 2" class="bg-green-100 px-2 py-0.5 rounded rounded-md text-green-500 text-xs">
        {{ $t("common.approved") }}
      </span>
      <span v-if="status === 0" class="bg-yellow-100 px-2 py-0.5 rounded rounded-md text-yellow-500 text-xs">
        {{ $t("common.onprocess") }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusBadge",
  props: ["status", "process"]
};
</script>

<style scoped></style>
