import request from "@/utils/request";

export function getContractTypes(param) {
  return request({
    url: "/contract_item_types/module/" + param,
    method: "get"
  });
}

export function getContractTypeById(id) {
  return request({
    url: "/contract_item_types/" + id,
    method: "get"
  });
}

export function getContractFrequency() {
  return request({
    url: `/contract_frequencies`,
    method: "get"
  });
}
export function getContractFrequencyById(id) {
  return request({
    url: `/contract_frequencies/${id}`,
    method: "get"
  });
}

export function getContractSupplierAccount() {
  return request({
    url: `/contract_supplier_accounts`,
    method: "get"
  });
}

export function submitConctractSupplierBuffer(data) {
  return request({
    url: "/buffer/contract-supplier",
    method: "post",
    data: data
  });
}
