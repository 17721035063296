import request from "@/utils/request";

export function fetchDetail(id) {
  return request({
    url: "/files/" + id,
    method: "GET"
  });
}

export function postFile(data) {
  return request({
    url: "/files",
    method: "post",
    data: data
  });
}

export function fetchDelete(id) {
  return request({
    url: "/files/" + id,
    method: "DELETE"
  });
}

export function download(url, params) {
  return request({
    url: url,
    method: "get",
    params: params,
    responseType: "blob"
  });
}
