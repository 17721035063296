<template>
  <div class="space-y-2">
    <d-text :label="$t('app.sc.item.content')" :value="item.content ? item.content : '-'" />
    <hr />
    <d-text :label="$t('app.sc.item.type1')" :value="item.type1 ? item.type1 : '-'" />
    <d-text :label="$t('app.sc.item.type2')" :value="item.type2 ? item.type2 : '-'" />
    <d-text :label="$t('app.sc.item.unit_price')" :value="amount" />
    <hr />
    <d-text :label="$t('app.sc.item.start_date')" :value="startDate" />
    <d-text :label="$t('app.sc.item.terms')" :value="item.term ? item.term : '-'" />
    <hr />
    <d-text :label="$t('common.notes')" :value="item.note ? item.note : '-'" />
  </div>
</template>

<script>
import { Round } from "../../../../utils/number";
export default {
  name: "SupplierContractItemServices",
  props: ["data"],
  computed: {
    item() {
      return this.data.item;
    },
    currency() {
      return this.data.currency;
    },
    amount() {
      return this.currency + " " + (this.item.unit_price ? Round(this.item.unit_price, this.currency) : "0");
    },
    startDate() {
      return this.item.start_date ? this.moment(this.item.start_date).format("YYYY/MM/DD") : "-";
    }
  }
};
</script>

<style></style>
