<template>
  <v-ons-page>
    <div
      class="-translate-x-1/2 -translate-y-1/2 absolute left-1/2 top-1/2 transform"
    >
      Allastarsea
    </div>
  </v-ons-page>
</template>

<script>
export default {
  name: "SplashScreen"
};
</script>

<style></style>
