<template>
  <BottomSheet :title="title" :showing="show" @on-close="$emit('close')">
    <v-ons-list class="pb-16">
      <v-ons-list-item v-for="(item, key) in option" :key="key" tappable @click="handleChange(item)">
        <label class="left">
          <v-ons-checkbox :input-id="`${title}_${key}`" :value="item.value" v-model="selectedOption"> </v-ons-checkbox>
        </label>
        <label :for="`${title}_${key}`" class="center">
          {{ item.label }}
        </label>
      </v-ons-list-item>
    </v-ons-list>
  </BottomSheet>
</template>
<script>
import BottomSheet from "@/components/BottomSheet";

export default {
  name: "SelectSingle",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    option: {
      type: Array
    },
    title: {
      type: String
    }
  },
  components: {
    BottomSheet
  },
  watch: {
    show: function(newVal) {
      if (newVal) {
        if (this.value) {
          this.selectedOption = this.option.filter((item) => item.value == this.value).map((item) => item.value);
        }
      }
    }
  },
  data() {
    return {
      selectedOption: []
    };
  },
  methods: {
    handleChange(val) {
      this.$emit("select", val);
    }
  }
};
</script>
