<template>
  <v-ons-list-item :class="classes">
    <v-ons-list-item class="pl-0">
      <div class="center py-0">
        <div class="flex items-center justify-between w-full">
          <div>
            <span class="list-item__title" :class="{ required: required }">
              {{ label }}
            </span>
            <p class="text-gray-400 text-xs">{{ sublabel }}</p>
          </div>
          <div>
            <div v-if="!selectable && files.length > 0" class="cursor-pointer mr-2 text-blue-500 text-sm" @click="selectable = true">Select file</div>
            <div v-if="selectable">
              <span
                class="mt-2 text-blue text-sm pr-2 mr-2 border-r cursor-pointer"
                @click="files.length == selectedFiles.length ? handleClearAll() : handleSelectAll()"
              >
                {{ files.length == selectedFiles.length ? "Clear all" : "Select all" }}
              </span>

              <span class="cursor-pointer mr-2 text-blue-500 text-sm" @click="handleCancelSelect">Cancel</span>
              <span
                class="p-2  text-white text-sm rounded-md"
                :class="selectedFiles.length > 0 ? 'bg-blue-500 cursor-pointer' : 'bg-blue-200 cursor-not-allowed'"
                @click="selectedFiles.length > 0 ? handleDownloadFile() : null"
              >
                Download
              </span>
            </div>
          </div>
        </div>

        <weui-cell-group class="w-full">
          <l-file
            v-for="(item, key) in files"
            :key="key"
            :file="item"
            @on-click="preview"
            :disabled="!canDelete || disabled"
            :selectable="selectable"
            :selected="selectedFiles.some((file) => file.id === item.id)"
            :isEdited="undefined !== item.is_edited ? item.is_edited : false"
            :history="history"
            @select="handleSelect"
            @on-action="deleteFile(item)"
          />
          <weui-cell v-if="!disabled">
            <template #title>
              <weui-uploader
                title=""
                accept="image/*, application/pdf, .xlsx, .xls, .doc, .docx, .ofd"
                :afterRead="afterRead"
                v-model="fileList"
                :multiple="false"
                @onClick="preview($event)"
                @oversize="oversize($event)"
              ></weui-uploader>
            </template>
          </weui-cell>
        </weui-cell-group>
        <!-- all file is downloadable -->
        <!-- <weui-gallery :visible="show" :src="img" :canDelete="false" @onClick="hide()"></weui-gallery> -->
      </div>
    </v-ons-list-item>
  </v-ons-list-item>
</template>
<script>
import { postFile } from "@/api/file";
import { b64toBlob } from "@/utils/b64toBlob";
import { humanFileSize } from "@/utils/helper";
import multiDownload from "multi-download";

export default {
  name: "form-uploader",
  props: {
    documentType: {
      type: String
    },
    multiple: {
      type: Boolean,
      default: true
    },
    label: {
      type: String
    },
    sublabel: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    existing: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: true
    },
    onlyNew: {
      type: Boolean,
      default: false
    },
    history: {
      type: Object,
      default: null
    },
    classes: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      url: process.env.VUE_APP_BASE_URL + "/files",
      show: false,
      fileList: [],
      files: [],
      newFiles: [],
      img: "",
      type: "",
      selectedFiles: [],
      selectable: false
    };
  },
  watch: {
    existing: function(n) {
      this.files = n;
    }
  },
  computed: {
    topbar() {
      return document.getElementsByTagName("ons-toolbar")[1];
    },
    to() {
      return this.onlyNew ? this.newFiles : this.files;
    }
  },
  methods: {
    afterRead(file) {
      let blob = b64toBlob(file.content, file.file.name);
      var formData = new FormData();

      formData.append("file", blob);
      formData.append("document_type", this.documentType);

      this.$toast.loading("Uploading", { duration: 0 });
      postFile(formData)
        .then((response) => {
          let item = {
            id: response.id,
            url: response.url,
            name: file.file.name,
            type: file.file.type,
            size: humanFileSize(file.file.size),
            created_at: response.created_at ? response.created_at : null,
            formated_name: response.formated_name ? response.formated_name : null
          };

          this.to.push(item);
          this.$emit("uploadedItem", item);
        })
        .then(() => {
          this.$emit("on-success", this.to);
          this.$toast.hide();
        })
        .catch(() => this.$toast.warn("Failed"));
    },
    preview(url) {
      //all file is downloadable
      // if (view) {
      //   this.img = url;
      //   this.show = true;

      //   if (this.topbar) {
      //     this.topbar.style.zIndex = "0";
      //   }

      //   return;
      // }

      window.open(url, "_blank");
    },
    deleteFile(file) {
      this.$ons.notification.confirm(this.$t("common.notif.decide"), { title: this.$t("common.notif.confirm") }).then((response) => {
        if (response === 1) {
          this.files = this.files.filter((item) => item.id !== file.id);
          this.$emit("deleted", file.id);
          this.$emit("on-success", this.files);
        }
      });
    },
    hide() {
      if (this.topbar) {
        this.topbar.style.zIndex = "9999";
      }
      this.show = false;
    },
    handleSelect(id) {
      let selected = this.files.filter((file) => file.id === id)[0];
      if (!this.selectedFiles.find((item) => item.id === id)) {
        this.selectedFiles.push(selected);
      } else {
        this.selectedFiles = this.selectedFiles.filter((item) => item.id !== id);
      }
    },
    handleCancelSelect() {
      this.selectedFiles = [];
      this.selectable = false;
    },
    handleClearAll() {
      this.selectedFiles = [];
    },
    handleSelectAll() {
      this.selectedFiles = this.files;
    },
    handleDownloadFile() {
      let files = this.selectedFiles.map((item) => ({
        url: item.url,
        name: item.formated_name
      }));

      multiDownload(
        files.map((u) => u.url),
        {
          rename: ({ url, index, urls }) => {
            console.log(url, urls);
            return files[index].name;
          }
        }
      );
    }
  }
};
</script>
<style scoped>
.weui-cells__title {
  font-size: 16px;
  padding-left: 0;
  color: black;
}
.w-full {
  padding-top: 8px;
}
.weui-cell {
  padding-left: 0;
}
.weui-cells:after {
  border-bottom: none !important;
}
.weui-cell:before {
  border-top: none !important;
}
.list-item {
  border-color: transparent;
}
</style>
