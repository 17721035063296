<template>
  <div class="border-l-4 mb-2 bg-gray-50" :class="{ 'border-red-400': data.is_edited }">
    <div class="ml-2">
      <div class="w-full pr-2">
        <div class="mb-1.5">{{ title }}</div>
        <div class="space-y-1 text-xs">
          <div>
            <span class="text-gray-500 mr-3">{{ $t("app.sc.item.name") }}</span>
            <span>{{ item.name }}</span>
          </div>
          <div>
            <span class="text-gray-500 mr-3">{{ $t("app.sc.item.budget_tax") }}</span>
            <span>{{ currency }} {{ item.amount }}</span>
          </div>
          <div>
            <span class="text-gray-500 mr-3">{{ $t("app.sc.item.unit_price") }}</span>
            <span>{{ currency }} {{ item.unit_price }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "goods",
  props: ["data", "title", "currency"],
  computed: {
    item() {
      return this.data.data;
    }
  }
};
</script>

<style></style>
