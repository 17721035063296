<template>
  <ons-row class="fixed bottom-0 border-t py-2 px-2 grid grid-cols-2 bg-gray-100 divide-x h-12">
    <div>
      <v-ons-button class="flex items-center justify-center text-sm" @click.native="$emit('filter')" modifier="quiet">
        <Icon name="adjustment" classes="h-6 w-6 mr-2 transform rotate-90" /> {{ $t("common.filter") }}
      </v-ons-button>
    </div>
    <div>
      <v-ons-button class="flex items-center justify-center text-sm" @click.native="$emit('sort')" modifier="quiet">
        <Icon name="sort-descending" classes="h-6 w-6 mr-2" /> {{ $t("common.sort") }}
      </v-ons-button>
    </div>
  </ons-row>
</template>

<script>
export default {
  name: "FilterBox"
};
</script>
