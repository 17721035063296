<template>
  <div class="py-2">
    <div class="bg-gray-100 p-2 border">{{ label }}</div>
    <div class="border border-t-0 p-2" v-for="(item, key) in data" :key="item.id">
      <div class="flex justify-between items-center">
        <div :class="{ 'text-red-500': isEdited(item) }">{{ label }} ({{ key + 1 }})</div>
        <div class="text-blue-500 cursor-pointer" @click="$emit('tap', item, key)">{{ $t("common.detail") }}</div>
      </div>
      <div v-if="isEdited(item)">
        <div class="p-1 rounded flex items-center justify-between" style="font-size:10px">
          <div class="flex">
            <Icon name="edit" classes="text-gray-500 mr-1" :size="12" />
            <span class="mr-1">{{ history.user.name }} </span>
          </div>
          <div>
            <span class="italic">{{ history.date ? moment(history.date).format("YYYY-MM-DD") : "" }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "d-items",
  props: ["data", "label", "history"],
  computed: {
    datas() {
      return this.history ? this.history.value : null;
    }
  },
  methods: {
    isEdited(item) {
      let ret = false;
      if (this.datas) {
        ret = _.find(this.datas, item);
      }
      return ret;
    }
  }
};
</script>

<style></style>
