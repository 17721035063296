<template>
  <v-ons-card v-if="data && history != undefined">
    <div class="content p-2 space-y-2">
      <d-text :label="$t('common.app_code')" :value="data.code" />
      <hr />
      <d-text :label="$t('common.company')" :value="company ? company : '-'" />
      <d-text :label="$t('common.department')" :value="department ? department : '-'" />
      <d-text :label="$t('common.related_approval')" :value="relatedApproval ? relatedApproval.code : '-'" />

      <d-items
        :data="obj.transaction_items"
        :label="$t('app.reimb.reimbursement')"
        :history="'transaction_items' in history ? history.transaction_items : null"
        @tap="handleDetailOpen"
      />

      <!-- <d-text :label="$t('common.total')" :value="obj.total + ' ' + currency" :history="'total' in history ? history.total : null" /> -->
      <d-total :label="$t('common.total')" :currency="currency" :amount="obj.total" :history="'total' in history ? history.total : null" />
      <div class="text-xs space-y-1 text-gray-500 pt-2">
        <div class="flex items-center ">
          <div class="bg-green checkbox mr-1" v-if="obj.submit_supplier_contract_information">
            <Icon name="check" size="12" classes="text-white" />
          </div>
          <div class="bg-white mr-1" v-else>
            <Icon name="circle" size="20" classes="text-gray-500" />
          </div>
          <div>
            {{ $t("app.reimb.with_supplier_account") }}
          </div>
        </div>
      </div>
      <br />
      <div v-if="obj.submit_supplier_contract_information">
        <d-text :label="$t('app.account.name')" :value="obj.payment_recipient_account.name ? obj.payment_recipient_account.name : '-'" />
        <d-text :label="$t('app.account.number')" :value="obj.payment_recipient_account.no ? obj.payment_recipient_account.no : '-'" />
        <d-text :label="$t('app.account.bank')" :value="obj.payment_recipient_account.bank_name ? obj.payment_recipient_account.bank_name : '-'" />
        <d-text
          :label="$t('app.account.branch')"
          :value="obj.payment_recipient_account.branch_name ? obj.payment_recipient_account.branch_name : '-'"
        />
      </div>
      <hr />
      <form-uploader
        classes="p-0"
        :label="$t('app.reimb.e_invoice')"
        :existing="invoices"
        :disabled="true"
        :history="'e_invoices' in history ? history.e_invoices : null"
      />
      <hr />
      <form-uploader
        classes="p-0"
        :label="$t('app.reimb.application_paper')"
        :existing="papers"
        :disabled="true"
        :history="'application_papers' in history ? history.application_papers : null"
      />
      <hr />
      <d-subtitle> {{ $t("app.invoice_later") }}</d-subtitle>
      <d-text
        :label="$t('common.amount')"
        :value="(obj.invoice_amount ? obj.invoice_amount : '-') + ' ' + currency"
        :history="'invoice_amount' in history ? history.invoice_amount : null"
      />
      <d-text
        :label="$t('app.reimb.supplier_name')"
        :value="obj.invoice_note ? obj.invoice_note : ' - '"
        :history="'invoice_note' in history ? history.invoice_note : null"
      />
      <hr />
      <d-subtitle> {{ $t("app.input_by_treasury") }}</d-subtitle>
      <d-text :label="$t('app.payment_account')" :value="paymentAccount" :history="'account_id' in history ? history.account_id : null" />
      <d-text
        :label="$t('app.payment_date')"
        :value="obj.payment_date ? moment(obj.payment_date).format('YYYY/MM/DD') : '-'"
        :history="'payment_date' in history ? history.payment_date : null"
      />
      <div class="font-16 text-dark-gray">{{ $t("app.deal_slip") }}</div>
      <form-uploader
        classes="p-0"
        :label="$t('app.deal_slip')"
        :existing="dealSlipList"
        :disabled="true"
        :history="'deal_slips' in history ? history.deal_slips : null"
      />
      <hr />
      <d-text :label="$t('app.reimb.tax')" :value="obj.note ? obj.note : ' - '" :history="'note' in history ? history.note : null" />
      <hr />
      <d-list :label="$t('app.forward_finance')" :items="finances" itemKey="name" />
      <!-- <hr />
      <d-process v-for="(item, key) in finances" :key="key" :initial="item.initial_name" :name="item.name" :process="$t('app.forward_finance')" /> -->
    </div>

    <detail v-if="detail.data" :data="detail" :currency="currency" @on-close="handleDetailClose" />
    <weui-gallery :visible="previewShow" :src="previewImg" :canDelete="false" @onClick="previewShow = false"></weui-gallery>
  </v-ons-card>
</template>

<script>
import { fetchDetail as getPaymentAccount } from "@/api/bank_account";
import { fetchUserDetail as getUser } from "@/api/user";
import detail from "@/views/Approval/component/reimbursement/detail";
import { fetchDetail as getFile } from "@/api/file";
import buffer from "@/mixins/buffer";
import history_detail from "@/mixins/history_detail";
import invoice from "@/mixins/invoice";
import { fetchDetail as getRelatedApproval } from "@/api/purchase";

const defaultDetail = {
  show: false,
  title: "detail",
  data: null
};

export default {
  name: "Reimbursement",
  props: ["data", "history"],
  mixins: [buffer, history_detail, invoice],
  components: { detail },
  computed: {
    obj() {
      return this.data ? this.data.data : null;
    },
    companyId() {
      return this.obj.transaction_items ? this.obj.transaction_items[0].company_id : null;
    },
    departmentId() {
      return this.obj.transaction_items ? this.obj.transaction_items[0].department_id : null;
    },
    relatedApprovalId() {
      return this.obj.transaction_items ? this.obj.transaction_items[0].related_approval_id : null;
    },
    bufferId() {
      return this.data ? (this.data.buffer_id ? this.data.buffer_id : this.data.id) : null;
    },
    isApproved() {
      return this.data ? this.data.status == 2 : false;
    },
    invoices() {
      let f = this.invoiceFile.e_invoices.map((item) => ({
        ...item.file,
        size: item.file.size_string,
        is_edited: !!item.user
      }));
      return [...this.eInvoiceList, ...f];
    },
    papers() {
      let f = this.invoiceFile.app_papers.map((item) => ({
        ...item.file,
        size: item.file.size_string,
        is_edited: !!item.user
      }));
      return [...this.applicationPaperList, ...f];
    }
  },
  data() {
    return {
      detail: Object.assign({}, defaultDetail),
      currency: "",
      paymentAccount: "-",
      finances: [],
      eInvoiceList: [],
      applicationPaperList: [],
      dealSlipList: [],
      relatedApproval: null
    };
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(n, o) {
        if (n != o) {
          this.fetchCurrency(this.obj.currency_id);
          if (this.obj.account_id) {
            this.fetchPaymentAccount(this.obj.account_id);
          }

          if (this.obj.user_finances) {
            this.obj.user_finances.forEach((user) => {
              this.fetchUserDetail(user);
            });
          }

          if (this.companyId) {
            this.fetchCompany(this.companyId);
          }
          if (this.departmentId) {
            this.fetchDepartment(this.departmentId);
          }

          if (this.relatedApprovalId) {
            this.fetchRelatedApproval(this.relatedApprovalId);
          }

          this.getEInvoiceFiles();
          this.getApplicationPapers();
          this.getDealSlips();
          //invoice
          if (this.bufferId && this.isApproved) {
            this.loadInvoiceFiles(this.bufferId);
          }
          this.loading = false;
        }
      }
    }
  },
  methods: {
    handleDetailOpen(item, key) {
      this.detail = {
        ...{
          show: true,
          title: this.$t("app.reimb.reimbursement") + " (" + (key + 1) + ")",
          data: item
        }
      };
    },
    handleDetailClose() {
      this.detail = Object.assign({}, defaultDetail);
    },
    fetchPaymentAccount(id) {
      getPaymentAccount(id).then((response) => {
        this.paymentAccount = response.bank_name + " (" + response.branch + ") " + response.type;
      });
    },
    fetchUserDetail(id) {
      this.finances = [];
      getUser(id).then((response) => {
        this.finances.push(response);
      });
    },
    getEInvoiceFiles() {
      this.eInvoiceList = [];
      this.obj.e_invoices.forEach((item) => {
        getFile(item).then((f) => {
          this.eInvoiceList.push({
            ...f,
            size: f.size_string,
            is_edited: this.isFileEdited(f.id, "e_invoices")
          });
        });
      });
    },
    getApplicationPapers() {
      this.applicationPaperList = [];
      this.obj.application_papers.forEach((item) => {
        getFile(item).then((f) => {
          this.applicationPaperList.push({
            ...f,
            size: f.size_string,
            is_edited: this.isFileEdited(f.id, "application_papers")
          });
        });
      });
    },
    getDealSlips() {
      this.dealSlipList = [];
      this.obj.deal_slips.forEach((item) => {
        getFile(item).then((f) => {
          this.dealSlipList.push({
            ...f,
            size: f.size_string,
            is_edited: this.isFileEdited(f.id, "deal_slips")
          });
        });
      });
    },
    async fetchRelatedApproval(id) {
      await getRelatedApproval(id).then((response) => {
        this.relatedApproval = response;
      });
    }
  }
};
</script>

<style></style>
