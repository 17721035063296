<template>
  <div class="space-y-6 mb-16">
    <v-ons-list>
      <v-ons-list-header />
      <form-textarea
        :label="$t('app.cc.item.content')"
        :placeholder="$t('app.cc.item.enter_content')"
        :required="true"
        v-model="item_other.content"
      />
    </v-ons-list>

    <v-ons-list>
      <form-input kind="text" :label="$t('app.cc.item.type1')" :placeholder="$t('app.cc.item.enter_type1')" v-model="item_other.type1" />
      <form-input kind="text" :label="$t('app.cc.item.type2')" :placeholder="$t('app.cc.item.enter_type2')" v-model="item_other.type2" />
    </v-ons-list>

    <v-ons-list>
      <form-input
        :label="$t('app.cc.item.amount_tax')"
        :placeholder="$t('app.cc.item.enter_amount_tax')"
        :required="true"
        v-model.number="item_other.amount"
      />
    </v-ons-list>

    <v-ons-list>
      <form-textarea :label="$t('common.notes')" :placeholder="$t('common.enter_notes')" v-model="item_other.note" />
    </v-ons-list>

    <bottom-submit :label="$t('common.next')" @click.native="handleSubmit" classes="bg-blue-500" />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "customerDetailOther",
  props: ["param"],
  mounted() {
    if (this.param.item.item) {
      this.item_other = this.param.item.item;
    } else if (this.param.item) {
      this.item_other = this.param.item;
    }
  },
  watch: {
    item_other: {
      deep: true,
      handler(n) {
        n.total = n.amount;
      }
    }
  },
  data() {
    return {
      item_other: {
        content: "",
        type1: "",
        type2: "",
        amount: null,
        note: ""
      }
    };
  },
  validations: {
    item_other: {
      content: {
        required
      },
      amount: {
        required
      }
    }
  },
  methods: {
    handleSubmit() {
      this.isFormValid().then((valid) => {
        if (valid) {
          this.$store.commit("app/SET_DATA", {
            index: this.param.item.index,
            total: this.item_other.amount,
            name: "item_other",
            item: this.item_other
          });

          this.$store.commit("navigator/pop");
        }
      });
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.item_other.content.$invalid) {
        await this.$ons.notification.alert(this.$t("app.cc.alert.item.content"), options);
        return false;
      }
      if (this.$v.item_other.amount.$invalid) {
        await this.$ons.notification.alert(this.$t("app.cc.alert.item.amount_tax"), options);
        return false;
      }

      return true;
    }
  }
};
</script>

<style scoped></style>
