<template>
  <div>
    <search-filter :showSort="true" @on-search="onSearch" @on-reset="onReset" @on-sort="onSort" class="fixed w-full" />

    <div class="pt-20">
      <div v-if="loading" class="bg-white flex items-center justify-center py-3">
        <v-ons-progress-circular class="h-4 w-4 my-4" indeterminate modifier="material" />
        <span class="text-xs ml-2">Loading..</span>
      </div>
      <div v-else>
        <card-list
          v-for="(item, key) in list"
          :key="key"
          :title="item.title"
          :code="item.code"
          :process="{ status: item.status, process: null }"
          :applicant="item.applicant"
          :datas="item.info"
          @click.native="push(page[0].component, item.code, item)"
        />
        <infinite-loading @infinite="getListData" :identifier="infiniteId" />
      </div>
    </div>
  </div>
</template>

<script>
import CardList from "@/components/CardList";
import { fetchMyBuffers as fetchList } from "@/api/buffer";
import { mapInfo } from "@/utils/application_mapper";
import ApplicationDataDetail from "@/views/Application/component/ApplicationDataDetail";
import SearchFilter from "@/components/SearchFilter";

export default {
  name: "ApplicationData",
  props: ["load", "module"],
  components: {
    CardList,
    SearchFilter
  },
  data() {
    return {
      loading: true,
      infiniteId: 1,
      list: [],
      page: [
        {
          component: ApplicationDataDetail,
          label: "Detail"
        }
      ],
      filter: Object.assign({}, this.defaultFilter),
      totalItems: 0,
      awaitingSearch: false,
      sortShow: false,
      sortBy: ""
    };
  },
  computed: {
    defaultFilter() {
      return { page: 1, itemsPerPage: 5, "module.code": this.module, search: "" };
    }
  },
  watch: {
    load: function(newVal) {
      if (newVal) {
        this.onLoad();
      }
    }
  },
  methods: {
    forceRerender() {
      this.infiniteId += 1;
    },
    async onLoad() {
      this.onReset();
      this.loading = false;
      this.$emit("loaded");
    },
    getListData($state) {
      let data = [];
      fetchList(this.filter).then((response) => {
        data = response["hydra:member"];
        this.totalItems = response["hydra:totalItems"];

        let responseData = data.map((item) => {
          let obj = {
            buffer_id: item.id,
            code: item.code,
            status: item.status,
            title: this.$t("common." + item.action + "['" + item.module.code + "']"),
            applicant: item.requester,
            data: item.data,
            module: item.module.code,
            stamp: item.stamp_no
          };

          obj.info = mapInfo(this.module, item.info, item.data);
          return obj;
        });

        this.list.push(...responseData);

        if (data.length) {
          this.filter.page += 1;
          $state && $state.loaded();
        } else {
          $state && $state.complete();
        }
      });
    },
    onSearch(data) {
      if (data.term.length == 0) {
        return;
      }

      this.onReset();
      this.filter.search = data.term;
    },
    onReset() {
      this.list = [];
      this.filter = Object.assign({}, this.defaultFilter);
      this.forceRerender();
    },
    onSort(filters) {
      this.resetSort();
      this.list = [];
      this.filter.page = 1;
      this.filter[filters.sort_by] = filters.sort;
      this.forceRerender();
    },
    async resetSort() {
      delete this.filter["sort[code]"];
      delete this.filter["sort[createdAt]"];
      delete this.filter["sort[updatedAt]"];
    },
    push(page, key, param) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped></style>
