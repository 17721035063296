<template>
  <ons-bottom-toolbar class="fixed z-50 bg-transparent">
    <div class="bottom-0 p-2 w-full" :class="classes">
      <v-ons-button class="shadow-md w-10/12" :disabled="loading">
        <div class="flex items-center justify-center">
          <slot />
        </div>
      </v-ons-button>
    </div>
  </ons-bottom-toolbar>
</template>

<script>
export default {
  name: "form-button",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: ""
    }
  }
};
</script>

<style></style>
