<template>
  <v-ons-page @init="handleInit" @show="handleShow">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <div class="fixed w-full z-50 bg-white">
      <div class="flex justify-between py-3 px-6">
        <div class="flex space-x-2 items-center" @click="showPeriodFilter = true">
          <span class="text-sm">{{ periodObj.text }}</span>
          <Icon name="chevron-down" size="12" />
        </div>
        <div class="flex space-x-2 items-center" @click="push(pages[0], $t('common.select_company'))">
          <span class="text-sm text-blue-500">{{ companyObj.name }}</span>
          <Icon name="chevron-down" size="12" />
        </div>
      </div>
    </div>

    <period-select :showing="showPeriodFilter" :selected="periodObj" @on-select="handlePeriodSelect" @on-close="showPeriodFilter = false" />

    <loading-small v-show="loading" class="pt-16" />
    <div v-show="!loading" class="pt-16 space-y-3">
      <payment-projection-card v-for="(item, key) in list" :key="key" :data="item" />
    </div>
  </v-ons-page>
</template>

<script>
import PeriodSelect from "@/views/Statistic/PaymentProjection/component/periodSelect";
import ContractPartyList from "@/views/common/list/ContractPartyList";
import { fetchData } from "@/api/payment_projection";
import PaymentProjectionCard from "@/views/Statistic/PaymentProjection/component/paymentProjectionCard";

export default {
  name: "PaymentProjectionIndex",
  components: { PaymentProjectionCard, PeriodSelect },
  data() {
    return {
      loading: false,
      showPeriodFilter: false,
      list: [],
      companyObj: {},
      pages: [ContractPartyList],
      periodObj: {
        value: "today",
        text: this.$t("stat.proj.today")
      }
    };
  },
  computed: {
    filter() {
      return {
        company: this.companyObj.id,
        dateFilter: this.periodObj.value
      };
    }
  },
  methods: {
    handleInit() {
      let profile = this.$store.getters.profile;
      this.companyObj = {
        id: profile.company.id,
        name: profile.company.name
      };
    },
    handleShow() {
      this.handleCompanySelect();
    },
    async getData() {
      this.loading = true;
      await fetchData(this.filter).then((response) => {
        this.list = response["hydra:member"];
        this.loading = false;
      });
    },
    handlePeriodSelect(item) {
      this.periodObj = item;
      this.showPeriodFilter = false;
      this.getData();
    },
    handleCompanySelect() {
      let param = this.$store.getters.param;

      if (param.selectedContractParty) {
        this.companyObj = param.selectedContractParty;
      }
      this.$store.commit("navigator/clearParam");
      this.getData();
    },
    push(page, key) {
      this.$store.commit("navigator/options", {
        animation: "lift-ios"
      });
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped></style>
