<template>
  <v-ons-card v-if="data">
    <div class="content p-2 space-y-2">
      <d-text :label="$t('common.app_code')" :value="data.code" />
      <d-text :label="$t('admin.background.group_name')" :value="data.info.name" />
      <d-text :label="$t('admin.background.company')" :value="companies.join(', ')" />

      <hr />
      <div class="bg-gray-100 p-2">{{ $t("admin.background.pic_help") }}</div>
      <div class="px-2 space-y-2">
        <d-text v-for="(item, key) in pics" :key="key" :label="item.type" :value="item.user" />
      </div>
    </div>
  </v-ons-card>
</template>

<script>
import { fetchUserDetail } from "@/api/user";
import { fetchPicById } from "@/api/background";
import { fetchCompanyDetail as getCompany } from "@/api/company";

export default {
  name: "Background",
  props: ["data"],
  data() {
    return {
      pics: [],
      companies: []
    };
  },
  watch: {
    data(newval) {
      if (newval) {
        this.loadCompanies();
        newval.data.background_pics.forEach((item) => {
          this.getDetail(item);
        });
      }
    }
  },
  methods: {
    async getDetail(item) {
      let a = {
        user: null,
        type: null
      };
      await fetchUserDetail(item.pic_id).then((response) => {
        a.user = response.name;
      });
      await fetchPicById(item.pic_type_id).then((response) => {
        a.type = response.name;
      });

      this.pics.push(a);
    },
    loadCompanies() {
      this.data.data.companies.forEach((item) => {
        getCompany(item).then((response) => {
          this.companies.push(response.name);
        });
      });
    }
  }
};
</script>

<style></style>
