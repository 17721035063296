<template>
  <ons-row class="fixed bottom-0 border-t py-2 px-2 grid grid-cols-2 bg-gray-100 divide-x h-12">
    <div class="relative">
      <v-ons-button
        class="flex items-center justify-center text-sm"
        :class="!hasFilter ? 'text-gray-500' : null"
        @click="showFilterComponent = true"
        modifier="quiet"
      >
        <Icon name="filter" classes="h-6 w-6 mr-2 " /> {{ $t("common.filter") }}
      </v-ons-button>
    </div>
    <div>
      <v-ons-button
        class="flex items-center justify-center text-sm"
        :class="!hasSort ? 'text-gray-500' : null"
        @click="showSort = true"
        modifier="quiet"
      >
        <Icon name="sort-descending" classes="h-6 w-6 mr-2" /> {{ $t("common.sort") }}
      </v-ons-button>
    </div>
    <sort-select :showing="showSort" :selected="filters.by" @on-close="showSort = false" @on-select="handleSortSelect" />
    <filter-component
      :param="param"
      :periodPrefix="periodPrefix"
      :showing="showFilterComponent"
      @on-close="showFilterComponent = false"
      @on-filter="handleFilter"
    />
  </ons-row>
</template>

<script>
import SortSelect from "@/views/common/select/SortSelect";
import FilterComponent from "./FilterComponent.vue";

const defaultFilter = { term: "", sort_by: "sort[createdAt]", by: "createdAt", sort: "desc" };
export default {
  name: "FilterBox",
  components: { SortSelect, FilterComponent },
  props: ["param", "periodPrefix"],
  data() {
    return {
      showSort: false,
      showFilterComponent: false,
      hasFilter: false,
      hasSort: true,
      filters: Object.assign({}, defaultFilter)
    };
  },
  methods: {
    handleSortSelect(sort) {
      if (sort.by.length > 0) {
        this.hasSort = true;
      } else {
        this.hasSort = false;
      }
      this.resetSort();
      this.filters.sort_by = "sort[" + sort.by + "]";
      this.filters.by = sort.by;
      this.filters.sort = sort.order;
      this.showSort = false;
      this.$emit("on-sort", this.filters);
    },
    handleFilter(filter) {
      if (null === filter) {
        this.hasFilter = false;
      } else {
        this.hasFilter = true;
      }

      this.showFilterComponent = false;
      this.$emit("on-filter", filter);
    },
    resetSort() {
      delete this.filters.by;
      delete this.filters.sort_by;
    }
  }
};
</script>
