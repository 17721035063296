<template>
  <v-ons-page @show="onShow">
    <ons-list>
      <ons-list-header class="pt-4">{{ $t("stat.select_one") }}</ons-list-header>
    </ons-list>
    <div class="space-y-3 mt-3">
      <ons-list>
        <form-select-icon
          :label="$t('stat.projection')"
          :sublabel="$t('stat.projection_info')"
          icon="cash"
          iconClass="text-purple-500"
          @tap="push(page[2].component, page[2].label)"
        />
      </ons-list>

      <ons-list>
        <form-select-icon
          :label="$t('stat.invoice')"
          :sublabel="$t('stat.invoice_info')"
          icon="invoice"
          iconClass="text-blue-500"
          @tap="push(page[3].component, page[3].label)"
        />
      </ons-list>

      <ons-list>
        <form-select-icon
          :label="$t('stat.bank')"
          :sublabel="$t('stat.bank_info')"
          icon="credit-card"
          iconClass="text-rose-500"
          @tap="push(page[0].component, page[0].label)"
        />
      </ons-list>

      <ons-list>
        <form-select-icon
          :label="$t('stat.expense')"
          :sublabel="$t('stat.expense_info')"
          icon="wallet"
          iconClass="text-yellow-500"
          @tap="push(page[1].component, page[1].label)"
        />
      </ons-list>

      <ons-list>
        <form-select-icon
          :label="$t('stat.document_search')"
          :sublabel="$t('stat.search_.desc')"
          icon="document-search"
          iconClass="text-green-500"
          @tap="push(page[5].component, page[5].label)"
        />
      </ons-list>

      <ons-list>
        <form-select-icon
          :label="$t('stat.export')"
          :sublabel="$t('stat.export_info')"
          icon="export"
          iconClass="text-green-500"
          @tap="push(page[4].component, page[4].label)"
        />
      </ons-list>
    </div>
  </v-ons-page>
</template>

<script>
import CompanyBankAccount from "@/views/Statistic/CompanyBankAccount";
import ExpenseAccount from "@/views/Statistic/ExpenseAccount";
import PaymentProjection from "@/views/Statistic/PaymentProjection";
import InvoiceManagement from "@/views/Statistic/InvoiceManagement";
import DocumentSearch from "@/views/Statistic/DocumentSearch";
import Export from "@/views/Statistic/Export";

export default {
  name: "Statistic",
  computed: {
    page() {
      return [
        {
          component: CompanyBankAccount,
          label: this.$t("stat.bank")
        },
        {
          component: ExpenseAccount,
          label: this.$t("stat.expense")
        },
        {
          component: PaymentProjection,
          label: this.$t("stat.projection")
        },
        {
          component: InvoiceManagement,
          label: this.$t("stat.invoice")
        },
        {
          component: Export,
          label: this.$t("stat.export")
        },
        {
          component: DocumentSearch,
          label: this.$t("stat.document_search")
        }
      ];
    }
  },
  methods: {
    onShow() {},
    push(page, key) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key
            }
          };
        }
      });
    }
  }
};
</script>
