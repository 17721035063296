<template>
  <div class="flex flex-col items-center" @click="$emit('on-click')">
    <div class="bg-blue-500 flex h-12 items-center justify-center rounded-full text-sm w-12 shadow-md">
      <Icon :name="icon" classes="text-white" :size="size" stroke="2" />
    </div>
    <div>
      <span class="font-semibold mt-1 text-blue-500 text-xs">{{ label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "circle-button",
  props: {
    icon: {
      type: String
    },
    size: {
      type: Number,
      default: 22
    },
    label: {
      type: String
    }
  }
};
</script>

<style></style>
