import request from "@/utils/request";

export function fetchList(params) {
  return request({
    url: "/stamp_statistics",
    method: "get",
    params: params
  });
}

export function exportStampStatistic(params) {
  return request({
    url: "/stamp_statistics/export",
    method: "get",
    params: params
  });
}

export function fetchNextStampNo(params) {
  return request({
    url: "/stamp_statistics/next_code",
    method: "GET",
    params: params
  });
}
