<template>
  <div class="flex items-center">
    <div class="mr-6">{{ label }}</div>
    <div class="text-lg">
      <span class="mr-2">{{ currency }}</span>
      <span class="text-orange">{{ Round(amount, currency) }}</span>

      <div v-if="history">
        <div class="p-1 rounded flex items-center " style="font-size:10px">
          <Icon name="edit" classes="text-gray-500 mr-1" :size="12" />
          <span class="mr-1">{{ history.user.name }} </span>
          <span class="italic">{{ history.date ? moment(history.date).format("YYYY/MM/DD") : "" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Round } from "../../utils/number";

export default {
  name: "d-total",
  props: ["label", "currency", "amount", "history"],
  computed: {
    edited() {
      return this.history != null;
    }
  },
  methods: { Round }
};
</script>
