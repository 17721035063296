<template>
  <v-ons-card v-if="data">
    <div class="content p-2 space-y-2">
      <d-text :label="$t('common.app_code')" :value="data.code" />
      <d-text :label="$t('stat.expense_.name_en')" :value="data.data.name" />
      <d-text :label="$t('stat.expense_.name_jp')" :value="data.data.name_jp" />
      <d-text :label="$t('stat.expense_.name_zh')" :value="data.data.name_zh" />
      <d-text :label="$t('common.company')" :value="company" />
      <d-text
        :label="$t('common.type')"
        :value="data.data.module_type === 'reimbursement' ? $t('stat.expense_.reimbursement') : $t('stat.expense_.payment')"
      />
      <div class="py-2">
        <div class="font-16 text-dark-gray">{{ $t("stat.expense_.transport") }}</div>
        <div class="right">
          <v-ons-switch input-id="switch1" v-model="data.data.is_transportation" disabled="true"> </v-ons-switch>
        </div>
      </div>
    </div>
  </v-ons-card>
</template>

<script>
import buffer from "@/mixins/buffer";

export default {
  name: "ExpenseAccount",
  props: ["data"],
  mixins: [buffer],
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(n, o) {
        if (n != o) {
          this.fetchCompany(this.data.data.company_id);
        }
      }
    }
  }
};
</script>

<style scoped></style>
