export const app = {
  application_number: "申请编号",
  invoice_later: "付款后供应商开发票的情况",
  input_by_treasury: "出纳用记载项目",
  deal_slip: "回单",
  deal_slip_sublabel: "上传回单",
  payment_account: "付款帐号",
  select_payment_account: "选择付款帐号",
  payment_date: "付款日期",
  forward_finance: "向财务担当转送",
  select_finance: "选择转达对象",
  page_application: "使用纸张张数",
  enter_page_application: "输入张数",
  item: "项目",
  quantity: "数量",
  enter_quantity: "输入数量",
  forward_document: "转达单据",
  estimated_approval_date: "预计批准期限",
  send_notification_approver: "向下一个审批者发送通知电子邮件",
  withdraw: "取消  ",
  withdraw_alert: "需要取消已提交的申请吗？",
  stepback: "退回",
  stepback_alert: "需要退回吗？",
  is_drawdown: "Automatic drawdown",

  account: {
    name: "账户名义",
    number: "账户号码",
    bank: "银行名称、BIC CODE",
    branch: "支行名称、账户类型"
  },

  reimb: {
    reimbursement: "报销申请",
    reimbursement_detail: "报销明细",
    add_more_info: "追加报销时，请按追加键",
    e_invoice_info: "将报销的原始凭证贴在A4张（每张纸仅使用一面），整理好后请交给出纳担当。请在第首页纸的右上角写上本次申请编号{number}。",
    e_invoice: "电子发票（不含扫描件）",
    e_invoice_sublabel: "OFD方式或PDF方式。最多可上转10张文档",
    application_paper: "纸质发票扫描件和清款单，付款明细等",
    application_paper_sublabel: "最多可上转10张文档",
    //detail
    reimbursement_content: "报销内容",
    expense_detail: "会计科目",
    select_expense: "选择会计科目",
    destination: "目的地",
    enter_destination: "输入目的地",
    from: "出发地",
    enter_from: "输入出发地",
    to: "到达地",
    enter_to: "输入到达地",
    supplier_name: "供应商名",
    enter_supplier_name: "输入供应商名",
    tax: "我们无法获取发票的情况",
    tax2: "供应商不能提供发票的原因",
    enter_tax: "输入原因",
    with_supplier_account: "如果这次收款账户信息跟您的工资卡账户信息不一样，请填写如下",
    alert: {
      item: "请选择一件以上之付款",
      page: "请输入纸张张数",
      finance: "财务之选择",
      content: "请选择1件以上事业名"
    }
  },
  pay: {
    payment: "付款申请",
    payment_detail: "付款明细",
    add_more_info: "追加付款申请时，请点击以下追加键。",
    e_invoice_info: "将报销的原始凭证贴在A4张（每张纸仅使用一面），整理好后请交给出纳担当。请在第首页纸的右上角写上本次申请编号{number}。",
    e_invoice: "电子发票（不含扫描件）",
    e_invoice_sublabel: "OFD方式或PDF方式。最多可上转10张文档",
    application_paper: "纸质发票扫描件和供应商的清款单，付款明细等",
    application_paper_sublabel: "最多可上转10张文档",
    supplier_name: "供应商名",
    enter_supplier_name: "输入供应商名",
    tax: "我们无法获取发票的情况",
    tax2: "供应商不能提供发票的原因",
    enter_tax: "输入原因",
    month: "月份",
    enter_month: "输入月份",
    estimated_payment_date: "希望付款日",
    recipient: "付款对象的公司或个人名称",
    enter_recipient: "输入名称",
    recipient_account: "付款对象账号",
    enter_recipient_account: "输入账号",
    payment_content: "费用明细",
    expense_detail: "会计科目",
    select_expense: "选择会计科目",
    alert: {
      item: "请选择一件以上之付款",
      finance: "财务之选择",
      month: "月份之输入",
      date: "请选择付款预测日",
      page: "请输入纸张张数",
      recipient: "付款收方之名称",
      account_no: "请输入帐号",
      bank: "请输入银行名",
      branch: "请输入分行名",
      content: "请选择1件以上事业名"
    }
  },
  purc: {
    purchase: "购买申请",
    goods_detail: "物品明细",
    add_more_info: "追加付款申请时，请点击以下追加键。",
    total_estimated: "支付总额",
    delivery_date: "交货日期及时间",
    delivery_place: "交货对象（地点）",
    enter_delivery_place: "输入交货对象（地点）",
    goods_name: "物品名称",
    enter_goods_name: "输入物品名称",
    recurrent: "下次作为已审批物品登记",
    type_brand: "规格、品牌",
    enter_type_brand: "输入规格、品牌",
    budget_tax: "预算金额（含税）",
    enter_budget: "输入金额",
    estimated_unit_price: "估计单价",
    upload_photo: "上传参考图片",
    upload_photo_desc: "最多可上传参考图片10件",
    source: "购买处或参考链接",
    enter_source: "输入购买处或参考链接",
    arrange_by: "准备者",
    by_me: "自已购入后报销",
    by_ga: "由总务购入后报销",
    alert: {
      item: "请选择1件以上之採购",
      delivery_date: "请选择送货日",
      delivery_place: "请选择送货地点",
      goods: "请输入物品名称",
      type: "请输入规格、品牌",
      quantity: "请输入数量",
      budget: "请输入预算金额（含税）",
      arrange: "请选择准备者"
    }
  },
  cc: {
    contract_party: "本公司合同主体",
    select_party: "选择合同主体",
    contract_type: "合同之种类",
    select_type: "从商品贩卖、服务销售、其它销售中选择",
    contract_period: "合同到期日",
    contract_fullname: "客户名正式名",
    enter_fullname: "输入客户名",
    items: "项目",
    add_more_info: "追加项目时，请点击以下的追加键",
    plan: "合同书案",
    plan_info: "合同书案之上传",
    stamp: "盖章编号",
    signed: "已盖章合同",
    signed_info: "已盖章合同上传",
    item: {
      name: "名称",
      enter_name: "名称之输入",
      brand: "规格、品牌",
      enter_brand: "规格、品牌之输入",
      qty: "数量",
      freq: "贩卖次数",
      enter_freq: "次数之输入",
      content: "事业名",
      enter_content: "名称之输入",
      type1: "大项目",
      enter_type1: "大项目之输入",
      type2: "小项目",
      enter_type2: "小项目之输入",
      unit: "单价",
      enter_unit: "单价之输入",
      term: "预先支付比率",
      enter_term: "比率之输入",
      amount_tax: "单价（含税）",
      enter_amount_tax: "单价之输入",
      ratio: "预付比例",
      enter_ratio: "输入比例"
    },
    alert: {
      party: "请选择合同主体",
      type: "请选择合同种类",
      fullname: "请输入合同名称",
      plan: "请上传合同书案",
      items: "请输入至少一个项目",
      item: {
        name: "请输入物品名称",
        brand: "请输入规格、品牌",
        qty: "请输入数量",
        amount: "请输入金额",
        unit: "请输入单价",
        content: "请输入事业名",
        term: "请输入预先支付比率",
        amount_tax: "请输入金额（含税）",
        freq: "请输入购入次数"
      }
    }
  },
  sc: {
    our_contract_party: "本公司（合同主体）",
    select_contract_party: "选择公司",
    contract_type: "合约种类",
    expense_account: "费用项目",
    select_account: "选择费用项目",
    contract_fullname: "合约对象全称",
    employee_fullname: "人員姓名　",
    enter_contract_fullname: "全名之输入",
    add_more_info: "项目追加时，请按下列追加键。",
    upload_contract_plan: "上传合同书案",
    select_file: "档案之选择",
    after_approval_before_sign: "等待承认完了之盖章",
    stamp: "盖章号码",
    upload_signed_contract: "上传盖章完了合同书",
    supplier_fullname: "签订份数与盖章处",
    frequency: "付款頻率",
    select_frequency: "頻率之選擇",
    payment_deadline: "付款期限",
    enter_payment_deadline: "记载付款期限",
    supplier_account_detail: "付款对象信息",
    employee_account_detail: "银行账户信息",
    supplier_name: "收款名称",
    enter_name: "输入名称",
    number: "银行帐号",
    contract_period: "合同到期日",
    auto_renew: "是否自动更新合同",
    enter_account_number: "输入帐号",
    bank: "银行名称、BIO CODE",
    enter_bank: "输入银行名称、BIC CODE",
    branch: "分行",
    enter_branch: "分行名之输入",
    item: {
      name: "姓名",
      enter_name: "输入姓名",
      type_brand: "规格、品牌",
      enter_type_brand: "输入规格、品牌",
      quantity: "数量",
      enter_quantity: "选择数量",
      budget_tax: "预算金额（含税）",
      enter_budget: "输入金额",
      unit_price: "单价",
      enter_unit_price: "输入单价",
      times: "购入次数",
      enter_times: "输入次数",
      content: "事业名",
      enter_content: "名称之输入",
      type1: "大项目",
      enter_type1: "大项目之输入",
      type2: "小项目",
      enter_type2: "小项目之输入",
      terms: "预付尾款比率",
      enter_terms: "比率之输入",
      national_amount: "面额",
      national_amount_hr: "税・社保前金额",
      enter_national_amount: "金额之输入",
      start_date: "开始日"
    },
    alert: {
      type: "请选择合同种类",
      fullname: "请输入合同名全称",
      plan: "请上传合同书案",
      items: "请输入一项目以上",
      supplier: "请选择银行帐号名义",
      contract_period: "合同到期日之输入",
      item: {
        content: "请输入事业名",
        unit: "请输入单价",
        date: "请选择开始日",
        amount: "请输入金额",
        goods: "请输入物品名称",
        type: "请输入规格、品牌",
        quantity: "请输入数量",
        budget: "请输入金额（含税）",
        frequency: "请输入购入次数"
      }
    }
  },
  col: {
    select_one: "请从下列选项中选择",
    _debt: "债权回收",
    _report: "入金报告",
    related: "相关已审核合同",
    select_related: "选择相关已审批合同",
    customer: "客户名",
    enter_customer: "输入客户名",
    debt: {
      uncollected: "未入金金额",
      enter_uncollected: "输入金额",
      payment_date: "合同签订汇款日期",
      situation: "情况",
      enter_situation: "输入情况",
      estimated_date: "预计收入金"
    },
    report: {
      collected: "入金金额",
      enter_collected: "输入入金金额",
      reciept: "收款账号",
      select_reciept: "输入收款账号",
      reciept_date: "入金日期"
    },
    sales: {
      amount: "Sales (delivery) amount",
      amount_enter: "Enter Sales (delivery) amount",
      evidence_type: "Evidence type",
      evidence_type_select: "Select evidence type",
      delivery_date: "Sales (delivery) date",
      evidence: "Evidence",
      evidence_upload: "Please upload evidence and related waybill, Packing list, or bill of lading"
    },
    alert: {
      customer: "请输入客户名",
      uncollected: "请输入未汇款金额",
      payment_date: "请选择汇款日期",
      collected: "请输入汇款金额",
      reciept: "请输入汇款帐号",
      evidence: "Please select evidence type"
    }
  },
  stamp: {
    usage: "用途对象",
    select_usage: "选择用途对象",
    purpose: "盖章用途",
    enter_purpose: "用途之输入",
    select_purpose: "输入用途（可多用途）",
    fullname: "提交对象和目的",
    enter_fullname: "输入提交对象名称和目的",
    // no1: "盖章文件名全称、份数①",
    // no2: "盖章文件名全称、份数②",
    // no3: "盖章文件名全称、份数③",
    stamp_docs: "资料名称",
    stamp_docs_detail: "输入详情（名称、指定份数、签章处、公章类型、是否需要原件等）",
    add_more_stamp_docs_info: "追加项目时，请点击以下的追加键",
    enter_notes: "电子营业执照需要授权时，输入授权对象、授权期间、电话号码、身份证号码",
    enter_detail: "详情之输入",
    party: "我方合同主体",
    select_party: "合同主体之选择",
    type: "印鑑种类之指定",
    sign_on: "签名处之指定",
    plan: "预定盖章书面之上传",
    plan_info: "请上传预定捺定书面",
    stamp: "盖章编号",
    sign: "盖章完成页面",
    sign_info: "请上传盖章完成页面",
    alert: {
      usage: "请选择用途对象",
      purpose: "请选择盖章用途",
      fullname: "请输入盖章全称",
      party: "请选择合同主体",
      plan: "请上传需要盖章的页面"
    },
    agreement: {
      one: "在借用印章时，盖章完成后，拍下所有标有用途的照片以确认。",
      two: "过去借用印章的时候，没有出现没有报告用途的情况",
      three: "I confirm borrowing deed or stamp should return during the day, unless the government or the bank collects it."
    },
    purposes: {
      borrow_stamp: "借用执照・印章",
      borrow_id: "借用身份证・护照原件",
      use_electronic_id: "使用身份证・执照电子版",
      use_photocopy_id: "使用身份证・执照复印件",
      authorize_deed: "授权电子执照",
      signature: "签字",
      without_stamp: "不用签章的对外有效手续"
    }
  }
};
