<template>
  <v-ons-page>
    <custom-toolbar v-bind="toolbarInfo">
      <v-ons-toolbar-button slot="right" id="info-button" @click="push(page[1], $t('stat.export'), { module: filter['module.code'], modules: tabs })">
        <span class="mr-2 text-blue">{{ $t("stat.export") }}</span>
      </v-ons-toolbar-button>
    </custom-toolbar>

    <div class="absolute flex flex-col inset-0">
      <top-bar :tabs="tabs" @change-tab="handleChangeTab" />

      <main class="w-full overflow-y-scroll relative" :key="componentKey">
        <stamp-filter
          :showFilter="true"
          :module="module"
          placeholder="Search by stamp no"
          @on-filter="handleFilterSubmit"
          @on-search="handleSearch"
          @on-reset="handleResetFilter"
        />
        <div class="space-y-2 px-2 pt-20">
          <Cards v-for="(item, key) in list" :key="key" :item="item" @detail="handleDetail" />
          <!-- TODO: any perspective approvers can view -->
          <!-- :allowView="item.applicant_id === userId || item.approvers.indexOf(userId) != -1" -->
          <infinite-loading :distance="500" @infinite="loadData" :identifier="infiniteId" />
        </div>
      </main>
    </div>
    <ons-bottom-toolbar />
  </v-ons-page>
</template>

<script>
import Cards from "./component/Cards";
import { fetchList } from "@/api/stamp_statistic";
import TopBar from "@/components/TopBar.vue";
import { mapInfo } from "@/utils/application_mapper";
import ApplicationDataDetail from "@/views/Application/component/ApplicationDataDetail";
import Export from "./component/Export.vue";
import { mapInfo as mapAdditionalInfo } from "@/utils/stamp_statistic_mapper";
import StampFilter from "@/views/Application/StampStatistic/component/StampFilter";

const defaultFilter = { page: 1, itemsPerPage: 10, "sort[createdAt]": "desc" };
export default {
  name: "StampStatistic",
  components: {
    Cards,
    TopBar,
    StampFilter
  },
  data() {
    return {
      module: "contract-supplier",
      list: [],
      page: [ApplicationDataDetail, Export],
      componentKey: 0,
      filter: Object.assign({}, defaultFilter),
      infiniteId: +new Date()
    };
  },
  computed: {
    tabs() {
      return [
        {
          name: this.$t("home.menu.supplier_contract"),
          module: "contract-supplier"
        },
        {
          name: this.$t("home.menu.customer_contract"),
          module: "contract-customer"
        },
        {
          name: this.$t("home.menu.corporate_seal"),
          module: "corporate-seal"
        }
      ];
    },
    userId() {
      return this.$store.getters.profile.user_id;
    }
  },
  methods: {
    forceRerender() {
      this.infiniteId += 1;
    },
    loadData($state) {
      if (this.filter.page === 1) {
        this.listData = [];
      }

      this.filter["module.code"] = this.module;

      fetchList(this.filter).then((response) => {
        let data = response["hydra:member"];

        let responseData = data.map((item) => {
          let buffer = item.buffer;

          let approval = buffer.approvals.map((item) => ({
            code: item.master_process.process.code,
            at: item.approved_at
          }));
          let signer_approved_at = approval.find((item) => item.code === "p-signer");

          item.buffer = {
            buffer_id: buffer.id,
            code: buffer.code,
            status: buffer.status,
            title: this.$t("common." + buffer.action + "['" + buffer.module.code + "']"),
            color: buffer.action === "delete" ? "text-red-500" : "",
            applicant: buffer.requester,
            data: buffer.data,
            module: buffer.module.code,
            info: mapInfo(buffer.module.code, buffer.info, buffer.data),
            additionalInfo: mapAdditionalInfo(buffer.module.code, item, signer_approved_at)
          };

          return item;
        });

        this.list.push(...responseData);

        if (data.length < 10) {
          $state && $state.complete();
        } else {
          this.filter.page += 1;
          $state && $state.loaded();
        }
      });
    },
    handleChangeTab(index) {
      this.module = this.tabs[index].module;
      this.handleResetFilter();
    },
    handleResetFilter() {
      this.list = [];
      this.filter = Object.assign({}, defaultFilter);
      this.forceRerender();
    },
    handleDetail(item) {
      this.push(this.page[0], item.code, item);
    },
    handleSearch(filters) {
      this.handleBeforeSearch();
      this.filter["stamp_no"] = filters.term;
      this.forceRerender();
    },
    handleFilterSubmit(val) {
      this.handleBeforeSearch();
      this.handleResetFilter();

      this.filter = Object.assign(this.filter, val);
      this.forceRerender();
    },
    handleBeforeSearch() {
      this.list = [];
      this.filter.page = 1;
    },
    push(page, key, param) {
      if (key !== 0) {
        this.$store.commit("navigator/options", {
          animation: "lift-ios"
        });
      }
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style></style>
