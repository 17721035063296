import i18n from "@/locale";
import moment from "moment";
// import { Round } from "./number";

export function mapInfo(module, item, signer_approved_at) {
  let buffer = item.buffer;
  if (module === "corporate-seal") {
    let docs = null;
    if (buffer?.data?.stamped_doc_full_names) {
      docs = buffer.data.stamped_doc_full_names.map((item, key) => {
        return {
          key: i18n.tc("app.stamp.stamp_docs") + String.fromCharCode(9312 + key),
          value: item
        };
      });
    }

    // let purpose = null;
    // if (buffer?.data?.purpose) {
    //   let trans = buffer.data.purpose.split(",").map((item) => i18n.tc(item));
    //   purpose = {
    //     key: i18n.tc("app.stamp.purpose"),
    //     value: trans.join(", ")
    //   };
    // }
    let t = [
      { key: i18n.tc("Signer approved at"), value: signer_approved_at ? moment(signer_approved_at.at).format("YYYY/MM/DD HH:mm") : "-" },
      { key: i18n.tc("app.stamp.party"), value: item?.company?.name },
      { key: i18n.tc("app.stamp.fullname"), value: buffer?.data?.stamped_party_full_name },
      { key: i18n.tc("app.stamp.usage"), value: buffer.info.type_name.name[i18n.locale] }
    ];

    return t.concat(docs);
  }
  if (module === "contract-supplier") {
    let items = [];
    if (buffer?.data?.item_goods.length > 0 && item?.type == "Purchase Goods") {
      items = buffer?.data?.item_goods.map((item, key) => {
        return {
          key: i18n.tc("app.item") + ` (${key + 1})`,
          value: item.name
        };
      });
    }
    if (buffer?.data?.item_services.length > 0 && item?.type == "Services") {
      items = buffer?.data?.item_services.map((item, key) => {
        return {
          key: i18n.tc("app.item") + ` (${key + 1})`,
          value: item.content
        };
      });
    }
    if (buffer?.data?.item_hr.length > 0 && item?.type == "Human Resource (HR)") {
      items = buffer?.data?.item_hr.map((item, key) => {
        return {
          key: i18n.tc("app.item") + ` (${key + 1})`,
          value: item.name
        };
      });
    }
    if (buffer?.data?.item_other.length > 0 && item?.type == "Other") {
      items = buffer?.data?.item_other.map((item, key) => {
        return {
          key: i18n.tc("app.item") + ` (${key + 1})`,
          value: item.content
        };
      });
    }

    let t = [
      { key: i18n.tc("Signer approved at"), value: signer_approved_at ? moment(signer_approved_at.at).format("YYYY/MM/DD HH:mm") : "-" },
      { key: i18n.tc("app.sc.our_contract_party"), value: item?.company?.name },
      { key: i18n.tc("app.sc.contract_fullname"), value: buffer?.data?.contract_full_name },
      { key: i18n.tc("app.sc.contract_period"), value: item.contract_period_till ? moment(item.contract_period_till).format("YYYY/MM/DD") : "-" },
      {
        key: i18n.tc("app.sc.auto_renew"),
        value:
          buffer?.data?.auto_renewal !== undefined
            ? buffer?.data?.auto_renewal
              ? i18n.tc("common.yes")
              : i18n.tc("common.no")
            : i18n.tc("common.no")
      }
    ];

    return t.concat(items);
  }

  if (module === "contract-customer") {
    let items = [];
    if (buffer?.data?.item_services.length > 0 && item?.type == "Service revenue") {
      items = buffer?.data?.item_services.map((item, key) => {
        return {
          key: i18n.tc("app.item") + ` (${key + 1})`,
          value: item.content
        };
      });
    }
    if (buffer?.data?.item_other.length > 0 && item?.type == "Other revenue") {
      items = buffer?.data?.item_other.map((item, key) => {
        return {
          key: i18n.tc("app.item") + ` (${key + 1})`,
          value: item.content
        };
      });
    }
    if (buffer?.data?.item_goods.length > 0 && item?.type == "Sales of goods") {
      items = buffer?.data?.item_goods.map((item, key) => {
        return {
          key: i18n.tc("app.item") + ` (${key + 1})`,
          value: item.name
        };
      });
    }

    let t = [
      { key: i18n.tc("Signer approved at"), value: signer_approved_at ? moment(signer_approved_at.at).format("YYYY/MM/DD HH:mm") : "-" },
      { key: i18n.tc("app.cc.contract_party"), value: item?.company?.name },
      { key: i18n.tc("app.cc.contract_fullname"), value: buffer?.data?.contract_full_name },
      { key: i18n.tc("app.cc.contract_period"), value: buffer.data.contract_period ? moment(buffer.data.contract_period).format("YYYY/MM/DD") : "-" }
    ];

    return t.concat(items);
  }
}
