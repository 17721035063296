<template>
  <BottomSheet
    :title="data.title"
    :showing="data.show"
    @on-close="$emit('on-close')"
  >
    <div class="content p-4 text-sm">
      <itemGoods :data="data" v-if="data.code === 'goods'" />
      <itemServices :data="data" v-if="data.code === 'services'" />
      <itemOther :data="data" v-if="data.code === 'other'" />
    </div>
  </BottomSheet>
</template>

<script>
import BottomSheet from "@/components/BottomSheet";
import itemGoods from "@/views/Approval/component/customer_contract/itemGoods";
import itemServices from "@/views/Approval/component/customer_contract/itemServices";
import itemOther from "@/views/Approval/component/customer_contract/itemOther";

export default {
  name: "detail",
  components: { itemGoods, itemServices, itemOther, BottomSheet },
  props: {
    data: {
      type: Object
    }
  }
};
</script>

<style>
</style>