import request from "@/utils/request";

export function postWithdraw(data) {
  return request({
    url: "/buffer/withdrawal",
    method: "POST",
    data: data
  });
}
export function postStepback(data) {
  return request({
    url: "/buffer/step_back",
    method: "POST",
    data: data
  });
}
export function postToInitiated(data) {
  return request({
    url: "/buffer/back_to_initiated",
    method: "POST",
    data: data
  });
}
