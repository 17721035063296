<template>
  <div>
    <v-ons-list-item tappable v-for="(obj, key) in content" :key="key">
      <div class="center list-item__center">
        <span class="list-item__title">
          {{ obj.expense.name }}
          <span style="color: #157afb">
            {{ selectedCurrency ? selectedCurrency.code : null }}
            {{ Round(obj.amount, selectedCurrency ? selectedCurrency.code : null) }}
          </span>
        </span>
        <span v-show="obj.expense.is_transportation" class="list-item__subtitle" style="font-size: 14px; color: #ea9762">
          {{ obj.destination }}, {{ obj.date }}
        </span>
        <span class="list-item__subtitle">
          {{ obj.notes }}
        </span>
      </div>
      <div class="right">
        <v-ons-button modifier="quiet" @click="onContentDelete(key)">
          <Icon name="trash" stroke-width="1.5" classes="icon list-item__thumbnail" />
        </v-ons-button>
        <v-ons-button modifier="quiet" @click="onContentEdit(obj, key)">
          <Icon name="edit" classes="icon list-item__thumbnail" />
        </v-ons-button>
      </div>
    </v-ons-list-item>
  </div>
</template>

<script>
import { Round } from "../../../../utils/number";
export default {
  name: "ContentList",
  props: ["content", "selectedCurrency"],
  methods: {
    Round,
    onContentDelete(index) {
      this.$emit("delete", index);
    },
    onContentEdit(item, index) {
      this.$emit("edit", item, index);
    }
  }
};
</script>

<style></style>
