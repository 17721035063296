<template>
  <v-ons-card v-if="data">
    <div class="content p-2 space-y-2">
      <d-text :label="$t('common.app_code')" :value="data.code" />
      <d-text :label="$t('admin.company.company')" :value="data.info.name" />
      <d-text :label="$t('admin.company.location')" :value="locations" />
      <d-text :label="$t('admin.company.department')" :value="departments" />
    </div>
  </v-ons-card>
</template>

<script>
export default {
  name: "Company",
  props: ["data"],
  computed: {
    locations() {
      return this.data.data.locations
        .map((item) => {
          return item.address;
        })
        .join(",");
    },
    departments() {
      return this.data.data.departments
        .map((item) => {
          return item.name;
        })
        .join(",");
    }
  }
};
</script>

<style></style>
