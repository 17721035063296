<template>
  <div class="relative mr-1">
    <div style="">
      <div class="user-thumb" :title="user.name">{{ user.name.charAt(0) }}</div>
      <div class="text-gray-500 truncate" style="max-width: 50px;min-width: 50px;font-size: 11px;" :title="user.name">{{ user.name }}</div>
    </div>
    <div v-if="isCanRemove" class="cursor-pointer" @click="handleRemove">
      <div class="status bg-black rounded-full absolute right-1 top-0">
        <Icon name="close" size="16" stroke="0.5" classes="text-white p-0.5" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserCircle",
  props: ["user", "processId", "isCanRemove"],
  methods: {
    handleRemove() {
      this.$emit("remove", this.user, this.processId);
    }
  }
};
</script>

<style scoped>
.user-thumb {
  height: 32px;
  width: 32px;
  border-radius: 0.375rem;
  background-color: #3b82f6;
  color: white;
}
</style>
