<template>
  <v-ons-page>
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <search-filter @on-search="handleSearch" @on-reset="handleReset" />

    <v-ons-list class="mt-14" v-show="listCount > 0">
      <v-ons-list-item v-for="(obj, key) in list" :key="key" modifier="chevron" @click="push(page[0], obj.name, obj)">
        <div class="left">
          <div class="user-thumb">{{ obj.initial_name }}</div>
        </div>
        <div class="center">
          <span class="list-item__title">{{ obj.name }}</span>
          <span class="list-item__subtitle"> {{ obj.colleague_count }} </span>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <div class="no-result" v-show="listCount === 0">
      {{ $t("common.empty") }}
    </div>
    <infinite-loading @infinite="fetchList" :identifier="infiniteId" />
  </v-ons-page>
</template>

<script>
import { fetchCompanyData } from "@/api/company";
import SearchFilter from "@/components/SearchFilter";
import ExpenseAccountList from "@/views/Statistic/ExpenseAccount/ExpenseAccountList";

const defaultFilter = { page: 1, itemsPerPage: 10, name: "", byGroup: true };
export default {
  name: "ExpenseAccountIndex",
  components: { SearchFilter },
  data() {
    return {
      list: [],
      listCount: 0,
      page: [ExpenseAccountList],
      filter: Object.assign({}, defaultFilter),
      infiniteId: +new Date()
    };
  },
  methods: {
    forceRerender() {
      this.infiniteId += 1;
    },
    fetchList($state) {
      fetchCompanyData(this.filter).then((response) => {
        let data = response["hydra:member"];
        this.listCount = response["hydra:totalItems"];

        this.list.push(...data);

        if (data.length) {
          this.filter.page += 1;
          $state && $state.loaded();
        } else {
          $state && $state.complete();
        }
      });
    },
    handleSearch(filter) {
      this.list = [];
      this.filter.page = 1;
      this.filter.name = filter.term;
      this.forceRerender();
    },
    handleReset() {
      this.list = [];
      this.filter = Object.assign({}, defaultFilter);
      this.forceRerender();
    },
    push(page, key, param) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped></style>
