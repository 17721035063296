<template>
  <div class="space-y-2">
    <d-text :label="$t('app.cc.item.content')" :value="item.content ? item.content : '-'" />
    <hr />
    <d-text :label="$t('app.cc.item.type1')" :value="item.type1 ? item.type1 : '-'" />
    <d-text :label="$t('app.cc.item.type2')" :value="item.type2 ? item.type2 : '-'" />
    <hr />
    <d-text :label="$t('app.cc.item.unit')" :value="currency + ' ' + (item.unit_price ? item.unit_price : '-')" />
    <d-text :label="$t('app.cc.item.freq')" :value="item.frequency ? item.frequency : '-'" />
    <hr />
    <d-text :label="$t('app.cc.item.term')" :value="item.term ? item.term : '-'" />
    <d-text :label="$t('common.notes')" :value="item.note ? item.note : '-'" />
  </div>
</template>

<script>
export default {
  name: "itemServices",
  props: ["data"],
  computed: {
    currency() {
      return this.data.currency;
    },
    item() {
      return this.data.item;
    }
  }
};
</script>

<style></style>
