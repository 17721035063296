<template>
  <div>
    <form-select
      :class="{ bold: bold }"
      :required="required"
      :label="label"
      @click="disabled ? null : onCalendarClick(mode)"
      :placeholder="placeholder"
      :value="value"
    />
    <calendar :show.sync="show" :mode="mode" :year-name="yearName" :month-names="monthNames" :week-names="weekNames" @change="onChange" />
  </div>
</template>

<script>
export default {
  name: "form-calendar",
  props: {
    mode: {
      type: String,
      default: "single"
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    value: {
      type: String
    },
    bold: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      yearName: "",
      monthNames: [
        this.$t("calendar.months.jan"),
        this.$t("calendar.months.feb"),
        this.$t("calendar.months.mar"),
        this.$t("calendar.months.apr"),
        this.$t("calendar.months.may"),
        this.$t("calendar.months.jun"),
        this.$t("calendar.months.jul"),
        this.$t("calendar.months.aug"),
        this.$t("calendar.months.sep"),
        this.$t("calendar.months.oct"),
        this.$t("calendar.months.nov"),
        this.$t("calendar.months.dec")
      ],
      weekNames: [
        this.$t("calendar.weeks.mon"),
        this.$t("calendar.weeks.tue"),
        this.$t("calendar.weeks.wed"),
        this.$t("calendar.weeks.thu"),
        this.$t("calendar.weeks.fri"),
        this.$t("calendar.weeks.sat"),
        this.$t("calendar.weeks.sun")
      ]
    };
  },
  methods: {
    onCalendarClick(mode) {
      this.show = true;
      this.mode = mode;
    },
    onChange(date) {
      if (this.mode === "single") {
        this.$emit("on-change", date.format("YYYY-MM-DD"));
        this.show = false;
      }
      if (this.mode === "during") {
        let tgl = date.map((item) => item.format("YYYY-MM-DD"));
        if (tgl.length === 2) {
          this.$emit("on-change", tgl);
          this.show = false;
        }
      }
    }
  }
};
</script>

<style scoped>
.bold /deep/ .list-item__title {
  font-weight: 700 !important;
}
</style>
