<template>
  <div>
    <d-highlight
      v-if="estimatedPaymentDate"
      :label="$t('app.pay.estimated_payment_date')"
      :value="estimatedPaymentDate ? moment(estimatedPaymentDate).format('YYYY/MM/DD') : '-'"
    />
    <v-ons-card v-if="data">
      <div class="content p-2 space-y-2">
        <d-text :label="$t('common.app_code')" :value="data.code" />
        <d-text :label="$t('common.company')" :value="company ? company : '-'" />
        <d-text :label="$t('common.department')" :value="department ? department : '-'" />
        <d-text :label="$t('common.related_approval')" :value="relatedApproval ? relatedApproval.stamp_no : '-'" />

        <d-items
          :data="obj.transaction_items"
          :label="$t('app.pay.payment')"
          :history="'transaction_items' in history ? history.transaction_items : null"
          @tap="handleTapDetail"
        />

        <!-- <d-text :label="$t('common.total')" :value="obj.total + ' ' + currency" :history="'total' in history ? history.total : null" /> -->
        <d-total :label="$t('common.total')" :currency="currency" :amount="obj.total" :history="'total' in history ? history.total : null" />
        <hr />
        <form-uploader
          classes="p-0"
          :label="$t('app.pay.e_invoice')"
          :existing="invoices"
          :disabled="true"
          :history="'e_invoices' in history ? history.e_invoices : null"
        />

        <hr />
        <form-uploader
          classes="p-0"
          :label="$t('app.pay.application_paper')"
          :existing="papers"
          :disabled="true"
          :history="'application_papers' in history ? history.application_papers : null"
        />

        <hr />
        <d-subtitle> {{ $t("app.invoice_later") }}</d-subtitle>
        <d-text
          :label="$t('common.amount')"
          :value="(obj.invoice_amount ? obj.invoice_amount : '-') + ' ' + currency"
          :history="'invoice_amount' in history ? history.invoice_amount : null"
        />
        <d-text
          :label="$t('app.pay.supplier_name')"
          :value="obj.invoice_note ? obj.invoice_note : ' - '"
          :history="'invoice_note' in history ? history.invoice_note : null"
        />
        <hr />
        <d-subtitle> {{ $t("app.input_by_treasury") }}</d-subtitle>
        <d-text :label="$t('app.payment_account')" :value="paymentAccount" :history="'account_id' in history ? history.account_id : null" />
        <d-text
          :label="$t('app.payment_date')"
          :value="obj.payment_date ? moment(obj.payment_date).format('YYYY/MM/DD') : '-'"
          :history="'payment_date' in history ? history.payment_date : null"
        />
        <form-uploader
          classes="p-0"
          :label="$t('app.deal_slip')"
          :existing="dealSlipList"
          :disabled="true"
          :history="'deal_slips' in history ? history.deal_slips : null"
        />
        <hr />
        <d-text :label="$t('app.pay.tax')" :value="obj.note ? obj.note : ' - '" :history="'note' in history ? history.note : null" />
        <hr />
        <d-list :label="$t('app.forward_finance')" :items="finances" itemKey="name" />
        <!-- <d-process v-for="(item, key) in finances" :key="key" :initial="item.initial_name" :name="item.name" :process="$t('app.forward_finance')" /> -->
        <weui-gallery :visible="previewShow" :src="previewImg" :canDelete="false" @onClick="previewShow = false"></weui-gallery>
      </div>
    </v-ons-card>
  </div>
</template>

<script>
import { fetchUserDetail as getUser } from "@/api/user";
import { fetchDetail as getFile } from "@/api/file";
import { fetchDetail as getPaymentAccount } from "@/api/bank_account";
import PaymentDetail from "@/views/Approval/component/payment/PaymentDetail";
import buffer from "@/mixins/buffer";
import invoice from "@/mixins/invoice";
import { fetchRelatedContractDetail as fetchContract } from "@/api/related_approval";
import history_detail from "@/mixins/history_detail";

export default {
  name: "Payment",
  props: ["data", "history"],
  mixins: [buffer, history_detail, invoice],
  computed: {
    obj() {
      return this.data?.data;
    },
    companyId() {
      return this.obj?.transaction_items ? this.obj.transaction_items[0].company_id : null;
    },
    departmentId() {
      return this.obj?.transaction_items ? this.obj.transaction_items[0].department_id : null;
    },
    relatedApprovalId() {
      return this.obj?.transaction_items ? this.obj.transaction_items[0].related_approval_id : null;
    },
    estimatedPaymentDate() {
      return this.obj?.transaction_items ? this.obj.transaction_items[0].payment_information?.estimated_payment_date : null;
    },
    bufferId() {
      return this.data ? (this.data.buffer_id ? this.data.buffer_id : this.data.id) : null;
    },
    isApproved() {
      return this.data ? this.data.status == 2 : false;
    },
    invoices() {
      let f = this.invoiceFile.e_invoices.map((item) => ({
        ...item.file,
        size: item.file.size_string,
        is_edited: !!item.user
      }));
      return [...this.eInvoiceList, ...f];
    },
    papers() {
      let f = this.invoiceFile.app_papers.map((item) => ({
        ...item.file,
        size: item.file.size_string,
        is_edited: !!item.user
      }));
      return [...this.applicationPaperList, ...f];
    }
  },
  data() {
    return {
      paymentAccount: "-",
      finances: [],
      eInvoiceList: [],
      applicationPaperList: [],
      dealSlipList: [],
      pages: [
        {
          component: PaymentDetail
        }
      ],
      relatedApproval: null
    };
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(n, o) {
        if (n != o) {
          this.fetchCurrency(this.obj.currency_id);

          if (this.obj.user_finances) {
            this.obj.user_finances.forEach((user) => {
              this.fetchUserDetail(user);
            });
          }

          if (this.obj.account_id) {
            this.fetchPaymentAccount(this.obj.account_id);
          }

          if (this.companyId) {
            this.fetchCompany(this.companyId);
          }
          if (this.departmentId) {
            this.fetchDepartment(this.departmentId);
          }

          if (this.relatedApprovalId) {
            this.fetchRelatedApproval(this.relatedApprovalId);
          }

          this.getEInvoiceFiles();
          this.getApplicationPapers();
          this.getDealSlips();
          //invoice
          if (this.bufferId && this.isApproved) {
            this.loadInvoiceFiles(this.bufferId);
          }
        }
      }
    }
  },
  methods: {
    fetchRelatedApproval(id) {
      fetchContract(id).then((response) => {
        this.relatedApproval = {
          stamp_no: response.stamp_no,
          type: response.contract_item_type.code,
          currency: response.currency.code
        };
      });
    },
    fetchPaymentAccount(id) {
      getPaymentAccount(id).then((response) => {
        this.paymentAccount = response.bank_name + " (" + response.branch + ") " + response.type;
      });
    },
    fetchUserDetail(id) {
      this.finances = [];
      getUser(id).then((response) => {
        this.finances.push(response);
      });
    },
    getEInvoiceFiles() {
      this.eInvoiceList = [];
      this.obj.e_invoices.forEach((item) => {
        getFile(item).then((f) => {
          this.eInvoiceList.push({ ...f, size: f.size_string, is_edited: this.isFileEdited(f.id, "e_invoices") });
        });
      });
    },
    getApplicationPapers() {
      this.applicationPaperList = [];
      this.obj.application_papers.forEach((item) => {
        getFile(item).then((f) => {
          this.applicationPaperList.push({ ...f, size: f.size_string, is_edited: this.isFileEdited(f.id, "application_papers") });
        });
      });
    },
    getDealSlips() {
      this.dealSlipList = [];
      this.obj.deal_slips.forEach((item) => {
        getFile(item).then((f) => {
          this.dealSlipList.push({ ...f, size: f.size_string, is_edited: this.isFileEdited(f.id, "deal_slips") });
        });
      });
    },
    handleTapDetail(item, key) {
      this.push(this.pages[0].component, item, this.$t("app.pay.payment") + " (" + (key + 1) + ")");
    },
    push(page, item, name) {
      this.$store.commit("navigator/options", {
        animation: "lift-ios"
      });

      let currency = this.currency;
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: name,
              data: {
                item: item,
                currency: currency
              }
            }
          };
        }
      });
    }
  }
};
</script>

<style></style>
