import request from "@/utils/request";

export function fetchDetail(id) {
  return request({
    url: "/buffers/" + id,
    method: "GET"
  });
}

export function fetchMyBuffers(params) {
  return request({
    url: "/my-buffers",
    method: "GET",
    params: params
  });
}

export function fetchForwarded(params) {
  return request({
    url: "/buffer/forwarded",
    method: "GET",
    params: params
  });
}

export function fetchNextCode(params) {
  return request({
    url: "/buffer/next_code",
    method: "GET",
    params: params
  });
}

//temporary data
export function postTempData(data) {
  return request({
    url: "/buffer_temporaries",
    method: "POST",
    data: data
  });
}

export function getTempData(params) {
  return request({
    url: "/buffer_temporaries",
    method: "GET",
    params: params
  });
}
