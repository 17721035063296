<template>
  <v-ons-page @show="handleShow" @init="handleInit()">
    <custom-toolbar v-bind="toolbarInfo">
      <v-ons-toolbar-button slot="right" id="info-button" v-if="allowToSubmitAgain" @click="actionSheetVisible = true">
        <span class="mr-2">{{ $t("common.app_detail_.more") }}</span>
      </v-ons-toolbar-button>
    </custom-toolbar>
    <div class="card flat pb-3 px-4">
      <div class="capitalize card-title">
        {{ param.title }}
      </div>
      <div class="text-sm text-dark-gray">{{ $t("common.requested_by") }} {{ param.applicant.name }}</div>
      <div class="text-xs mt-3 text-orange">
        <status-badge :status="param.status" />
      </div>
    </div>

    <component :is="moduleCode" :data="param" :history="history"></component>

    <loading-small v-show="loading" />
    <process v-show="!loading" :data="processData" :requester="param.applicant" />

    <applicant-action
      v-if="canWithdraw || canStepback"
      :canWithdraw="canWithdraw"
      :canStepback="canStepback"
      @withdraw="handleWithdraw"
      @stepback="handleStepback"
    />
    <v-ons-action-sheet :visible.sync="actionSheetVisible" cancelable :title="$t('common.app_detail_.more_opt')">
      <v-ons-action-sheet-button icon="md-square-o" @click="resubmit">{{ $t("common.app_detail_.submit_again") }}</v-ons-action-sheet-button>
      <v-ons-action-sheet-button icon="md-square-o" @click="actionSheetVisible = false">{{
        $t("common.app_detail_.cancel")
      }}</v-ons-action-sheet-button>
    </v-ons-action-sheet>
  </v-ons-page>
</template>

<script>
import StatusBadge from "@/components/StatusBadge";
import SupplierContract from "@/views/Approval/component/supplier_contract/SupplierContract";
import CollectionReport from "@/views/Approval/component/collection_report/index";
import CollectionDebt from "@/views/Approval/component/collection_debt/index";
import CollectionSales from "@/views/Approval/component/collection_sales/index";
import Company from "@/views/Approval/component/company/Company";
import User from "@/views/Approval/component/user/User";
import Process from "@/views/Approval/component/Process";
import Background from "@/views/Approval/component/background/Background";
import Purchase from "@/views/Approval/component/purchase/Purchase";
import CustomerContract from "@/views/Approval/component/customer_contract/index";
import Reimbursement from "@/views/Approval/component/reimbursement/index";
import CorporateSeal from "@/views/Approval/component/corporate_seal/index";
import Payment from "@/views/Approval/component/payment/Payment.vue";
import ApplicantAction from "@/views/Approval/ApplicantAction.vue";

import Application from "@/views/Application";

import { fetchApprovalByBuffer } from "@/api/approval";
import { postWithdraw, postStepback } from "@/api/applicant";
//buffer history
import { getHistory } from "@/api/edit";

export default {
  name: "ApplicationDataDetail",
  components: {
    "status-badge": StatusBadge,
    "contract-supplier": SupplierContract,
    "contract-customer": CustomerContract,
    "collection-report": CollectionReport,
    "collection-sales": CollectionSales,
    "collection-debt": CollectionDebt,
    "corporate-seal": CorporateSeal,
    company: Company,
    user: User,
    background: Background,
    purchase: Purchase,
    reimbursement: Reimbursement,
    payment: Payment,
    process: Process,
    "applicant-action": ApplicantAction
  },
  data() {
    return {
      loading: true,
      processData: [],
      actionSheetVisible: false,
      history: []
    };
  },
  computed: {
    param() {
      return this.toolbarInfo.param;
    },
    bufferId() {
      return this.param?.buffer_id;
    },
    moduleCode() {
      return this.toolbarInfo.param.module;
    },
    hideSubmit() {
      return this.toolbarInfo.hideSubmit ? this.toolbarInfo.hideSubmit : false;
    },
    allowToSubmitAgain() {
      return !this.hideSubmit && (this.moduleCode === "reimbursement" || this.moduleCode === "purchase" || this.moduleCode === "payment");
    },
    canWithdraw() {
      return this.param.status == 0;
      // if (!this.processData) {
      //   return false;
      // }
      // return !this.processData.filter((e) => e.status === 1).length > 0 && this.param?.status == 0;
    },
    canStepback() {
      return this.param.status == 0;
      // if (!this.processData) {
      //   return false;
      // }
      // return !this.canWithdraw && this.processData.filter((e) => e.status === 0).length > 0 && this.param?.status == 0;
    },
    pages() {
      return [
        {
          module: "reimbursement",
          title: this.$t("app.reimb.reimbursement")
        },
        {
          module: "payment",
          title: this.$t("home.menu.payment")
        },
        {
          module: "purchase",
          title: this.$t("home.menu.purchase")
        },
        {
          module: "collection-report",
          title: this.$t("home.menu.collection_report")
        },
        {
          module: "contract-supplier",
          title: this.$t("home.menu.supplier_contract")
        },
        {
          module: "contract-customer",
          title: this.$t("home.menu.customer_contract")
        },
        {
          module: "corporate-seal",
          title: this.$t("home.menu.corporate_seal")
        }
      ];
    }
  },
  methods: {
    handleInit() {
      this.loading = true;
      getHistory(this.param.buffer_id)
        .then((response) => {
          this.history = response.merged_info;
        })
        .catch(() => {
          this.history = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleShow() {
      this.loading = true;
      this.getProccess();
      this.loading = false;
    },
    resubmit() {
      let page = this.pages.filter((item) => item.module == this.moduleCode);
      if (page[0]) {
        let parameter = this.param;
        this.$store.commit("navigator/pop");
        this.$store.commit("navigator/pop");
        this.push(Application, page[0].title, parameter);
      }
    },
    async getProccess() {
      await fetchApprovalByBuffer(this.param.buffer_id).then((response) => {
        this.processData = response["hydra:member"];
      });
    },
    handleWithdraw() {
      this.$ons.notification.confirm(this.$t("app.withdraw_alert"), { title: this.$t("common.approve") }).then((response) => {
        if (response === 1) {
          postWithdraw({ buffer_id: this.bufferId }).then(() => {
            this.$store.commit("navigator/popSubmit");
          });
        }
      });
    },
    handleStepback() {
      this.$ons.notification.confirm(this.$t("app.stepback_alert"), { title: this.$t("common.approve") }).then((response) => {
        if (response === 1) {
          postStepback({ buffer_id: this.bufferId }).then(() => {
            this.$store.commit("navigator/pop");
          });
        }
      });
    },
    push(page, key, param) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped></style>
