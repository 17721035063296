<template>
  <v-ons-list-item>
    <span class="list-item__title" :class="{ required: required }">
      {{ label }}
    </span>
    <slot />
    <span class="list-item__subtitle">
      <input :class="'text-input w-full ' + classes" :type="kind" :placeholder="placeholder" :value="value" @input="handleInput" />
    </span>
  </v-ons-list-item>
</template>

<script>
export default {
  name: "form-input",
  props: {
    kind: {
      type: String,
      default: "number"
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    value: {
      type: [String, Number]
    },
    required: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: ""
    },
    max: {
      type: Number,
      default: null
    }
  },
  methods: {
    handleInput(val) {
      if (null !== this.max && val.target.value > this.max) {
        val.target.value = this.max;
        this.$forceUpdate();
        this.$emit("input", this.max);
      }
      this.$emit("input", val.target.value);
    }
  }
};
</script>

<style scoped></style>
