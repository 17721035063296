import request from "@/utils/request";

export function deleteFiles(file_id) {
  return request({
    url: `/files/${file_id}`,
    method: "delete"
  });
}

export function getCurrencies() {
  return request({
    url: `/currencies`,
    method: "get"
  });
}

export function getCurrency(id) {
  return request({
    url: `/currencies/${id}`,
    method: "get"
  });
}

export function getPaymentAccount() {
  return request({
    url: `/payment_accounts`,
    method: "get"
  });
}

export function submitReimbursementBuffer(data) {
  return request({
    url: "/buffer/reimbursement",
    method: "post",
    data: data
  });
}
