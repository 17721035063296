import request from "@/utils/request";

export function getTypes() {
  return request({
    url: "/corporate_seal_types",
    method: "get"
  });
}

export function getDetail(id) {
  return request({
    url: "/corporate_seal_types/" + id,
    method: "get"
  });
}

export function submit(data) {
  return request({
    url: "/buffer/corporate-seal",
    method: "post",
    data: data
  });
}
