export const admin = {
  menu: {
    my_organization: "本公司",
    external_companies: "外部客户",
    background: "后台（详细",
    create_company: "添加企业",
    create_user: "添加使用者",
    log_out: "退出"
  },
  background: {
    add_group: "添加群组",
    edit_group: "编辑群组",
    group_name: "群组名",
    enter_group_name: "输入群组名",
    company: "公司名",
    select_company: "选择公司",
    pic_help: "星霜担当登录",
    finance: "财务担当",
    select_finance: "选择财务担当",
    treasury: "出纳担当",
    select_treasury: "选择出纳担当",
    hr: "人事担当",
    select_hr: "选择人事担当",
    ga: "总务负责人",
    select_ga: "总务负责人之选择",
    select_pic: "负责人之选择"
  },
  company: {
    create_company: "添加公司•部门•地点信息",
    edit_company: "编辑公司",
    company: "公司",
    enter_company: "输入公司名",
    location: "地点",
    enter_location: "输入地点",
    department: "部门",
    enter_department: "选择部门"
  },
  user: {
    create_user: "添加使用者",
    edit_user: "编辑使用者",
    delete_user: "使用者之删除",
    company_info: "公司信息",
    company: "公司",
    select_company: "公司名之选择",
    department: "部门",
    select_department: "部门之选择",
    location: "地点",
    select_location: "地点之选择",
    join_date: "加入公司日期",
    select_join_date: "进入公司日期之选择",
    personal_info: "个人信息",
    name: "姓名",
    enter_name: "输入姓名",
    email: "电子邮箱地址",
    enter_email: "输入电子邮箱地址",
    company_auth: "公司责任者",
    company_finance: "Company Finance / Treasury",
    company_treasury: "Company Internal Auditor",
    company_legal: "Company Legal",
    role: "User role",
    setting: "设定"
  }
};
