<template>
  <BottomSheetFull
    :class="{ 'z-low': zlow }"
    :showing="showing"
    @close="handleClose"
    @ok="handleSearch"
    :button="$t('common.search')"
    :title="$t('common.filter')"
  >
    <ons-list>
      <form-select
        :label="$t('common.company')"
        @click="handleListSelect(page[0].component, page[0].label)"
        :placeholder="$t('common.select_company')"
        :value="data ? (data.companyName ? data.companyName : null) : null"
      />

      <form-select
        :label="$t('common.currency')"
        @click="showCurrency = true"
        :placeholder="$t('common.select_currency')"
        :value="data ? (data.currency ? data.currency : 'All') : 'All'"
      />

      <form-input
        :label="$t('common.amount') + (data && data.currency ? ' (' + data.currency + ')' : '')"
        :placeholder="$t('common.enter_amount')"
        v-model.number="data.inputAmount"
      >
        <div class="grid grid-cols-3 py-1.5 space-x-1 text-center w-full">
          <div
            v-for="(item, key) in options"
            :key="'amount_' + key"
            :class="{ 'border-blue-500 text-blue-500': item.value === selectedOption }"
            @click="selectedOption = item.value"
            class="bg-gray-50 border py-1 rounded"
          >
            {{ item.text }}
          </div>
        </div>
      </form-input>

      <form-input kind="text" :label="$t('common.vendor')" :placeholder="$t('common.enter_vendor')" v-model="data.vendor" />

      <v-ons-list-item>
        <span class="list-item__title required"> {{ $t("stat.export_.period") }} </span>
        <div class="grid grid-cols-3 py-1.5 space-x-1 text-center w-full">
          <div
            v-for="(item, key) in periods"
            :key="'period_' + key"
            @click="periodType = item"
            class="bg-gray-50 border py-1 rounded flex items-center justify-center"
            :class="{ 'border-blue-500 text-blue-500': null !== periodType && item.value === periodType.value }"
          >
            {{ item.text }}
          </div>
        </div>
        <form-calendar
          class="w-full"
          :bold="true"
          :placeholder="$t('stat.export_.select_period')"
          mode="during"
          @on-change="handlePeriodSelect"
          :disabled="!this.periodType"
          :value="period"
        />
      </v-ons-list-item>

      <!-- :label="$t('stat.export_.select_period')" -->
    </ons-list>

    <CurrencySelect :showing="showCurrency" @on-close="showCurrency = false" @on-select="handleCurrencySelect" :includeAll="true" />
  </BottomSheetFull>
</template>

<script>
import BottomSheetFull from "@/components/BottomSheetFull";
import CurrencySelect from "@/views/common/select/CurrencySelect";
import CompanySelect from "@/views/common/list/ContractPartyList";

import { required } from "vuelidate/lib/validators";

export default {
  name: "FilterForm",
  components: { BottomSheetFull, CurrencySelect },
  props: {
    showing: Boolean,
    defaultFilter: Object
  },
  data() {
    return {
      showCurrency: false,
      zlow: false,
      data: this.defaultFilter,
      selectedOption: ">=:",
      periodType: null,
      period: null
    };
  },
  validations: {
    periodType: {
      required
    },
    period: {
      required
    }
  },
  computed: {
    options() {
      return [
        {
          value: "=:",
          text: this.$t("common.equal_to")
        },
        {
          value: ">=:",
          text: this.$t("common.greater_than")
        },
        {
          value: "<=:",
          text: this.$t("common.less_than")
        }
      ];
    },
    periods() {
      return [
        {
          value: "createdAt",
          text: "Submission Date"
        },
        {
          value: "estPaymentDate",
          text: "Estimated Payment Date"
        },
        {
          value: "completionDate",
          text: "Completion Date"
        }
      ];
    },
    page() {
      return [{ component: CompanySelect, label: this.$t("common.select_company") }];
    }
  },
  watch: {
    "$store.getters.param.selectedContractParty": function(n) {
      if (n) {
        this.zlow = false;
        this.data.companyName = n.name ? n.name : null;
      }
    },
    "data.inputAmount": function(n) {
      if (n) {
        this.formatAmount();
      }
    },
    selectedOption: function(n) {
      if (n) {
        this.formatAmount();
      }
    },
    periodType: function(n) {
      let excludes = this.periods.filter((item) => item.value !== n.value);
      excludes.forEach((type) => {
        this.data[type.value] = null;
      });

      this.data[n.value] = this.period;
    },
    period: function(n) {
      if (!this.periodType) {
        return;
      }

      this.data[this.periodType.value] = n;
    }
  },
  mounted() {
    this.formatAmount();
  },
  methods: {
    formatAmount() {
      this.data.amount = this.selectedOption + this.data.inputAmount;
      this.options.forEach((item) => {
        if (item.value === this.selectedOption) {
          this.data.condition = item.text;
        }
      });
    },

    handleClose() {
      this.$emit("close");
    },
    handleSearch() {
      this.isFormValid().then((valid) => {
        if (valid) {
          this.$emit("search", this.data);
        }
      });
    },
    handleCurrencySelect(value) {
      this.showCurrency = false;
      this.data.currency = value.code !== "All" ? value.code : null;
    },
    handlePeriodSelect(value) {
      this.period = value.join("to");
    },
    handleListSelect(component, label) {
      this.push(component, label);
      this.zlow = true;
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.periodType.$invalid) {
        await this.$ons.notification.alert(this.$t("Please select the period type"), options);
        return false;
      }
      if (this.$v.period.$invalid) {
        await this.$ons.notification.alert(this.$t("Please select the period"), options);
        return false;
      }

      return true;
    },
    push(page, key) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped>
.list-item /deep/ .list-item__title {
  font-weight: 700 !important;
}
.z-low {
  z-index: 0 !important;
}
</style>
