<template>
  <v-ons-page @show="onPageShow" @init="onPageInit">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <br />
    <div class="mb-28">
      <v-ons-list>
        <v-ons-list-header>
          {{ $t("admin.user.company_info") }}
        </v-ons-list-header>
        <form-select
          :label="$t('admin.user.company')"
          :required="true"
          @click="push(page[0].component, page[0].label, { isHoshino: isHoshino })"
          :placeholder="$t('admin.user.select_company')"
          :value="selectedCompany ? selectedCompany.name : null"
        />

        <form-select
          v-show="selectedCompany"
          :label="$t('admin.user.department')"
          :required="true"
          @click="
            push(page[1].component, page[1].label, {
              companyId: selectedCompany.id
            })
          "
          :placeholder="$t('admin.user.select_department')"
          :value="selectedDepartment ? selectedDepartment.name : null"
        />

        <form-select
          v-show="selectedCompany"
          :label="$t('admin.user.location')"
          :required="true"
          @click="
            push(page[2].component, page[2].label, {
              companyId: selectedCompany.id
            })
          "
          :placeholder="$t('admin.user.select_location')"
          :value="selectedLocation ? selectedLocation.address : null"
        />

        <form-calendar
          :label="$t('admin.user.join_date')"
          :placeholder="$t('admin.user.select_join_date')"
          mode="single"
          :required="true"
          @on-change="handleJoinDate"
          :value="form.user.join_date"
        />
      </v-ons-list>
      <br />

      <v-ons-list>
        <v-ons-list-header>
          {{ $t("admin.user.personal_info") }}
        </v-ons-list-header>

        <form-input kind="text" :label="$t('admin.user.name')" :required="true" :placeholder="$t('admin.user.enter_name')" v-model="form.user.name" />

        <form-input
          kind="text"
          :label="$t('admin.user.email')"
          :required="true"
          :placeholder="$t('admin.user.select_join_date')"
          v-model="form.user.email"
        />
      </v-ons-list>
      <br />
      <v-ons-list>
        <v-ons-list-header>
          {{ $t("admin.user.role") }}
        </v-ons-list-header>
        <v-ons-list-item>
          <label class="center">
            {{ $t("admin.user.company_auth") }}
          </label>
          <div class="right">
            <v-ons-switch v-model="form.user.is_authorizer"> </v-ons-switch>
          </div>
        </v-ons-list-item>
        <v-ons-list-item>
          <label class="center">
            {{ $t("admin.user.company_finance") }}
          </label>
          <div class="right">
            <v-ons-switch v-model="form.user.is_finance"> </v-ons-switch>
          </div>
        </v-ons-list-item>
        <v-ons-list-item>
          <label class="center">
            {{ $t("admin.user.company_treasury") }}
          </label>
          <div class="right">
            <v-ons-switch v-model="form.user.is_treasury"> </v-ons-switch>
          </div>
        </v-ons-list-item>
        <v-ons-list-item>
          <label class="center">
            {{ $t("admin.user.company_legal") }}
          </label>
          <div class="right">
            <v-ons-switch v-model="form.user.is_legal"> </v-ons-switch>
          </div>
        </v-ons-list-item>
      </v-ons-list>
      <br />
      <v-ons-list>
        <v-ons-list-header>
          {{ $t("admin.user.setting") }}
        </v-ons-list-header>
        <v-ons-list-item v-for="setting in settings" :key="setting.id">
          <div class="center">
            <div class="list-item__title">
              <span>{{ setting.title }}</span>
            </div>
            <div class="list-item__subtitle">{{ setting.selectedItem ? setting.selectedItem.name : setting.desc }}</div>
          </div>
          <div class="right">
            <v-ons-button modifier="quiet" @click="setting.editable ? onSetttingClick(setting) : ''">
              <Icon
                v-if="!setting.selectedItem"
                name="plus-circle"
                size="42"
                stroke="0.5"
                :class="{ 'text-blue': setting.editable, 'text-gray-500': !setting.editable }"
              />
              <div v-else style="height: 42px; width: 42px;">
                <div class="user-thumb border border-blue-500" :class="{ 'border-dashed ': setting.editable }">
                  {{ setting.selectedItem.initial_name }}
                </div>
              </div>
            </v-ons-button>
            <ons-button
              v-if="setting.selectedItem && setting.editable"
              modifier="quiet"
              class="absolute right-1.5 top-2.5"
              @click="onSettingRemove(setting)"
            >
              <div class="status bg-black rounded-full">
                <Icon name="close" size="18" stroke="0.5" classes="text-white p-0.5" />
              </div>
            </ons-button>
          </div>
        </v-ons-list-item>
      </v-ons-list>
      <br />
      <process-card :module-code="moduleCode">
        <hr />
        <v-ons-list class="bg-transparent mb-5">
          <v-ons-list-item>
            <label class="center">{{ $t("common.remember_approvers") }}</label>
            <div class="right">
              <v-ons-switch v-model="form.save_approvers"> </v-ons-switch>
            </div>
          </v-ons-list-item>
        </v-ons-list>
      </process-card>

      <bottom-submit
        @submit="handleSubmit"
        :loading="submitting"
        :label="isDeleteAction ? $t('admin.user.delete_user') : null"
        :classes="isDeleteAction ? 'bg-red-500 active:bg-red-700' : null"
      >
        <template v-slot:additional>
          <div class="flex items-center p-2 space-x-4 w-full text-sm">
            <label class="left">
              <v-ons-checkbox :input-id="'checkbox-email'" v-model="form.send_notification_first_approval"> </v-ons-checkbox>
            </label>
            <label class="center" :for="'checkbox-email'"> {{ $t("app.send_notification_approver") }} </label>
          </div>
        </template>
      </bottom-submit>
    </div>
  </v-ons-page>
</template>

<script>
import { mapMutations } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { createUser, fetchUserDetail, updateUser, deleteUser } from "@/api/user";
import { fetchSettingType } from "@/api/setting_type";
import { fetchBackgroundPic as fetchPic } from "@/api/background";

import EmployeeList from "@/views/common/list/ApproverList";
import CompanyList from "@/views/common/list/CompanyList";
import DepartementList from "@/views/common/list/DepartmentList";
import LocationList from "@/views/common/list/LocationList";
import ProcessCard from "@/views/common/ProcessCard";

import Admin from "@/mixins/admin";

export default {
  name: "UserForm",
  components: { ProcessCard },
  mixins: [Admin],
  validations: {
    form: {
      user: {
        company_id: {
          required
        },
        department_id: {
          required
        },
        location_id: {
          required
        },
        name: {
          required
        },
        email: {
          required,
          email
        },
        join_date: {
          required
        }
      }
    }
  },
  computed: {
    isHoshino() {
      let roles = this.$store.getters.roles;
      return this.$store.getters.profile.is_allastarsea_group_member && roles.includes("ROLE_COMPANY_AUTHORIZER");
    },
    page() {
      return [
        {
          component: CompanyList,
          label: this.$t("admin.user.select_company")
        },
        {
          component: DepartementList,
          label: this.$t("admin.user.select_department")
        },
        {
          component: LocationList,
          label: this.$t("admin.user.select_location")
        },
        {
          component: EmployeeList,
          label: this.$t("common.select")
        }
      ];
    },
    isDeleteAction() {
      let param = this.toolbarInfo.param;
      if (!param) {
        return undefined;
      }

      return this.toolbarInfo.param.action && this.toolbarInfo.param.action === "DELETE";
    }
  },
  data() {
    return {
      submitting: false,
      moduleCode: "user",
      selectedCompany: null,
      selectedDepartment: null,
      selectedLocation: null,
      form: {
        user: {
          company_id: null,
          department_id: null,
          location_id: null,
          is_authorizer: false,
          is_finance: false,
          is_treasury: false,
          is_legal: false,
          name: null,
          email: null,
          join_date: null,
          user_settings: []
        },
        save_approvers: true,
        send_notification_first_approval: true,
        approvals: []
      },
      settings: [],
      processes: []
    };
  },
  methods: {
    ...mapMutations("navigator", ["clearParam", "setParam", "pop"]),
    async onPageInit() {
      let profile = this.$store.getters.profile;

      if (this.toolbarInfo.param !== undefined) {
        this.loadUserDetail(this.toolbarInfo.param.userId);
      }

      if (!this.isHoshino) {
        this.form.user.company_id = profile.company.id;
        this.selectedCompany = profile.company;
      }

      fetchSettingType().then((response) => {
        this.settings = response["hydra:member"].map((item) => ({
          id: item.id,
          title: item.name,
          desc: this.$t("common.select"),
          selectedItem: null,
          editable: false
        }));
      });

      if (this.selectedCompany) {
        this.loadPic(this.selectedCompany.id);
      }
    },
    onPageShow() {
      let param = this.$store.getters.param;
      let selectedCompany = param?.selectedCompany;
      let selectedDepartment = param?.selectedDepartment;
      let selectedLocation = param?.selectedLocation;

      let setting = param?.setting;
      if (selectedCompany) {
        this.form.user.company_id = selectedCompany.id;
        this.selectedCompany = selectedCompany;
        this.loadPic(selectedCompany.id);
        this.clearSelection();
      }
      if (selectedDepartment) {
        this.form.user.department_id = selectedDepartment.id;
        this.selectedDepartment = selectedDepartment;
      }
      if (selectedLocation) {
        this.form.user.location_id = selectedLocation.id;
        this.selectedLocation = selectedLocation;
      }

      if (setting) {
        this.settings.forEach((item) => {
          if (item.id === setting.key) {
            item.selectedItem = setting.value;
          }
        });
        this.loadSetting();
      }
      this.clearParam();
    },
    loadSetting() {
      let availableSetting = this.settings.filter((setting) => setting.selectedItem);
      this.form.user.user_settings = availableSetting.map((item) => ({
        setting_type_id: item.id,
        approver_id: item.selectedItem?.id
      }));
    },
    loadUserDetail(userId) {
      fetchUserDetail(userId).then((response) => {
        if (response.company) {
          this.selectedCompany = response.company;
          this.form.user.company_id = response.company.id;
        }
        if (response.department) {
          this.selectedDepartment = response.department;
          this.form.user.department_id = response.department.id;
        }

        if (response.location) {
          this.selectedLocation = response.location;
          this.form.user.location_id = response.location.id;
        }

        this.form.user.join_date = this.$moment(response.join_date).format("YYYY-MM-DD");
        this.form.user.name = response.name;
        this.form.user.email = response.email;
        this.form.user.is_authorizer = response.is_authorizer;
        this.form.user.is_finance = response.is_finance;
        this.form.user.is_treasury = response.is_treasury;
        this.form.user.is_legal = response.is_legal;

        if (response.user_settings.length > 0) {
          this.loadPic(response.company.id);
          if (response.user_settings) {
            this.form.user.user_settings = response.user_settings.map((item) => ({
              approver_id: item.approver_id,
              setting_type_id: item.setting_type.id
            }));

            //assign settings
            this.form.user.user_settings.forEach((item) => {
              fetchUserDetail(item.approver_id).then((response) => {
                this.settings.find((obj) => obj.id === item.setting_type_id).selectedItem = response;
              });
            });
          }
        } else {
          this.loadPic(response.company.id);
        }
      });
    },
    async loadPic(companyId) {
      await fetchPic(companyId).then((response) => {
        this.settings = this.settings.map((item) => {
          item.editable = true;
          return item;
        });

        let res = response["hydra:member"][0].background_pics;
        if (res === undefined) {
          return;
        }

        let data = res.map((item) => ({
          setting_type_id: item.setting_type.id,
          approver: item.pic
        }));

        data.forEach((item) => {
          let a = this.settings.find((obj) => obj.id === item.setting_type_id);
          if (a) {
            a.editable = false;
            a.selectedItem = item.approver;
          }
        });

        this.loadSetting();
      });
    },
    clearSelection() {
      this.form.user.department_id = null;
      this.selectedDepartment = null;
      this.form.user.location_id = null;
      this.selectedLocation = null;
    },
    onApproverClick(obj) {
      this.push(this.page[3].component, this.page[3].label, {
        processId: obj.id
      });
    },
    onSetttingClick(setting) {
      this.push(this.page[3].component, this.page[3].label, {
        settingId: setting.id
      });
    },
    onSettingRemove(setting) {
      this.settings.forEach((item) => {
        if (item.id === setting.id) {
          item.selectedItem = null;
        }
      });

      this.form.user.user_settings = this.form.user.user_settings.filter((item) => item.setting_type_id !== setting.id);
    },
    handleSubmit() {
      this.isFormValid().then((valid) => {
        if (valid) {
          this.submitting = true;
          if (this.isDeleteAction) {
            this.handleDeleteUser();
          } else {
            if (this.toolbarInfo.param !== undefined) {
              this.handleUpdateUser();
            } else {
              this.handleCreateUser();
            }
          }
          this.submitting = false;
        }
      });
    },
    async handleDeleteUser() {
      await deleteUser(this.toolbarInfo.param.userId, this.form).then((res) => {
        this.$ons.notification.alert({
          title: res.code ? res.code : this.$t("common.notif.saved"),
          messageHTML: ` ${this.$t("common.notif.submit")}`
        });
        this.pop();
      });
    },
    async handleCreateUser() {
      await createUser(this.form).then((res) => {
        this.$ons.notification.alert({
          title: res.code ? res.code : this.$t("common.notif.saved"),
          messageHTML: ` ${this.$t("common.notif.submit")}`
        });
        this.pop();
      });
    },
    async handleUpdateUser() {
      await updateUser(this.form, this.toolbarInfo.param.userId).then((res) => {
        this.$ons.notification.alert({
          title: res.code ? res.code : this.$t("common.notif.saved"),
          messageHTML: ` ${this.$t("common.notif.submit")}`
        });
        this.pop();
      });
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.user.company_id.$invalid) {
        await this.$ons.notification.alert(this.$t("common.alert.company"), options);
        return false;
      }
      if (this.$v.form.user.department_id.$invalid) {
        await this.$ons.notification.alert(this.$t("common.alert.department"), options);
        return false;
      }
      if (this.$v.form.user.location_id.$invalid) {
        await this.$ons.notification.alert(this.$t("admin.user.select_location"), options);
        return false;
      }
      if (this.$v.form.user.join_date.$invalid) {
        await this.$ons.notification.alert(this.$t("admin.user.select_join_date"), options);
        return false;
      }
      if (this.$v.form.user.name.$invalid) {
        await this.$ons.notification.alert(this.$t("admin.user.enter_name"), options);
        return false;
      }
      if (!this.$v.form.user.email.email) {
        await this.$ons.notification.alert(this.$t("admin.user.enter_email"), options);
        return false;
      }
      if (this.$v.form.user.email.$invalid) {
        await this.$ons.notification.alert(this.$t("admin.user.enter_email"), options);
        return false;
      }
      if (!this.approverIsValid) {
        await this.$ons.notification.alert(this.$t("common.alert.approver"), options);
        return false;
      }

      return true;
    },
    handleJoinDate(val) {
      this.form.user.join_date = val;
    },
    push(page, key, param) {
      this.$store.commit("navigator/options", {
        animation: "lift-ios"
      });
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped></style>
