import request from "@/utils/request";

export function login(username, password) {
  return request({
    url: "/login_check",
    method: "post",
    data: {
      username,
      password
    }
  });
}

export function getInfo() {
  return request({
    url: "/profile",
    method: "get"
  });
}
