<template>
  <v-ons-list class="w-full">
    <v-ons-list-item class="pl-0">
      <div class="left">
        <div class="approval-icon bg-transparent" v-if="selectable" @click="$emit('select', file.id)">
          <Icon :name="selected ? 'check-circle-filled' : 'circle'" :classes="selected ? 'text-blue-500' : 'text-gray-300'" :size="32" />
        </div>
        <img :src="require('@/assets/icon/' + icon + '.png')" style="width:34px" alt="" />
      </div>
      <div class="center" @click="handleClick">
        <div class="flex items-center justify-between w-full">
          <div>
            <span class="list-item__title" :class="{ 'text-green-600': isEdited }">{{ filename }}</span>
          </div>
          <div v-if="history && isEdited && !selectable">
            <div class="p-1 rounded flex items-center " style="font-size:10px">
              <Icon name="edit" classes="text-gray-500 mr-1" :size="12" />
              <span class="mr-1">{{ history.user ? history.user.name : "" }}</span>
              <span class="italic">{{ history.date ? moment(history.date).format("YYYY/MM/DD") : "" }}</span>
            </div>
          </div>
        </div>
        <div class="list-item__subtitle">
          <span>{{ file.size ? file.size : "" }}</span>
          <span class="text-xs" v-if="file.created_at">
            - {{ $t("common.uploaded_at") }} {{ moment(file.created_at).format("YYYY/MM/DD HH:mm") }}
          </span>
        </div>
      </div>
      <div class="right" v-if="show_action">
        <v-ons-button v-if="!disabled" modifier="quiet" @click="handleAction">
          <div class="status bg-black rounded-full">
            <Icon name="close" size="18" stroke="0.5" classes="text-white p-0.5" />
          </div>
        </v-ons-button>
      </div>
    </v-ons-list-item>
  </v-ons-list>
</template>

<script>
import { limitText } from "@/utils/helper";

export default {
  name: "listFile",
  props: {
    show_action: {
      type: Boolean,
      default: true
    },
    file: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isEdited: {
      type: Boolean,
      default: false
    },
    history: {
      type: [Object, null],
      default: null
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon() {
      let type = this.file.type;
      if (type.includes("image")) {
        return "jpg";
      }
      if (type.includes("pdf")) {
        return "pdf";
      }
      if (type.includes("spreadsheet")) {
        return "xls";
      }
      if (type.includes("ofd") || type.includes("zip")) {
        return "ofd";
      }

      return "doc";
    },
    filename() {
      let name = this.file.formated_name ? this.file.formated_name : this.file.name ? this.file.name : null;
      if (name) {
        return limitText(this.file.name, 50);
      } else {
        return "";
      }
    }
  },
  methods: {
    handleClick() {
      // this.$emit("on-click", this.icon === "jpg", this.file.url);
      this.$emit("on-click", this.file.url);
    },
    handleAction() {
      this.$emit("on-action");
    }
  }
};
</script>

<style scoped>
.list-item__left {
  padding: 0px 6px 0px 0 !important;
}
.list-item__center {
  padding: 0px 6px 0px 0 !important;
}
.list-item__right {
  padding: 0px 6px 0px 0 !important;
}
</style>
