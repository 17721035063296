const navigator = {
  strict: true,
  namespaced: true,
  state: {
    stack: [],
    options: {},
    param: {},
    isSubmit: false,
    isPop: false
  },
  getters: {
    getParam(state) {
      return state.param;
    }
  },
  mutations: {
    setParam(state, param) {
      state.param = param;
    },
    clearParam(state) {
      state.param = {};
    },
    push(state, page) {
      state.isPop = false;
      state.stack.push(page);
    },
    pop(state) {
      if (state.stack.length > 1) {
        state.stack.pop();
      }
      state.isPop = true;
    },
    popSubmit(state) {
      if (state.stack.length > 1) {
        state.stack.pop();
        state.isSubmit = true;
      }
    },
    resetSubmit(state) {
      state.isSubmit = false;
    },
    replace(state, page) {
      state.stack.pop();
      state.stack.push(page);
    },
    reset(state, page) {
      state.stack = [page || state.stack[0]];
    },
    resetOptions(state) {
      state.options = {
        animation: "default"
      };
    },
    options(state, newOptions = {}) {
      state.options = newOptions;
    }
  }
};

export default navigator;
