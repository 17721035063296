<template>
  <ons-bottom-toolbar class="z-10" style="height:59px !important">
    <div class="bg-gray-100 shadow w-full bottom-0 fixed  border-t border-gray-100 p-2 ">
      <div class="flex justify-center">
        <!-- <v-ons-button
          v-show="canStepback"
          class="btn-primary outline text-white mr-2 button rounded-md"
          style="padding: 4px 24px"
          @click="$emit('stepback')"
        >
          <div class="flex items-center">
            <Icon name="reply" classes="mr-2" />
            <div>{{ $t("app.stepback") }}</div>
          </div>
        </v-ons-button> -->
        <v-ons-button v-show="canWithdraw" class="btn-primary text-white mr-2 button rounded-md" style="padding: 4px 24px" @click="$emit('withdraw')">
          <div>{{ $t("app.withdraw") }}</div>
        </v-ons-button>
      </div>
    </div>
  </ons-bottom-toolbar>
</template>
<script>
export default {
  name: "ApplicantAction",
  props: {
    canWithdraw: {
      Type: Boolean
    },
    canStepback: {
      Type: Boolean
    }
  }
};
</script>
