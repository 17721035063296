import _ from "lodash";

import { difference } from "@/utils/helper";
import { FILE_TYPE } from "@/utils/constant";
import { postHistory } from "@/api/edit";

//detail comps
import ReiDForm from "@/views/Application/Reimbursement/ReimbursementDetailForm";
import PayDForm from "@/views/Application/Payment/PaymentDetailForm";
import PurDForm from "@/views/Application/Purchase/PurchaseDetailForm";
import SupDForm from "@/views/Application/SupplierContract/DetailForm";
import cusDForm from "@/views/Application/CustomerContract/customerDetailForm";

//number format
import { Round } from "@/utils/number";

export default {
  name: "ApplicationEditMixins",
  props: {
    param: {
      type: Object,
      require: true
    }
  },
  computed: {
    user() {
      let profile = this.$store.getters.profile;
      return { id: profile.user_id, name: profile.name };
    },
    detailPage() {
      return [
        {
          module: "reimbursement",
          component: ReiDForm,
          label: this.$t("app.reimb.reimbursement_detail")
        },
        {
          module: "payment",
          component: PayDForm,
          label: this.$t("app.pay.payment_detail")
        },
        {
          module: "purchase",
          component: PurDForm,
          label: this.$t("app.purc.goods_detail")
        },
        {
          module: "contract-supplier",
          component: SupDForm,
          label: null
        },
        {
          module: "contract-customer",
          component: cusDForm,
          label: null
        }
      ];
    }
  },
  watch: {
    formObj: {
      deep: true,
      handler(n) {
        //set form see index
        //set diff
        if (!_.isEmpty(this.defaultForm)) {
          let diff = {};
          let obj = this.getDiff(n, this.defaultForm);
          let user = this.user;
          _.forEach(obj, function(value, key) {
            diff[key] = {
              user: user,
              date: new Date().toISOString(),
              value: value
            };
          });
          this.diff = diff;
        }
        //set merged diff
        this.setDefaultMergeDiff();
        if (!_.isEmpty(this.mergedDiff)) {
          let merge = _.mergeWith(this.mergedDiff, this.diff, function(objValue, srcValue) {
            if (_.isArray(objValue)) {
              return objValue.concat(srcValue);
            }
          });
          this.mergedDiff = merge;
        } else {
          this.mergedDiff = this.diff;
        }
      }
    }
  },
  data() {
    return {
      defaultForm: {},
      form: {},
      formObj: {},
      diff: null,
      mergedDiff: null,
      FILE_TYPE: FILE_TYPE,
      submitting: false
    };
  },
  mounted() {
    this.setDefaultForm();
    this.setDefaultMergeDiff();
    this.setDefaultLocalData();
  },
  methods: {
    Round,
    setDefaultLocalData() {},
    setDefaultForm() {
      this.form = Object.assign({}, this.param.form);
      this.formObj = Object.assign({}, this.param.formObj);
      //moved to local
      // this.defaultForm = _.cloneDeep(this.formObj)
    },
    setDefaultMergeDiff() {
      this.mergedDiff = _.cloneDeep(this.param.history);
    },
    getDiff(object, base) {
      return difference(object, base);
    },
    handleDateSelect(objName, value) {
      this.formObj[objName] = value;
    },
    HandleDetailClick(item, params) {
      let param = {
        item: item,
        currency: this.formObj.currency_id
      };

      if (params) {
        param = Object.assign({ item }, params);
      }

      let page = this.detailPage.filter((item) => item.module === this.local.moduleCode);
      if (page && page[0]) {
        this.push(page[0].component, page[0].label, param);
      }
    },
    handleSubmit() {
      this.$ons.notification.confirm(this.$t("common.notif.decide"), { title: this.$t("common.notif.confirm") }).then((response) => {
        if (response === 1) {
          postHistory(this.param.id, { data: this.form, info: this.diff, merged_info: this.mergedDiff }).then(() => {
            this.$ons.notification.toast(this.$t("common.notif.submit"), {
              timeout: 2000,
              animation: "fall"
            });
            this.$store.commit("navigator/popSubmit");
          });
        }
      });
    },
    push(page, key, param) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
