<template>
  <div>
    <Loading v-show="loading" />
    <v-ons-list>
      <v-ons-list-header />
      <form-calendar
        :required="false"
        :label="$t('app.estimated_approval_date')"
        mode="single"
        @on-change="handleEstimatedApprovalDeadline"
        :placeholder="$t('common.select_date')"
        :value="form.corporate_seal.estimated_approval_date ? moment(form.corporate_seal.estimated_approval_date).format('YYYY-MM-DD') : null"
      />

      <form-select
        :label="$t('app.stamp.usage')"
        :placeholder="$t('app.stamp.select_usage')"
        :required="true"
        @click="sealTypeShow = true"
        :value="sealTypeObj ? sealTypeObj.name : null"
      />

      <form-multipleselect
        :label="$t('app.stamp.purpose')"
        :placeholder="$t('app.stamp.select_purpose')"
        :required="true"
        :items="purpose"
        @on-click="purposeShow = true"
        @on-remove="handlePurposeRemove($event)"
      />

      <form-input
        kind="text"
        :label="$t('app.stamp.fullname')"
        :placeholder="$t('app.stamp.enter_fullname')"
        :required="true"
        v-model="form.corporate_seal.stamped_party_full_name"
      />

      <form-select
        :label="$t('app.stamp.party')"
        :placeholder="$t('app.stamp.select_party')"
        :required="true"
        @click="push(page[0].component, page[0].label)"
        :value="contractPartyObj ? contractPartyObj.name : null"
      />
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-header />
      <form-input
        v-for="(item, key) in stamp_docs"
        kind="text"
        :key="'doc_' + key"
        :label="$t('app.stamp.stamp_docs') + String.fromCharCode(9312 + key)"
        :placeholder="$t('app.stamp.stamp_docs_detail')"
        :classes="'text-xs'"
        v-model="item.value"
      />

      <v-ons-list-item>
        <div class="center" style="font-size: 13px; color: #a8a8a8">
          {{ $t("app.stamp.add_more_stamp_docs_info") }}
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-item class="p-0">
        <div class="center flex justify-center items-center p-0">
          <v-ons-button modifier="quiet" style="justify-content: center" @click="addStampDoc">
            <ons-icon icon="md-plus"> </ons-icon>
            {{ $t("common.add_more") }}
          </v-ons-button>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-header />
      <form-input kind="text" :label="$t('app.stamp.type')" :placeholder="$t('app.stamp.enter_detail')" v-model="form.corporate_seal.stamp_type" />

      <form-input
        kind="text"
        :label="$t('app.stamp.sign_on')"
        :placeholder="$t('app.stamp.enter_detail')"
        v-model="form.corporate_seal.where_to_sign_on"
      />

      <form-textarea :label="$t('common.notes')" :placeholder="$t('app.stamp.enter_notes')" v-model="form.corporate_seal.notes" />
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-header />
      <form-uploader
        :required="true"
        :label="$t('app.stamp.plan')"
        :sublabel="$t('app.stamp.plan_info')"
        :multiple="true"
        :documentType="FILE_TYPE.CORPORATE_SEAL"
        :existing="this.files[FILE_TYPE.CORPORATE_SEAL]"
        @on-success="onUpload($event, FILE_TYPE.CORPORATE_SEAL)"
      />
    </v-ons-list>

    <v-ons-list class="mb-5">
      <v-ons-list-header />
      <v-ons-list-item modifier="nodivider">
        <v-ons-col width="30%">
          <label>{{ $t("app.stamp.stamp") }}</label>
        </v-ons-col>
        <v-ons-col style="margin-left: 10px"> {{ nextStampNo }} </v-ons-col>
      </v-ons-list-item>
      <!-- <form-uploader
        :label="$t('app.stamp.sign')"
        :sublabel="$t('app.stamp.sign_info')"
        :multiple="true"
        :documentType="FILE_TYPE.CORPORATE_SEAL_SIGNED"
        :existing="this.files[FILE_TYPE.CORPORATE_SEAL_SIGNED]"
        @on-success="onUpload($event, FILE_TYPE.CORPORATE_SEAL_SIGNED)"
      /> -->
    </v-ons-list>

    <br />
    <v-ons-list>
      <form-select-user
        :label="$t('app.forward_document')"
        :users="component.finance ? component.finance : []"
        @remove="handleRemoveFinance"
        @add="onClickComponent(COMPONENT.FINANCE, { default: component.finance })"
      />
    </v-ons-list>

    <process-card :required="false" :module-code="moduleCode">
      <hr />
      <v-ons-list class="bg-transparent mb-5">
        <v-ons-list-item>
          <label class="center">{{ $t("common.remember_approvers") }}</label>
          <div class="right">
            <v-ons-switch v-model="form.save_approvers"> </v-ons-switch>
          </div>
        </v-ons-list-item>
      </v-ons-list>
      <!-- agreement -->
      <div v-if="isIncludeBorrowStamp">
        <hr style="margin-top:0 !important" />
        <v-ons-list class="bg-transparent mb-5">
          <v-ons-list-item v-for="(agree, $index) in agreements" :key="$index" tappable class="text-sm">
            <label class="left">
              <v-ons-checkbox :input-id="'checkbox-' + $index" v-model="agree.value"> </v-ons-checkbox>
            </label>
            <label class="center" :for="'checkbox-' + $index">
              {{ agree.text }}
            </label>
          </v-ons-list-item>
        </v-ons-list>
      </div>
    </process-card>

    <bottom-submit @submit="handleSubmit" :loading="submitting" :disabled="!isAbleToSubmit">
      <template v-slot:additional>
        <div class="flex items-center p-2 space-x-4 w-full text-sm">
          <label class="left">
            <v-ons-checkbox :input-id="'checkbox-email'" v-model="form.send_notification_first_approval"> </v-ons-checkbox>
          </label>
          <label class="center" :for="'checkbox-email'"> {{ $t("app.send_notification_approver") }} </label>
        </div>
      </template>
    </bottom-submit>

    <corporate-seal-type :showing="sealTypeShow" @on-close="sealTypeShow = false" @on-select="handleSealTypeSelect" />
    <purpose-select :showing="purposeShow" :selection="purpose" @on-close="purposeShow = false" @on-save="handlePurposeSelect($event)" />
  </div>
</template>

<script>
import ProcessCard from "@/views/common/ProcessCard";
import CorporateSealType from "@/views/common/select/CorporateSealType";
import ContractPartyList from "@/views/common/list/ContractPartyList";
import { submit } from "@/api/corporate_seal";
import { required } from "vuelidate/lib/validators";
import application from "@/mixins/application";
import PurposeSelect from "@/views/common/select/PurposeSelect";

const defaultForm = {
  type_id: null,
  purpose: "",
  stamped_party_full_name: "",
  stamped_doc_full_names: [],
  contract_party_id: null,
  stamp_type: "",
  where_to_sign_on: "",
  notes: "",
  document_plan_files: [],
  document_signed_files: [],
  estimated_approval_date: null,
  user_finances: []
};
export default {
  name: "CorporateSealForm",
  components: {
    CorporateSealType,
    ProcessCard,
    PurposeSelect
  },
  props: ["show", "init", "param"],
  mixins: [application],
  computed: {
    approver() {
      return this.$store.getters.approvers;
    },
    page() {
      return [
        {
          component: ContractPartyList,
          label: this.$t("app.stamp.party")
        }
      ];
    },
    isIncludeBorrowStamp() {
      return this.purpose.includes("app.stamp.purposes.borrow_stamp");
    },
    isAllAgreementAreChecked() {
      let checks = true;
      this.agreements.forEach((item) => {
        if (item.value === false) {
          checks = false;
          return;
        }
      });

      return checks;
    },
    isAbleToSubmit() {
      if (this.isIncludeBorrowStamp) {
        return this.isIncludeBorrowStamp && this.isAllAgreementAreChecked;
      } else {
        return true;
      }
    }
  },
  watch: {
    show: function(newVal) {
      if (newVal) {
        this.onShow();
      }
    },
    init: function(newVal) {
      if (newVal) {
        this.onInit("corporate_seal");
      }
    },
    stamp_docs: {
      handler(n) {
        this.form.corporate_seal.stamped_doc_full_names = n.map((item) => item.value);
      },
      deep: true
    },
    contractPartyObj: {
      handler(n) {
        if (n) {
          this.form.corporate_seal.contract_party_id = n.id;
        }
      },
      deep: true
    },
    purpose: function(n) {
      if (n) {
        this.form.corporate_seal.purpose = n.toString();
      }
    },
    "component.finance": function(n) {
      if (n) {
        this.form.corporate_seal.user_finances = n.map((item) => item.id);
      }
    }
  },
  data() {
    return {
      moduleCode: "corporate-seal",
      tempMaster: ["form", "sealTypeObj", "contractPartyObj", "stamp_docs", "common", "files", "purpose", "component"],
      defaultTemp: ["common", "contractPartyObj"],
      stamp_docs: [{ value: "" }],
      loading: true,
      submitting: false,
      sealTypeShow: false,
      sealTypeObj: null,
      contractPartyObj: null,
      agreements: [
        {
          text: this.$t("app.stamp.agreement.one"),
          required: false,
          value: false
        },
        {
          text: this.$t("app.stamp.agreement.two"),
          required: false,
          value: false
        },
        {
          text: this.$t("app.stamp.agreement.three"),
          required: false,
          value: false
        }
      ],
      form: {
        send_notification_first_approval: true,
        corporate_seal: Object.assign({}, defaultForm),
        save_approvers: true,
        approvals: []
      },
      purposeShow: false,
      purpose: []
    };
  },
  validations: {
    form: {
      corporate_seal: {
        type_id: {
          required
        },
        purpose: {
          required
        },
        stamped_party_full_name: {
          required
        },
        contract_party_id: {
          required
        },
        document_plan_files: {
          required
        }
      }
    }
  },
  methods: {
    onShow() {
      let param = this.$store.getters.param;

      let selectedFinance = param.selectedFinance;

      if (selectedFinance) {
        this.component[this.COMPONENT.FINANCE] = selectedFinance;
        this.form.corporate_seal.user_finances = selectedFinance.map((item) => item.id);
      }

      if (param.selectedContractParty) {
        let obj = param.selectedContractParty;

        this.contractPartyObj = obj;
        this.form.corporate_seal.contract_party_id = obj.id;
      }

      this.$store.commit("navigator/clearParam");
      this.loading = false;
      this.$emit("reloaded");
    },
    loadData() {
      if (this.form.corporate_seal.user_finances) {
        this.form.corporate_seal.user_finances.forEach((item) => {
          this.loadApi(this.COMPONENT.FINANCE, item);
        });
      }
    },
    handlePurposeSelect(list) {
      this.purpose = list;
      this.purposeShow = false;
    },
    handlePurposeRemove(selected) {
      this.purpose = this.purpose.filter((item) => item != selected);
    },
    handleSubmit() {
      if (this.loading || !this.isAbleToSubmit) {
        return;
      }

      this.isFormValid().then((valid) => {
        //remove approver if null
        this.form.approvals = this.form.approvals.filter((item) => item.approver_id !== null);

        if (valid) {
          this.submitting = true;
          submit(this.form)
            .then((res) => {
              this.$ons.notification.alert({
                title: res.code ? res.code : this.$t("common.notif.saved"),
                messageHTML: ` ${this.$t("common.notif.submit")}`
              });
              this.populateDefaultTemp();
              this.$store.commit("navigator/pop");
            })
            .then(() => {
              this.submitting = false;
            });
        }
      });
    },
    handleSealTypeSelect(val) {
      this.sealTypeShow = false;
      this.sealTypeObj = val;
      this.form.corporate_seal.type_id = this.sealTypeObj.id;
    },
    handleEstimatedApprovalDeadline(val) {
      this.form.corporate_seal.estimated_approval_date = val;
    },
    addStampDoc() {
      this.stamp_docs.push({ value: "" });
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.corporate_seal.type_id.$invalid) {
        await this.$ons.notification.alert(this.$t("app.stamp.alert.usage"), options);
        return false;
      }

      if (this.$v.form.corporate_seal.purpose.$invalid) {
        await this.$ons.notification.alert(this.$t("app.stamp.alert.purpose"), options);
        return false;
      }

      if (this.$v.form.corporate_seal.stamped_party_full_name.$invalid) {
        await this.$ons.notification.alert(this.$t("app.stamp.alert.fullname"), options);
        return false;
      }

      if (this.$v.form.corporate_seal.contract_party_id.$invalid) {
        await this.$ons.notification.alert(this.$t("app.stamp.alert.party"), options);
        return false;
      }

      if (this.$v.form.corporate_seal.document_plan_files.$invalid) {
        await this.$ons.notification.alert(this.$t("app.stamp.alert.plan"), options);
        return false;
      }

      if (!this.approverIsValid) {
        await this.$ons.notification.alert(this.$t("common.alert.approver"), options);
        return false;
      }

      return true;
    },
    updateFormFile(type) {
      if (type === this.FILE_TYPE.CORPORATE_SEAL) {
        this.form.corporate_seal.document_plan_files = this.files[this.FILE_TYPE.CORPORATE_SEAL].map((file) => file.id);
      }
      if (type === this.FILE_TYPE.CORPORATE_SEAL_SIGNED) {
        this.form.corporate_seal.document_signed_files = this.files[this.FILE_TYPE.CORPORATE_SEAL_SIGNED].map((file) => file.id);
      }
    },
    handleReset() {
      this.$ons.notification.confirm(this.$t("common.notif.decide"), { title: this.$t("common.notif.confirm") }).then((response) => {
        if (response === 1) {
          this.form.corporate_seal = Object.assign({}, defaultForm);
          this.stamp_docs = [{ value: "" }];
          this.purpose = [];
          this.sealTypeObj = null;
          this.files = {};
          if (this.contractPartyObj) {
            this.form.corporate_seal.contract_party_id = this.contractPartyObj.id;
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.list-item {
  border-bottom: none;
}
</style>
