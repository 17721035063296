import request from "@/utils/request";

export function createUser(params) {
  return request({
    url: "/buffer/users",
    method: "post",
    data: params
  });
}

export function updateUser(params, userId) {
  return request({
    url: `/buffer/users/${userId}/edit`,
    method: "post",
    data: params
  });
}

export function fetchUserDetail(user_id) {
  return request({
    url: `/users/${user_id}`,
    method: "get"
  });
}

export function deleteUser(id, data) {
  return request({
    url: "/buffer/users/" + id + "/delete",
    method: "POST",
    data: data
  });
}
