<template>
  <div>
    <div v-if="name == 'reimbursement'" class="approval-icon bg-blue-500">
      <Icon name="wallet-duo" classes="text-white" :size="size" />
    </div>
    <div v-if="name == 'payment'" class="approval-icon bg-purple-500">
      <Icon name="credit-card-duo" classes="text-white" :size="size" />
    </div>
    <div v-if="name == 'report'" class="approval-icon bg-green-500">
      <Icon name="book-duo" classes="text-white" :size="size" />
    </div>
    <div v-if="name == 'purchase'" class="approval-icon bg-yellow-500">
      <Icon name="check-edit-duo" classes="text-white" :size="size" />
    </div>
    <div v-if="name == 'contract-supplier'" class="approval-icon bg-rose-500">
      <Icon name="cube-duo" classes="text-white" :size="size" />
    </div>
    <div v-if="name == 'customer'" class="approval-icon bg-rose-500">
      <Icon name="user-2-duo" classes="text-white" :size="size" />
    </div>
    <div v-if="name == 'seal'" class="approval-icon bg-rose-500">
      <Icon name="stamp-duo" classes="text-white" :size="size" />
    </div>
    <div v-if="name == 'stamp'" class="approval-icon bg-green-500">
      <Icon name="chart-bar-duo" classes="text-white" :size="size" />
    </div>
    <div v-if="name == 'background'" class="approval-icon bg-green-500">
      <Icon name="exchange-duo" classes="text-white" :size="size" />
    </div>
    <div v-if="name == 'user'" class="approval-icon bg-blue-500">
      <Icon name="user-plus-duo" classes="text-white" :size="size" />
    </div>
    <div v-if="name == 'company'" class="approval-icon bg-yellow-500">
      <Icon name="briefcase-duo" classes="text-white" :size="size" />
    </div>
  </div>
</template>

<script>
import Icon from "./Icon";
export default {
  name: "Logo",
  props: {
    name: {
      type: String,
    },
    size: {
      type: String,
      default: "26",
    },
  },
  components: {
    Icon,
  },
};
</script>

<style></style>
