const getters = {
  token: (state) => state.user.token,
  name: (state) => state.user.name,
  roles: (state) => state.user.roles,
  expenses: (state) => state.user.expenses,
  datalang: (state) => state.user.datalang,
  data: (state) => state.app.data,
  profile: (state) => state.user.profile,
  loginError: (state) => state.user.error,
  stack: (state) => state.navigator.stack,
  isSubmit: (state) => state.navigator.isSubmit,
  isPop: (state) => state.navigator.isPop,
  options: (state) => state.navigator.options,
  param: (state) => state.navigator.param,
  processes: (state) => state.app.processes,
  approvers: (state) => state.app.approvers,
  open: (state) => state.splitter.open,
  index: (state) => state.tabbar.index,
  app: (state) => state.tabbar.app,
  lang: (state) => state.app.lang
};
export default getters;
