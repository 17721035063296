<template>
  <v-ons-action-sheet :visible.sync="isBottomMenuVisible" cancelable class="action-sheet-form">
    <div class="action-sheet-title">
      <div class="left" @click="closeBottomMenu">
        <Icon name="chevron-down" classes="h-6 w-6" />
      </div>
      <div>{{ $t("common.content") }}</div>
    </div>
    <div class="action-sheet-content max-h-80 overflow-auto" style="max-height: 60vh;">
      <v-ons-list>
        <v-ons-list-item tappable>
          <span class="list-item__title">
            {{ $t("app.reimb.expense_detail") }}
          </span>
          <span class="list-item__subtitle">
            <label class="center">
              <select @change="onExpenseChange" v-model="contentForm.expense" class="select-input w-full">
                <option :value="null" disabled selected>
                  {{ $t("app.reimb.select_expense") }}
                </option>
                <option :key="expense.id" v-for="expense in expenseList" :value="expense">
                  {{ expense.name }}
                </option>
              </select>
            </label>
          </span>
        </v-ons-list-item>
        <v-ons-list-item :modifier="md ? 'nodivider' : ''" v-if="isTransportation">
          <span class="list-item__title">
            {{ $t("app.reimb.destination") }}
          </span>
          <span class="list-item__subtitle">
            <v-ons-input
              v-model="contentForm.destination"
              style="width: 80%"
              type="text"
              float
              maxlength="20"
              :placeholder="$t('app.reimb.enter_destination')"
            >
            </v-ons-input>
          </span>
        </v-ons-list-item>
        <div class="flex items-center justify-between" v-if="isTransportation">
          <div class="w-1/2">
            <form-input kind="text" :label="$t('app.reimb.from')" :placeholder="$t('app.reimb.enter_from')" v-model="local.from" />
          </div>
          <div class="w-1/2">
            <form-input kind="text" :label="$t('app.reimb.to')" :placeholder="$t('app.reimb.enter_to')" v-model="local.to" />
          </div>
        </div>
        <v-ons-list-item>
          <span class="list-item__title">
            {{ $t("common.amount") }}
            ({{ selectedCurrency ? selectedCurrency.code : null }})
          </span>
          <span class="list-item__subtitle">
            <v-ons-input v-model.number="contentForm.amount" type="number" float :placeholder="$t('common.enter_amount')"> </v-ons-input>
          </span>
        </v-ons-list-item>
        <v-ons-list-item>
          <span class="list-item__title">
            {{ $t("common.date") }}
          </span>
          <span class="list-item__subtitle">
            <v-ons-input v-model="contentForm.date" type="date" float class="w-full" :placeholder="$t('common.select')"> </v-ons-input>
          </span>
        </v-ons-list-item>
        <v-ons-list-item>
          <span class="list-item__title">{{ $t("common.notes") }}</span>
          <span class="list-item__subtitle">
            <label class="center">
              <textarea
                type="textarea"
                class="textarea"
                float
                :placeholder="$t('common.enter_notes')"
                style="width: 100%"
                cols="40"
                rows="4"
                v-model="contentForm.notes"
              >
              </textarea>
            </label>
          </span>
        </v-ons-list-item>
      </v-ons-list>
    </div>
    <ons-row class="border-t flex items-center justify-center py-4">
      <v-ons-button @click="onSubmitContent" modifier="quiet">
        {{ $t("common.submit") }}
      </v-ons-button>
    </ons-row>
  </v-ons-action-sheet>
</template>

<script>
export default {
  name: "ReimbursementContent",
  model: {
    prop: "showBottomMenu",
    event: "onVisibleChange"
  },
  watch: {
    local: {
      deep: true,
      handler(n) {
        this.local.from = n.from == "" ? null : n.from;
        this.local.to = n.to == "" ? null : n.to;

        this.contentForm.from_to = [n.from, n.to].toString();
      }
    },
    isBottomMenuVisible(n) {
      if (n) {
        if (this.contentForm.from_to && this.contentForm.from_to.length > 0) {
          let fromTo = this.contentForm.from_to.split(",");
          this.local.from = fromTo[0];
          this.local.to = fromTo[1];
        }
      }
    }
  },
  props: {
    contentForm: {
      type: Object
    },
    expenseList: {
      type: Array
    },
    showBottomMenu: {
      type: Boolean
    },
    selectedCurrency: {
      type: Object
    }
  },
  data() {
    return {
      local: {
        from: null,
        to: null
      }
    };
  },
  methods: {
    onSubmitContent() {
      this.$emit("onSubmitContent");
    },
    onExpenseChange() {
      this.$emit("onExpenseChange");
    },
    closeBottomMenu() {
      this.$emit("closeBottomMenu");
    }
  },
  computed: {
    isTransportation() {
      return this.contentForm.expense ? this.contentForm.expense.is_transportation : false;
    },
    isBottomMenuVisible: {
      set(newValue) {
        this.$emit("closeBottomMenu", newValue);
      },
      get() {
        return this.showBottomMenu;
      }
    }
  }
};
</script>

<style scoped></style>
