<template>
  <v-ons-page @init="initPage" @show="showPage">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <v-ons-progress-bar indeterminate v-if="loading"></v-ons-progress-bar>
    <div class="card flat p-4" style="text-align: center">
      <div style="position: relative">
        <v-ons-search-input maxlength="20" placeholder="Search" style="width: 100%" v-model="terms" @change="onSearch"> </v-ons-search-input>
        <span
          style="
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
          "
          @click="onReset"
        >
          <Icon v-if="terms.length > 0" name="x" classes="icon list-item__thumbnail text-gray" />
        </span>
      </div>
    </div>
    <v-ons-list>
      <v-ons-list-item @click="onSelectExpense(obj)" tappable v-for="(obj, key) in expenseDetailList" :key="key">
        <div class="left"></div>
        <div class="center">
          <span class="list-item__title">{{ obj.name }}</span>
          <span class="list-item__subtitle"></span>
        </div>
      </v-ons-list-item>
    </v-ons-list>
    <div class="no-result" v-show="expenseDetailList.length === 0">
      {{ $t("common.empty") }}
    </div>
  </v-ons-page>
</template>

<script>
export default {
  name: "ExpenseDetailList",
  data: () => ({
    expenseDetailList: [
      {
        id: 1,
        name: "transportation"
      }
    ],
    loading: true,
    terms: ""
  }),
  methods: {
    initPage() {
      console.log("init page");
    },
    showPage() {
      console.log("show page");
    },
    onSelectExpense(expense) {
      console.log(expense);
    },
    onReset() {
      console.log("on select");
    },
    onSearch() {
      console.log("on search");
    }
  }
};
</script>

<style scoped></style>
