import { FILE_TYPE, COMPONENT, COMMON } from "@/utils/constant";
import ReiDForm from "@/views/Application/Reimbursement/ReimbursementDetailForm";
import PayDForm from "@/views/Application/Payment/PaymentDetailForm";
import PurDForm from "@/views/Application/Purchase/PurchaseDetailForm";

import { fetchNextStampNo } from "../api/stamp_statistic";

import BankAccountList from "@/views/common/list/BankAccountList";
// import FinanceList from "@/views/common/list/FinanceList";
import FinanceListMultiple from "@/views/common/list/FinanceListMultiple";
import CompanyList from "@/views/common/list/ContractPartyList";
import DepartmentList from "@/views/common/list/DepartmentList";
import RelatedApprovalList from "@/views/common/list/related_approval/RelatedApproval";

//buffer
import { fetchNextCode as fetch_next_code, postTempData, getTempData } from "@/api/buffer";

import { getCurrency as fetch_currency } from "@/api/rembursement";
import { fetchDetail as fetch_file } from "@/api/file";
import { fetchDetail as fetch_bank_account } from "@/api/bank_account";
import { fetchDetail as fetch_user } from "@/api/approver";
import { fetchCompanyDetail as fetch_company } from "@/api/company";
import { fetchDepartmentDetail as fetch_department } from "@/api/department";
import { fetchRelatedReimbursementDetail as fetch_purchase, fetchRelatedContractDetail as fetch_contract } from "@/api/related_approval";

//util
import { Round } from "@/utils/number";

const defaultComponent = {
  finance: [],
  bank_account: null,
  company: null,
  department: null,
  related_approval: null
};

const needStampNo = ["contract-supplier", "contract-customer", "corporate-seal"];

export default {
  name: "ApplicationMixin",
  data() {
    return {
      loading: true,
      nextCode: "",
      nextStampNo: "",
      common: {
        currency: null
      },
      cshow: {
        currency: false
      },
      files: {},
      component: Object.assign({}, defaultComponent),
      FILE_TYPE: FILE_TYPE,
      COMPONENT: COMPONENT,
      COMMON: COMMON,
      tempMaster: ["common", "component", "files"],
      defaultTemp: ["common", "component"],
      temp: {},
      mandatoryApproval: []
    };
  },
  beforeDestroy() {
    if (!this.submitting) {
      this.populateTemp();
    }
  },
  watch: {
    approver: {
      deep: true,
      handler(n) {
        this.form.approvals = n.map((item) => ({
          approver_id: item.approver_id,
          master_process_id: item.master_process_id
        }));

        this.mandatoryApproval = n.filter((item) => item.optional == false);
      }
    }
  },
  computed: {
    oldData() {
      return this.param && this.param.data ? this.param.data : null;
    },
    approver() {
      return this.$store.getters.approvers;
    },
    approverIsValid() {
      let any = this.mandatoryApproval.filter((item) => !item.approver_id.length > 0);
      return any.length == 0;
    },
    detailPage() {
      return [
        {
          module: "reimbursement",
          component: ReiDForm,
          label: this.$t("app.reimb.reimbursement_detail")
        },
        {
          module: "payment",
          component: PayDForm,
          label: this.$t("app.pay.payment_detail")
        },
        {
          module: "purchase",
          component: PurDForm,
          label: this.$t("app.purc.goods_detail")
        }
      ];
    },
    componentPage() {
      return [
        {
          name: this.COMPONENT.BANK_ACCOUNT,
          component: BankAccountList,
          label: this.$t("common.select")
        },
        {
          name: this.COMPONENT.FINANCE,
          component: FinanceListMultiple,
          label: this.$t("app.select_finance")
        },
        {
          name: this.COMPONENT.COMPANY,
          component: CompanyList,
          label: this.$t("common.select_company")
        },
        {
          name: this.COMPONENT.DEPARTMENT,
          component: DepartmentList,
          label: this.$t("common.select_department")
        },
        {
          name: this.COMPONENT.RELATED_APPROVAL,
          component: RelatedApprovalList,
          label: this.$t("common.select")
        }
      ];
    },
    currencyCode() {
      return this.common.currency ? this.common.currency.code : null;
    }
  },
  methods: {
    Round,
    onInit(obj) {
      if (!obj) {
        obj = "transaction";
      }

      if (this.oldData) {
        //load re-submit from param data
        this.form[obj] = Object.assign({}, this.param.data);

        this.loadData();
      } else {
        //load temporary data
        this.getForm();
      }

      this.getNextCode();
      if (needStampNo.includes(this.moduleCode)) {
        this.getNextStampNo();
      }
    },
    onCommonSelect(value, key) {
      this.cshow[key] = false;
      this.common[key] = value;
      this.updateFormCommon(key);
    },
    onClickDetail(item) {
      let page = this.detailPage.filter((item) => item.module === this.moduleCode);
      if (page && page[0]) {
        this.push(page[0].component, page[0].label, {
          item: item,
          currency: this.common[COMMON.CURRENCY]
        });
      }
    },
    onClickComponent(name, param) {
      let page = this.componentPage.filter((item) => item.name === name);
      if (page && page[0]) {
        this.push(page[0].component, page[0].label, param);
      }
    },
    onUpload(file, type) {
      this.files[type] = file;
      this.updateFormFile(type);
    },
    push(page, key, param) {
      this.$store.commit("navigator/options", {
        animation: "lift-ios"
      });
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    },
    postForm() {
      postTempData({ module_code: this.moduleCode, data: this.temp });
    },
    getForm() {
      getTempData({ moduleCode: this.moduleCode }).then((res) => {
        this.temp = res["hydra:member"][0].data;
        this.loadTemp();
      });
    },
    getNextCode() {
      fetch_next_code({ moduleCode: this.moduleCode }).then((res) => (this.nextCode = res.code));
    },
    getNextStampNo() {
      fetchNextStampNo({ moduleCode: this.moduleCode }).then((res) => (this.nextStampNo = res.code));
    },
    loadApi(type, val) {
      switch (type) {
        case this.COMMON.CURRENCY:
          fetch_currency(val).then((r) => (this.common.currency = r));
          break;
        case this.COMPONENT.BANK_ACCOUNT:
          fetch_bank_account(val).then((r) => (this.component.bank_account = r));
          break;
        case this.COMPONENT.FINANCE:
          fetch_user(val).then((r) => (this.component.finance = [...this.component.finance, r]));
          break;
        case this.COMPONENT.COMPANY:
          fetch_company(val).then((r) => (this.component.company = r));
          break;
        case this.COMPONENT.DEPARTMENT:
          fetch_department(val).then((r) => (this.component.department = r));
          break;
        default:
          return;
      }
    },
    loadFile(type, val) {
      if (this.files[type] === undefined) {
        this.files[type] = [];
      }

      fetch_file(val).then((r) => {
        this.files[type].push(r);
      });
    },
    populateTemp() {
      this.tempMaster.forEach((item) => {
        this.temp[item] = Object.assign({}, this[item]);
      });
      this.postForm();
    },
    populateDefaultTemp() {
      this.temp = {};
      this.defaultTemp.forEach((item) => {
        this.temp[item] = Object.assign({}, this[item]);
      });
      this.postForm();
    },
    loadTemp() {
      this.tempMaster.forEach((item) => {
        if (this.temp[item]) {
          this[item] = this.temp[item];
        }
      });
    },
    handleRemoveFinance(id) {
      this.component.finance = this.component.finance.filter((user) => user.id !== id);
    },
    loadRelatedApproval(type, id) {
      if (type === "reimbursement") {
        fetch_purchase(id).then((res) => {
          this.component.related_approval = {
            id: res.id,
            code: res.code,
            currency: res.currency.code,
            data: res.purchase_goods.map((item) => ({
              budget: parseInt(item.budget),
              currency: res.currency.code,
              edited: false,
              goods_name: item.goods_name,
              item_id: item.id,
              unit_price: item.estimated_unit_price
            }))
          };
        });
      }
      if (type === "payment") {
        fetch_contract(id).then((obj) => {
          let data = null;
          switch (obj.contract_item_type.code) {
            case "goods":
              data = obj.contract_item_goods["hydra:member"];
              break;
            case "services":
              data = obj.contract_item_services["hydra:member"];
              break;
            case "hr":
              data = obj.contract_item_hr["hydra:member"];
              break;
            case "other":
              data = obj.contract_item_other["hydra:member"];
              break;
          }
          this.component.related_approval = {
            id: obj.id,
            code: obj.code,
            currency: obj.currency.code,
            data: data,
            module: obj.module.code,
            type: obj.contract_item_type.code,
            contract_name: obj.contract_full_name,
            supplier_account: obj.contract_supplier_account
          };
        });
      }
    },
    handleReset() {}
  }
};
