<template>
  <BottomSheet :class="{ 'z-low': zlow }" :title="$t('common.filter')" :showing="showing" @on-close="$emit('on-close')" classes="pb-0" :min="90">
    <!-- <loading-small class="test" /> -->
    <div class="overflow-y-auto  pb-20">
      <v-ons-list class="">
        <!-- <form-select-user
          :label="$t('common.applicant')"
          :users="component.users ? component.users : []"
          @remove="handleRemoveUser"
          @add="onClickComponent(COMPONENT.USER, { default: component.users })"
        /> -->

        <form-select
          :label="$t('common.company')"
          @click="onClickComponent(COMPONENT.COMPANY)"
          :placeholder="$t('common.select_company')"
          :value="component.company ? component.company.name : null"
        />

        <form-input kind="text" :label="$t('app.cc.stamp')" :placeholder="$t('app.cc.stamp')" v-model="filter[defaultParamArr.stamp_no.key]" />

        <v-ons-list-item>
          <span class="list-item__title">
            Status
          </span>
          <div class="grid grid-cols-4 py-1.5 space-x-1 text-center w-full">
            <div
              v-for="(item, key) in statuses"
              :key="'status_' + key"
              :class="{ 'border-blue-500 text-blue-500': selectedStatus && item.value === selectedStatus.value }"
              @click="selectedStatus = item"
              class="bg-gray-50 border py-1 rounded"
            >
              {{ item.text }}
            </div>
          </div>
        </v-ons-list-item>
        <v-ons-list-item>
          <span class="list-item__title">
            Modules
          </span>
          <div class="gap-1.5 grid grid-cols-3 py-1.5 text-center text-xs w-full">
            <div
              v-for="(item, key) in modules"
              :key="'status_' + key"
              :class="{ 'border-blue-500 text-blue-500': selectedModules.find((m) => m.value === item.value) }"
              @click="handleSelectModules(item)"
              class="bg-gray-50 border py-1 rounded flex items-center justify-center"
            >
              <span class="truncate">{{ item.text }}</span>
            </div>
          </div>
        </v-ons-list-item>
      </v-ons-list>
      <ons-list class="pt-3">
        <v-ons-list-header> {{ $t("stat.export_.period") }} </v-ons-list-header>
        <v-ons-list-item>
          <span class="list-item__title"> {{ $t("stat.export_.period") }} </span>
          <div class="grid grid-cols-3 py-1.5 space-x-1 text-center w-full">
            <div
              v-for="(item, key) in periods"
              :key="'period_' + key"
              @click="periodType = item"
              class="bg-gray-50 border py-1 rounded"
              :class="{ 'border-blue-500 text-blue-500': null !== periodType && item.value === periodType.value }"
            >
              {{ item.text }}
            </div>
          </div>
        </v-ons-list-item>
        <v-ons-list-item v-if="periodType">
          <span class="list-item__title"> {{ periodType.text }} </span>
          <div class="p-4 grid grid-cols-3 gap-3 text-sm text-center w-full">
            <div
              v-for="(item, key) in human_periods"
              :key="'conditional_' + key"
              :class="{ 'border-blue-500 text-blue-500': null !== selectedHumanPeriod && item.value === selectedHumanPeriod.value }"
              @click="selectedHumanPeriod = item"
              class="bg-gray-50 border py-1 rounded"
            >
              {{ item.text }}
            </div>
          </div>
        </v-ons-list-item>
        <form-calendar
          v-if="selectedHumanPeriod && selectedHumanPeriod.value === 'custom'"
          :bold="true"
          label="Custom"
          :placeholder="$t('stat.export_.select_period')"
          :mode="'during'"
          @on-change="handlePeriodSelect"
          :value="period"
        />
        <!-- @on-change="handlePeriodSelect" -->
      </ons-list>
    </div>
    <div class="fixed bottom-0 w-full bg-white flex items-center px-6">
      <ons-row class="flex items-center justify-center py-4">
        <v-ons-button modifier="quiet" class="w-full text-center rounded" @click="onReset">
          {{ this.$t("common.sort_.reset") }}
        </v-ons-button>
      </ons-row>
      <ons-row class="flex items-center justify-center py-4">
        <v-ons-button class="w-full text-center rounded" @click="onSubmit">
          {{ $t("common.search") }}
        </v-ons-button>
      </ons-row>
    </div>
  </BottomSheet>
</template>
<script>
import { COMPONENT } from "@/utils/constant";
import BottomSheet from "@/components/BottomSheet";

import CompanyList from "@/views/common/list/ContractPartyList";
import UserListMultipleSelect from "../../../common/select/UserListMultipleSelect.vue";

const defaultComponent = {
  company: null,
  users: []
};

// const defaultFilter = {
//   "buffer.company.id": null,
//   "buffer.requester.id": [],
//   "buffer.stamp_no": null,
//   "buffer.status": null
// };

export default {
  name: "FilterComponent",
  props: ["showing", "param", "periodPrefix"],
  components: {
    BottomSheet
  },
  data() {
    return {
      zlow: false,
      COMPONENT: COMPONENT,
      component: Object.assign({}, defaultComponent),
      filter: Object.assign({}, this.defaultParam),
      periodType: null,
      selectedHumanPeriod: null,
      period: null,
      selectedStatus: null,
      selectedModules: []
    };
  },
  watch: {
    selectedStatus: function(n) {
      if (n) {
        this.filter[this.defaultParamArr.status.key] = n.value;
      } else {
        this.filter[this.defaultParamArr.status.key] = this.defaultParamArr.status.value;
      }
    },
    selectedModules: function(n) {
      if (n) {
        this.filter[this.defaultParamArr.module.key] = n.map((m) => m.value);
      }
    },
    "$store.getters.param.selectedContractParty": function(n) {
      if (n) {
        this.zlow = false;
        this.component.company = n;
        this.filter[this.defaultParamArr.company.key] = n.id;
        // this.filter["buffer.company.id"] = n.id;
      }
    },
    "$store.getters.param.selectedUsers": function(n) {
      if (n) {
        this.zlow = false;
        this.component.users = n;
        this.filter[this.defaultParamArr.requester.key] = n.map((user) => user.id);
        // this.filter["buffer.requester.id"] = n.map((user) => user.id);
      }
    },
    selectedHumanPeriod: function(n) {
      if (n) {
        if (n.value !== "custom") this.period = n.value;
      }
    },
    "$store.getters.isPop": function(n) {
      if (n) {
        this.zlow = false;
      }
    }
  },
  computed: {
    defaultParamArr() {
      let r = {};
      if (this.param.length > 0) {
        for (let i = 0; i <= this.param.length; i++) {
          if (this.param[i]) {
            if (this.param[i].prefix) {
              r[this.param[i].alias] = {
                key: this.param[i].prefix + "." + this.param[i].key,
                value: this.param[i].value
              };
            } else {
              r[this.param[i].alias] = {
                key: this.param[i].key,
                value: this.param[i].value
              };
            }
          }
        }
      }
      return r;
    },
    defaultParam() {
      let x = {};
      Object.keys(this.defaultParamArr).forEach((k) => {
        x[this.defaultParamArr[k]["key"]] = this.defaultParamArr[k]["value"];
      });
      return x;
    },
    str() {
      let prefix = this.periodPrefix ? this.periodPrefix + "." : "";
      if (!this.periodType || !this.selectedHumanPeriod) {
        return null;
      }

      return `${prefix}${this.periodType.value}[${this.selectedHumanPeriod.condition}]`;
    },
    componentPage() {
      return [
        {
          name: this.COMPONENT.COMPANY,
          component: CompanyList,
          label: this.$t("common.select_company")
        },
        {
          name: this.COMPONENT.USER,
          component: UserListMultipleSelect,
          label: this.$t("common.applicant")
        }
      ];
    },
    statuses() {
      return [
        {
          value: 0,
          text: this.$t("common.onprocess")
        },
        {
          value: 2,
          text: this.$t("common.approved")
        },
        {
          value: 3,
          text: this.$t("common.rejected")
        },
        {
          value: 10,
          text: this.$t("common.withdrawn")
        }
      ];
    },
    modules() {
      return [
        {
          value: "reimbursement",
          text: this.$t("home.menu.reimbursements")
        },
        {
          value: "payment",
          text: this.$t("home.menu.payment")
        },
        {
          value: "purchase",
          text: this.$t("home.menu.purchase")
        },
        {
          value: "collection-sales",
          text: this.$t("home.menu.sales_registration")
        },
        {
          value: "collection-debt",
          text: this.$t("app.col._debt")
        },
        {
          value: "collection-report",
          text: this.$t("home.menu.collection_report")
        },
        {
          value: "contract-supplier",
          text: this.$t("home.menu.supplier_contract")
        },
        {
          value: "contract-customer",
          text: this.$t("home.menu.customer_contract")
        },
        {
          value: "corporate-seal",
          text: this.$t("home.menu.corporate_seal")
        }
      ];
    },
    periods() {
      return [
        {
          value: "completion_date",
          text: "Completion Date"
        },
        {
          value: "payment_date",
          text: "Payment Date"
        },
        {
          value: "contract_till_date",
          text: "Contract Date"
        }
      ];
    },
    human_periods() {
      let format = "YYYY-MM-DD";
      let today = this.moment().format(format);

      return [
        {
          value: `${today}..${today}`,
          condition: "between",
          text: "Today"
        },
        {
          value: `${this.moment()
            .subtract(7, "days")
            .format(format)}..${today}`,
          condition: "between",
          text: "Last 7 days"
        },
        {
          value: `${this.moment()
            .startOf("month")
            .format(format)}..${this.moment()
            .endOf("month")
            .format(format)}`,
          condition: "between",
          text: "This month"
        },
        {
          value: `${this.moment()
            .subtract(1, "months")
            .startOf("month")
            .format(format)}..${this.moment()
            .subtract(1, "months")
            .endOf("month")
            .format(format)}`,
          condition: "between",
          text: "Last month"
        },
        {
          value: "custom",
          condition: "between",
          text: "Custom"
        }
      ];
    }
  },
  mounted() {
    this.filter = Object.assign({}, this.defaultParam);
  },
  methods: {
    onClickComponent(name, param) {
      this.zlow = true;
      let page = this.componentPage.filter((item) => item.name === name);
      if (page && page[0]) {
        this.push(page[0].component, page[0].label, param);
      }
    },
    onSubmit() {
      let payload = Object.assign({}, this.filter);
      if (this.str && this.period) {
        payload[this.str] = this.period;
      }
      this.$emit("on-filter", payload);
    },
    onReset() {
      this.filter = Object.assign({}, this.defaultParam);
      this.component = Object.assign({}, defaultComponent);
      this.periodType = null;
      this.conditionalType = null;
      this.period = null;
      this.selectedStatus = null;
      this.selectedModules = [];
      this.selectedHumanPeriod = null;
      this.$emit("on-filter", null);
    },
    handlePeriodSelect(value) {
      if (null !== this.selectedHumanPeriod && this.selectedHumanPeriod.condition === "between") {
        this.period = value.join("to");
      } else {
        this.period = value;
      }
    },
    handleRemoveUser(id) {
      this.component.users = this.component.users.filter((user) => user.id !== id);
    },
    handleSelectModules(module) {
      if (!this.selectedModules.find((item) => item.value === module.value)) {
        this.selectedModules.push(module);
      } else {
        this.selectedModules = this.selectedModules.filter((item) => item.value !== module.value);
      }
    },
    push(page, key, param) {
      this.$store.commit("navigator/options", {
        animation: "none"
      });
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.cancel"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped>
.list-item /deep/ .list-item__title {
  font-weight: 700 !important;
}
.z-low {
  z-index: 0 !important;
}
</style>
