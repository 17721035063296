<template>
  <v-ons-page @init="loadPage">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <search-filter @on-search="onSearch" @on-reset="onReset" />
    <div class="mt-14">
      <div class="no-result" v-if="approverList.length === 0">{{ $t("common.empty") }}</div>
      <v-ons-list v-else>
        <form-select-icon
          v-for="(obj, key) in approverList"
          :key="key"
          :label="obj.name"
          :sublabel="obj.company.name + ' | ' + obj.department.name"
          :badge="obj.is_authorizer ? $t('common.company_authorizer') : null"
          icon="circle"
          iconClass="text-gray-300"
          modifier="none"
          @tap="onSelectApprover(obj.id)"
        />
      </v-ons-list>
    </div>
    <infinite-loading @infinite="getData" />
  </v-ons-page>
</template>

<script>
import { mapMutations } from "vuex";
import { fetchList } from "@/api/approver";
import SearchFilter from "@/components/SearchFilter";

const defaultFilter = { page: 1, itemsPerPage: 10, name: "", masterProcessId: null };
export default {
  name: "ApproverList",
  components: { SearchFilter },
  data: () => ({
    processId: null,
    settingId: null,
    approverList: [],
    filter: Object.assign({}, defaultFilter)
  }),
  methods: {
    ...mapMutations("navigator", ["pop", "setParam", "clearParam"]),
    onSelectApprover(id) {
      let selectedApprover = this.approverList.filter((approver) => approver.id === id)[0];

      let param = {};
      if (this.settingId) {
        param = { key: this.settingId, value: selectedApprover };
        this.setParam({ setting: param });
      } else if (this.processId) {
        param = { key: this.processId, value: selectedApprover };
        this.setParam({ approver: param });
      }
      this.pop();
    },
    loadPage() {
      this.processId = this.toolbarInfo.param.processId;
      this.settingId = this.toolbarInfo.param.settingId;
      this.filter.masterProcessId = this.processId;
    },
    async getData($state) {
      await fetchList(this.filter).then((response) => {
        let data = response["hydra:member"];

        this.approverList.push(...data);
        if (data.length) {
          this.filter.page += 1;
          $state && $state.loaded();
        } else {
          $state && $state.complete();
        }
      });
    },
    onSearch(filter) {
      this.approverList = [];
      this.filter.page = 1;
      this.filter.name = filter.term;
      this.getData();
    },
    onReset() {
      this.approverList = [];
      this.filter = Object.assign({}, defaultFilter);
      this.getData();
    }
  }
};
</script>

<style scoped></style>
