<template>
  <BottomSheetAction
    :title="title ? title : $t('app.sc.supplier_account_detail')"
    :showing="showing"
    @on-close="$emit('on-close')"
    @on-save="handleSave"
  >
    <v-ons-list>
      <v-ons-list-item>
        <v-ons-col width="30%" class="required" style="padding-right: 5px">
          <label class="ml-1">{{ $t("app.account.name") }}</label>
        </v-ons-col>
        <v-ons-col>
          <v-ons-input v-model="form.name" type="text" float class="w-full" :placeholder="$t('app.sc.enter_name')"> </v-ons-input>
        </v-ons-col>
      </v-ons-list-item>
      <v-ons-list-item>
        <v-ons-col width="30%" class="required">
          <label class="ml-1">{{ $t("app.account.number") }}</label>
        </v-ons-col>
        <v-ons-col>
          <v-ons-input v-model="form.no" type="text" float class="w-full" :placeholder="$t('app.sc.enter_account_number')"> </v-ons-input>
        </v-ons-col>
      </v-ons-list-item>
      <v-ons-list-item>
        <v-ons-col width="30%" class="required">
          <label class="ml-1">{{ $t("app.account.bank") }}</label>
        </v-ons-col>
        <v-ons-col>
          <v-ons-input v-model="form.bank_name" type="text" float class="w-full" :placeholder="$t('app.sc.enter_bank')"> </v-ons-input>
        </v-ons-col>
      </v-ons-list-item>
      <v-ons-list-item>
        <v-ons-col width="30%" class="required">
          <label class="ml-1">{{ $t("app.account.branch") }}</label>
        </v-ons-col>
        <v-ons-col>
          <v-ons-input v-model="form.branch_name" type="text" float class="w-full" :placeholder="$t('app.sc.enter_branch')"> </v-ons-input>
        </v-ons-col>
      </v-ons-list-item>
    </v-ons-list>
  </BottomSheetAction>
</template>

<script>
import BottomSheetAction from "@/components/BottomSheetAction";
import { required } from "vuelidate/lib/validators";
export default {
  name: "SupplierAccount",
  props: ["showing", "title"],
  components: {
    BottomSheetAction
  },
  watch: {
    showing: function(newVal) {
      return newVal;
    }
  },
  data() {
    return {
      form: {
        name: null,
        no: null,
        bank_name: null,
        branch_name: null
      }
    };
  },
  validations: {
    form: {
      name: {
        required
      },
      no: {
        required
      },
      bank_name: {
        required
      },
      branch_name: {
        required
      }
    }
  },
  methods: {
    handleSave() {
      this.isFormValid().then((res) => {
        if (res) {
          this.$emit("save", this.form);
        }
      });
    },
    async isFormValid() {
      if (this.$v.form.name.$invalid) {
        await this.$ons.notification.alert("Please input account name");
        return false;
      }
      if (this.$v.form.no.$invalid) {
        await this.$ons.notification.alert("Please input account number");
        return false;
      }
      if (this.$v.form.bank_name.$invalid) {
        await this.$ons.notification.alert("Please input bank name");
        return false;
      }
      if (this.$v.form.branch_name.$invalid) {
        await this.$ons.notification.alert("Please input branch name");
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<style></style>
