<template>
  <div>
    <d-highlight
      v-if="estimatedApprovalDate"
      :label="$t('app.estimated_approval_date')"
      :value="estimatedApprovalDate ? moment(estimatedApprovalDate).format('YYYY/MM/DD') : '-'"
      :history="history ? ('estimated_approval_date' in history ? history.estimated_approval_date : null) : null"
    />
    <v-ons-card v-if="data">
      <div class="content p-2 space-y-2">
        <d-text :label="$t('app.application_number')" :value="data.code" />
        <d-text :label="$t('app.cc.contract_party')" :value="company" />
        <d-text :label="$t('common.department')" :value="department" />
        <!-- <d-text
          :label="$t('app.estimated_approval_date')"
          :value="obj.estimated_approval_date ? moment(obj.estimated_approval_date).format('DD/MM/YYYY') : '-'"
        /> -->
        <hr />
        <d-text :label="$t('app.cc.contract_type')" :value="contractType ? contractType.name : '-'" />
        <d-text
          :label="$t('app.cc.contract_fullname')"
          :value="data.data.contract_full_name"
          :history="'contract_full_name' in history ? history.contract_full_name : null"
        />
        <d-text
          :label="$t('app.cc.contract_period')"
          :value="obj.contract_period ? moment(obj.contract_period).format('YYYY/MM/DD') : '-'"
          :history="'contract_period' in history ? history.contract_period : null"
        />
        <hr />
        <d-items :data="items" :label="$t('app.item')" @tap="handleDetailOpen" :history="itemType in history ? history[itemType] : null" />
        <hr />
        <!-- <d-image :images="contractPlanFiles" :label="$t('app.cc.plan')" /> -->

        <d-total
          v-if="obj.amount"
          :label="$t('common.total')"
          :currency="currency"
          :amount="obj.amount"
          :history="'total' in history ? history.amount : null"
        />

        <form-uploader
          classes="p-0"
          :label="$t('app.cc.plan')"
          :existing="contractPlanFiles"
          :disabled="true"
          :history="'contract_plan_files' in history ? history.contract_plan_files : null"
        />

        <hr />
        <d-text :label="$t('app.cc.stamp')" :value="stamp" />

        <form-uploader
          classes="p-0"
          :label="$t('app.cc.signed')"
          :existing="signedContractFiles"
          :disabled="true"
          :history="'signed_contract_files' in history ? history.signed_contract_files : null"
        />
        <hr />
        <d-list :label="$t('app.forward_finance')" :items="finances" itemKey="name" />
      </div>

      <detail :data="detail" @on-close="handleDetailClose" />
      <weui-gallery :visible="previewShow" :src="previewImg" :canDelete="false" @onClick="previewShow = false"></weui-gallery>
    </v-ons-card>
  </div>
</template>

<script>
import { fetchDetail as getFile } from "@/api/file";
import detail from "@/views/Approval/component/customer_contract/detail";
import buffer from "@/mixins/buffer";
import history_detail from "@/mixins/history_detail";
import { fetchUserDetail as getUser } from "@/api/user";

const defaultDetail = {
  show: false,
  code: "",
  title: "",
  item: null,
  currency: ""
};

const itemConst = [
  {
    code: "goods",
    data: "item_goods",
    total: "total"
  },
  {
    code: "services",
    data: "item_services",
    total: "total"
  },
  {
    code: "other",
    data: "item_other",
    total: "total"
  }
];

export default {
  name: "CustomerContract",
  props: ["data", "history"],
  mixins: [buffer, history_detail],
  components: { detail },
  data() {
    return {
      contractPlanFiles: [],
      signedContractFiles: [],
      detail: Object.assign({}, defaultDetail),
      finances: []
    };
  },
  computed: {
    obj() {
      return this.data?.data;
    },
    estimatedApprovalDate() {
      return this.obj?.estimated_approval_date;
    },
    stamp() {
      return this.data.stamp_no ? this.data.stamp_no : this.data.stamp ? this.data.stamp : null;
    },
    items() {
      if (this.contractType) {
        if (this.contractType.code === "goods") {
          return this.obj.item_goods;
        } else if (this.contractType.code === "services") {
          return this.obj.item_services;
        } else if (this.contractType.code === "other") {
          return this.obj.item_other;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    itemType() {
      let f = itemConst.find((i) => i.code === this.contractType.code);
      return f?.data;
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(n, o) {
        if (n != o) {
          this.fetchCurrency(this.obj.currency_id);
          this.fetchCompany(this.obj.company_id);
          this.fetchDepartment(this.obj.department_id);
          this.fetchContractType(this.obj.contract_item_type_id);
          this.fetchContractPlainFiles();
          this.fetchSignedContractFiles();
          if (this.obj.user_finances) {
            this.obj.user_finances.forEach((user) => {
              this.fetchUserDetail(user);
            });
          }
        }
      }
    }
  },
  methods: {
    fetchContractPlainFiles() {
      this.contractPlanFiles = [];
      this.obj.contract_plan_files.forEach((item) => {
        getFile(item).then((f) => {
          this.contractPlanFiles.push({ ...f, size: f.size_string, is_edited: this.isFileEdited(f.id, "contract_plan_files") });
        });
      });
    },
    fetchSignedContractFiles() {
      this.signedContractFiles = [];
      this.obj.signed_contract_files.forEach((item) => {
        getFile(item).then((f) => {
          this.signedContractFiles.push({ ...f, size: f.size_string, is_edited: this.isFileEdited(f.id, "signed_contract_files") });
        });
      });
    },
    handleDetailOpen(item, key) {
      this.detail = {
        ...{
          show: true,
          title: this.$t("app.item") + " (" + (key + 1) + ")",
          code: this.contractType ? this.contractType.code : "",
          item: item,
          currency: this.currency
        }
      };
    },
    handleDetailClose() {
      this.detail = Object.assign({}, defaultDetail);
    },
    fetchUserDetail(id) {
      this.finances = [];
      getUser(id).then((response) => {
        this.finances.push(response);
      });
    }
  }
};
</script>

<style scoped></style>
