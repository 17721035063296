<template>
  <BottomSheet :title="$t('stat.export_.type')" :showing="showing" @on-close="$emit('on-close')">
    <v-ons-list class="pb-16">
      <v-ons-list-item v-for="(item, key) in list" :key="key" tappable @click="handleChange(item)">
        <label class="left">
          <v-ons-checkbox :input-id="'export-type-select-' + key" :value="item.value" v-model="selectedOption"> </v-ons-checkbox>
        </label>
        <label :for="'export-type-select-' + key" class="center">
          {{ item.text }}
        </label>
      </v-ons-list-item>
    </v-ons-list>
  </BottomSheet>
</template>

<script>
import BottomSheet from "@/components/BottomSheet";

export default {
  name: "moduleSelect",
  props: {
    showing: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BottomSheet
  },
  watch: {
    showing: function(newVal) {
      if (newVal) {
        this.selectedOption = [];
      }
    }
  },
  data() {
    return {
      selectedOption: []
    };
  },
  computed: {
    list() {
      return [
        {
          value: "reimbursement",
          text: this.$t("app.reimb.reimbursement")
        },
        {
          value: "payment",
          text: this.$t("app.pay.payment")
        },
        {
          value: "collection-report",
          text: this.$t("app.col._report")
        }
      ];
    }
  },
  methods: {
    handleChange(val) {
      this.$emit("on-select", val);
    }
  }
};
</script>

<style></style>
