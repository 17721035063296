<template>
  <div>
    <v-ons-list>
      <v-ons-list-header />
      <form-calendar
        :required="false"
        :label="$t('app.estimated_approval_date')"
        mode="single"
        @on-change="handleEstimatedApprovalDeadline"
        :placeholder="$t('common.select_date')"
        :value="formObj.estimated_approval_date ? moment(formObj.estimated_approval_date).format('YYYY-MM-DD') : null"
      />

      <form-select
        :label="$t('app.stamp.usage')"
        :placeholder="$t('app.stamp.select_usage')"
        :required="true"
        modifier="quiet"
        :value="formObj.type_id ? formObj.type_id.name : null"
      />

      <form-multipleselect
        :label="$t('app.stamp.purpose')"
        :placeholder="$t('app.stamp.select_purpose')"
        :required="true"
        :items="formObj.purpose ? formObj.purpose.split(',') : []"
        :allowModify="false"
      />

      <form-input
        kind="text"
        :label="$t('app.stamp.fullname')"
        :placeholder="$t('app.stamp.enter_fullname')"
        :required="true"
        v-model="formObj.stamped_party_full_name"
      />

      <form-select
        :label="$t('app.stamp.party')"
        :placeholder="$t('app.stamp.select_party')"
        :required="true"
        modifier="quiet"
        :value="formObj.contract_party_id ? formObj.contract_party_id.name : null"
      />
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-header />
      <form-input
        v-for="(item, key) in formObj.stamped_doc_full_names"
        kind="text"
        :key="'doc_' + key"
        :label="$t('app.stamp.stamp_docs') + String.fromCharCode(9312 + key)"
        :placeholder="$t('app.stamp.stamp_docs_detail')"
        v-model="formObj.stamped_doc_full_names[key]"
      />

      <!-- <v-ons-list-item>
        <div class="center" style="font-size: 13px; color: #a8a8a8">
          {{ $t("app.stamp.add_more_stamp_docs_info") }}
        </div>
      </v-ons-list-item> -->
    </v-ons-list>

    <!-- <v-ons-list>
      <v-ons-list-item class="p-0">
        <div class="center flex justify-center items-center p-0">
          <v-ons-button modifier="quiet" style="justify-content: center" @click="addStampDoc">
            <ons-icon icon="md-plus"> </ons-icon>
            {{ $t("common.add_more") }}
          </v-ons-button>
        </div>
      </v-ons-list-item>
    </v-ons-list> -->
    <div class="mb-6" />

    <v-ons-list>
      <v-ons-list-header />
      <form-input kind="text" :label="$t('app.stamp.type')" :placeholder="$t('app.stamp.enter_detail')" v-model="formObj.stamp_type" />

      <form-input kind="text" :label="$t('app.stamp.sign_on')" :placeholder="$t('app.stamp.enter_detail')" v-model="formObj.where_to_sign_on" />

      <form-textarea :label="$t('common.notes')" :placeholder="$t('app.stamp.enter_notes')" v-model="formObj.notes" />
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-header />
      <form-uploader
        :required="true"
        :label="$t('app.stamp.plan')"
        :sublabel="$t('app.stamp.plan_info')"
        :multiple="true"
        :documentType="FILE_TYPE.CORPORATE_SEAL"
        :existing="formObj.document_plan_files"
        :canDelete="false"
        @on-success="onUpload($event, FILE_TYPE.CORPORATE_SEAL)"
      />
    </v-ons-list>

    <v-ons-list class="mb-5">
      <v-ons-list-header />
      <v-ons-list-item modifier="nodivider">
        <v-ons-col width="30%">
          <label>{{ $t("app.stamp.stamp") }}</label>
        </v-ons-col>
        <v-ons-col style="margin-left: 10px"> {{ param.stampNo ? param.stampNo : "-" }}</v-ons-col>
      </v-ons-list-item>
      <form-uploader
        :label="$t('app.stamp.sign')"
        :sublabel="$t('app.stamp.sign_info')"
        :multiple="true"
        :documentType="FILE_TYPE.CORPORATE_SEAL_SIGNED"
        :existing="formObj.document_signed_files"
        :canDelete="false"
        @on-success="onUpload($event, FILE_TYPE.CORPORATE_SEAL_SIGNED)"
      />
    </v-ons-list>

    <br />
    <v-ons-list>
      <form-select-user :label="$t('app.forward_document')" :users="formObj.user_finances ? formObj.user_finances : []" :editable="false" />
    </v-ons-list>

    <bottom-submit @click.native="handleSubmit" />
  </div>
</template>

<script>
import _ from "lodash";
import ApplicationEdit from "@/mixins/application_edit";

export default {
  name: "CorporateSealEdit",
  mixins: [ApplicationEdit],
  watch: {
    formObj: {
      deep: true,
      handler(n) {
        //set form
        let form = {
          type_id: n.type_id ? n.type_id.id : null,
          purpose: n.purpose,
          stamped_party_full_name: n.stamped_party_full_name,
          stamped_doc_full_names: n.stamped_doc_full_names,
          contract_party_id: n.contract_party_id ? n.contract_party_id.id : null,
          stamp_type: n.stamp_type,
          where_to_sign_on: n.where_to_sign_on,
          notes: n.notes,
          document_plan_files: n.document_plan_files.length > 0 ? n.document_plan_files.map((item) => item.id) : [],
          document_signed_files: n.document_signed_files.length > 0 ? n.document_signed_files.map((item) => item.id) : [],
          estimated_approval_date: n.estimated_approval_date
        };
        this.form = Object.assign({}, form);
      }
    }
  },
  data() {
    return {
      local: {
        moduleCode: "corporate-seal"
      }
    };
  },
  methods: {
    setDefaultLocalData() {
      this.defaultForm = _.cloneDeep(this.formObj);
    },
    handleShow() {},
    handleEstimatedApprovalDeadline(val) {
      this.formObj.estimated_approval_date = val;
    },
    onUpload(file, type) {
      if (type == this.FILE_TYPE.CORPORATE_SEAL) {
        this.formObj.document_plan_files = file;
      }
      if (type == this.FILE_TYPE.CORPORATE_SEAL_SIGNED) {
        this.formObj.document_signed_files = file;
      }
    }
  }
};
</script>

<style></style>
