<template>
  <div class="no-result">
    <Icon :name="icon" classes="text-gray-300" size="82" />
    <span class="text-gray-500 mt-4">{{ $t("common.empty") }}</span>
  </div>
</template>

<script>
export default {
  name: "Empty",
  props: {
    icon: {
      type: String,
      default: "document-search"
    }
  }
};
</script>
