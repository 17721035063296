<template>
  <ons-bottom-toolbar class="fixed z-50">
    <div class="bg-white bottom-0 py-2 px-6 w-full flex justify-between items-center">
      <div class="text-sm text-blue-500">{{ $t("stat.inv.selected") }} : {{ selectedCount }}</div>
      <div
        class="px-3 py-2 rounded-md text-sm"
        :class="{
          'text-gray-600': selectedCount === 0,
          'bg-blue-500 text-white ': selectedCount > 0 && !submitting,
          'bg-blue-200 text-white': submitting
        }"
        @click="selectedCount > 0 ? $emit('tap') : ''"
      >
        {{ selectedCount > 0 ? $t("stat.inv.ok") + " (" + selectedCount + ")" : $t("stat.inv.select") }}
      </div>
    </div>
  </ons-bottom-toolbar>
</template>

<script>
export default {
  name: "invoiceAction",
  props: ["selectedCount", "submitting"]
};
</script>

<style scoped></style>
