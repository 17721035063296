<template>
  <div class="mb-3">
    <span class="border-b border-blue-500">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  name: "d-text"
};
</script>

<style scoped></style>
