import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import app from "./modules/app";
import user from "./modules/user";
import navigator from "./modules/navigator";
import splitter from "./modules/splitter";
import tabbar from "./modules/tabbar";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    user,
    navigator,
    splitter,
    tabbar
  },
  getters
});

export default store;
