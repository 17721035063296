import { home } from "@/locales/zh/home";
import { calendar } from "@/locales/zh/calendar";
import { admin } from "@/locales/zh/admin";
import { common } from "@/locales/zh/common";
import { app } from "@/locales/zh/application";
import { stat } from "@/locales/zh/statistic";

export const zh = {
  home: home,
  calendar: calendar,
  admin: admin,
  common: common,
  app: app,
  stat: stat
};
