<template>
  <v-ons-page @init="loadPage">
    <custom-toolbar v-bind="toolbarInfo" class="shadow-sm"></custom-toolbar>
    <div class="bg-white flex flex-col h-full justify-between">
      <div class="border-b-8 border-blue-600">
        <img :src="require('@/assets/images/bg1.png')" alt="allastarsea" style="height: 40vh;width: 100%;object-fit: cover;" />
      </div>
      <div class="md:w-96 px-10 m-auto mt-16 w-full">
        <div>
          <div class="border-b border-gray-400 flex items-center w-full">
            <span class="approval-icon bg-transparent">
              <Icon name="mail" classes="text-gray-400" size="24" />
            </span>
            <input v-model="form.email" type="text" class="px-3 py-2 rounded-md text-input h-12 w-full" placeholder="邮箱地址" />
          </div>
        </div>
        <div class="mt-8">
          <div class="bg-white bottom-0">
            <ons-button
              @click="handleSubmit"
              class="button rounded-md text-center w-full py-1.5"
              :class="{ 'bg-blue-200': loading, 'bg-blue-500': !loading }"
            >
              {{ loading ? "发电子邮件" : "重设密码" }}
            </ons-button>
          </div>
        </div>
      </div>
      <div class="px-4 text-left mb-5">
        <div style="font-size:0.6rem">
          <a href="https://beian.miit.gov.cn/" target="_blank" class="text-blue-500">
            沪公网备 31010502006668 号 <br />
            沪ICP备2023007206号-1
          </a>
          <span class="">ALLASTARSEA版权</span>
        </div>
        <div style="font-size:0.5rem" class="text-gray-500">
          <div>
            主办单位：星翌霜信息科技（上海）有限公司
          </div>
          <div>
            对外办公时间 周一至周五 地址 上海市，广东省珠海市，江苏省苏州市
          </div>
          <div>
            电话：86-2168413688（上海），86-7562991525（珠海），86-51268204329（苏州）
          </div>
          <div>
            建议使用IE9.0,1024×768以上分辨率浏览本网站
          </div>
        </div>
      </div>
    </div>
  </v-ons-page>
</template>

<script>
import { forgotPassword } from "@/api/security";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ResetPassword",
  data() {
    return {
      loading: false,
      form: {
        email: ""
      }
    };
  },
  validations: {
    form: {
      email: {
        required
      }
    }
  },
  methods: {
    loadPage() {},
    async isFormValid() {
      if (this.$v.form.email.$invalid) {
        await this.$ons.notification.alert("请输入邮箱地址");
        return false;
      }

      return true;
    },
    handleSubmit() {
      this.isFormValid().then((valid) => {
        if (valid) {
          this.loading = true;
          forgotPassword(this.form)
            .then(() => {
              this.$ons.notification.toast("发送！ 请查看你的邮箱", {
                timeout: 2000,
                animation: "fall"
              });
              this.loading = false;
              this.$store.commit("navigator/pop");
            })
            .catch(() => {
              this.$ons.notification.alert("邮箱地址无效");
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style scoped></style>
