<template>
  <v-ons-page>
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>

    <ons-list>
      <ons-list-header class="pt-4">{{ $t("app.col.select_one") }} </ons-list-header>
    </ons-list>
    <div class="space-y-3 mt-3">
      <ons-list>
        <form-select-icon
          :label="$t('home.menu.sales_registration')"
          :sublabel="$t('home.menu.sales_registration')"
          icon="file-plus-solid"
          iconClass="text-blue-500"
          @tap="push(page[0], $t('home.menu.sales_registration'), { module: 'collection-sales' })"
        />
      </ons-list>

      <ons-list>
        <form-select-icon
          :label="$t('app.col._report')"
          :sublabel="$t('app.col._report')"
          icon="file-check-solid"
          iconClass="text-green-500"
          @tap="push(page[0], $t('app.col._report'), { module: 'collection-report' })"
        />
      </ons-list>

      <ons-list>
        <form-select-icon
          :label="$t('app.col._debt')"
          :sublabel="$t('app.col._debt')"
          icon="file-exclamation-solid"
          iconClass="text-rose-500"
          @tap="push(page[0], $t('app.col._debt'), { module: 'collection-debt' })"
        />
      </ons-list>
    </div>
  </v-ons-page>
</template>

<script>
import Application from "@/views/Application/index";

export default {
  name: "CollectionReportIndex",
  data() {
    return {
      page: [Application]
    };
  },
  methods: {
    push(page, key, param) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style></style>
