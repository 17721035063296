<template>
  <v-ons-page @init="handleInit" @show="handleShow">
    <custom-toolbar v-bind="toolbarInfo" />
    <div class="mb-28">
      <Loading v-if="!ready" />
      <component
        v-else
        ref="edit_form"
        :is="toolbarInfo.param.module"
        :param="{ id: bufferId, form: form, formObj: map.formObj, history: history, title: toolbarInfo.title, stampNo: stampNo }"
      />
    </div>
  </v-ons-page>
</template>

<script>
import _ from "lodash";
//mixins
import ApplicationMap from "@/mixins/application_map";
//components
import reimbursement from "@/views/ApplicationEdit/Reimbursement";
import payment from "@/views/ApplicationEdit/Payment";
import purchase from "@/views/ApplicationEdit/Purchase";
import supplierContract from "@/views/ApplicationEdit/SupplierContract";
import customerContract from "@/views/ApplicationEdit/CustomerContract";
import corporateSeal from "@/views/ApplicationEdit/CorporateSeal";

export default {
  name: "ApplicationEdit",
  components: {
    reimbursement,
    payment,
    purchase,
    "contract-supplier": supplierContract,
    "contract-customer": customerContract,
    "corporate-seal": corporateSeal
  },
  mixins: [ApplicationMap],
  data() {
    return {
      bufferId: null,
      form: {},
      history: {}
    };
  },
  computed: {
    ready() {
      return !_.isEmpty(this.map.formObj, true);
    },
    stampNo() {
      return this.toolbarInfo.param.buffer.stamp_no ? this.toolbarInfo.param.buffer.stamp_no : "";
    }
  },
  methods: {
    handleShow() {
      if (this.$refs.edit_form) {
        this.$refs.edit_form.handleShow();
      }
    },
    handleInit() {
      let buffer = this.toolbarInfo.param.buffer ?? undefined;
      if (buffer == undefined) {
        return;
      }
      this.bufferId = buffer.id;
      this.form = Object.assign({}, buffer.data);
      this.populateForms(buffer.data);

      //history
      this.history = this.toolbarInfo.param.history ?? {};
    }
  }
};
</script>

<style scoped></style>
