export const common = {
  yes: "Yes",
  no: "No",
  back: "Back",
  cancel: "Cancel",
  add_more: "Add more",
  submit: "Submit",
  next: "Go to next",
  view_data: "View Data",
  process: "Process",
  applicant: "Applicant",
  //app
  currency: "Currency",
  select_currency: "Select currency",
  amount: "Amount",
  enter_amount: "Enter amount",
  total: "Total",
  date: "Date",
  select_date: "Select date",
  destination: "Destination",
  enter_destination: "Enter destination",
  notes: "Notes",
  enter_notes: "Enter notes",
  company: "Company",
  select_company: "Select company",
  department: "Department",
  select_department: "Select department",
  related_approval: "Related Approval",
  related_approval_info: "Related Approval Information",
  select: "Select",
  enter_number: "Enter number",
  content: "Content",
  //other
  select_finance: "Select finance",
  //payment
  bank_name: "Bank name",
  enter_bank_name: "Enter bank name",
  branch_name: "Branch name",
  enter_branch_name: "Enter branch name",
  colleague: "Colleague",
  search: "Search",
  filter: "Filter",
  change: "Change",
  reset: "Reset",
  sort: "Sort",
  ascending: "Ascending",
  descending: "Descending",
  greater_than: "Greater than",
  less_than: "Less than",
  equal_to: "Equal to",
  bypass: "By pass",
  remember_approvers: "Remember approvers",
  uploaded_at: "uploaded at",
  confirm: "Confirm",
  vendor: "Vendor",
  enter_vendor: "Enter vendor name",
  ok: "OK",
  empty: "No Item",
  add: "Add",
  save: "Save",
  select_languange: "Select a language",
  alert: {
    currency: "Please select currency",
    approver: "Please select approvers",
    company: "Please select company",
    department: "Please select department"
  },
  notif: {
    submit: "Application has been submitted",
    approve: "Application has been approved",
    reject: "Application has been rejected",
    alert: "Alert",
    confirm: "Confirm",
    decide: "Are you sure?",
    saved: "Saved",
    email: "Already sent reports to your e-mail box, and please check it"
  },
  create: {
    user: "Create user",
    company: "Create company",
    background: "Create group",
    reimbursement: "Create reimbursement",
    payment: "Create payment",
    purchase: "Create purchase",
    "collection-debt": "Create collection for debt",
    "collection-report": "Create collection report",
    "collection-sales": "Create Sales/Custom Info Registration",
    "contract-supplier": "Create supplier contract",
    "contract-customer": "Create customer contract",
    "corporate-seal": "Create corporate seal",
    "expense-account": "Create expense account",
    "bank-account": "Create bank account"
  },
  edit: {
    user: "Edit user",
    company: "Edit company",
    background: "Edit group",
    reimbursement: "Edit reimbursement",
    payment: "Edit payment",
    purchase: "Edit purchase",
    "collection-debt": "Edit collection for debt",
    "collection-report": "Edit collection report",
    "contract-supplier": "Edit supplier contract",
    "contract-customer": "Edit customer contract",
    "corporate-seal": "Edit corporate seal",
    "expense-account": "Edit expense account",
    "bank-account": "Edit bank account"
  },
  delete: {
    user: "Delete User",
    "bank-account": "Delete bank account",
    "expense-account": "Delete expense account"
  },
  waiting: "Waiting for {type}",
  app_code: "Application code",
  type: "Type",
  approve: "Approve",
  reject: "Reject",
  approved: "Completed",
  rejected: "Rejected",
  withdrawn: "Withdrawn",
  onprocess: "On process",
  detail: "Detail",
  requested_by: "Requested by ",
  company_authorizer: "Company Authorizer",
  sort_: {
    by: "Sort by",
    app_code: "Application code",
    created_at: "Created at",
    updated_at: "Updated at",
    ascending: "Ascending",
    descending: "Descending",
    reset: "Reset"
  },
  app_detail_: {
    more: "More",
    more_opt: "More options",
    submit_again: "Submit Again",
    cancel: "Cancel"
  }
};
