<template>
  <BottomSheet
    :title="data.title"
    :showing="data.show"
    @on-close="$emit('on-close')"
  >
    <div class="content p-4 text-sm">
      <SupplierContractItemGoods :data="data" v-if="data.code === 'goods'" />
      <SupplierContractItemServices :data="data" v-if="data.code === 'services'" />
      <SupplierContractItemHr :data="data" v-if="data.code === 'hr'" />
      <SupplierContractItemOther :data="data" v-if="data.code === 'other'" />
    </div>
  </BottomSheet>
</template>

<script>
import BottomSheet from "@/components/BottomSheet";
import SupplierContractItemGoods from "@/views/Approval/component/supplier_contract/SupplierContractItemGoods";
import SupplierContractItemServices from "@/views/Approval/component/supplier_contract/SupplierContractItemServices";
import SupplierContractItemHr from "@/views/Approval/component/supplier_contract/SupplierContractItemHr";
import SupplierContractItemOther from "@/views/Approval/component/supplier_contract/SupplierContractItemOther";

export default {
  name: "Detail",
  props: ["data"],
  components: {
    BottomSheet,
    SupplierContractItemGoods,
    SupplierContractItemServices,
    SupplierContractItemHr,
    SupplierContractItemOther
  }
};
</script>

<style>
</style>