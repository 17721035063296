import request from "@/utils/request";

export function submitDebt(data) {
  return request({
    url: "/buffer/collection-debt",
    method: "post",
    data: data
  });
}

export function submitReport(data) {
  return request({
    url: "/buffer/collection-report",
    method: "post",
    data: data
  });
}

export function submitSales(data) {
  return request({
    url: "/buffer/collection-sales",
    method: "post",
    data: data
  });
}

export function fetchEvidenceType(params) {
  return request({
    url: "/collection_sales_evidence_types",
    method: "GET",
    params: params
  });
}

export function fetchEvidenceTypeDetail(id) {
  return request({
    url: `/collection_sales_evidence_types/${id}`,
    method: "get"
  });
}
