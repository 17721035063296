import request from "@/utils/request";

export function fetchExternalCompanyData(params) {
  return request({
    url: "/companies-external",
    method: "get",
    params: params
  });
}

export function fetchCompanyData(params) {
  return request({
    url: "/companies",
    method: "get",
    params: params
  });
}

export function fetchCompanyBackground(params) {
  return request({
    url: "/companies/background",
    method: "get",
    params: params
  });
}

export function fetchCompanyDetail(company_id) {
  return request({
    url: `/companies/${company_id}`,
    method: "get"
  });
}

export function fetchCompanyDepartment(company_id) {
  return request({
    url: `/companies/${company_id}/departments`,
    method: "get"
  });
}

export function fetchCompanyLocation(company_id) {
  return request({
    url: `/companies/${company_id}/locations`,
    method: "get"
  });
}

export function fetchMyOrganization(params) {
  return request({
    url: "/my-organization",
    method: "get",
    params: params
  });
}

export function fetchUserPic(params) {
  return request({
    url: "/users/pic",
    method: "get",
    params: params
  });
}

export function submitData(data) {
  return request({
    url: "/buffer/companies",
    method: "post",
    data: data
  });
}

export function submitEdit(company_id, data) {
  return request({
    url: `/buffer/companies/${company_id}/edit`,
    method: "post",
    data: data
  });
}

export function fetchEmployees(company_id) {
  return request({
    url: `/${company_id}/employees`,
    method: "get"
  });
}
