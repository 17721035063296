<template>
  <v-ons-card v-if="data">
    <div class="content p-2 space-y-2">
      <d-text :label="$t('common.app_code')" :value="data.code" />
      <d-text :label="$t('app.col.related')" :value="relatedApproval ? relatedApproval.stamp_no : '-'" />
      <d-text :label="$t('app.col.customer')" :value="obj.customer_name" />
      <d-text :label="$t('app.col.debt.uncollected')" :value="currency + ' ' + Round(data.data.amount, currency)" />
      <d-text :label="$t('app.col.debt.payment_date')" :value="obj.payment_date ? moment(obj.payment_date).format('YYYY/MM/DD') : '-'" />

      <d-text :label="$t('app.col.debt.situation')" :value="obj.situation" />
      <d-text
        :label="$t('app.col.debt.estimated_date')"
        :value="obj.estimated_collection_date ? moment(obj.estimated_collection_date).format('YYYY/MM/DD') : '-'"
      />
      <hr />
      <d-list :label="$t('app.forward_finance')" :items="finances" itemKey="name" />
    </div>
  </v-ons-card>
</template>

<script>
import buffer from "@/mixins/buffer";
import { fetchRelatedContractDetail } from "@/api/related_approval";
import { fetchUserDetail as getUser } from "@/api/user";
import { Round } from "../../../../utils/number";

export default {
  name: "CollectionDebt",
  props: ["data"],
  mixins: [buffer],
  data() {
    return {
      currency: "",
      relatedApproval: null,
      finances: []
    };
  },
  computed: {
    obj() {
      return this.data.data;
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(n, o) {
        if (n != o) {
          this.fetchCurrency(this.data.data.currency_id);
          this.fetchContractDetail(this.obj.related_approval_id);
          if (this.obj.user_finances) {
            this.obj.user_finances.forEach((user) => {
              this.fetchUserDetail(user);
            });
          }
        }
      }
    }
  },
  methods: {
    Round,
    fetchContractDetail(id) {
      fetchRelatedContractDetail(id).then((response) => {
        this.relatedApproval = response;
      });
    },
    fetchUserDetail(id) {
      this.finances = [];
      getUser(id).then((response) => {
        this.finances.push(response);
      });
    }
  }
};
</script>

<style scoped></style>
