<template>
  <v-ons-card v-if="data">
    <div class="content p-2 space-y-2">
      <d-text :label="$t('common.app_code')" :value="data.code" />
      <d-text :label="$t('admin.user.name')" :value="data.info.name" />
      <d-text :label="$t('admin.user.company')" :value="data.info.company" />
      <d-text :label="$t('admin.user.department')" :value="data.info.department" />
      <d-text :label="$t('admin.user.location')" :value="data.info.location" />
      <d-text :label="$t('admin.user.join_date')" :value="joinDate" />
      <d-text :label="$t('admin.user.email')" :value="data.data.email" />
    </div>
    <div class="content p-2 space-y-2">
      <div class="bg-gray-100 p-2">{{ $t("admin.user.role") }}</div>
      <div class="pt-1 flex justify-between">
        <div class="font-16 text-gray-500 text-sm">{{ $t("admin.user.company_auth") }}</div>
        <div class="right">
          <v-ons-switch input-id="switch1" v-model="isAuthorizer" disabled="true"> </v-ons-switch>
        </div>
      </div>
      <div class="pt-1 flex justify-between">
        <div class="font-16 text-gray-500 text-sm">{{ $t("admin.user.company_finance") }}</div>
        <div class="right">
          <v-ons-switch input-id="switch1" v-model="isFinance" disabled="true"> </v-ons-switch>
        </div>
      </div>
      <div class="pt-1 flex justify-between">
        <div class="font-16 text-gray-500 text-sm">{{ $t("admin.user.company_treasury") }}</div>
        <div class="right">
          <v-ons-switch input-id="switch1" v-model="isTreasury" disabled="true"> </v-ons-switch>
        </div>
      </div>
      <div class="pt-1 flex justify-between">
        <div class="font-16 text-gray-500 text-sm">{{ $t("admin.user.company_legal") }}</div>
        <div class="right">
          <v-ons-switch input-id="switch1" v-model="isLegal" disabled="true"> </v-ons-switch>
        </div>
      </div>
    </div>
    <div class="content p-2 space-y-2">
      <div class="bg-gray-100 p-2">{{ $t("admin.user.setting") }}</div>
      <div class="px-2 space-y-2" v-for="(item, key) in settings" :key="key">
        <div class="flex justify-between items-center py-2 text-sm " :class="{ 'border-b border-gray-100': key < settings.length - 1 }">
          <div class="text-gray-500">{{ item.title }}</div>
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
  </v-ons-card>
</template>

<script>
import { fetchSettingType } from "@/api/setting_type";
import { fetchUserDetail } from "@/api/user";
import { isNumeric } from "@/utils/helper";

export default {
  name: "User",
  props: ["data"],
  data() {
    return {
      settings: []
    };
  },
  computed: {
    joinDate() {
      return this.data.data.join_date ? this.$moment(this.data.data.join_date.date).format("YYYY/MM/DD") : "";
    },
    isAuthorizer() {
      return this.data.data.is_authorizer;
    },
    isFinance() {
      return this.data.data.is_finance;
    },
    isTreasury() {
      return this.data.data.is_treasury;
    },
    isLegal() {
      return this.data.data.is_legal;
    },
    userSettings() {
      return this.data.data.user_settings;
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(n, o) {
        if (n != o) {
          this.loadSettingTypes();
        }
      }
    }
  },
  methods: {
    async loadSettingTypes() {
      await fetchSettingType().then((response) => {
        let data = response["hydra:member"].map((item) => ({
          id: item.id,
          title: item.name,
          name: ""
        }));

        if (this.userSettings) {
          this.userSettings.forEach((item) => {
            data.find((obj) => obj.id === item.setting_type_id).userId = item.approver_id;
          });
        }

        data.forEach((item) => {
          if (!isNumeric(item.userId)) {
            return;
          }

          fetchUserDetail(item.userId).then((response) => {
            item.name = response.name;
          });
        });

        this.settings = data;
      });
    }
  }
};
</script>

<style></style>
