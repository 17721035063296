<template>
  <div id="app">
    <AppNavigator />
  </div>
</template>

<script>
import AppNavigator from "./AppNavigator";

export default {
  components: {
    AppNavigator
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  }
};
</script>
