<template>
  <ons-list-item :modifier="modifier" tappable @click="$emit('tap')">
    <div class="left">
      <div class="approval-icon bg-transparent">
        <Icon :name="icon" :classes="iconClass" :size="iconSize" />
      </div>
    </div>
    <div class="center">
      <div class="list-item__title flex items-center">
        <div>{{ label }}</div>
        <div v-if="badge">
          <span class="border border-blue-500 ml-2 p-1 rounded-xl text-blue-500" style="font-size:0.5rem;"> {{ badge }}</span>
        </div>
      </div>
      <div class="list-item__subtitle text-xs mt-1">
        <div>{{ sublabel }}</div>
      </div>
    </div>
  </ons-list-item>
</template>

<script>
export default {
  name: "form-select-icon",
  props: {
    label: {
      type: String
    },
    sublabel: {
      type: String
    },
    badge: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: "chevron"
    },
    iconClass: {
      type: String,
      default: "text-gray-500"
    },
    iconSize: {
      type: Number,
      default: 32
    },
    modifier: {
      type: String,
      default: "chevron"
    }
  }
};
</script>

<style scoped></style>
