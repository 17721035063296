import request from "@/utils/request";

export function fetchList(params) {
  return request({
    url: "/expense_accounts",
    method: "get",
    params: params
  });
}

export function fetchAll(params) {
  return request({
    url: "/expense_accounts_all",
    method: "get",
    params: params
  });
}

export function createExpense(data) {
  return request({
    url: "/buffer/expense-account",
    method: "post",
    data: data
  });
}

export function submitEdit(id, data) {
  return request({
    url: "/buffer/expense-account/" + id + "/edit",
    method: "post",
    data: data
  });
}

export function submitDelete(id, data) {
  return request({
    url: "/buffer/expense-account/" + id + "/delete",
    method: "post",
    data: data
  });
}

export function fetchExpenseAccountDetail(id) {
  return request({
    url: `/expense_accounts/${id}`,
    method: "get"
  });
}
