import { render, staticRenderFns } from "./ProcessCard.vue?vue&type=template&id=a03d87fa&scoped=true&"
import script from "./ProcessCard.vue?vue&type=script&lang=js&"
export * from "./ProcessCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a03d87fa",
  null
  
)

export default component.exports