<template>
  <v-ons-page @show="showPage" @init="initPage">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <div>
      <v-ons-progress-bar indeterminate v-if="loading"></v-ons-progress-bar>
      <v-ons-list>
        <v-ons-list-header>
          <br />
          {{ $t("app.purc.goods_detail") }} ({{ index }})
        </v-ons-list-header>
        <!-- <form-select
          :required="true"
          :label="$t('common.department')"
          :placeholder="$t('common.select_department')"
          :value="selectedDepartment ? selectedDepartment.name : null"
          @click="
            push(page[0].component, page[0].label, {
              companyId: $store.getters.profile.company.id
            })
          "
        /> -->

        <form-calendar
          :required="true"
          :label="$t('app.purc.delivery_date')"
          mode="single"
          @on-change="handleDeliveryDateSelect"
          :placeholder="$t('common.select_date')"
          :value="form.delivery_date ? moment(form.delivery_date).format('YYYY-MM-DD') : null"
        />

        <form-input
          kind="text"
          :label="$t('app.purc.delivery_place')"
          :placeholder="$t('app.purc.enter_delivery_place')"
          v-model="form.delivery_place"
        />
      </v-ons-list>

      <br />
      <v-ons-list>
        <form-input
          :required="true"
          kind="text"
          :label="$t('app.purc.goods_name')"
          :placeholder="$t('app.purc.enter_goods_name')"
          v-model="form.goods_name"
        />

        <v-ons-list-item>
          <label class="center"> {{ $t("app.purc.recurrent") }} </label>
          <div class="right">
            <v-ons-switch v-model="form.is_recurrent"> </v-ons-switch>
          </div>
        </v-ons-list-item>

        <form-input kind="text" :label="$t('app.purc.type_brand')" :placeholder="$t('app.purc.enter_type_brand')" v-model="form.brand" />

        <form-input :required="true" :label="$t('app.quantity')" :placeholder="$t('app.enter_quantity')" v-model.number="form.qty" />

        <form-input :required="true" :label="$t('app.purc.budget_tax')" :placeholder="$t('app.purc.enter_budget')" v-model.number="form.budget" />

        <form-input
          :required="true"
          :label="$t('app.purc.estimated_unit_price')"
          :placeholder="$t('app.purc.estimated_unit_price')"
          v-model.number="form.estimated_unit_price"
        />

        <form-textarea :label="$t('common.notes')" :placeholder="$t('common.enter_notes')" v-model="form.notes" />
      </v-ons-list>
      <br />
      <v-ons-list>
        <form-uploader
          :label="$t('app.purc.upload_photo')"
          :sublabel="$t('app.purc.upload_photo_desc')"
          :multiple="true"
          :documentType="FILE_TYPE.PURCHASE"
          :existing="files[FILE_TYPE.PURCHASE]"
          @on-success="onUpload($event, FILE_TYPE.PURCHASE)"
        />
        <form-input kind="text" :label="$t('app.purc.source')" :placeholder="$t('app.purc.enter_source')" v-model="form.source" />
      </v-ons-list>
      <v-ons-list class="mb-16">
        <v-ons-list-item>
          <v-ons-col>
            <div>
              <span class="ml-2">{{ $t("app.purc.arrange_by") }}</span>
            </div>
          </v-ons-col>
          <v-ons-col>
            <select v-model="form.arrange_by" class="select-input">
              <option :value="null" disabled selected>
                {{ $t("common.select") }}
              </option>
              <option value="me">{{ $t("app.purc.by_me") }}</option>
              <option value="ga">{{ $t("app.purc.by_ga") }}</option>
            </select>
          </v-ons-col>
        </v-ons-list-item>
      </v-ons-list>

      <bottom-submit :label="$t('common.next')" @click.native="onSubmitPaymentDetail" classes="bg-blue-500" :loading="loading" />
    </div>
  </v-ons-page>
</template>

<script>
import { mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import DepartmentList from "@/views/common/list/DepartmentList";
import applicationDetail from "@/mixins/application_detail";
import { Round } from "../../../utils/number";

export default {
  name: "purchaseDetailForm",
  mixins: [applicationDetail],
  computed: {
    page() {
      return [
        {
          component: DepartmentList,
          label: this.$t("common.select_department")
        }
      ];
    },
    unitPrice() {
      return parseFloat(Round(this.form.budget / this.form.qty, this.selectedCurrency?.code));
    }
  },
  watch: {
    unitPrice: function(newVal) {
      if (newVal) {
        this.form.estimated_unit_price = parseFloat(newVal);
      }
    }
  },
  data: () => ({
    showBottomMenu: false,
    loading: true,
    index: null,
    selectedCurrency: null,
    // selectedDepartment: null,
    form: {
      department_id: null,
      delivery_date: null,
      delivery_place: null,
      goods_name: null,
      is_recurrent: false,
      brand: null,
      qty: null,
      budget: null,
      estimated_unit_price: 0,
      notes: "",
      source: "",
      arrange_by: null,
      purchase_goods_photo: [],
      //mbuh?
      is_approved: false,
      purchase_goods_approved: {
        approved_unit_price: 0,
        qty: 0
      }
    }
  }),
  validations: {
    form: {
      department_id: {
        required
      },
      delivery_date: {
        required
      },
      goods_name: {
        required
      },
      qty: {
        required
      },
      budget: {
        required
      }
    }
  },
  methods: {
    ...mapMutations("navigator", ["clearParam", "setParam", "pop"]),
    onExpenseChange() {
      this.contentForm.date = null;
      this.contentForm.destination = null;
    },
    addPaymentContent() {
      this.showBottomMenu = !this.showBottomMenu;
    },
    showPage() {
      // let param = this.$store.getters["navigator/getParam"];
      // let department = param?.selectedDepartment;
      // if (department) {
      //   this.selectedDepartment = department;
      //   this.form.department_id = this.selectedDepartment.id;
      // }
      this.clearParam();
    },
    initPage() {
      // this.form.company_id = this.$store.getters.profile.company.id;

      let param = this.toolbarInfo.param;

      let selectedCurrency = param.currency;
      if (selectedCurrency) {
        this.selectedCurrency = selectedCurrency;
      }

      let item = param.item;
      this.index = item.index;
      this.form.company_id = item.company_id;
      this.form.department_id = item.department_id;

      if (item.purchase_goods_photo) {
        item.purchase_goods_photo.forEach((obj) => {
          this.loadFile(this.FILE_TYPE.PURCHASE, obj);
        });
      }

      if (item.budget > 0) {
        this.form = item;
      }

      // if (item.department_id) {
      //   this.getDepartment(item.department_id, "selectedDepartment");
      // }

      this.loading = false;
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.department_id.$invalid) {
        await this.$ons.notification.alert(this.$t("common.alert.department"), options);
        return false;
      }
      if (this.$v.form.delivery_date.$invalid) {
        await this.$ons.notification.alert(this.$t("app.purc.alert.delivery_date"), options);
        return false;
      }
      if (this.$v.form.goods_name.$invalid) {
        await this.$ons.notification.alert(this.$t("app.purc.alert.goods"), options);
        return false;
      }
      if (this.$v.form.qty.$invalid) {
        await this.$ons.notification.alert(this.$t("app.purc.alert.quantity"), options);
        return false;
      }
      if (this.$v.form.budget.$invalid) {
        await this.$ons.notification.alert(this.$t("app.purc.alert.budget"), options);
        return false;
      }

      return true;
    },
    onSubmitPaymentDetail() {
      this.isFormValid().then((valid) => {
        if (valid) {
          let form = this.form;
          form.index = this.index;
          this.setParam({ purchaseDetail: form });
          this.pop();
        }
      });
    },
    handleDeliveryDateSelect(val) {
      this.form.delivery_date = val;
    },
    updateFormFile(type) {
      if (type === this.FILE_TYPE.PURCHASE) {
        this.form.purchase_goods_photo = this.files[this.FILE_TYPE.PURCHASE].map((file) => file.id);
      }
    },
    push(page, key, param) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped></style>
