<template>
  <div>
    <d-highlight
      v-if="estimatedApprovalDate"
      :label="$t('app.estimated_approval_date')"
      :value="estimatedApprovalDate ? moment(estimatedApprovalDate).format('YYYY/MM/DD') : '-'"
      :history="history ? ('estimated_approval_date' in history ? history.estimated_approval_date : null) : null"
    />
    <v-ons-card v-if="data">
      <div class="content p-2 space-y-2">
        <d-text :label="$t('common.app_code')" :value="data.code" />
        <!-- <d-text
        :label="$t('app.estimated_approval_date')"
        :value="obj.estimated_approval_date ? moment(obj.estimated_approval_date).format('DD/MM/YYYY') : '-'"
      /> -->
        <d-text :label="$t('app.stamp.usage')" :value="type ? type.name : '-'" />
        <!-- <d-text :label="$t('app.stamp.purpose')" :value="purposes" /> -->
        <div class="font-16 text-dark-gray">{{ $t("app.stamp.purpose") }}</div>
        <div class="flex flex-wrap">
          <div
            v-for="(item, key) in purposes"
            :key="'items_' + key"
            class="flex bg-blue-100 font-medium px-2 py-1 rounded-sm text-blue-700 text-xs border border-blue-300 mr-2 mb-2"
          >
            {{ $t(item) }}
          </div>
        </div>
        <d-text
          :label="$t('app.stamp.fullname')"
          :value="obj.stamped_party_full_name"
          :history="'stamped_party_full_name' in history ? history.stamped_party_full_name : null"
        />
        <d-text :label="$t('app.stamp.party')" :value="company" />
        <hr />
        <d-text
          v-for="(item, key) in obj.stamped_doc_full_names"
          :key="'doc_full_name_' + key"
          :label="$t('app.stamp.stamp_docs') + String.fromCharCode(9312 + key)"
          :value="item"
          :history="
            'stamped_doc_full_names' in history ? (history.stamped_doc_full_names.value.includes(item) ? history.stamped_doc_full_names : null) : null
          "
        />
        <hr />
        <d-text :label="$t('app.stamp.type')" :value="obj.stamp_type" :history="'stamp_type' in history ? history.stamp_type : null" />
        <d-text
          :label="$t('app.stamp.sign_on')"
          :value="obj.where_to_sign_on"
          :history="'where_to_sign_on' in history ? history.where_to_sign_on : null"
        />
        <d-text :label="$t('common.notes')" :value="obj.notes" :history="'notes' in history ? history.notes : null" />
        <hr />

        <form-uploader
          classes="p-0"
          :label="$t('app.stamp.plan')"
          :existing="contractPlanFiles"
          :disabled="true"
          :history="'document_plan_files' in history ? history.document_plan_files : null"
        />
        <hr />
        <d-text :label="$t('app.stamp.stamp')" :value="stamp" />

        <form-uploader
          classes="p-0"
          :label="$t('app.stamp.sign')"
          :existing="signedContractFiles"
          :disabled="true"
          :history="'document_signed_files' in history ? history.document_signed_files : null"
        />

        <hr />
        <d-list :label="$t('app.forward_finance')" :items="finances" itemKey="name" />

        <!-- approval -->
        <div class="pt-10 text-xs space-y-1 text-gray-500" v-if="isIncludeBorrowStamp">
          <div class="flex items-center ">
            <div class="bg-green checkbox mr-2">
              <Icon name="check" size="12" classes="text-white" />
            </div>
            <div>
              {{ $t("app.stamp.agreement.one") }}
            </div>
          </div>
          <div class="flex items-center">
            <div class="bg-green checkbox rounded-full mr-2">
              <Icon name="check" size="12" classes="text-white" />
            </div>
            <div>
              {{ $t("app.stamp.agreement.two") }}
            </div>
          </div>
          <div class="flex items-center">
            <div class="bg-green checkbox rounded-full mr-2">
              <Icon name="check" size="12" classes="text-white" />
            </div>
            <div>
              {{ $t("app.stamp.agreement.three") }}
            </div>
          </div>
        </div>
      </div>
      <weui-gallery :visible="previewShow" :src="previewImg" :canDelete="false" @onClick="previewShow = false"></weui-gallery>
    </v-ons-card>
  </div>
</template>

<script>
import { fetchDetail as getFile } from "@/api/file";
import { getDetail } from "@/api/corporate_seal";
import buffer from "@/mixins/buffer";
import history_detail from "@/mixins/history_detail";
import { fetchUserDetail as getUser } from "@/api/user";

export default {
  name: "CorporateSeal",
  props: ["data", "history"],
  mixins: [buffer, history_detail],
  data() {
    return {
      type: null,
      contractPlanFiles: [],
      signedContractFiles: [],
      finances: []
    };
  },
  computed: {
    obj() {
      return this.data?.data;
    },
    estimatedApprovalDate() {
      return this.obj?.estimated_approval_date;
    },
    stamp() {
      return this.data.stamp ? this.data.stamp : this.data.info.stamp_no;
    },
    purposes() {
      return this.obj.purpose ? this.obj.purpose.split(",") : "";
    },
    isIncludeBorrowStamp() {
      return this.purposes.includes("app.stamp.purposes.borrow_stamp");
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(n, o) {
        if (n != o) {
          this.fetchType(this.obj.type_id);
          this.fetchCompany(this.obj.contract_party_id);
          this.fetchPlanFiles();
          this.fetchSignedContractFiles();
          if (this.obj.user_finances) {
            this.obj.user_finances.forEach((user) => {
              this.fetchUserDetail(user);
            });
          }
        }
      }
    }
  },
  methods: {
    fetchType(id) {
      getDetail(id).then((response) => {
        this.type = response;
      });
    },
    fetchPlanFiles() {
      this.contractPlanFiles = [];
      this.obj.document_plan_files.forEach((item) => {
        getFile(item).then((f) => {
          this.contractPlanFiles.push({ ...f, size: f.size_string, is_edited: this.isFileEdited(f.id, "document_plan_files") });
        });
      });
    },
    fetchSignedContractFiles() {
      this.signedContractFiles = [];
      this.obj.document_signed_files.forEach((item) => {
        getFile(item).then((f) => {
          this.signedContractFiles.push({ ...f, size: f.size_string, is_edited: this.isFileEdited(f.id, "document_signed_files") });
        });
      });
    },
    fetchUserDetail(id) {
      this.finances = [];
      getUser(id).then((response) => {
        this.finances.push(response);
      });
    }
  }
};
</script>

<style>
.checkbox {
  height: 22px;
  width: 22px;
  border-radius: 22px;
  font-size: 10px;
  color: white;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
  border: 2px solid white;
}
</style>
