<template>
  <div class="bg-white flex items-center justify-center py-3">
    <v-ons-progress-circular
      class="h-4 w-4 my-4"
      indeterminate
      modifier="material"
    />
    <span class="text-xs ml-2">Loading..</span>
  </div>
</template>

<script>
export default {
  name: "LoadingSmall"
};
</script>

<style scoped></style>
