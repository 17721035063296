<template>
  <v-ons-page @init="handleInit">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <Uploading v-show="uploading" />
    <!-- <v-ons-list>
      <v-ons-list-header />
      <div class="p-4">
        <div class="text-sm text-blue-500">{{ $t("stat.inv.selected") }}: {{ selectedCount }} {{ $t("stat.inv.items") }}</div>
      </div>
    </v-ons-list> -->
    <v-ons-list>
      <v-ons-list-header />
      <form-uploader
        :label="$t('stat.inv.einvoice')"
        :sublabel="$t('stat.inv.einvoice_info')"
        :multiple="true"
        :documentType="FILE_TYPE.INVOICE"
        :existing="formFile.e_invoices"
        :canDelete="false"
        :onlyNew="true"
        @on-success="onUpload($event, FILE_TYPE.INVOICE)"
      />

      <form-uploader
        :label="$t('stat.inv.paper')"
        :sublabel="$t('stat.inv.paper_info')"
        :multiple="true"
        :documentType="FILE_TYPE.APP_PAPER"
        :existing="formFile.application_papers"
        :canDelete="false"
        :onlyNew="true"
        @on-success="onUpload($event, FILE_TYPE.APP_PAPER)"
      />
    </v-ons-list>

    <bottom-submit @click.native="handleSubmit" :loading="submitting" />
  </v-ons-page>
</template>

<script>
import { upload } from "@/api/invoice_management";
import { FILE_TYPE } from "@/utils/constant";
import _ from "lodash";

export default {
  name: "invoiceForm",
  data() {
    return {
      submitting: false,
      uploading: false,
      form: {
        transactions: [],
        e_invoices: [],
        application_papers: []
      },
      formFile: {
        e_invoices: [],
        application_papers: [],
        tmp_i: [],
        tmp_a: []
      },
      eInvoices: null,
      applicationPapers: null,
      FILE_TYPE: FILE_TYPE
    };
  },
  watch: {
    eInvoices(n) {
      if (n) {
        this.form.e_invoices = n.length > 0 ? n.map((item) => item.id) : [];
      }
    },
    applicationPapers(n) {
      if (n) {
        this.form.application_papers = n.length > 0 ? n.map((item) => item.id) : [];
      }
    }
  },
  methods: {
    async handleInit() {
      let param = this.toolbarInfo.param;

      if (param) {
        this.form.transactions = param.selectedEdit;

        this.formFile.e_invoices = param.eInvoices;
        this.formFile.application_papers = param.appPapers;
        this.formFile.tmp_i = _.cloneDeep(param.eInvoices);
        this.formFile.tmp_a = _.cloneDeep(param.appPapers);
      }
    },
    onUpload(file, type) {
      if (type == this.FILE_TYPE.INVOICE) {
        this.eInvoices = file;
        this.formFile.e_invoices = [...this.formFile.e_invoices, ...file];
      }
      if (type == this.FILE_TYPE.APP_PAPER) {
        this.applicationPapers = file;
        let a = this.formFile.tmp_a.concat(file);
        this.formFile.application_papers = a;
      }
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.form.e_invoices.length === 0 && this.form.application_papers.length === 0) {
        await this.$ons.notification.alert(this.$t("stat.inv.alert.upload"), options);
        return false;
      }

      return true;
    },
    handleSubmit() {
      this.submitting = true;
      this.isFormValid().then((valid) => {
        if (valid) {
          upload(this.form).then(() => {
            this.$ons.notification.toast(this.$t("common.notif.submit"), {
              timeout: 2000,
              animation: "fall"
            });
            this.$store.commit("navigator/popSubmit");
          });
        }
        this.submitting = false;
      });
    }
  }
};
</script>

<style scoped></style>
