<template>
  <v-ons-list-item>
    <label class="left">
      <v-ons-checkbox :input-id="'checkbox' + data.id" :value="data.id" @input="handleInput"> </v-ons-checkbox>
    </label>
    <label class="center " :for="'checkbox' + data.id">
      <div class="space-y-1 w-full text-xs">
        <div class="flex justify-between ">
          <div>
            {{ data.code ? data.code : " - " }}
            <span class="text-orange-500">
              {{ data.currency.code ? data.currency.code : " " }}
              {{ data.total ? Round(data.total, data.currency ? data.currency.code : null) : "0" }}
            </span>
          </div>
        </div>
        <div class="text-gray-500">
          <div class="flex space-x-2">
            <div>
              <span>
                {{ data.requester.name ? data.requester.name : " - " }}
              </span>
            </div>
            <div class="border-gray-300 border-l pl-2 text-gray-500">
              {{ data.created_at ? moment(data.created_at).format("YYYY/MM/DD") : "-" }}
            </div>
          </div>
        </div>
      </div>
    </label>
    <div class="right" v-if="showUpload !== false">
      <v-ons-button modifier="quiet" @click="$emit('onUpload', data)">
        <Icon name="upload" :classes="'icon list-item__thumbnail ' + hasFiles(data)" />
      </v-ons-button>
    </div>
  </v-ons-list-item>
</template>

<script>
import { Round } from "../../../../utils/number";

export default {
  name: "InvoiceSelect",
  props: ["data", "showUpload"],
  methods: {
    Round,
    handleInput(val) {
      this.$emit("input", val.target.value);
    },
    hasFiles(item) {
      return item.count_application_paper > 0 && item.count_einvoice > 0 ? "text-green-500" : "text-gray-500";
    }
  }
};
</script>

<style scoped></style>
