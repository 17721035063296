<template>
  <v-ons-page @init="onInit" @show="onShow" :key="componentKey">
    <custom-toolbar v-bind="toolbarInfo">
      <v-ons-toolbar-button slot="right" id="info-button" v-if="showTopEdit">
        <div
          class="justify-end flex px-6"
          v-if="editableModules.includes(moduleCode)"
          @click="push(page[0], toolbarInfo.title, { buffer: buffer, approval: data.id, module: moduleCode, history: history })"
        >
          <Icon name="edit" size="24" stroke="1.5" classes="text-gray-500" />
        </div>
      </v-ons-toolbar-button>
    </custom-toolbar>
    <div class="mb-16">
      <Loading v-show="loading" />
      <div class="card flat pb-3 px-4">
        <div class="capitalize card-title">
          {{ $t("common." + data.buffer.action + "['" + data.master_process.module.code + "']") }}
        </div>
        <div class="text-sm text-dark-gray">{{ $t("common.requested_by") }} {{ data.buffer.requester.name }}</div>
        <div class="text-xs mt-3">
          <StatusBadge :status="status" :process="process" />
        </div>
      </div>

      <component :is="moduleCode" :data="buffer" :history="history"></component>

      <process v-show="!loading" :data="processData" :requester="data.buffer.requester" />
    </div>
    <actions
      v-if="showAction"
      :process="data.master_process"
      :buffer="buffer"
      :code="toolbarInfo.title"
      :approval="data.id"
      :history="history"
      :canStepback="canStepback"
      :canSendNotif="!isLastApprover"
      :sendNotif="sendNotif"
      @approve="handleApprove"
      @reject="handleReject"
      @stepback="handleStepback"
    />
  </v-ons-page>
</template>

<script>
import Process from "./component/Process";
import { mapMutations } from "vuex";
import { fetchApprovalByBuffer, approve, fetchRememberNotification } from "@/api/approval";
import { fetchDetail } from "@/api/buffer";
import Action from "./Action";
import StatusBadge from "@/components/StatusBadge";
//application detail
import Company from "@/views/Approval/component/company/Company";
import User from "@/views/Approval/component/user/User";
import Background from "@/views/Approval/component/background/Background";
import Purchase from "@/views/Approval/component/purchase/Purchase";
import CollectionDebt from "@/views/Approval/component/collection_debt/index";
import CollectionReport from "@/views/Approval/component/collection_report/index";
import CollectionSales from "@/views/Approval/component/collection_sales/index";
import SupplierContract from "@/views/Approval/component/supplier_contract/SupplierContract";
import ExpenseAccount from "@/views/Approval/component/expense_account/ExpenseAccount";
import CustomerContract from "@/views/Approval/component/customer_contract/index";
import Reimbursement from "@/views/Approval/component/reimbursement/index";
import CorporateSeal from "@/views/Approval/component/corporate_seal/index";
import Payment from "@/views/Approval/component/payment/Payment.vue";
import bank_account from "@/views/Approval/component/bank_account/index";

//buffer history
import { getHistory } from "@/api/edit";
import { postStepback, postWithdraw } from "@/api/applicant";

//application edit
import ApplicationEdit from "@/views/ApplicationEdit";

export default {
  name: "Detail",
  data() {
    return {
      loading: true,
      approvals: undefined,
      buffer: undefined,
      processData: undefined,
      componentKey: 0,
      history: undefined,
      sendNotif: true,
      page: [ApplicationEdit],
      editableModules: ["reimbursement", "payment", "purchase", "contract-supplier", "contract-customer", "corporate-seal"]
    };
  },
  components: {
    "contract-customer": CustomerContract,
    "expense-account": ExpenseAccount,
    "contract-supplier": SupplierContract,
    "collection-report": CollectionReport,
    "collection-debt": CollectionDebt,
    "collection-sales": CollectionSales,
    "corporate-seal": CorporateSeal,
    company: Company,
    user: User,
    background: Background,
    purchase: Purchase,
    reimbursement: Reimbursement,
    payment: Payment,
    "bank-account": bank_account,
    process: Process,
    actions: Action,
    StatusBadge
  },
  computed: {
    data() {
      return this.toolbarInfo.data;
    },
    bufferData() {
      if (this.buffer) {
        return this.buffer.data;
      } else {
        return null;
      }
    },
    showAction() {
      return this.toolbarInfo.showAction ? this.toolbarInfo.showAction : false;
    },
    showTopEdit() {
      return this.toolbarInfo.showTopEdit ? this.toolbarInfo.showTopEdit : false;
    },
    moduleCode() {
      return this.toolbarInfo.data.master_process.module.code;
    },
    status() {
      if (this.buffer) {
        return this.data.buffer.status;
      } else {
        return null;
      }
    },
    process() {
      let text = this.data.buffer.last_approval ? this.data.buffer.last_approval.process.name : this.data.master_process.process.name;
      return this.status === 0 ? this.$t("common.waiting", { type: text }) : null;
    },
    canWithdraw() {
      if (!this.processData) {
        return false;
      }
      return !this.processData.filter((e) => e.status === 1).length > 0 && this.buffer.status == 0;
    },
    canStepback() {
      if (!this.processData) {
        return false;
      }
      return this.processData.filter((e) => e.status === 0).length > 0 && this.buffer.status == 0;
    },
    isLastApprover() {
      if (!this.processData) {
        return false;
      }
      return this.processData.filter((e) => e.status === 0).length === 1 && this.processData.filter((e) => e.id == this.data.id).length === 1;
    }
  },
  methods: {
    ...mapMutations("navigator", ["popSubmit"]),
    onShow() {
      if (this.$store.getters.isSubmit) {
        this.$store.commit("navigator/resetSubmit");
        this.componentKey += 1;
      }
    },
    onInit() {
      this.loading = true;
      fetchApprovalByBuffer(this.data.buffer.id).then((response) => {
        let processes = response["hydra:member"];
        let nextApprover = undefined;
        this.processData = processes;

        let current = processes.map((e) => e.id).indexOf(this.data.id);
        if (processes[current + 1]) {
          nextApprover = processes[current + 1];
        }

        if (nextApprover) {
          fetchRememberNotification(nextApprover.approver.id).then((response) => {
            this.sendNotif = response.is_notified;
          });
        }
      });
      fetchDetail(this.data.buffer.id).then((response) => {
        this.buffer = response;
      });

      getHistory(this.data.buffer.id)
        .then((response) => {
          this.history = response.merged_info;
        })
        .catch(() => {
          this.history = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleApprove(notification) {
      this.$ons.notification.confirm(this.$t("common.notif.decide"), { title: this.$t("common.approve") }).then((response) => {
        if (response === 1) {
          approve(this.data.id, {
            approve: true,
            send_email_push: notification,
            data: this.bufferData
          }).then(() => {
            this.$ons.notification.toast(this.$t("common.notif.approve"), {
              timeout: 2000,
              animation: "fall"
            });
            this.popSubmit();
          });
        }
      });
    },
    handleReject() {
      this.$ons.notification.confirm(this.$t("common.notif.decide"), { title: this.$t("common.reject") }).then((response) => {
        if (response === 1) {
          approve(this.data.id, {
            approve: false,
            data: this.bufferData
          }).then(() => {
            this.$ons.notification.toast(this.$t("common.notif.reject"), {
              timeout: 2000,
              animation: "fall"
            });
            this.popSubmit();
          });
        }
      });
    },
    handleStepback() {
      if (this.canWithdraw) {
        this.handleWithdraw();
        return;
      }

      this.$ons.notification.confirm(this.$t("app.stepback_alert"), { title: this.$t("app.stepback") }).then((response) => {
        if (response === 1) {
          postStepback({ buffer_id: this.buffer.id }).then(() => {
            this.popSubmit();
          });
        }
      });
    },
    handleWithdraw() {
      this.$ons.notification.confirm(this.$t("app.stepback_alert"), { title: this.$t("app.stepback") }).then((response) => {
        if (response === 1) {
          postWithdraw({ buffer_id: this.buffer.id }).then(() => {
            this.popSubmit();
          });
        }
      });
    },
    push(page, key, param) {
      this.$store.commit("navigator/options", {
        animation: "lift-ios"
      });

      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped></style>
