<template>
  <div class="space-y-6 mb-16">
    <v-ons-list>
      <form-textarea :label="$t('app.sc.item.content')" :placeholder="$t('app.sc.item.enter_content')" :required="true" v-model="form.content" />
    </v-ons-list>

    <v-ons-list>
      <form-input kind="text" :label="$t('app.sc.item.type1')" :placeholder="$t('app.sc.item.enter_type1')" v-model="form.type1" />
      <form-input kind="text" :label="$t('app.sc.item.type2')" :placeholder="$t('app.sc.item.enter_type2')" v-model="form.type2" />

      <v-ons-list-item class="center">
        <div class="list-item__title required">
          <span class="ml-1">{{ $t("app.sc.item.unit_price") }}</span>
        </div>
        <div class="list-item__subtitle">
          <div class="flex items-center">
            <span class="mr-4 text-orange-500"> ({{ currency ? currency.code : null }}) </span>

            <v-ons-input v-model.number="form.unit_price" type="number" float :placeholder="$t('app.sc.item.enter_unit_price')" />
          </div>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <v-ons-list>
      <form-calendar
        :label="$t('app.sc.item.start_date')"
        :placeholder="$t('common.select_date')"
        mode="single"
        @on-change="handleStartDateSelect"
        :required="true"
        :value="form.start_date"
      />

      <form-textarea :label="$t('app.sc.item.terms')" :placeholder="$t('app.sc.item.enter_terms')" v-model="form.term" />
    </v-ons-list>

    <v-ons-list>
      <form-textarea :label="$t('common.notes')" :placeholder="$t('common.enter_notes')" v-model="form.note" />
    </v-ons-list>

    <bottom-submit :label="$t('common.next')" @click.native="handleSubmit" classes="bg-blue-500" />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "services",
  props: ["param"],
  data() {
    return {
      form: {
        content: null,
        type1: "",
        type2: "",
        unit_price: null,
        start_date: null,
        term: "",
        note: ""
      }
    };
  },
  computed: {
    currency() {
      return this.param.currencyObj ? this.param.currencyObj : "-";
    },
    index() {
      return this.param.item ? this.param.item.index : null;
    }
  },
  created() {
    if (this.param.item.total > 0) {
      this.form = this.param.item;
    }
  },
  validations: {
    form: {
      content: {
        required
      },
      unit_price: {
        required
      },
      start_date: {
        required
      }
    }
  },
  methods: {
    handleStartDateSelect(val) {
      this.form.start_date = val;
    },
    handleSubmit() {
      this.isFormValid().then((valid) => {
        if (valid) {
          this.form.total = this.form.unit_price;
          this.form.amount = this.form.unit_price;
          this.form.index = this.index;

          this.$store.commit("navigator/setParam", { contractItems: this.form });
          this.$store.commit("navigator/pop");
        }
      });
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.content.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.item.content"), options);
        return false;
      }
      if (this.$v.form.unit_price.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.item.unit"), options);
        return false;
      }
      if (this.$v.form.start_date.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.item.date"), options);
        return false;
      }

      return true;
    }
  }
};
</script>

<style></style>
