<template>
  <div class="fixed inset-0 flex items-center justify-center" style="background-color:#fff;z-index:20001;">
    <div class="bg-white flex h-20 items-center justify-center w-20">
      <img src="@/assets/images/loading-box.svg" style="width: 58px !important" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Uploading"
};
</script>

<style></style>
