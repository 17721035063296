<template>
  <v-ons-action-sheet :visible="showing" @posthide="handleClose" cancelable class="action-sheet-form full-sheet">
    <div class="action-sheet-title bg-gray-100">
      <div class="left" @click="handleClose">
        <Icon name="close" classes="h-6 w-6" />
      </div>
      <div>{{ title }}</div>
    </div>

    <div style="overflow: auto;max-height: 75vh;">
      <slot />
    </div>
    <ons-row class="fixed bottom-0 border-t flex items-center justify-center py-4">
      <v-ons-button @click="handleOk" class="flex font-bold items-center justify-center rounded text-white w-10/12">
        <Icon name="document-search" classes="h-6 w-6 mr-2" /> {{ button }}
      </v-ons-button>
    </ons-row>
  </v-ons-action-sheet>
</template>

<script>
export default {
  name: "BottomSheetFull",
  props: {
    showing: Boolean,
    button: String,
    title: String
  },
  methods: {
    handleOk() {
      this.$emit("ok");
    },
    handleClose() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.full-sheet {
  z-index: 99 !important;
}
.full-sheet /deep/ .action-sheet {
  height: 100%;
}
.full-sheet /deep/ .action-sheet-title {
  border-radius: 0;
}
</style>
