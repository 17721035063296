import request from "@/utils/request";

export function fetchList(params) {
  return request({
    url: "/approvals/my",
    method: "get",
    params: params
  });
}

export function fetchApprovalByBuffer(id) {
  return request({
    url: `/approvals/${id}/buffer`,
    method: "get"
  });
}

export function approve(id, data) {
  return request({
    url: `/approvals/${id}/action`,
    method: "post",
    data: data
  });
}

export function fetchRememberNotification(next_user_id) {
  return request({
    url: `/remember_email_push/next_user/${next_user_id}`,
    method: "get"
  });
}
