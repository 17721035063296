<template>
  <v-ons-page @init="onInitPage">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>

    <v-ons-progress-bar indeterminate v-if="loading"></v-ons-progress-bar>

    <div class="mb-28">
      <v-ons-list>
        <v-ons-list-header />
        <form-input
          kind="text"
          :label="$t('admin.company.company')"
          :required="true"
          :placeholder="$t('admin.company.enter_company')"
          v-model="form.company.name"
        />
      </v-ons-list>
      <br />
      <v-ons-list>
        <v-ons-list-header>
          {{ $t("admin.company.location") }}
        </v-ons-list-header>
        <v-ons-list-item :modifier="md ? 'nodivider' : ''">
          <div class="list-item__title required">
            <span class="ml-1">{{ $t("admin.company.location") }}</span>
          </div>
          <span class="list-item__subtitle">
            <label class="center">
              <div v-for="(item, key) in form.company.locations" :key="key" style="position: relative">
                <v-ons-input type="textarea" float :placeholder="$t('admin.company.enter_location')" style="width: 100%" v-model="item.address">
                </v-ons-input>
                <span
                  style="
                  position: absolute;
                  right: 10px;
                  top: 65%;
                  transform: translateY(-50%);
                "
                  @click="removeLocation(key)"
                >
                  <Icon name="x" classes="icon list-item__thumbnail text-gray" />
                </span>
              </div>
            </label>
          </span>
        </v-ons-list-item>
      </v-ons-list>
      <v-ons-list>
        <v-ons-list-item :modifier="md ? 'nodivider' : ''">
          <div class="center" style="justify-content: center; padding: 6px">
            <v-ons-button modifier="quiet" style="justify-content: center" @click="addLocation">
              {{ $t("common.add_more") }}
            </v-ons-button>
          </div>
        </v-ons-list-item>
      </v-ons-list>

      <br />
      <v-ons-list>
        <v-ons-list-header>
          {{ $t("admin.company.department") }}
        </v-ons-list-header>
        <v-ons-list-item :modifier="md ? 'nodivider' : ''">
          <div class="list-item__title required">
            <span class="ml-1">{{ $t("admin.company.department") }}</span>
          </div>
          <span class="list-item__subtitle">
            <label class="center">
              <div v-for="(item, key) in form.company.departments" :key="key" style="position: relative">
                <v-ons-input type="textarea" float :placeholder="$t('admin.company.enter_department')" style="width: 100%" v-model="item.name">
                </v-ons-input>
                <span
                  style="
                  position: absolute;
                  right: 10px;
                  top: 65%;
                  transform: translateY(-50%);
                "
                  @click="removeDepartment(key)"
                >
                  <Icon name="x" classes="icon list-item__thumbnail text-gray" />
                </span>
              </div>
            </label>
          </span>
        </v-ons-list-item>
      </v-ons-list>
      <v-ons-list>
        <v-ons-list-item :modifier="md ? 'nodivider' : ''">
          <div class="center" style="justify-content: center; padding: 6px">
            <v-ons-button modifier="quiet" style="justify-content: center" @click="addDepartment">
              {{ $t("common.add_more") }}
            </v-ons-button>
          </div>
        </v-ons-list-item>
      </v-ons-list>

      <br />
      <process-card module-code="company">
        <hr />
        <v-ons-list class="bg-transparent mb-5">
          <v-ons-list-item>
            <label class="center">{{ $t("common.remember_approvers") }}</label>
            <div class="right">
              <v-ons-switch v-model="form.save_approvers"> </v-ons-switch>
            </div>
          </v-ons-list-item>
        </v-ons-list>
      </process-card>

      <bottom-submit @submit="handleSubmit" :loading="submitting">
        <template v-slot:additional>
          <div class="flex items-center p-2 space-x-4 w-full text-sm">
            <label class="left">
              <v-ons-checkbox :input-id="'checkbox-email'" v-model="form.send_notification_first_approval"> </v-ons-checkbox>
            </label>
            <label class="center" :for="'checkbox-email'"> {{ $t("app.send_notification_approver") }} </label>
          </div>
        </template>
      </bottom-submit>
    </div>
  </v-ons-page>
</template>

<script>
import { mapMutations } from "vuex";
import { submitData, submitEdit, fetchCompanyDetail } from "@/api/company";
import ProcessCard from "@/views/common/ProcessCard";
import { required } from "vuelidate/lib/validators";

import Admin from "@/mixins/admin";

export default {
  name: "CompanyForm",
  components: { ProcessCard },
  mixins: [Admin],
  data() {
    return {
      form: {
        company: {
          name: null,
          departments: [],
          locations: []
        },
        save_approvers: true,
        send_notification_first_approval: true,
        approvals: []
      },
      loading: true,
      submitting: false
    };
  },
  validations: {
    form: {
      company: {
        name: {
          required
        },
        departments: {
          $each: {
            name: {
              required
            }
          }
        },
        locations: {
          $each: {
            address: {
              required
            }
          }
        }
      }
    }
  },
  methods: {
    ...mapMutations("navigator", ["clearParam", "pop"]),
    onInitPage() {
      if (this.toolbarInfo.param !== undefined) {
        this.loadCompanyDetail(this.toolbarInfo.param.companyId);
      } else {
        this.form.company.departments = [{ name: "" }];
        this.form.company.locations = [{ address: "" }];
      }
      this.loading = false;
    },
    addLocation() {
      this.form.company.locations.push({ address: "" });
    },
    removeLocation(key) {
      this.form.company.locations = this.form.company.locations.filter((item, index) => index != key);
    },
    addDepartment() {
      this.form.company.departments.push({ name: "" });
    },
    removeDepartment(key) {
      this.form.company.departments = this.form.company.departments.filter((item, index) => index != key);
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.company.name.$invalid) {
        await this.$ons.notification.alert(this.$t("admin.company.enter_company"), options);
        return false;
      }
      if (this.$v.form.company.locations.$each.$invalid) {
        await this.$ons.notification.alert(this.$t("admin.company.enter_location"), options);
        return false;
      }
      if (this.$v.form.company.departments.$each.$invalid) {
        await this.$ons.notification.alert(this.$t("admin.company.enter_department"), options);
        return false;
      }
      if (!this.approverIsValid) {
        await this.$ons.notification.alert(this.$t("common.alert.approver"), options);
        return false;
      }

      return true;
    },
    handleSubmit() {
      this.isFormValid().then((valid) => {
        if (valid) {
          this.submitting = true;
          if (this.toolbarInfo.param !== undefined) {
            this.handleEditCompany();
          } else {
            this.handleCreateCompany();
          }
          this.submitting = false;
          this.pop();
        }
      });
    },
    async handleEditCompany() {
      await submitEdit(this.toolbarInfo.param.companyId, this.form).then((res) => {
        this.$ons.notification.alert({
          title: res.code ? res.code : this.$t("common.notif.saved"),
          messageHTML: ` ${this.$t("common.notif.submit")}`
        });
      });
    },
    async handleCreateCompany() {
      await submitData(this.form).then((res) => {
        this.$ons.notification.alert({
          title: res.code ? res.code : this.$t("common.notif.saved"),
          messageHTML: ` ${this.$t("common.notif.submit")}`
        });
      });
    },
    loadCompanyDetail(id) {
      fetchCompanyDetail(id).then((response) => {
        this.form.company.name = response.name;
        this.form.company.departments = response.departments.map((item) => ({
          id: item.id,
          name: item.name
        }));
        this.form.company.locations = response.locations.map((item) => ({
          id: item.id,
          address: item.address
        }));
      });
    },
    push(page, key, param) {
      this.$store.commit("navigator/options", {
        animation: "lift-ios"
      });
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped></style>
