<template>
  <div>
    <search-filter />

    <v-ons-list class="pt-14">
      <v-ons-list-item v-for="(item, key) in list" :key="key">
        <div class="left">
          <div class="approval-icon bg-transparent">
            <Icon name="circle" classes="text-gray-300" :size="32" />
          </div>
        </div>

        <div class="center" @click="handleSelect(item)">
          <span class="list-item__title">
            {{ item.code ? item.code : " - " }}
          </span>
          <span class="list-item__subtitle">{{ item.currency_code + " " + item.total_estimated }}</span>
        </div>

        <div class="right" @click="handleShowInfo(item)">
          <v-ons-button modifier="quiet">
            <Icon name="info" classes="icon list-item__thumbnail" />
          </v-ons-button>
        </div>
      </v-ons-list-item>
    </v-ons-list>
  </div>
</template>

<script>
import SearchFilter from "@/components/SearchFilter";
import { fetchRelatedReimbursement as fetchList } from "@/api/related_approval";
import { fetchDetail } from "@/api/purchase";

export default {
  name: "RelatedPurchases",
  components: { SearchFilter },
  data() {
    return {
      list: [],
      showInfo: false
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      await fetchList().then((response) => {
        this.list = response["hydra:member"];
      });
    },
    handleShowInfo(item) {
      this.getDetail(item).then((obj) => {
        this.$emit("detail", item, obj);
      });
    },
    handleSelect(item) {
      this.getDetail(item).then((obj) => {
        this.$emit("select", item, obj);
      });
    },
    async getDetail(item) {
      let obj = [];
      let currency = item.currency_code;
      await fetchDetail(item.id).then((response) => {
        let detail = response.purchase_goods;

        obj = detail.map((item) => ({
          currency: currency,
          item_id: item.id,
          edited: false,
          goods_name: item.goods_name,
          budget: parseInt(item.budget),
          unit_price: parseInt(item.estimated_unit_price)
        }));
      });

      return obj;
    }
  }
};
</script>

<style></style>
