<template>
  <div>
    <v-ons-list-item>
      <div class="left" />
      <div class="center">
        <div class="list-item__title" :class="{ required: required }">&nbsp; {{ label }}</div>
        <div class="list-item__subtitle items-center">
          <span v-if="items === undefined || items.length == 0">{{ placeholder }}</span>
          <div class="flex flex-wrap" v-else>
            <div
              v-for="(item, key) in items"
              :key="'items_' + key"
              class="flex bg-blue-100 font-medium px-2 py-1 rounded-sm text-blue-700 text-xs border border-blue-300 mr-2 mt-2"
            >
              {{ $t(item) }}
              <Icon v-if="allowModify" name="close" size="14" classes="ml-2" @click.native="handleRemove(item)" />
            </div>
          </div>
        </div>
      </div>
      <div class="right items-start p-0" v-if="allowModify">
        <ons-button modifier="quiet" @click="handleClick()">
          <Icon name="plus-circle" size="28" stroke="1" classes="text-blue" />
        </ons-button>
      </div>
    </v-ons-list-item>
  </div>
</template>

<script>
export default {
  name: "form-multipleselect",
  props: {
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array
    },
    allowModify: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleClick() {
      this.$emit("on-click");
    },
    handleRemove(item) {
      this.$emit("on-remove", item);
    }
  }
};
</script>

<style scoped></style>
