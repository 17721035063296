<template>
  <div>
    <Loading v-show="loading" />
    <ons-list>
      <v-ons-list-header />
      <form-select
        :label="$t('common.currency')"
        @click="cshow.currency = true"
        :required="true"
        :placeholder="$t('common.select_currency')"
        :value="common.currency ? common.currency.code : null"
      />

      <form-select
        :required="true"
        :label="$t('common.company')"
        @click="onClickComponent(COMPONENT.COMPANY)"
        :placeholder="$t('common.select_company')"
        :value="component.company ? component.company.name : null"
      />

      <form-select
        :required="true"
        v-if="component.company !== null"
        :label="$t('common.department')"
        @click="onClickComponent(COMPONENT.DEPARTMENT, { companyId: component.company.id })"
        :placeholder="$t('common.select_department')"
        :value="component.department ? component.department.name : null"
      />

      <form-calendar
        :required="true"
        :label="$t('app.pay.estimated_payment_date')"
        mode="single"
        @on-change="handleEstimatedPaymentSelect"
        :placeholder="$t('common.select')"
        :value="tmp.estimatedPaymentDate ? moment(tmp.estimatedPaymentDate).format('YYYY-MM-DD') : ''"
      />

      <form-select
        :label="$t('common.related_approval')"
        @click="onClickComponent(COMPONENT.RELATED_APPROVAL, { moduleCode: moduleCode })"
        :placeholder="$t('common.select')"
        :value="component.related_approval ? component.related_approval.code : null"
      />
    </ons-list>

    <ons-list v-show="common.currency" class="mt-6">
      <v-ons-list-header> {{ $t("app.pay.payment") }} </v-ons-list-header>
      <form-select
        v-for="(item, key) in payments"
        :key="key"
        :label="$t('app.pay.payment') + ' (' + item.index + ')'"
        @click="paymentDetailClick(item)"
        :value="(common.currency ? common.currency.code : '') + ' ' + Round(item.total, common.currency ? common.currency.code : null)"
      />

      <v-ons-list-item>
        <div class="center" style="font-size: 13px; color: rgba(0, 0, 0, 0.6)">
          {{ $t("app.pay.add_more_info") }}
        </div>
      </v-ons-list-item>
    </ons-list>
    <v-ons-list v-show="common.currency">
      <v-ons-list-item class="p-0">
        <div class="center flex justify-center items-center p-0">
          <v-ons-button modifier="quiet" style="justify-content: center" @click="addPayment">
            <ons-icon icon="md-plus"> </ons-icon>
            {{ $t("common.add_more") }}
          </v-ons-button>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <br />
    <v-ons-list class="border-b border ">
      <v-ons-list-item>
        <div class="left">{{ $t("common.total") }}</div>
        <div class="center text-xl" style="margin-left: 10px">
          {{ common.currency ? common.currency.code : "" }}
          <span class="text-orange ml-2 font-bold">
            {{ Round(form.transaction.total, common.currency ? common.currency.code : null) }}
          </span>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <br />
    <div style="color: #828282; padding: 5px 15px; text-align: left">
      <span style="font-size: 12px">
        {{ $t("app.pay.e_invoice_info", { number: nextCode }) }}
      </span>
    </div>

    <ons-list>
      <form-uploader
        :disabled="form.transaction.transaction_items.length === 0"
        :label="$t('app.pay.e_invoice')"
        :sublabel="$t('app.pay.e_invoice_sublabel')"
        :multiple="true"
        :documentType="FILE_TYPE.INVOICE"
        :existing="this.files[FILE_TYPE.INVOICE]"
        @uploadedItem="handleUploadedItem($event, FILE_TYPE.INVOICE)"
        @deleted="handleFileLinkSelectionDelete"
        @on-success="onUpload($event, FILE_TYPE.INVOICE)"
      />
    </ons-list>

    <ons-list>
      <form-uploader
        :disabled="form.transaction.transaction_items.length === 0"
        :label="$t('app.pay.application_paper')"
        :sublabel="$t('app.pay.application_paper_sublabel')"
        :multiple="true"
        :documentType="FILE_TYPE.APP_PAPER"
        :existing="this.files[FILE_TYPE.APP_PAPER]"
        @uploadedItem="handleUploadedItem($event, FILE_TYPE.APP_PAPER)"
        @deleted="handleFileLinkSelectionDelete"
        @on-success="onUpload($event, FILE_TYPE.APP_PAPER)"
      />
    </ons-list>
    <br />
    <v-ons-list>
      <v-ons-list-header> {{ $t("app.invoice_later") }} </v-ons-list-header>
      <form-input
        :label="$t('common.amount') + (common.currency ? '(' + common.currency.code + ')' : '')"
        :placeholder="$t('common.enter_amount')"
        v-model.number="form.transaction.invoice_amount"
      />
      <form-textarea :label="$t('app.pay.supplier_name')" :placeholder="$t('app.pay.enter_supplier_name')" v-model="form.transaction.invoice_note" />
    </v-ons-list>

    <!-- hide input by treasury in application -->
    <!-- <br />
    <v-ons-list>
      <v-ons-list-header> {{ $t("app.input_by_treasury") }} </v-ons-list-header>
      <form-select
        :label="$t('app.payment_account')"
        :placeholder="$t('app.select_payment_account')"
        @click="push(page[1].component, page[1].label)"
        :value="selectedAccount ? selectedAccount.bank_name + ' - ' + selectedAccount.branch : null"
      />

      <form-calendar
        :label="$t('app.payment_date')"
        mode="single"
        @on-change="handlePaymentDateSelect"
        :placeholder="$t('common.select')"
        :value="form.transaction.payment_date"
      />

      <form-upload
        :label="$t('app.deal_slip')"
        :sublabel="$t('app.deal_slip_sublabel')"
        documentType="deal-slip"
        :limit="1"
        @on-uploading="uploading = true"
        @on-success="handleUploadDealSlip"
      />
    </v-ons-list> -->
    <br />
    <v-ons-card class="flat p-0">
      <v-ons-list>
        <v-ons-list-header>
          {{ $t("app.pay.tax") }}
        </v-ons-list-header>
        <form-textarea :label="$t('app.pay.tax2')" :placeholder="$t('app.pay.enter_tax')" v-model="form.transaction.note" />
      </v-ons-list>
    </v-ons-card>

    <br />
    <v-ons-list>
      <form-select-user
        :label="$t('app.forward_document')"
        :users="component.finance ? component.finance : []"
        @remove="handleRemoveFinance"
        @add="onClickComponent(COMPONENT.FINANCE, { default: component.finance })"
      />
    </v-ons-list>
    <!--    </v-ons-card>-->

    <br />
    <v-ons-card class="flat p-0">
      <v-ons-list class="bg-transparent mb-5">
        <v-ons-list-item>
          <label class="center">{{ $t("common.bypass") }}</label>
          <div class="right">
            <v-ons-switch v-model="form.bypass"> </v-ons-switch>
          </div>
        </v-ons-list-item>
      </v-ons-list>
    </v-ons-card>

    <process-card :module-code="moduleCode" v-if="!form.bypass">
      <hr />
      <v-ons-list class="bg-transparent mb-5">
        <v-ons-list-item>
          <label class="center">{{ $t("common.remember_approvers") }}</label>
          <div class="right">
            <v-ons-switch v-model="form.save_approvers"> </v-ons-switch>
          </div>
        </v-ons-list-item>
      </v-ons-list>
    </process-card>

    <bottom-submit @submit="handleSubmit" :loading="submitting">
      <template v-slot:additional>
        <div class="flex items-center p-2 space-x-4 w-full text-sm" v-if="!form.bypass">
          <label class="left">
            <v-ons-checkbox :input-id="'checkbox-email'" v-model="form.send_notification_first_approval"> </v-ons-checkbox>
          </label>
          <label class="center" :for="'checkbox-email'"> {{ $t("app.send_notification_approver") }} </label>
        </div>
      </template>
    </bottom-submit>

    <FileLinkSelection
      :show="showFileLinkSelection"
      :item="form.transaction.transaction_items"
      :currency="common.currency"
      @close="showFileLinkSelection = false"
      @confirm="handleFileLinkSelectionConfirm"
    />
    <currency-select :showing="cshow.currency" @on-close="cshow.currency = false" @on-select="onCommonSelect($event, COMMON.CURRENCY)" />
  </div>
</template>

<script>
import ProcessCard from "@/views/common/ProcessCard";
import { required } from "vuelidate/lib/validators";
import { submitPaymentBuffer } from "@/api/payment";
import CurrencySelect from "@/views/common/select/CurrencySelect";
import application from "@/mixins/application";
import FileLinkSelection from "@/views/Application/Payment/component/FileLinkSelection";

const defaultForm = {
  currency_id: null,
  total: 0,
  note: "",
  e_invoices: [],
  application_papers: [],
  invoice_amount: null,
  invoice_note: "",
  account_id: null,
  payment_date: null,
  deal_slips: [],
  user_finances: [],
  transaction_items: []
};

const defaultItem = [
  {
    index: 1,
    total: 0,
    company_id: null,
    department_id: null,
    related_approval_id: null,
    related_items: [],
    estimated_payment_date: null,
    is_drawdown: false
  }
];

export default {
  name: "PaymentForm",
  components: {
    CurrencySelect,
    ProcessCard,
    FileLinkSelection
  },
  mixins: [application],
  props: ["show", "init", "param"],
  watch: {
    show: function(newVal) {
      if (newVal) {
        this.onShow();
      }
    },
    init: function(newVal) {
      if (newVal) {
        this.onInit();
      }
    },
    payments: function(newVal) {
      if (newVal) {
        this.form.transaction.total = newVal.reduce((sum, obj) => sum + obj.total, 0);
      }
    },
    "component.company": function(n) {
      if (n) {
        this.payments = this.payments.map((item) => ({ ...item, company_id: n.id }));
        this.form.transaction.transaction_items = this.payments.filter((item) => item.total > 0);
      }
    },
    "component.department": function(n) {
      if (n) {
        this.payments = this.payments.map((item) => ({ ...item, department_id: n.id }));
        this.form.transaction.transaction_items = this.payments.filter((item) => item.total > 0);
      }
    },
    "tmp.estimatedPaymentDate": function(n) {
      if (n) {
        this.payments = this.payments.map((item) => ({ ...item, payment_information: { ...item.payment_information, estimated_payment_date: n } }));
        this.form.transaction.transaction_items = this.payments.filter((item) => item.total > 0);
      }
    },
    "common.currency": function(n) {
      if (n) {
        this.form.transaction.currency_id = n.id;
      }
    },
    "component.finance": function(n) {
      if (n) {
        this.form.transaction.user_finances = n.map((item) => item.id);
      }
    },
    "form.bypass": function(n) {
      this.form.send_notification_first_approval = !n;
    }
  },
  computed: {
    companyId() {
      return this.component.company ? this.component.company.id : null;
    },
    departmentId() {
      return this.component.department ? this.component.department.id : null;
    },
    relatedApprovalId() {
      return this.component.related_approval ? this.component.related_approval.id : null;
    },
    relatedApprovalItems() {
      let items = [];
      if (this.component.related_approval) {
        items = this.component.related_approval.data.map((item) => ({
          item_id: item.item_id,
          is_edited: false,
          data: item
        }));
      }

      return items;
    },
    relatedApprovalAccountInfo() {
      let info = null;
      if (this.component.related_approval) {
        info = this.component.related_approval.supplier_account;
      }

      return info;
    }
  },
  data: () => ({
    showFileLinkSelection: false,
    fileBuffer: null,
    moduleCode: "payment",
    tempMaster: ["form", "payments", "common", "component", "files", "tmp"],
    loading: true,
    submitting: false,
    form: {
      bypass: false,
      send_notification_first_approval: true,
      transaction: Object.assign({}, defaultForm),
      save_approvers: true,
      approvals: []
    },
    payments: defaultItem,
    tmp: { estimatedPaymentDate: null }
  }),
  validations: {
    common: {
      currency: {
        required
      }
    }
  },
  methods: {
    onShow() {
      let param = this.$store.getters["navigator/getParam"];
      let paymentDetail = param?.paymentDetail;
      let selectedAccount = param.selectedAccount;
      let selectedFinance = param.selectedFinance;
      let selectedCompany = param.selectedContractParty;
      let selectedDepartment = param.selectedDepartment;
      let selectedApproval = param.selectedApproval;

      if (paymentDetail) {
        this.payments = this.payments.map((obj) => {
          if (obj.index === paymentDetail.index) {
            return { ...paymentDetail };
          } else {
            return obj;
          }
        });

        this.form.transaction.transaction_items = this.payments.filter((item) => item.total > 0);
      }
      if (selectedAccount) {
        this.component[this.COMPONENT.BANK_ACCOUNT] = selectedAccount;
        this.form.transaction.account_id = selectedAccount.id;
      }
      if (selectedFinance) {
        this.component[this.COMPONENT.FINANCE] = selectedFinance;
        this.form.transaction.user_finances = selectedFinance.map((item) => item.id);
      }

      if (selectedCompany) {
        this.component[this.COMPONENT.COMPANY] = selectedCompany;
        this.component[this.COMPONENT.DEPARTMENT] = null;
      }
      if (selectedDepartment) {
        this.component[this.COMPONENT.DEPARTMENT] = selectedDepartment;
      }
      if (selectedApproval) {
        this.component[this.COMPONENT.RELATED_APPROVAL] = selectedApproval;
        this.payments = this.payments.map((item) => ({
          ...item,
          related_approval_id: selectedApproval.id,
          related_items: selectedApproval.data.map((item) => ({
            item_id: item.item_id,
            is_edited: false,
            data: item
          })),
          related_supplier_account: selectedApproval.supplier_account
        }));
      }

      this.$store.commit("navigator/clearParam");
      this.$emit("reloaded");
      this.loading = false;
    },
    addPayment() {
      this.payments = [
        ...this.payments,
        {
          index: this.payments.length + 1,
          total: 0,
          company_id: this.companyId,
          department_id: this.departmentId,
          related_approval_id: this.relatedApprovalId,
          related_items: this.relatedApprovalItems,
          related_supplier_account: this.relatedApprovalAccountInfo,
          estimated_payment_date: this.tmp.estimatedPaymentDate
        }
      ];
    },
    handleSubmit() {
      this.isFormValid().then((valid) => {
        if (valid) {
          this.submitting = true;
          submitPaymentBuffer(this.form)
            .then((res) => {
              this.$ons.notification.alert({
                title: res.code ? res.code : this.$t("common.notif.saved"),
                messageHTML: ` ${this.$t("common.notif.submit")}`
              });
              this.component[this.COMPONENT.RELATED_APPROVAL] = null;
              this.populateDefaultTemp();
              this.$store.commit("navigator/pop");
            })
            .then(() => {
              this.submitting = false;
            });
        }
      });
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.common.currency.$invalid) {
        await this.$ons.notification.alert(this.$t("common.alert.currency"), options);
        return false;
      }

      if (null == this.companyId) {
        await this.$ons.notification.alert(this.$t("common.alert.company"), options);
        return;
      }
      if (null == this.departmentId) {
        await this.$ons.notification.alert(this.$t("common.alert.department"), options);
        return;
      }

      let cPaymentDate = this.form.transaction.transaction_items.filter((item) => item.payment_information.estimated_payment_date == null);
      if (cPaymentDate.length > 0) {
        await this.$ons.notification.alert(this.$t("app.pay.alert.date"), options);
        return false;
      }

      if (this.payments[0].total === 0) {
        await this.$ons.notification.alert(this.$t("app.pay.alert.item"), options);
        return false;
      }
      if (!this.form.bypass) {
        if (!this.approverIsValid) {
          await this.$ons.notification.alert(this.$t("common.alert.approver"), options);
          return false;
        }
      }

      return true;
    },
    handlePaymentDateSelect(val) {
      this.form.transaction.payment_date = val;
    },
    updateFormFile(type) {
      if (type === this.FILE_TYPE.INVOICE) {
        this.form.transaction.e_invoices = this.files[this.FILE_TYPE.INVOICE].map((file) => file.id);
      }
      if (type === this.FILE_TYPE.APP_PAPER) {
        this.form.transaction.application_papers = this.files[this.FILE_TYPE.APP_PAPER].map((file) => file.id);
      }
      if (type === this.FILE_TYPE.DEAL_SLIP) {
        this.form.transaction.deal_slips = this.files[this.FILE_TYPE.DEAL_SLIP].map((file) => file.id);
      }
    },
    updateFormCommon(type) {
      if (type === this.COMMON.CURRENCY) {
        this.form.transaction.currency_id = this.common.currency.id;
      }
    },
    paymentDetailClick(item) {
      let options = { title: this.$t("common.notif.alert") };

      if (null == item.company_id) {
        this.$ons.notification.alert(this.$t("common.alert.company"), options);
        return;
      }
      if (null == item.department_id) {
        this.$ons.notification.alert(this.$t("common.alert.department"), options);
        return;
      }

      this.onClickDetail(item);
    },
    loadData() {
      if (this.form.transaction.currency_id) {
        this.loadApi(this.COMMON.CURRENCY, this.form.transaction.currency_id);
      }
      if (this.form.transaction.user_finances) {
        this.form.transaction.user_finances.forEach((item) => {
          this.loadApi(this.COMPONENT.FINANCE, item);
        });
      }
      if (this.form.transaction.e_invoices) {
        this.form.transaction.e_invoices.forEach((item) => {
          this.loadFile(this.FILE_TYPE.INVOICE, item);
        });
      }
      if (this.form.transaction.application_papers) {
        this.form.transaction.application_papers.forEach((item) => {
          this.loadFile(this.FILE_TYPE.APP_PAPER, item);
        });
      }

      this.form.transaction.account_id = null;
      this.form.transaction.payment_date = null;
      this.form.transaction.deal_slips = [];

      if (this.form.transaction.transaction_items) {
        this.component[this.COMPONENT.COMPANY] = this.loadApi(this.COMPONENT.COMPANY, this.form.transaction.transaction_items[0].company_id);
        this.component[this.COMPONENT.DEPARTMENT] = this.loadApi(this.COMPONENT.DEPARTMENT, this.form.transaction.transaction_items[0].department_id);
        this.component[this.COMPONENT.RELATED_APPROVAL] = this.loadRelatedApproval(
          this.moduleCode,
          this.form.transaction.transaction_items[0].related_approval_id
        );
        this.tmp.estimatedPaymentDate = this.form.transaction.transaction_items[0].payment_information.estimated_payment_date;

        this.payments = this.form.transaction.transaction_items.map((obj, index) => {
          let total = 0;
          if (obj.contents.length > 1) {
            total = obj.contents.reduce(function(p, c) {
              return p.amount + c.amount;
            });
          } else {
            total = obj.contents[0].amount;
          }

          return {
            ...obj,
            contents: obj.contents.map((item) => ({
              ...item,
              date: this.moment(obj.date).format("YYYY-MM-DD")
            })),
            payment_information: {
              month: obj.payment_information.month,
              estimated_payment_date: this.moment(obj.payment_information.estimated_payment_date).format("YYYY-MM-DD")
            },
            index: index + 1,
            total: total
          };
        });
      }
    },
    handleReset() {
      this.$ons.notification.confirm(this.$t("common.notif.decide"), { title: this.$t("common.notif.confirm") }).then((response) => {
        if (response === 1) {
          this.form.transaction = Object.assign({}, defaultForm);
          this.payments = defaultItem;
          this.files = {};
          this.component[this.COMPONENT.FINANCE] = null;
          this.component[this.COMPONENT.RELATED_APPROVAL] = null;
          this.component[this.COMPONENT.COMPANY] = null;
          this.component[this.COMPONENT.DEPARTMENT] = null;
          this.tmp = { estimatedPaymentDate: null };

          if (this.common.currency) {
            this.form.transaction.currency_id = this.common.currency.id;
          }

          if (this.companyId) {
            this.payments[0].company_id = this.companyId;
          }
          if (this.departmentId) {
            this.payments[0].department_id = this.departmentId;
          }
        }
      });
    },
    handleUploadedItem(file, type) {
      this.fileBuffer = { file: file, type: type };
      this.showFileLinkSelection = true;
    },
    handleEstimatedPaymentSelect(val) {
      this.tmp.estimatedPaymentDate = val;
    },
    handleFileLinkSelectionConfirm(item) {
      let fileId = this.fileBuffer.file.id;
      let type = this.fileBuffer.type === this.FILE_TYPE.INVOICE ? "item_e_invoices" : "item_application_papers";
      if (fileId) {
        this.form.transaction.transaction_items.forEach((obj) => {
          if (obj[type] === undefined) {
            obj[type] = [];
          }

          if (obj.index === item.index) {
            obj[type].push(fileId);
            this.showFileLinkSelection = false;
            this.fileBuffer = null;
          }
        });
      }
    },
    handleFileLinkSelectionDelete(fileId) {
      this.form.transaction.transaction_items.forEach((obj) => {
        obj.item_e_invoices = obj.item_e_invoices.filter((item) => item !== fileId);
        obj.item_application_papers = obj.item_application_papers.filter((item) => item !== fileId);
      });
    }
  }
};
</script>

<style scoped></style>
