<template>
  <div class="bg-white fixed px-3 py-4 rounded-0 w-full text-center z-20">
    <div class=" flex items-center">
      <div class="relative w-full">
        <v-ons-search-input
          maxlength="20"
          :placeholder="placeholder ? placeholder : $t('common.search')"
          style="width: 100%"
          v-model="filters.term"
          @change="handleSearch"
          @focus="showCancel = true"
        >
        </v-ons-search-input>
        <span
          style="
              position: absolute;
              right: 5px;
              top: 50%;
              transform: translateY(-50%);
            "
          @click="handleReset"
        >
          <Icon v-if="filters.term.length > 0" name="x" classes="h-5 w-5 list-item__thumbnail text-gray-500" />
        </span>
      </div>

      <div class="w-1/6 whitespace-nowrap ml-2" v-show="showCancel">
        <btn-default :label="$t('common.cancel')" label_class="text-sm text-blue-800" @on-click="handleReset" />
      </div>
      <div class="border py-1 ml-2 rounded" v-show="!showCancel" v-if="showFilter">
        <btn-default
          :class="filterClass"
          :icon_class="filterClass"
          @on-click="filterShow = true"
          :label="$t('common.filter')"
          icon="filter"
          :size="16"
        />
      </div>
    </div>
    <!-- <sort-select :showing="filterShow" :selected="filters.by" @on-close="filterShow = false" @on-select="handleSortSelect" /> -->
    <stamp-filter-form ref="filter" :showing="filterShow" @on-close="filterShow = false" @on-filter="handleFilterSubmit" />
    <slot name="info" />
  </div>
</template>

<script>
// import SortSelect from "@/views/common/select/SortSelect";
import StampFilterForm from "@/views/Application/StampStatistic/component/StampFilterForm";

const defaultFilter = { term: "" };
export default {
  name: "StampFilter",
  props: {
    placeholder: {
      type: String
    },
    showFilter: {
      type: Boolean,
      default: false
    },
    module: {
      type: String
    }
  },
  components: { StampFilterForm },
  watch: {
    "filters.term": function() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.handleSearch();
          this.awaitingSearch = false;
        }, 800);
      }
      this.awaitingSearch = true;
    },
    module: function() {
      //reset filter on tab change
      this.$refs.filter.onReset();
    }
  },
  computed: {
    filterClass() {
      return !this.hasFilter ? "text-gray-500" : "text-blue-500";
    }
  },
  data() {
    return {
      showCancel: false,
      awaitingSearch: false,
      filterShow: false,
      filters: Object.assign({}, defaultFilter),
      hasFilter: false
    };
  },
  methods: {
    handleSearch() {
      this.$emit("on-search", this.filters);
    },
    handleReset() {
      this.filters = Object.assign({}, defaultFilter);
      this.showCancel = false;
      this.$emit("on-reset", this.filters);
    },
    handleFilterSubmit(filter) {
      if (null === filter) {
        this.hasFilter = false;
      } else {
        this.hasFilter = true;
      }
      this.$emit("on-filter", filter);
      this.filterShow = false;
    }
  }
};
</script>

<style scoped></style>
