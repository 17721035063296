<template>
  <div class="content p-2">
    <div class="relative">
      <div class="approval-process">
        <div class="relative">
          <div class="thumb p-0">{{ initial }}</div>
        </div>
        <div class="ml-4">
          <div class="font-16">{{ process }}</div>
          <div class="text-dark-gray">{{ name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "d-process",
  props: ["initial", "name", "process"]
};
</script>

<style></style>
