export function Round(number, currency) {
  if (!currency) {
    currency = "CNY";
  }

  if (undefined === number) {
    return 0;
  }

  if (currency == "JPY" || currency == "other") {
    // return Math.ceil(number);
    return number.toLocaleString("ja", { maximumFractionDigits: 0 });
  } else {
    return number.toLocaleString("zh-cn", { minimumFractionDigits: 0, maximumFractionDigits: 2 });
  }
}
