<template>
  <BottomSheet :title="$t('app.col.sales.evidence_type_select')" :showing="showing" @on-close="$emit('on-close')">
    <loading-small v-if="loading" class="test" />
    <v-ons-list class="pb-16">
      <v-ons-list-item v-for="(item, key) in list" :key="key" tappable @click="handleChange(item)">
        <label class="left">
          <v-ons-checkbox :input-id="'evidence_type_select-' + key" :value="item.id" v-model="selectedOption"> </v-ons-checkbox>
        </label>
        <label :for="'evidence_type_select-' + key" class="center">
          {{ item.name }}
        </label>
      </v-ons-list-item>
    </v-ons-list>
  </BottomSheet>
</template>

<script>
import BottomSheet from "@/components/BottomSheet";
import { fetchEvidenceType as getEvidences } from "@/api/collection_report";

export default {
  name: "evidenceTypeSelect",
  props: {
    showing: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BottomSheet
  },
  watch: {
    showing: function(newVal) {
      if (newVal) {
        this.fetchList();
      }
    }
  },
  data() {
    return {
      loading: true,
      list: [],
      selectedOption: []
    };
  },
  methods: {
    handleChange(val) {
      this.$emit("on-select", val);
    },
    fetchList() {
      this.selectedOption = [];
      getEvidences().then((response) => {
        this.list = response["hydra:member"];
        this.loading = false;
      });
    }
  }
};
</script>
