<template>
  <div>
    <Loading v-show="loading" />
    <ons-list>
      <v-ons-list-header />
      <form-select
        :label="$t('common.currency')"
        @click="cshow.currency = true"
        :required="true"
        :placeholder="$t('common.select_currency')"
        :value="common.currency ? common.currency.code : null"
      />

      <form-select
        :required="true"
        :label="$t('common.company')"
        @click="onClickComponent(COMPONENT.COMPANY)"
        :placeholder="$t('common.select_company')"
        :value="component.company ? component.company.name : null"
      />

      <form-select
        :required="true"
        v-if="component.company !== null"
        :label="$t('common.department')"
        @click="onClickComponent(COMPONENT.DEPARTMENT, { companyId: component.company.id })"
        :placeholder="$t('common.select_department')"
        :value="component.department ? component.department.name : null"
      />

      <form-select
        :label="$t('common.related_approval')"
        @click="onClickComponent(COMPONENT.RELATED_APPROVAL, { moduleCode: moduleCode })"
        :placeholder="$t('common.select')"
        :value="component.related_approval ? component.related_approval.code : null"
      />
    </ons-list>

    <ons-list class="mt-6" v-show="common.currency && component.company && component.department">
      <v-ons-list-header>
        {{ $t("app.reimb.reimbursement") }}
      </v-ons-list-header>
      <form-select
        v-for="(item, key) in reimbursements"
        :key="key"
        :label="$t('app.reimb.reimbursement') + ' (' + item.index + ')'"
        @click="onClickDetail(item)"
        :value="(common.currency ? common.currency.code : '') + ' ' + Round(item.total, common.currency ? common.currency.code : null)"
      />
      <v-ons-list-item>
        <div class="center" style="font-size: 13px; color: #a8a8a8">
          {{ $t("app.reimb.add_more_info") }}
        </div>
      </v-ons-list-item>
    </ons-list>

    <v-ons-list v-show="common.currency">
      <v-ons-list-item class="p-0">
        <div class="center flex justify-center items-center p-0">
          <v-ons-button modifier="quiet" style="justify-content: center" @click="addReimbursement">
            <ons-icon icon="md-plus"> </ons-icon>
            {{ $t("common.add_more") }}
          </v-ons-button>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <br />
    <v-ons-list class="border-b border ">
      <v-ons-list-item>
        <div class="left">{{ $t("common.total") }}</div>
        <div class="center text-xl" style="margin-left: 10px">
          {{ common.currency ? common.currency.code : "" }}
          <span class="text-orange ml-2 font-bold">
            {{ Round(form.transaction.total, common.currency ? common.currency.code : null) }}
          </span>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <br />

    <ons-list>
      <div class="flex items-center px-2 py-4 space-x-4 w-full text-sm border-b border-gray-100">
        <label class="left">
          <v-ons-checkbox :input-id="'checkbox-drawdown'" v-model="form.transaction.submit_supplier_contract_information"> </v-ons-checkbox>
        </label>
        <label class="center" :for="'checkbox-drawdown'"> {{ $t("app.reimb.with_supplier_account") }} </label>
      </div>

      <div v-if="form.transaction.submit_supplier_contract_information">
        <form-input-search
          kind="text"
          :label="$t('app.pay.recipient')"
          :placeholder="$t('app.pay.enter_recipient')"
          v-model="form.transaction.payment_recipient_account.name"
        />
        <form-input
          kind="text"
          :label="$t('app.account.number')"
          :placeholder="$t('app.pay.enter_recipient_account')"
          v-model="form.transaction.payment_recipient_account.no"
        />

        <form-input
          kind="text"
          :label="$t('app.account.bank')"
          :placeholder="$t('common.enter_bank_name')"
          v-model="form.transaction.payment_recipient_account.bank_name"
        />

        <form-input
          kind="text"
          :label="$t('app.account.branch')"
          :placeholder="$t('common.enter_branch_name')"
          v-model="form.transaction.payment_recipient_account.branch_name"
        />
      </div>
    </ons-list>

    <br />

    <div style="color: #828282; padding: 5px 15px; text-align: left">
      <span style="font-size: 12px">
        {{ $t("app.reimb.e_invoice_info", { number: nextCode }) }}
      </span>
    </div>

    <ons-list>
      <form-uploader
        :label="$t('app.reimb.e_invoice')"
        :sublabel="$t('app.reimb.e_invoice_sublabel')"
        :multiple="true"
        :documentType="FILE_TYPE.INVOICE"
        :existing="this.files[FILE_TYPE.INVOICE]"
        @on-success="onUpload($event, FILE_TYPE.INVOICE)"
      />

      <form-uploader
        :label="$t('app.reimb.application_paper')"
        :sublabel="$t('app.reimb.application_paper_sublabel')"
        :multiple="true"
        :documentType="FILE_TYPE.APP_PAPER"
        :existing="this.files[FILE_TYPE.APP_PAPER]"
        @on-success="onUpload($event, FILE_TYPE.APP_PAPER)"
      />
    </ons-list>
    <br />
    <v-ons-list>
      <v-ons-list-header>
        {{ $t("app.invoice_later") }}
      </v-ons-list-header>
      <form-input
        :label="$t('common.amount') + (common.currency ? '(' + common.currency.code + ')' : '')"
        :placeholder="$t('common.enter_amount')"
        v-model.number="form.transaction.invoice_amount"
      />
      <form-textarea
        :label="$t('app.reimb.supplier_name')"
        :placeholder="$t('app.reimb.enter_supplier_name')"
        v-model="form.transaction.invoice_note"
      />
    </v-ons-list>
    <!-- hide input by trasury in the application -->
    <!--
    <br />
    <v-ons-list>
      <v-ons-list-header>
        {{ $t("app.input_by_treasury") }}
      </v-ons-list-header>
      <form-select
        :label="$t('app.payment_account')"
        @click="push(page[1].component, page[1].label)"
        :placeholder="$t('app.select_payment_account')"
        :value="selectedAccount ? selectedAccount.bank_name + ' - ' + selectedAccount.branch : null"
      />

      <form-calendar
        :label="$t('app.payment_date')"
        mode="single"
        @on-change="handlePaymentDateSelect"
        :placeholder="$t('common.select')"
        :value="form.transaction.payment_date"
      />

      <form-uploader
        :label="$t('app.deal_slip')"
        :sublabel="$t('app.deal_slip_sublabel')"
        :multiple="false"
        documentType="deal-slip"
        @on-success="handleUploadDealSlip"
      />
    </v-ons-list> -->
    <br />
    <v-ons-card class="flat p-0">
      <v-ons-list>
        <v-ons-list-header>
          {{ $t("app.reimb.tax") }}
        </v-ons-list-header>
        <form-textarea :label="$t('app.reimb.tax2')" :placeholder="$t('app.reimb.enter_tax')" v-model="form.transaction.note" />
      </v-ons-list>
    </v-ons-card>

    <br />
    <v-ons-card class="flat p-0">
      <v-ons-list>
        <form-select-user
          :label="$t('app.forward_document')"
          :users="component.finance ? component.finance : []"
          @remove="handleRemoveFinance"
          @add="onClickComponent(COMPONENT.FINANCE, { default: component.finance })"
        />
      </v-ons-list>
    </v-ons-card>

    <br />
    <v-ons-card class="flat p-0">
      <v-ons-list class="bg-transparent mb-5">
        <v-ons-list-item>
          <label class="center">{{ $t("common.bypass") }}</label>
          <div class="right">
            <v-ons-switch v-model="form.bypass"> </v-ons-switch>
          </div>
        </v-ons-list-item>
      </v-ons-list>
    </v-ons-card>

    <process-card :module-code="moduleCode" v-if="!form.bypass">
      <hr />
      <v-ons-list class="bg-transparent mb-5">
        <v-ons-list-item>
          <label class="center">{{ $t("common.remember_approvers") }}</label>
          <div class="right">
            <v-ons-switch v-model="form.save_approvers"> </v-ons-switch>
          </div>
        </v-ons-list-item>
      </v-ons-list>
    </process-card>

    <bottom-submit @submit="handleSubmit" :loading="submitting">
      <template v-slot:additional>
        <div class="flex items-center p-2 space-x-4 w-full text-sm" v-if="!form.bypass">
          <label class="left">
            <v-ons-checkbox :input-id="'checkbox-email'" v-model="form.send_notification_first_approval"> </v-ons-checkbox>
          </label>
          <label class="center" :for="'checkbox-email'"> {{ $t("app.send_notification_approver") }} </label>
        </div>
      </template>
    </bottom-submit>

    <currency-select :showing="cshow.currency" @on-close="cshow.currency = false" @on-select="onCommonSelect($event, COMMON.CURRENCY)" />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { submitReimbursementBuffer } from "@/api/rembursement";
import CurrencySelect from "@/views/common/select/CurrencySelect";
import ProcessCard from "@/views/common/ProcessCard";
import application from "@/mixins/application";

const defaultForm = {
  transaction_items: [],
  currency_id: null,
  total: 0,
  note: null,
  e_invoices: [],
  application_papers: [],
  invoice_amount: null,
  invoice_note: "",
  account_id: null,
  payment_date: null,
  deal_slips: [],
  user_finances: [],
  submit_supplier_contract_information: false,
  payment_recipient_account: {
    name: "",
    no: "",
    bank_name: "",
    branch_name: ""
  }
};

const defaultItem = [
  {
    index: 1,
    total: 0,
    company_id: null,
    department_id: null,
    related_approval_id: null,
    related_items: []
  }
];

export default {
  name: "ReimbursementForm",
  components: {
    CurrencySelect,
    ProcessCard
  },
  mixins: [application],
  props: ["show", "init", "param"],
  watch: {
    show: function(newVal) {
      if (newVal) {
        this.onShow();
      }
    },
    init: function(newVal) {
      if (newVal) {
        this.onInit();
      }
    },
    reimbursements: function(newVal) {
      if (newVal) {
        this.form.transaction.total = newVal.reduce((sum, obj) => sum + obj.total, 0);
      }
    },
    "component.company": function(n) {
      if (n) {
        this.reimbursements = this.reimbursements.map((item) => ({ ...item, company_id: n.id }));
        this.form.transaction.transaction_items = this.reimbursements.filter((item) => item.total > 0);
      }
    },
    "component.department": function(n) {
      if (n) {
        this.reimbursements = this.reimbursements.map((item) => ({ ...item, department_id: n.id }));
        this.form.transaction.transaction_items = this.reimbursements.filter((item) => item.total > 0);
      }
    },
    "common.currency": function(n) {
      if (n) {
        this.form.transaction.currency_id = n.id;
      }
    },
    "component.finance": function(n) {
      if (n) {
        this.form.transaction.user_finances = n.map((item) => item.id);
      }
    },
    "form.bypass": function(n) {
      this.form.send_notification_first_approval = !n;
    }
  },
  computed: {
    companyId() {
      return this.component.company ? this.component.company.id : null;
    },
    departmentId() {
      return this.component.department ? this.component.department.id : null;
    },
    relatedApprovalId() {
      return this.component.related_approval ? this.component.related_approval.id : null;
    },
    relatedApprovalItems() {
      let items = [];
      if (this.component.related_approval) {
        items = this.component.related_approval.data.map((item) => ({
          item_id: item.item_id,
          is_edited: false,
          data: item
        }));
      }

      return items;
    }
  },
  data: () => ({
    moduleCode: "reimbursement",
    tempMaster: ["form", "reimbursements", "common", "component", "files"],
    existingDetail: undefined,
    submitting: false,
    form: {
      send_notification_first_approval: true,
      bypass: false,
      approvals: [],
      save_approvers: true,
      transaction: Object.assign({}, defaultForm)
    },
    reimbursements: defaultItem
  }),
  validations: {
    selectedAccount: {
      required
    },
    common: {
      currency: {
        required
      }
    }
  },
  methods: {
    onShow() {
      let param = this.$store.getters["navigator/getParam"];
      let reimbursementDetail = param?.reimbursementDetail;
      let selectedAccount = param.selectedAccount;
      let selectedFinance = param.selectedFinance;
      let selectedCompany = param.selectedContractParty;
      let selectedDepartment = param.selectedDepartment;
      let selectedApproval = param.selectedApproval;

      if (reimbursementDetail) {
        this.reimbursements = this.reimbursements.map((obj) => {
          if (obj.index === reimbursementDetail.index) {
            return { ...reimbursementDetail };
          } else {
            return obj;
          }
        });

        this.form.transaction.transaction_items = this.reimbursements.filter((item) => item.total > 0);
      }
      if (selectedAccount) {
        this.component[this.COMPONENT.BANK_ACCOUNT] = selectedAccount;
        this.form.transaction.account_id = selectedAccount.id;
      }

      if (selectedFinance) {
        this.component[this.COMPONENT.FINANCE] = selectedFinance;
        this.form.transaction.user_finances = selectedFinance.map((item) => item.id);
      }

      if (selectedCompany) {
        this.component[this.COMPONENT.COMPANY] = selectedCompany;
        this.component[this.COMPONENT.DEPARTMENT] = null;
      }
      if (selectedDepartment) {
        this.component[this.COMPONENT.DEPARTMENT] = selectedDepartment;
      }
      if (selectedApproval) {
        this.component[this.COMPONENT.RELATED_APPROVAL] = selectedApproval;
        this.reimbursements = this.reimbursements.map((item) => ({
          ...item,
          related_approval_id: selectedApproval.id,
          related_items: selectedApproval.data.map((item) => ({
            item_id: item.item_id,
            is_edited: false,
            data: item
          }))
        }));
      }

      this.$store.commit("navigator/clearParam");
      this.$emit("reloaded");
      this.loading = false;
    },
    updateFormFile(type) {
      if (type === this.FILE_TYPE.INVOICE) {
        this.form.transaction.e_invoices = this.files[this.FILE_TYPE.INVOICE].map((file) => file.id);
      }
      if (type === this.FILE_TYPE.APP_PAPER) {
        this.form.transaction.application_papers = this.files[this.FILE_TYPE.APP_PAPER].map((file) => file.id);
      }
      if (type === this.FILE_TYPE.DEAL_SLIP) {
        this.form.transaction.deal_slips = this.files[this.FILE_TYPE.DEAL_SLIP].map((file) => file.id);
      }
    },
    updateFormCommon(type) {
      if (type === this.COMMON.CURRENCY) {
        this.form.transaction.currency_id = this.common.currency.id;
      }
    },
    addReimbursement() {
      this.reimbursements = [
        ...this.reimbursements,
        {
          index: this.reimbursements.length + 1,
          total: 0,
          company_id: this.companyId,
          department_id: this.departmentId,
          related_approval_id: this.relatedApprovalId,
          related_items: this.relatedApprovalItems
        }
      ];
    },
    async handleSubmit() {
      this.isFormValid().then((valid) => {
        if (valid) {
          this.submitting = true;
          submitReimbursementBuffer(this.form)
            .then((res) => {
              this.$ons.notification.alert({
                title: res.code ? res.code : this.$t("common.notif.saved"),
                messageHTML: ` ${this.$t("common.notif.submit")}`
              });
              this.component[this.COMPONENT.RELATED_APPROVAL] = null;
              this.populateDefaultTemp();
              this.$store.commit("navigator/pop");
            })
            .then(() => {
              this.submitting = false;
            });
        }
      });
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.common.currency.$invalid) {
        await this.$ons.notification.alert(this.$t("common.alert.currency"), options);
        return false;
      }
      if (this.reimbursements[0].total === 0) {
        await this.$ons.notification.alert(this.$t("app.reimb.alert.item"), options);
        return false;
      }
      if (!this.form.bypass) {
        if (!this.approverIsValid) {
          await this.$ons.notification.alert(this.$t("common.alert.approver"), options);
          return false;
        }
      }

      return true;
    },
    loadData() {
      if (!this.form.transaction.submit_supplier_contract_information) {
        this.$set(this.form.transaction, "submit_supplier_contract_information", false);
      }

      if (!this.form.transaction.payment_recipient_account) {
        this.form.transaction.payment_recipient_account = {
          name: "",
          no: "",
          bank_name: "",
          branch_name: ""
        };
      }

      if (this.form.transaction.currency_id) {
        this.loadApi(this.COMMON.CURRENCY, this.form.transaction.currency_id);
      }
      if (this.form.transaction.user_finances) {
        this.form.transaction.user_finances.forEach((item) => {
          this.loadApi(this.COMPONENT.FINANCE, item);
        });
      }
      if (this.form.transaction.e_invoices) {
        this.form.transaction.e_invoices.forEach((item) => {
          this.loadFile(this.FILE_TYPE.INVOICE, item);
        });
      }
      if (this.form.transaction.application_papers) {
        this.form.transaction.application_papers.forEach((item) => {
          this.loadFile(this.FILE_TYPE.APP_PAPER, item);
        });
      }

      this.form.transaction.account_id = null;
      this.form.transaction.payment_date = null;
      this.form.transaction.deal_slips = [];

      if (this.form.transaction.transaction_items) {
        this.component[this.COMPONENT.COMPANY] = this.loadApi(this.COMPONENT.COMPANY, this.form.transaction.transaction_items[0].company_id);
        this.component[this.COMPONENT.DEPARTMENT] = this.loadApi(this.COMPONENT.DEPARTMENT, this.form.transaction.transaction_items[0].department_id);
        this.component[this.COMPONENT.RELATED_APPROVAL] = this.loadRelatedApproval(
          this.moduleCode,
          this.form.transaction.transaction_items[0].related_approval_id
        );

        this.reimbursements = this.form.transaction.transaction_items.map((obj, index) => {
          let total = 0;
          if (obj.contents.length > 1) {
            total = obj.contents.reduce(function(p, c) {
              return p.amount + c.amount;
            });
          } else {
            total = obj.contents[0].amount;
          }

          return {
            ...obj,
            contents: obj.contents.map((item) => ({
              ...item,
              date: this.moment(obj.date).format("YYYY-MM-DD")
            })),
            index: index + 1,
            total: total
          };
        });
      }
    },
    handleReset() {
      this.$ons.notification.confirm(this.$t("common.notif.decide"), { title: this.$t("common.notif.confirm") }).then((response) => {
        if (response === 1) {
          this.form.transaction = Object.assign({}, defaultForm);
          this.reimbursements = defaultItem;
          this.files = {};
          this.component[this.COMPONENT.FINANCE] = null;
          this.component[this.COMPONENT.RELATED_APPROVAL] = null;
          this.component[this.COMPONENT.COMPANY] = null;
          this.component[this.COMPONENT.DEPARTMENT] = null;
          if (this.common.currency) {
            this.form.transaction.currency_id = this.common.currency.id;
          }

          if (this.companyId) {
            this.reimbursements[0].company_id = this.companyId;
          }
          if (this.departmentId) {
            this.reimbursements[0].department_id = this.departmentId;
          }
          this.form.transaction.submit_supplier_contract_information = false;
          this.form.transaction.payment_recipient_account = {
            name: "",
            no: "",
            bank_name: "",
            branch_name: ""
          };
        }
      });
    }
  }
};
</script>

<style scoped>
.vux-uploader_del {
  position: absolute;
  right: 0;
  bottom: 50px !important;
  left: 0;
  background-color: #0d0d0d;
  color: #fff;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-family: weui;
}
</style>
