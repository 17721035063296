// import _ from "lodash";
import { getCurrency as fetch_currency } from "@/api/rembursement";
import { fetchDetail as fetch_file } from "@/api/file";
import { fetchUserDetail as fetch_user } from "@/api/user";
import { fetchDetail as fetch_bank_account } from "@/api/bank_account";
import { fetchCompanyDetail as fetch_company } from "@/api/company";
import { fetchDepartmentDetail as fetch_department } from "@/api/department";
import { getContractTypeById as fetch_supplier_contract_type, getContractFrequencyById as fetch_contract_frequency } from "@/api/contractSupplier";
import { fetchExpenseAccountDetail as fetch_expense_account } from "@/api/expense_account";
import { getDetail as fetch_corporate_seal_type } from "@/api/corporate_seal";

export default {
  name: "ApplicationMap",
  data() {
    return {
      map: {
        formObj: {}
      }
    };
  },
  methods: {
    async populateForms(obj) {
      let forms = [];
      forms = await Promise.all(
        Object.entries(obj).map(async (item) => {
          const data = await this.loadData(item[0], item[1]);

          return { name: item[0], value: data, updates: [] };
        })
      );

      let x = {};
      forms.forEach((item) => {
        x[item.name] = item.value;
      });

      this.map.formObj = Object.assign({}, x);
    },
    async loadData(param, value) {
      if (!value) {
        return value;
      }

      switch (param) {
        case "currency_id":
          return fetch_currency(value).then((r) => r);
        case "company_id":
        case "contract_party_id":
          return fetch_company(value).then((r) => r);
        case "department_id":
          return fetch_department(value).then((r) => r);
        case "contract_item_type_id":
          return fetch_supplier_contract_type(value).then((r) => r);
        case "expense_account_id":
          return fetch_expense_account(value).then((r) => r);
        case "contract_frequency_id":
          return fetch_contract_frequency(value).then((r) => r);
        case "type_id":
          return fetch_corporate_seal_type(value).then((r) => r);
        case "user_finances": {
          let users = await Promise.all(
            value.map(async (item) => {
              return await fetch_user(item);
            })
          );

          return users;
        }
        case "account_id": {
          if (value) {
            return fetch_bank_account(value).then((r) => r);
          }
          return value;
        }
        case "e_invoices":
        case "application_papers":
        case "contract_plan_files":
        case "signed_contract_files":
        case "document_plan_files":
        case "document_signed_files":
        case "deal_slips": {
          let files = await Promise.all(
            value.map(async (item) => {
              return await fetch_file(item);
            })
          );

          return files;
        }
        default:
          return value;
      }
    }
  }
};
