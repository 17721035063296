<template>
  <ons-list-item @click="$emit('click')" :modifier="modifier" :tappable="tappable">
    <div class="center">
      <span class="list-item__title" :class="{ required: required }">
        {{ label }}
      </span>
      <slot />
      <span class="list-item__subtitle py-1 text-base">
        <span v-if="showPlaceholder"> {{ placeholder }}</span>
        <span v-else class="text-gray-900"> {{ value }}</span>
      </span>
    </div>
  </ons-list-item>
</template>

<script>
export default {
  name: "form-select",
  props: {
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    value: {
      type: String
    },
    tappable: {
      type: Boolean,
      default: true
    },
    modifier: {
      type: String,
      default: "chevron"
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showPlaceholder() {
      return this.value ? false : true;
    }
  }
};
</script>

<style scoped></style>
