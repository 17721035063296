import { FILE_TYPE } from "@/utils/constant";

import { fetchCompanyDetail as fetch_company } from "@/api/company";
import { fetchDepartmentDetail as fetch_department } from "@/api/department";
import { fetchDetail as fetch_file } from "@/api/file";

//related
import { fetchRelatedReimbursementDetail as fetch_purchase, fetchRelatedContractDetail as fetch_contract } from "@/api/related_approval";
import { fetchExpenseAccountDetail as fetch_expense } from "@/api/expense_account";

export default {
  name: "ApplicationDetailMixin",
  data() {
    return {
      common: {
        currency: null
      },
      files: {},
      FILE_TYPE: FILE_TYPE
    };
  },
  methods: {
    getCompany(id) {
      fetch_company(id).then((res) => {
        this.form.company = {
          id: res.id,
          name: res.name
        };
      });
    },
    getDepartment(id, obj) {
      fetch_department(id).then((res) => {
        if (!obj) {
          this.form.department = {
            id: res.id,
            name: res.name
          };
        }

        this[obj] = {
          id: res.id,
          name: res.name
        };
      });
    },
    onUpload(file, type) {
      this.files[type] = file;
      this.updateFormFile(type);
    },
    loadFile(type, val) {
      if (this.files[type] === undefined) {
        this.files[type] = [];
      }

      fetch_file(val).then((r) => {
        this.files[type].push(r);
      });
    },
    getRelatedApproval(type, id) {
      if (type === "reimbursement") {
        fetch_purchase(id).then((res) => {
          this.form.approval = res;
        });
      }
      if (type === "payment") {
        fetch_contract(id).then((obj) => {
          let data = null;
          switch (obj.contract_item_type.code) {
            case "goods":
              data = obj.contract_item_goods["hydra:member"];
              break;
            case "services":
              data = obj.contract_item_services["hydra:member"];
              break;
            case "hr":
              data = obj.contract_item_hr["hydra:member"];
              break;
            case "other":
              data = obj.contract_item_other["hydra:member"];
              break;
          }
          this.form.approval = {
            id: obj.id,
            code: obj.code,
            currency: obj.currency.code,
            data: data,
            module: obj.module.code,
            type: obj.contract_item_type.code,
            contract_name: obj.contract_full_name,
            supplier_account: obj.contract_supplier_account
          };
        });
      }
    },
    getExpenseDetail(id, obj, arr) {
      fetch_expense(id).then((res) => {
        let x = {
          ...obj,
          expense: {
            id: res.id,
            name: res.name,
            is_transportation: res.is_transportation
          },
          notes: obj.note
        };
        arr.push(x);
      });
    }
  }
};
