import request from "@/utils/request";

export function fetchData(moduleCode) {
  return request({
    url: "/my-default-process/" + moduleCode,
    method: "get"
  });
}

export function fetchSavedApprovers(moduleCode) {
  return request({
    url: "/saved_approvers?moduleCode=" + moduleCode,
    method: "get"
  });
}
