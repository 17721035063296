<template>
  <bottom-sheet-action :showing="show" :title="title" @on-close="$emit('cancel')" @on-save="handleSave">
    <form-input
      kind="text"
      :required="true"
      :label="$t('app.purc.goods_name')"
      :placeholder="$t('app.purc.enter_goods_name')"
      v-model="form.goods_name"
    />
    <form-input
      :required="true"
      :label="$t('app.purc.budget_tax') + ' (' + form.currency + ')'"
      :placeholder="$t('app.purc.enter_budget')"
      v-model.number="form.budget"
    />
    <form-input
      :required="true"
      :label="$t('app.purc.estimated_unit_price') + ' (' + form.currency + ')'"
      :placeholder="$t('app.purc.estimated_unit_price')"
      v-model.number="form.unit_price"
    />
  </bottom-sheet-action>
</template>

<script>
import BottomSheetAction from "@/components/BottomSheetAction";
import { required } from "vuelidate/lib/validators";

const defaultForm = {
  goods_name: "",
  budget: 0,
  unit_price: 0,
  currency: ""
};

export default {
  name: "RelatedForm",
  components: { BottomSheetAction },
  props: ["show", "title", "data"],
  data() {
    return {
      form: Object.assign({}, defaultForm)
    };
  },
  watch: {
    data(n) {
      this.form = Object.assign({}, n);
    }
  },
  validations: {
    form: {
      goods_name: {
        required
      },
      budget: {
        required
      }
    }
  },
  methods: {
    async validate() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.goods_name.$invalid) {
        await this.$ons.notification.alert(this.$t("app.purc.alert.goods"), options);
        return false;
      }
      if (this.$v.form.budget.$invalid) {
        await this.$ons.notification.alert(this.$t("app.purc.alert.budget"), options);
        return false;
      }

      return true;
    },
    handleSave() {
      this.validate().then((valid) => {
        if (valid) {
          this.$emit("save", this.form);
        }
      });
    }
  }
};
</script>

<style></style>
