<template>
  <div>
    <d-text :label="label" value=" - " v-if="images.length === 0" />
    <Photoswipe v-if="images.length > 0">
      <div class="font-16 text-dark-gray">{{ label }}</div>
      <div
        v-for="(item, index) in images"
        :key="`practical-bg-${index}`"
        class="space-x-2"
        v-pswp="item"
        :style="getImageItemStyle(item.src)"
        style="display: inline-block"
      />
    </Photoswipe>
  </div>
</template>

<script>
export default {
  name: "d-image",
  props: ["images", "label"],
  methods: {
    getImageItemStyle(src) {
      return {
        width: "60px",
        height: "60px",
        marginRight: "5px",
        backgroundImage: `url(${src})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      };
    }
  }
};
</script>

<style></style>
