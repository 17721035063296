<template>
  <div>
    <CardList
      v-for="(item, key) in data"
      :key="key"
      :title="item.title"
      :code="item.code"
      :process="{ status: item.status, process: null }"
      :applicant="item.applicant"
      :datas="item.info"
      @click.native="push(page[0], item.code, item, true)"
    />
  </div>
</template>

<script>
import CardList from "@/components/CardList";
import ApplicationDataDetail from "@/views/Application/component/ApplicationDataDetail";

export default {
  name: "ApplicationList",
  components: { CardList },
  props: {
    data: Array
  },
  computed: {
    page() {
      return [ApplicationDataDetail];
    }
  },
  methods: {
    push(page, key, param, hide) {
      this.$store.commit("navigator/options", {
        animation: "lift-ios"
      });
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param,
              hideSubmit: hide
            }
          };
        }
      });
    }
  }
};
</script>
