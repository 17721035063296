<template>
  <Modal :showing="isVisible" @on-close="$emit('close')" @on-confirm="handleConfirm">
    <weui-media
      v-for="(item, key) in suppliers"
      :key="key"
      @click.native="handleSelect(key)"
      :class="{ 'bg-gray-100 ': selected === key }"
      :title="item.name"
      :desc="item.no + ' ' + item.bankName + ' ' + item.branchName"
    >
    </weui-media>
  </Modal>
</template>
<script>
import Modal from "@/components/Modal";
export default {
  name: "SelectSupplier",
  components: { Modal },
  props: {
    show: Boolean,
    radio: null,
    suppliers: Array
  },
  data() {
    return {
      text: "",
      selected: null
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.show;
      },
      set(bool) {
        this.$emit("close", bool);
      }
    }
  },
  methods: {
    handleSelect(value) {
      this.selected = value;
    },
    handleConfirm() {
      this.$emit("confirm", this.suppliers[this.selected]);
    }
  }
};
</script>

<style scoped></style>
