<template>
  <v-ons-list>
    <v-ons-list-item v-for="(item, key) in data" :key="key">
      <div class="w-full pr-2">
        <div class="mb-1.5">{{ $t("app.item") }} ({{ key + 1 }})</div>
        <div class="space-y-1 text-sm">
          <div>
            <div class="flex justify-between">
              <div class="text-gray-500">{{ $t("app.cc.item.name") }}</div>
              <div>{{ item.name }}</div>
            </div>
            <div class="flex justify-between">
              <div class="text-gray-500">{{ $t("common.amount") }}</div>
              <div>{{ currency }} {{ item.amount }}</div>
            </div>
            <div class="flex justify-between">
              <div class="text-gray-500">{{ $t("app.cc.item.unit") }}</div>
              <div>{{ currency }} {{ item.unit_price }}</div>
            </div>
          </div>
        </div>
      </div>
    </v-ons-list-item>
  </v-ons-list>
</template>

<script>
export default {
  name: "CustomerGoods",
  props: ["data", "currency"]
};
</script>

<style></style>
