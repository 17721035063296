<template>
  <v-ons-card>
    <div class="title m-0 p-3">
      {{ $t("common.process") }}
    </div>
    <div class="content">
      <v-ons-list>
        <v-ons-list-item v-for="(item, key) in processes" :key="key">
          <div class="left relative">
            <span v-if="processes.length !== key + 1" class="vertical-line-xs" />
            <div class="process-thumb" />
          </div>
          <div class="center">
            <div class="list-item__title" :class="{ required: !item.optional }">
              <span :class="{ 'ml-1.5': !item.optional }">{{ item.title }}</span>
            </div>
            <div class="list-item__subtitle">
              {{ item.selectedItem ? `${item.selectedItem.length} People Approval` : $t("common.select") }}
            </div>
          </div>

          <div class="right">
            <div class="flex justify-end">
              <user-circle
                v-for="approver in item.selectedItem"
                :key="approver.id"
                :isCanRemove="isCanSelect(item)"
                :user="approver"
                :processId="item.id"
                @remove="onHandleRemove"
              />
              <div
                class="border rounded-md flex items-center justify-center"
                :class="isCanSelect(item) ? 'cursor-pointer' : 'cursor-not-allowed'"
                style="height: 35px;width: 35px;margin-bottom: 16.8px;"
                @click="isCanSelect(item) ? onApproverClick(item) : null"
              >
                <Icon name="plus" size="28" stroke="0.5" :classes="isCanSelect(item) ? 'text-black' : 'text-gray-400'" />
              </div>
            </div>
          </div>
        </v-ons-list-item>
      </v-ons-list>
    </div>
    <slot />
  </v-ons-card>
</template>

<script>
import _ from "lodash";
import ApproverListMultiple from "@/views/common/list/ApproverListMultiple";
import { fetchData as fetchProcess } from "@/api/master_process";
import { fetchData as fetchUserProcess, fetchSavedApprovers as fetchSavedUserProccess } from "@/api/user_process";

export default {
  name: "ProcessCard",
  props: {
    moduleCode: {
      Type: String
    },
    required: {
      Type: Boolean,
      default: true
    }
  },
  data() {
    return {
      processes: [],
      approvers: [],
      loading: true
    };
  },
  computed: {
    param() {
      return this.$store.getters.param;
    },
    isAllastarseaGroupUser() {
      return this.$store.getters.profile.is_allastarsea_group_member;
    },
    page() {
      return [
        {
          component: ApproverListMultiple,
          label: this.$t("common.alert.approver")
        }
      ];
    }
  },
  watch: {
    param(newVal) {
      let approvers = newVal.approvers;
      if (approvers) {
        this.processes.forEach((process) => {
          if (process.processId === approvers.key) {
            process.selectedItem = [...approvers.value];
          }
        });

        this.setApprover();
      }
    }
  },
  mounted() {
    this.getProcess();
  },
  methods: {
    onHandleRemove(user, processId) {
      this.processes.forEach((process) => {
        if (process.id === processId) {
          process.selectedItem = process.selectedItem.filter((item) => item.id !== user.id);
        }
      });

      this.setApprover();
    },
    onApproverClick(process) {
      this.push(this.page[0].component, this.page[0].label, {
        processId: process.processId,
        default: process.selectedItem,
        masterProcessId: process.id
      });
    },
    async getProcess() {
      this.loading = true;
      let process = [];
      await fetchProcess({ module_code: this.moduleCode }).then((response) => {
        process = response["hydra:member"].map((item) => {
          return {
            id: item.id,
            processId: item.process.id,
            title: item.process.name,
            desc: "Select " + item.process.name,
            selectedItem: [],
            isAllastarsea: false,
            optional: item.optional
          };
        });
      });
      // eslint-disable-next-line no-unused-vars
      let process1 = _.cloneDeep(process);
      let process2 = _.cloneDeep(process);

      //load group approvers
      let userProcess = [];
      await fetchUserProcess(this.moduleCode).then((response) => {
        userProcess = response["hydra:member"];
      });
      userProcess.forEach((item) => {
        let i = process1.find((obj) => obj.processId === item.process.id);
        i.selectedItem = item.default_approvers;

        i.isAllastarsea = i.selectedItem.filter((y) => y.is_allastarsea_group_member === true).length > 0;
      });
      //end group approvers

      //load saved approvers
      let userApprovers = [];
      await fetchSavedUserProccess(this.moduleCode).then((response) => {
        userApprovers = response["hydra:member"];
      });

      if (userApprovers.length > 0) {
        userApprovers.forEach((item) => {
          let i = process2.find((obj) => obj.id === item.master_process_id);
          //find if is default group approvers ? if so then use it's "isAllastarsea" property, if not then use saved property
          let x = process1.find((obj) => obj.id === item.master_process_id);
          if (i && x) {
            if (x.selectedItem) {
              i.isAllastarsea = x.selectedItem.id === item.approver.id ? x.isAllastarsea : item.is_allastarsea_group_member;
            }

            i.selectedItem = item.approver;
          }
        });

        process = process2;
      } else {
        process = process1;
      }
      //end saved approvers

      this.loading = false;
      this.processes = process;
      this.$store.commit("app/SET_PROCESSES", process);
      this.setApprover();
    },
    setApprover() {
      this.approvers = this.processes.map((obj) => {
        let apps = obj.selectedItem ? obj.selectedItem.map((item) => item.id) : [];

        return {
          master_process_id: obj.id,
          approver_id: apps,
          optional: obj.optional
        };
      });
      this.$store.commit("app/SET_APPROVERS", this.approvers);
    },
    isCanSelect(item) {
      if (this.isAllastarseaGroupUser) {
        return true;
      } else {
        return !item.isAllastarsea;
      }
    },
    push(page, key, param) {
      this.$store.commit("navigator/options", {
        animation: "lift-ios"
      });
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped></style>
