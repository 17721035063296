<template>
  <v-ons-list-item>
    <div class="center">
      <span class="list-item__title" :class="{ required: required }">
        {{ label }}
      </span>
      <uploader
        @change.native="$emit('on-uploading')"
        :url="uploadFilesUrl"
        :headers="uploadFilesHeader"
        :show-header="false"
        :params="{
          document_type: documentType
        }"
        @on-success="onSuccessFiles"
        @on-error="onErrorFiles"
        @on-delete="onDeleteFiles"
        v-model="fileList"
        name="file"
        :title="sublabel"
        :limit="limit"
      />
    </div>
  </v-ons-list-item>
</template>

<script>
import { getToken } from "@/utils/auth";
import Uploader from "vux-uploader-component";
import { deleteFiles } from "@/api/rembursement";

export default {
  name: "form-upload",
  components: { Uploader },
  props: {
    documentType: {
      type: String
    },
    label: {
      type: String
    },
    sublabel: {
      type: String
    },
    limit: {
      type: Number
    },
    value: {
      type: Array
    },
    required: {
      type: Boolean,
      default: false
    },
    files: {
      type: Array
    }
  },
  data() {
    return {
      uploadFilesUrl: process.env.VUE_APP_BASE_URL + "/files",
      uploadFilesHeader: {
        authorization: getToken()
      },
      fileList: []
    };
  },
  watch: {
    files(n) {
      n && (this.fileList = n);
    }
  },
  methods: {
    onErrorFiles(xhr) {
      console.log("Error XHR", xhr);
    },
    onSuccessFiles(result, fileItem) {
      fileItem.data = result;
      this.$emit("on-success", this.fileList);
    },
    async onDeleteFiles(fileItem, delFn) {
      await deleteFiles(fileItem.data.id).then(() => {
        delFn();
      });
    }
  }
};
</script>

<style scoped></style>
