import Vue from "vue";
import VueI18n from "vue-i18n";
import { locales } from "./locales/locales";
import { getLang } from "@/utils/lang";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: getLang(), // set locale
  messages: locales // set locale messages
});

export default i18n;
