<template>
  <div>
    <v-ons-list>
      <v-ons-list-header />
      <form-select
        :label="$t('app.cc.contract_party')"
        :placeholder="$t('app.cc.select_party')"
        :required="true"
        modifier="quiet"
        :value="formObj.company_id ? formObj.company_id.name : null"
      />

      <form-select
        :label="$t('common.department')"
        :placeholder="$t('common.select_department')"
        :required="false"
        modifier="quiet"
        :value="formObj.department_id ? formObj.department_id.name : null"
      />

      <form-calendar
        :required="false"
        :label="$t('app.estimated_approval_date')"
        mode="single"
        @on-change="handleEstimatedApprovalDeadline"
        :placeholder="$t('common.select_date')"
        :value="formObj.estimated_approval_date ? moment(formObj.estimated_approval_date).format('YYYY-MM-DD') : null"
      />
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-header />
      <form-select
        :label="$t('app.cc.contract_type')"
        :placeholder="$t('app.cc.select_type')"
        :required="true"
        modifier="quiet"
        :value="formObj.contract_item_type_id ? formObj.contract_item_type_id.name : null"
      />

      <form-select
        :label="$t('common.currency')"
        :placeholder="$t('common.select_currency')"
        :required="true"
        modifier="quiet"
        :value="currencyCode"
      />

      <form-input
        kind="text"
        :label="$t('app.cc.contract_fullname')"
        :placeholder="$t('app.cc.enter_fullname')"
        :required="true"
        v-model="formObj.contract_full_name"
      />

      <form-input-mask
        kind="text"
        :required="false"
        :label="$t('app.cc.contract_period')"
        :placeholder="'YYYY-MM-DD'"
        :mask="{ mask: '9999-99-99', placeholder: 'YYYY-MM-DD' }"
        v-model="formObj.contract_period"
      />
    </v-ons-list>

    <br />
    <ons-list>
      <ons-list-header>{{ $t("app.item") }}</ons-list-header>
      <form-select
        v-for="(item, key) in local.items"
        :key="'cc_item_' + key"
        :label="$t('app.cc.items') + ' (' + ++key + ')'"
        @click="HandleDetailClick(item, { currencyObj: formObj.currency_id, contractTypeObj: formObj.contract_item_type_id })"
        :value="currencyCode + ' ' + Round(item.total, currencyCode)"
      />
      <v-ons-list-item>
        <div class="center" style="font-size: 13px; color: rgba(0, 0, 0, 0.6)">
          {{ $t("app.sc.add_more_info") }}
        </div>
      </v-ons-list-item>
    </ons-list>
    <v-ons-list class="mb-6">
      <v-ons-list-item class="p-0">
        <div class="center flex justify-center items-center p-0">
          <v-ons-button modifier="quiet" style="justify-content: center" @click="handleAddItem">
            <ons-icon icon="md-plus"> </ons-icon>
            {{ $t("common.add_more") }}
          </v-ons-button>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <div class="mb-6" />

    <br />
    <v-ons-list class="border-b border ">
      <v-ons-list-item>
        <div class="left">{{ $t("common.total") }}</div>
        <div class="center text-xl" style="margin-left: 10px">
          {{ currencyCode }}
          <span class="text-orange ml-2 font-bold">
            {{ Round(formObj.amount, currencyCode) }}
          </span>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-header />
      <form-uploader
        :label="$t('app.cc.plan')"
        :sublabel="$t('app.cc.plan_info')"
        :multiple="true"
        :required="false"
        :documentType="FILE_TYPE.CONTRACT_PLAN"
        :existing="formObj.contract_plan_files"
        :canDelete="false"
        @on-success="onUpload($event, FILE_TYPE.CONTRACT_PLAN)"
      />
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-header />
      <v-ons-list-item modifier="nodivider">
        <v-ons-col width="30%">
          <label>{{ $t("app.cc.stamp") }}</label>
        </v-ons-col>
        <v-ons-col style="margin-left: 10px"> {{ param.stampNo ? param.stampNo : "-" }} </v-ons-col>
      </v-ons-list-item>
      <form-uploader
        :label="$t('app.cc.signed')"
        :sublabel="$t('app.cc.signed_info')"
        :multiple="true"
        :documentType="FILE_TYPE.CONTRACT_SIGNED"
        :existing="formObj.signed_contract_files"
        :canDelete="false"
        @on-success="onUpload($event, FILE_TYPE.CONTRACT_SIGNED)"
      />
    </v-ons-list>

    <br />
    <v-ons-list>
      <form-select-user :label="$t('app.forward_document')" :users="formObj.user_finances ? formObj.user_finances : []" :editable="false" />
    </v-ons-list>

    <bottom-submit @click.native="handleSubmit" :loading="submitting" />
  </div>
</template>

<script>
import _ from "lodash";
import ApplicationEdit from "@/mixins/application_edit";

const itemConst = [
  {
    code: "goods",
    data: "item_goods",
    total: "unit_price"
  },
  {
    code: "services",
    data: "item_services",
    total: "unit_price"
  },
  {
    code: "other",
    data: "item_other",
    total: "amount"
  }
];

export default {
  name: "CustomerContractEdit",
  mixins: [ApplicationEdit],
  data() {
    return {
      local: {
        moduleCode: "contract-customer",
        items: []
      },
      itemTypeObj: null,
      itemName: undefined
    };
  },
  computed: {
    currencyCode() {
      return this.formObj.currency_id ? this.formObj.currency_id.code : null;
    }
  },
  watch: {
    formObj: {
      deep: true,
      handler(n) {
        //set form
        let form = {
          company_id: n.company_id ? n.company_id.id : null,
          department_id: n.department_id ? n.department_id.id : null,
          currency_id: n.currency_id ? n.currency_id.id : null,
          contract_item_type_id: n.contract_item_type_id ? n.contract_item_type_id.id : null,
          contract_full_name: n.contract_full_name,
          contract_plan_files: n.contract_plan_files.length > 0 ? n.contract_plan_files.map((item) => item.id) : [],
          signed_contract_files: n.signed_contract_files.length > 0 ? n.signed_contract_files.map((item) => item.id) : [],
          item_goods: n.item_goods,
          item_services: n.item_services,
          item_other: n.item_other,
          estimated_approval_date: n.estimated_approval_date,
          contract_period: n.contract_period,
          amount: n.amount
        };
        this.form = Object.assign({}, form);
      }
    },
    "local.items": function(n) {
      if (n) {
        this.formObj.amount = n.reduce((sum, obj) => sum + obj.total, 0);
        if (this.itemTypeObj && this.itemTypeObj.data) {
          this.formObj[this.itemTypeObj.data] = n;
        }
      }
    }
  },
  methods: {
    setDefaultLocalData() {
      if (this.formObj.contract_item_type_id) {
        let item = itemConst.find((i) => i.code === this.formObj.contract_item_type_id.code);

        if (item) {
          this.itemTypeObj = item;
          this.formObj[item.data] = this.formObj[item.data].map((item, key) => ({
            ...item,
            total: this.getTotalItem(item),
            index: key
          }));

          this.defaultForm = _.cloneDeep(this.formObj);
          this.local.items = this.formObj[item.data];
        }
      }
    },
    handleAddItem() {
      this.local.items = [...this.local.items, { index: this.local.items.length, total: 0 }];
    },
    getTotalItem(item) {
      if (this.itemTypeObj.code === "services") {
        return item.unit_price;
      }

      if (this.itemTypeObj.code === "other") {
        return item.amount;
      }

      return item.total ? item.total : 0;
    },
    handleShow() {
      let contractItem = this.$store.getters.data;

      this.itemName = contractItem.name;
      if (contractItem) {
        this.local.items = this.local.items.map((obj) => {
          if (obj.index === contractItem.index) {
            return { ...contractItem?.item };
          } else {
            return obj;
          }
        });

        if (this.itemName) {
          this.formObj[this.itemName] = this.local.items.map((item) => item.item);
        }
      }

      this.$store.commit("app/SET_DATA", {});
    },

    handleEstimatedApprovalDeadline(val) {
      this.formObj.estimated_approval_date = val;
    },
    onUpload(file, type) {
      if (type == this.FILE_TYPE.CONTRACT_PLAN) {
        this.formObj.contract_plan_files = file;
      }
      if (type == this.FILE_TYPE.CONTRACT_SIGNED) {
        this.formObj.signed_contract_files = file;
      }
    }
  }
};
</script>

<style></style>
