import { getCurrency } from "@/api/rembursement";
import { fetchCompanyDetail as getCompany } from "@/api/company";
import { fetchDepartmentDetail as getDepartment } from "@/api/department";
import { getContractTypeById } from "@/api/contractSupplier";
import { fetchCompanyBankDetail as getBankAccount } from "@/api/bank_account";
import { fetchExpenseAccountDetail as getExpenseAccount } from "@/api/expense_account";
import { isNumeric } from "@/utils/helper";

export default {
  name: "bufferMixin",
  data() {
    return {
      previewShow: false,
      previewImg: "",
      currency: "",
      company: "",
      department: "",
      contractType: null,
      bankAccountObj: null,
      expenseAccountObj: null
    };
  },
  computed: {
    topbar() {
      return document.getElementsByTagName("ons-toolbar")[2];
    },
    bottomBar() {
      return document.getElementsByTagName("ons-bottom-toolbar")[0];
    }
  },
  methods: {
    preview(url) {
      // if (view) {
      //   this.previewImg = url;
      //   this.previewShow = true;

      //   if (this.topbar) {
      //     this.topbar.style.zIndex = "0";
      //   }
      //   if (this.bottomBar) {
      //     this.bottomBar.style.zIndex = "0";
      //   }

      //   return;
      // }
      window.open(url, "_blank");
    },
    fetchCurrency(id) {
      if (!isNumeric(id)) {
        return;
      }

      getCurrency(id).then((response) => {
        this.currency = response.code;
      });
    },
    fetchCompany(id) {
      if (!isNumeric(id)) {
        return;
      }

      getCompany(id).then((response) => {
        this.company = response.name;
      });
    },
    fetchDepartment(id) {
      if (!isNumeric(id)) {
        return;
      }

      getDepartment(id).then((response) => {
        this.department = response.name;
      });
    },
    fetchContractType(id) {
      if (!isNumeric(id)) {
        return;
      }

      getContractTypeById(id).then((response) => {
        this.contractType = response;
      });
    },
    fetchBankAccount(id) {
      if (!isNumeric(id)) {
        return;
      }

      getBankAccount(id).then((response) => {
        this.bankAccountObj = response;
      });
    },
    fetchExpenseAccount(id) {
      if (!isNumeric(id)) {
        return;
      }

      getExpenseAccount(id).then((response) => {
        this.expenseAccountObj = response;
      });
    }
  }
};
