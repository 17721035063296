<template>
  <div class="px-3">
    <div class="bg-white rounded-md shadow-sm border-gray-200">
      <div class="flex justify-between items-center border-b px-3 py-2" @click="showDetail = !showDetail">
        <div class="space-x-2">
          <span>{{ data.bank_name + " - " + data.bank_branch }}</span>
          <span class="text-xs text-gray-500">{{ data.bank_type }}</span>
        </div>
        <div>
          <icon name="chevron-down" classes="h-4 w-4" :class="{ 'transform rotate-180': showDetail }" />
        </div>
      </div>
      <div class="py-2 px-3" v-show="showDetail">
        <div class="grid grid-cols-2">
          <div>
            <div class="text-2xl">{{ data.count_transaction }}</div>
            <div class="text-xs text-gray-400">{{ $t("stat.proj.pending") }}</div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "paymentProjectionCard",
  props: ["data"],
  data() {
    return {
      showDetail: true
    };
  }
};
</script>

<style scoped></style>
