<template>
  <div>
    <v-ons-list>
      <v-ons-list-header />
      <form-select
        :label="$t('app.sc.our_contract_party')"
        :required="true"
        :placeholder="$t('app.sc.select_contract_party')"
        modifier="quiet"
        :value="formObj.company_id ? formObj.company_id.name : null"
      />
      <form-select
        :label="$t('common.department')"
        :required="false"
        :placeholder="$t('common.select_department')"
        modifier="quiet"
        :value="formObj.department_id ? formObj.department_id.name : null"
      />
      <form-calendar
        :required="false"
        :label="$t('app.estimated_approval_date')"
        mode="single"
        @on-change="handleEstimatedApprovalDeadline"
        :placeholder="$t('common.select_date')"
        :value="formObj.estimated_approval_date ? moment(formObj.estimated_approval_date).format('YYYY-MM-DD') : null"
      />
    </v-ons-list>

    <br />
    <ons-list>
      <form-select
        :label="$t('app.sc.contract_type')"
        :required="true"
        :placeholder="$t('common.select')"
        modifier="quiet"
        :value="formObj.contract_item_type_id ? formObj.contract_item_type_id.name : null"
      />

      <form-select
        :label="$t('common.currency')"
        :required="true"
        :placeholder="$t('common.select_currency')"
        modifier="quiet"
        :value="currencyCode"
      />

      <form-select
        :label="$t('app.sc.expense_account')"
        :required="false"
        :placeholder="$t('app.sc.select_account')"
        modifier="quiet"
        :value="formObj.expense_account_id ? formObj.expense_account_id.name : null"
      />

      <form-input
        kind="text"
        :required="true"
        :label="itemTypeObj && itemTypeObj.code === 'hr' ? $t('app.sc.employee_fullname') : $t('app.sc.contract_fullname')"
        :placeholder="$t('app.sc.enter_contract_fullname')"
        v-model="formObj.contract_full_name"
      />

      <form-input kind="text" :label="$t('app.sc.supplier_fullname')" :placeholder="$t('app.sc.enter_name')" v-model="formObj.supplier_full_name" />

      <div>
        <ons-list-item modifier="quiet" tappable class="border-b-0">
          <div class="center required">
            <span class="list-item__title ml-1">
              {{ itemTypeObj && itemTypeObj.code === "hr" ? $t("app.sc.employee_account_detail") : $t("app.sc.supplier_account_detail") }}
            </span>
          </div>
        </ons-list-item>
        <ons-list-item>
          <div class="center pt-0">
            <div class="w-full">
              <div class=" flex text-sm">
                <div class="w-1/2 text-gray-400">{{ $t("app.account.name") }}</div>
                <div>{{ formObj.supplier_account ? formObj.supplier_account.name : "" }}</div>
              </div>
              <div class=" flex text-sm">
                <div class="w-1/2 text-gray-400">{{ $t("app.account.number") }}</div>
                <div>{{ formObj.supplier_account ? formObj.supplier_account.no : "" }}</div>
              </div>
              <div class=" flex text-sm">
                <div class="w-1/2 text-gray-400">{{ $t("app.account.bank") }}</div>
                <div>{{ formObj.supplier_account ? formObj.supplier_account.bank_name : "" }}</div>
              </div>
              <div class=" flex text-sm">
                <div class="w-1/2 text-gray-400">{{ $t("app.account.branch") }}</div>
                <div>{{ formObj.supplier_account ? formObj.supplier_account.branch_name : "" }}</div>
              </div>
            </div>
          </div>
        </ons-list-item>
      </div>

      <form-input-mask
        kind="text"
        :required="false"
        :label="$t('app.sc.contract_period')"
        :placeholder="'YYYY-MM-DD'"
        :mask="{ mask: '9999-99-99', placeholder: 'YYYY-MM-DD' }"
        v-model="formObj.contract_period"
      />

      <v-ons-list-item>
        <label class="center">
          {{ $t("app.sc.auto_renew") }}
        </label>
        <div class="right">
          <v-ons-switch v-model="formObj.auto_renewal"> </v-ons-switch>
        </div>
      </v-ons-list-item>
    </ons-list>

    <br />
    <ons-list>
      <ons-list-header>{{ $t("app.item") }}</ons-list-header>
      <form-select
        v-for="(purchase, key) in local.items"
        :key="'item_purchase_' + key"
        :label="$t('app.item') + ' (' + ++key + ')'"
        @click="HandleDetailClick(purchase, { currencyObj: formObj.currency_id, contractType: formObj.contract_item_type_id })"
        :value="currencyCode + ' ' + Round(purchase.amount, currencyCode)"
      />
      <v-ons-list-item>
        <div class="center" style="font-size: 13px; color: rgba(0, 0, 0, 0.6)">
          {{ $t("app.sc.add_more_info") }}
        </div>
      </v-ons-list-item>
    </ons-list>
    <v-ons-list class="mb-6">
      <v-ons-list-item class="p-0">
        <div class="center flex justify-center items-center p-0">
          <v-ons-button modifier="quiet" style="justify-content: center" @click="handleAddItem">
            <ons-icon icon="md-plus"> </ons-icon>
            {{ $t("common.add_more") }}
          </v-ons-button>
        </div>
      </v-ons-list-item>
    </v-ons-list>
    <div class="mb-6" />

    <br />
    <v-ons-list class="border-b border ">
      <v-ons-list-item>
        <div class="left">{{ $t("common.total") }}</div>
        <div class="center text-xl" style="margin-left: 10px">
          {{ currencyCode }}
          <span class="text-orange ml-2 font-bold">
            {{ Round(formObj.amount, currencyCode) }}
          </span>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <v-ons-list>
      <form-uploader
        :label="$t('app.sc.upload_contract_plan')"
        :sublabel="$t('app.sc.select_file')"
        :multiple="true"
        :required="false"
        :documentType="FILE_TYPE.CONTRACT_PLAN"
        :existing="formObj.contract_plan_files"
        :canDelete="false"
        @on-success="onUpload($event, FILE_TYPE.CONTRACT_PLAN)"
      />
    </v-ons-list>

    <v-ons-list class="mt-6">
      <v-ons-list-header>
        {{ $t("app.sc.after_approval_before_sign") }}
      </v-ons-list-header>
      <v-ons-list-item modifier="nodivider">
        <v-ons-col width="30%">
          <label>{{ $t("app.sc.stamp") }}</label>
        </v-ons-col>
        <v-ons-col style="margin-left: 10px"> {{ param.stampNo ? param.stampNo : "-" }} </v-ons-col>
      </v-ons-list-item>

      <form-uploader
        :label="$t('app.sc.upload_signed_contract')"
        :sublabel="$t('app.sc.select_file')"
        :multiple="true"
        :documentType="FILE_TYPE.CONTRACT_SIGNED"
        :existing="formObj.signed_contract_files"
        :canDelete="false"
        @on-success="onUpload($event, FILE_TYPE.CONTRACT_SIGNED)"
      />
    </v-ons-list>

    <v-ons-list class="mt-6">
      <form-select
        :label="$t('app.sc.frequency')"
        :placeholder="$t('app.sc.select_frequency')"
        modifier="quiet"
        :value="formObj.contract_frequency_id ? formObj.contract_frequency_id.name : null"
      />

      <form-input
        kind="text"
        :required="false"
        :label="$t('app.sc.payment_deadline')"
        :placeholder="$t('app.sc.payment_deadline')"
        v-model="formObj.payment_deadline_text"
      />
    </v-ons-list>

    <br />
    <v-ons-list>
      <form-select-user :label="$t('app.forward_document')" :users="formObj.user_finances ? formObj.user_finances : []" :editable="false" />
    </v-ons-list>

    <bottom-submit @click.native="handleSubmit" />
  </div>
</template>

<script>
import _ from "lodash";
import ApplicationEdit from "@/mixins/application_edit";

const itemConst = [
  {
    code: "hr",
    data: "item_hr",
    total: "amount"
  },
  {
    code: "goods",
    data: "item_goods",
    total: "amount"
  },
  {
    code: "services",
    data: "item_services",
    total: "unit_price"
  },
  {
    code: "other",
    data: "item_other",
    total: "amount"
  }
];

export default {
  name: "SupplierContractEdit",
  mixins: [ApplicationEdit],
  data() {
    return {
      itemTypeObj: null,
      local: {
        moduleCode: "contract-supplier",
        items: []
      }
    };
  },
  computed: {
    currencyCode() {
      return this.formObj.currency_id ? this.formObj.currency_id.code : null;
    }
  },
  watch: {
    formObj: {
      deep: true,
      handler(n) {
        //set form
        let form = {
          company_id: n.company_id ? n.company_id.id : null,
          department_id: n.department_id ? n.department_id.id : null,
          currency_id: n.currency_id ? n.currency_id.id : null,
          contract_item_type_id: n.contract_item_type_id ? n.contract_item_type_id.id : null,
          expense_account_id: n.expense_account_id ? n.expense_account_id.id : null,
          contract_full_name: n.contract_full_name,
          supplier_full_name: n.supplier_full_name,
          contract_frequency_id: n.contract_frequency_id ? n.contract_frequency_id.id : null,
          payment_deadline: n.payment_deadline,
          payment_deadline_text: n.payment_deadline_text,
          supplier_account_id: n.supplier_account_id ? n.supplier_account_id.id : null,
          supplier_account: n.supplier_account,
          contract_plan_files: n.contract_plan_files.length > 0 ? n.contract_plan_files.map((item) => item.id) : [],
          signed_contract_files: n.signed_contract_files.length > 0 ? n.signed_contract_files.map((item) => item.id) : [],
          item_goods: n.item_goods,
          item_services: n.item_services,
          item_hr: n.item_hr,
          item_other: n.item_other,
          auto_renewal: n.auto_renewal,
          contract_period: n.contract_period,
          estimated_approval_date: n.estimated_approval_date,
          amount: n.amount
        };
        this.form = Object.assign({}, form);
      }
    },
    "local.items": {
      deep: true,
      handler(n) {
        if (n) {
          this.formObj.amount = n.reduce((sum, obj) => sum + (obj.amount ? obj.amount : obj.total), 0);
          if (this.itemTypeObj && this.itemTypeObj.data) {
            this.formObj[this.itemTypeObj.data] = n;
          }
        }
      }
    }
  },
  methods: {
    setDefaultLocalData() {
      if (this.formObj.contract_item_type_id) {
        let item = itemConst.find((i) => i.code === this.formObj.contract_item_type_id.code);
        if (item) {
          this.itemTypeObj = item;
          this.formObj[item.data] = this.formObj[item.data].map((item, key) => ({
            ...item,
            index: key
          }));

          this.defaultForm = _.cloneDeep(this.formObj);
          this.local.items = this.formObj[item.data].map((o) => ({
            ...o,
            total: o[item.total],
            amount: o[item.total]
          }));
          // this.local.items = this.formObj[item.data];
        }
      }
    },
    handleAddItem() {
      this.local.items = [...this.local.items, { index: this.local.items.length, amount: 0 }];
    },
    handleShow() {
      let param = this.$store.getters["navigator/getParam"];
      let contractItems = param?.contractItems;

      if (contractItems) {
        this.local.items = this.local.items.map((obj) => {
          if (obj.index === contractItems.index) {
            return { ...contractItems };
          } else {
            return obj;
          }
        });

        this.formObj[this.itemTypeObj.data] = this.local.items;
      }
    },
    handleEstimatedApprovalDeadline(val) {
      this.formObj.estimated_approval_date = val;
    },
    onUpload(file, type) {
      if (type == this.FILE_TYPE.CONTRACT_PLAN) {
        this.formObj.contract_plan_files = file;
      }
      if (type == this.FILE_TYPE.CONTRACT_SIGNED) {
        this.formObj.signed_contract_files = file;
      }
    }
  }
};
</script>

<style></style>
