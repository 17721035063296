<template>
  <v-ons-page>
    <custom-toolbar v-bind="toolbarInfo">
      <v-ons-toolbar-button slot="right" id="info-button" @click="handleCreate">
        <span>{{ $t("common.add") }}</span>
      </v-ons-toolbar-button>
    </custom-toolbar>

    <search-filter @on-search="onSearch" @on-reset="onReset" />

    <div class="no-result" v-if="listCount === 0">
      <Icon name="credit-card" classes="text-gray-300" size="82" />
      <span class="text-gray-500 mt-4">{{ $t("common.empty") }}</span>
    </div>

    <v-ons-list v-show="listCount > 0" class="pt-14">
      <v-ons-list-item v-for="(item, key) in list" :key="key">
        <div class="center" @click="handleEdit(item)">
          <span class="list-item__subtitle">
            <div class="flex">
              <span class="w-28">{{ $t("stat.bank_.name") }}</span>
              <span class="text-gray-900">{{ item.bank_name }}</span>
            </div>
            <div class="flex">
              <span class="w-28">{{ $t("stat.bank_.branch") }}</span>
              <span class="text-gray-900">{{ item.branch }}</span>
            </div>
            <div class="flex">
              <span class="w-28">{{ $t("stat.bank_.no") }}</span>
              <span class="text-gray-900">{{ item.number }}</span>
            </div>
            <div class="flex">
              <span class="w-28">{{ $t("stat.bank_.type") }}</span>
              <span class="text-gray-900">{{ item.type }}</span>
            </div>
          </span>
        </div>

        <div class="right">
          <v-ons-button modifier="quiet" @click="handleDelete(item)">
            <Icon name="trash" classes="icon list-item__thumbnail" />
          </v-ons-button>
          <v-ons-button modifier="quiet" @click="handleEdit(item)">
            <Icon name="edit" classes="icon list-item__thumbnail" />
          </v-ons-button>
        </div>
      </v-ons-list-item>
    </v-ons-list>
    <infinite-loading @infinite="getData" />
  </v-ons-page>
</template>

<script>
import { fetchListByCompany as fetchList } from "@/api/bank_account";
import SearchFilter from "@/components/SearchFilter";
import BankAccountForm from "@/views/Statistic/CompanyBankAccount/BankAccountForm";

const defaultFilter = { page: 1, itemsPerPage: 10, bankName: "" };
export default {
  name: "BankAccountList",
  components: { SearchFilter },
  computed: {
    companyId() {
      return this.toolbarInfo ? this.toolbarInfo.param.id : null;
    }
  },
  data() {
    return {
      list: [],
      listCount: 0,
      itemData: {},
      filter: Object.assign({}, defaultFilter),
      pages: [BankAccountForm],
      action: null
    };
  },
  methods: {
    getData($state) {
      fetchList(this.toolbarInfo.param.id, this.filter).then((response) => {
        let data = response["hydra:member"];
        this.listCount = response["hydra:totalItems"];

        this.list.push(...data);

        if (data.length) {
          this.filter.page += 1;
          $state && $state.loaded();
        } else {
          $state && $state.complete();
        }
      });
    },
    onSearch(terms) {
      this.list = [];
      this.filter.page = 1;
      this.filter.bankName = terms;
      this.getData();
    },
    onReset() {
      this.list = [];
      this.filter = Object.assign({}, defaultFilter);
      this.getData();
    },
    handleCreate() {
      this.itemData = {};
      this.action = "create";
      this.showForm();
    },
    handleEdit(item) {
      this.itemData = item;
      this.action = "edit";
      this.showForm();
    },
    handleDelete(item) {
      this.itemData = item;
      this.action = "delete";
      this.showForm();
    },
    resetFilter() {
      this.list = [];
      this.filter = Object.assign({}, defaultFilter);
    },
    showForm() {
      this.push(this.pages[0], this.$t("stat.bank_.account"), this.itemData, this.action);
    },
    push(page, key, param, action) {
      let company = this.companyId;

      this.$store.commit("navigator/options", {
        animation: "lift-ios"
      });
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: {
                item: param,
                action: action,
                company: company
              }
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped></style>
