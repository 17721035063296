import { login, getInfo } from "@/api/login";
import { getToken, setToken, removeToken } from "@/utils/auth";

const user = {
  namespaced: true,
  state: {
    token: getToken(),
    name: "",
    roles: [],
    profile: null,
    error: null,
    expenses: [],
    datalang: null
  },

  getters: {
    getProfile(state) {
      return state.profile;
    }
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PROFILE: (state, profile) => {
      state.profile = profile;
    },
    SET_ERROR: (state, error) => {
      state.error = error;
    },
    SET_EXPENSES: (state, expenses) => {
      state.expenses = expenses;
    },
    SET_DATALANG: (state, datalang) => {
      state.datalang = datalang;
    }
  },

  actions: {
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim();
      return new Promise((resolve, reject) => {
        login(username, userInfo.password)
          .then((response) => {
            const tokenStr = "Bearer " + response.token;
            setToken(tokenStr);
            commit("SET_TOKEN", tokenStr);
            commit("SET_ERROR", null);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get info
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((response) => {
            const data = response.data;
            if (data.roles && data.roles.length > 0) {
              commit("SET_ROLES", data.roles);
            } else {
              reject("getInfo: roles must be a non-null array !");
            }
            commit("SET_NAME", data.username);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // logout
    LogOut({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "");
        commit("SET_ROLES", []);
        removeToken();
        resolve();
      });
    },

    // clear token
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "");
        removeToken();
        resolve();
      });
    }
  }
};

export default user;
