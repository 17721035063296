<template>
  <v-ons-page @show="showPage" @init="initPage">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <v-ons-progress-bar indeterminate v-if="loading"></v-ons-progress-bar>
    <div>
      <v-ons-list>
        <v-ons-list-header> <br />{{ $t("app.pay.payment") }} ({{ index }}) </v-ons-list-header>

        <form-input :max="12" :label="$t('app.pay.month')" :placeholder="$t('app.pay.enter_month')" v-model.number="form.payment_information.month" />

        <form-input :label="$t('app.page_application')" :placeholder="$t('common.enter_number')" v-model.number="form.page_application" />
      </v-ons-list>

      <!-- search for supplier by text -->
      <v-ons-list>
        <v-ons-list-header />

        <div class="flex items-center px-2 py-4 space-x-4 w-full text-sm border-b border-gray-100">
          <label class="left">
            <v-ons-checkbox :input-id="'checkbox-drawdown'" v-model="form.is_drawdown"> </v-ons-checkbox>
          </label>
          <label class="center" :for="'checkbox-drawdown'"> {{ $t("app.is_drawdown") }} </label>
        </div>

        <div v-if="!form.is_drawdown">
          <supplier-input :text="form.payment_recipient_account.name" @result="handleSupplierSearchResult" />

          <form-input
            :required="true"
            kind="text"
            :label="$t('app.account.number')"
            :placeholder="$t('app.pay.enter_recipient_account')"
            v-model="form.payment_recipient_account.no"
          />

          <form-input
            :required="true"
            kind="text"
            :label="$t('app.account.bank')"
            :placeholder="$t('common.enter_bank_name')"
            v-model="form.payment_recipient_account.bank_name"
          />

          <form-input
            :required="true"
            kind="text"
            :label="$t('app.account.branch')"
            :placeholder="$t('common.enter_branch_name')"
            v-model="form.payment_recipient_account.branch_name"
          />
        </div>
      </v-ons-list>

      <!-- <br />
      <v-ons-list>
        <ons-list-item modifier="chevron" tappable class="border-b-0" @click="handleSupplierSelect">
          <div class="center required">
            <span class="list-item__title ml-1">
              {{ $t("app.pay.recipient") }}
            </span>
          </div>
        </ons-list-item>
        <ons-list-item>
          <div class="center pt-0">
            <div class="w-full">
              <div class=" py-1 text-sm">
                <div class="w-full text-gray-400">
                  {{ $t("app.pay.recipient") }}
                </div>
                <div>{{ form.payment_recipient_account.name }}</div>
              </div>
              <div class=" py-1 text-sm">
                <div class="w-full text-gray-400">{{ $t("app.pay.recipient_account") }}</div>
                <div>{{ form.payment_recipient_account.no }}</div>
              </div>
              <div class=" py-1 text-sm">
                <div class="w-full text-gray-400">{{ $t("common.bank_name") }}</div>
                <div>{{ form.payment_recipient_account.bank_name }}</div>
              </div>
              <div class=" py-1 text-sm">
                <div class="w-full text-gray-400">{{ $t("app.sc.branch") }}</div>
                <div>{{ form.payment_recipient_account.branch_name }}</div>
              </div>
            </div>
          </div>
        </ons-list-item>
      </v-ons-list> -->

      <!-- content -->
      <v-ons-list>
        <v-ons-list-header />
        <v-ons-list-item>
          <div class="right">
            <ons-button modifier="quiet" @click="addPaymentContent">
              <Icon name="plus-circle" size="25" stroke="1" classes="text-blue" />
            </ons-button>
          </div>
          <div class="center">
            <div class="list-item__title required">
              {{ $t("common.content") }}
            </div>
            <div class="list-item__subtitle">
              {{ $t("app.pay.payment_content") }}
            </div>
          </div>
        </v-ons-list-item>

        <!-- content list-->
        <content-list
          v-if="form.content.length > 0"
          :content="form.content"
          :selectedCurrency="selectedCurrency"
          @delete="onContentDelete"
          @edit="onContentEdit"
        />
      </v-ons-list>

      <!-- related approval list -->
      <v-ons-list class="mb-16">
        <v-ons-list-header>
          <br />
          {{ $t("common.related_approval") }}
        </v-ons-list-header>

        <related-list
          v-if="form.approval"
          :type="form.approval.type"
          :currency="form.approval.currency"
          :data="form.related_items"
          @edit="onRelatedEdit"
          @delete="onRelatedDelete"
        />
        <div v-else class="text-gray-300 p-5">{{ $t("common.empty") }}</div>
      </v-ons-list>

      <!-- content form -->
      <payment-content
        v-model="showBottomMenu"
        :content-form="contentForm"
        :selected-currency="selectedCurrency"
        :expense-list="expenseList"
        @onSubmitContent="onSubmitContent"
        @onExpenseChange="onExpenseChange"
        @closeBottomMenu="resetContent"
      />

      <select-supplier :show="showSupplierSelect" :suppliers="suppliers" @close="handleSupplierSelectClose" @confirm="handleSupplierSelectConfirm" />

      <!-- related form -->
      <component
        v-if="form.approval"
        :is="form.approval.type + '_form'"
        :show="showRelatedMenu"
        :data="relatedForm"
        :title="form.approval.code"
        @cancel="resetRelated"
        @save="onSubmitRelated"
      />
      <bottom-submit :label="$t('common.next')" @click.native="onSubmitPaymentDetail" classes="bg-blue-500" :loading="loading" />
    </div>
  </v-ons-page>
</template>

<script>
import applicationDetail from "@/mixins/application_detail";
import { mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import { fetchAll as fetchExpenseList } from "@/api/expense_account";

import ContractPartyList from "@/views/common/list/ContractPartyList";
import DepartmentList from "@/views/common/list/DepartmentList";
import RelatedApproval from "@/views/common/list/related_approval/RelatedApproval";
import ExpenseDetailList from "@/views/common/list/ExpenseDetailList";
import PaymentContent from "@/views/Application/Payment/component/PaymentContent";
import SupplierInput from "@/views/Application/Payment/component/search/supplierInput";
import SelectSupplier from "@/views/Application/Payment/component/SelectSupplier";
//content & related approval list
import ContentList from "@/views/Application/Payment/component/ContentList";
import RelatedList from "@/views/Application/Payment/component/RelatedList";
//related form
import RelatedGoodsForm from "@/views/Application/Payment/component/form/RelatedGoodsForm";
import RelatedServicesForm from "@/views/Application/Payment/component/form/RelatedServicesForm";
import RelatedHrForm from "@/views/Application/Payment/component/form/RelatedHrForm";
import RelatedOtherForm from "@/views/Application/Payment/component/form/RelatedOtherForm";

//supplier selection
// import { search as searchSupplier } from "@/api/supplier";

const defaultContent = {
  expense: null,
  amount: null,
  date: null,
  notes: null,
  destination: null,
  edited: false
};

export default {
  name: "paymentDetailForm",
  mixins: [applicationDetail],
  components: {
    "payment-content": PaymentContent,
    "supplier-input": SupplierInput,
    "select-supplier": SelectSupplier,
    "content-list": ContentList,
    "related-list": RelatedList,
    goods_form: RelatedGoodsForm,
    services_form: RelatedServicesForm,
    hr_form: RelatedHrForm,
    other_form: RelatedOtherForm
  },
  watch: {
    "form.company": function(n) {
      if (n) {
        this.expenseFilter.companyId = n.id;
        this.loadExpenses();
      }
    },
    "form.payment_information.month": function(n) {
      if (n == "") {
        this.form.payment_information.month = null;
      }
    },
    "form.page_application": function(n) {
      if (n == "") {
        this.form.page_application = null;
      }
    },
    "form.is_drawdown": function(n) {
      if (n) {
        this.form.payment_recipient_account = Object.assign(
          {},
          {
            name: null,
            no: null,
            bank_name: null,
            branch_name: null
          }
        );
      }
    }
  },
  data: () => ({
    showBottomMenu: false,
    showRelatedMenu: false,
    showSupplierSelect: false,
    suppliers: [],
    loading: true,
    expenseList: [],
    index: null,
    selectedCurrency: null,
    form: {
      page_application: null,
      payment_information: {
        month: null,
        estimated_payment_date: null
      },
      payment_recipient_account: {
        name: null,
        no: null,
        bank_name: null,
        branch_name: null
      },
      company: null,
      department: null,
      approval: null,
      content: [],
      related_approval_id: null,
      related_items: [],
      is_drawdown: false
    },
    currentIndex: null,
    currentRelatedIndex: null,
    contentForm: Object.assign({}, defaultContent),
    relatedForm: {},
    expenseFilter: {
      moduleCode: "payment",
      companyId: null
    },
    total: 0
  }),
  validations: {
    form: {
      // page_application: {
      //   required,
      //   minValue: minValue(1)
      // },
      payment_information: {
        // month: {
        //   required,
        //   between: between(1, 12)
        // },
        estimated_payment_date: {
          required
        }
      },
      payment_recipient_account: {
        name: {
          required
        },
        no: {
          required
        },
        bank_name: {
          required
        },
        branch_name: {
          required
        }
      }
    },
    contentForm: {
      expense: {
        required
      },
      amount: {
        required
      },
      date: {
        required
      },
      destination: {
        required
      }
    }
  },
  computed: {
    page() {
      return [
        {
          component: ContractPartyList,
          label: this.$t("common.select_company")
        },
        {
          component: DepartmentList,
          label: this.$t("common.select_department")
        },
        {
          component: RelatedApproval,
          label: this.$t("common.related_approval")
        },
        {
          component: ExpenseDetailList,
          label: this.$t("app.pay.select_expense")
        }
      ];
    }
  },
  methods: {
    ...mapMutations("navigator", ["clearParam", "setParam", "pop"]),
    onExpenseChange() {
      this.contentForm.date = null;
      this.contentForm.destination = null;
    },
    addPaymentContent() {
      this.showBottomMenu = !this.showBottomMenu;
    },
    showPage() {
      let param = this.$store.getters["navigator/getParam"];
      let selectedCompany = param?.selectedContractParty;
      if (selectedCompany) {
        this.expenseFilter.companyId = selectedCompany.id;
        this.loadExpenses();

        this.form.company = selectedCompany;
        this.form.department = null;
      }
      let selectedDepartment = param?.selectedDepartment;
      if (selectedDepartment) {
        this.form.department = selectedDepartment;
      }
      let selectedApproval = param.selectedApproval;
      if (selectedApproval) {
        this.form.approval = selectedApproval;
        this.form.related_items = selectedApproval.data;
        this.form.related_approval_id = selectedApproval.id;

        let supplierAccount = selectedApproval.supplier_account;
        if (supplierAccount) {
          this.form.payment_recipient_account = {
            name: supplierAccount.name ? supplierAccount.name : "",
            no: supplierAccount.no ? supplierAccount.no : "",
            bank_name: supplierAccount.bank_name ? supplierAccount.bank_name : "",
            branch_name: supplierAccount.branch_name ? supplierAccount.branch_name : ""
          };
        }
      }
      this.clearParam();
    },
    loadExpenses() {
      fetchExpenseList(this.expenseFilter).then((response) => {
        this.expenseList = response["hydra:member"].map((item) => ({
          id: item.id,
          name: item.name,
          is_transportation: item.is_transportation
        }));
      });
    },
    initPage() {
      // let param = this.toolbarInfo.param;
      // let payment = param.item;
      // if (payment.total > 0) {
      //   this.form = JSON.parse(JSON.stringify(payment));
      // }
      // this.index = payment.index;
      // let selectedCurrency = param.currency;
      // if (selectedCurrency) {
      //   this.selectedCurrency = selectedCurrency;
      // }

      let param = this.toolbarInfo.param;

      if (param.currency) {
        this.selectedCurrency = param.currency;
      }

      let item = param.item;
      this.index = item.index;
      if (item.company_id) {
        this.getCompany(item.company_id);
      }
      if (item.department_id) {
        this.getDepartment(item.department_id);
      }
      if (item.estimated_payment_date) {
        this.form.payment_information.estimated_payment_date = item.estimated_payment_date;
      }
      if (item.page_number) {
        this.form.page_application = item.page_number;
      }
      if (item.payment_information) {
        this.form.payment_information = item.payment_information;
      }
      if (item.payment_recipient_account) {
        this.form.payment_recipient_account = item.payment_recipient_account;
      }
      if (item.related_approval_id) {
        this.form.related_approval_id = item.related_approval_id;
        this.getRelatedApproval("payment", item.related_approval_id);
      }
      if (item.related_items) {
        this.form.related_items = item.related_items.map((item) => ({
          ...item.data
        }));
      }
      item.is_drawdown !== undefined ? (this.form.is_drawdown = item.is_drawdown) : (this.form.is_drawdown = false);

      if (item.related_supplier_account) {
        let account = item.related_supplier_account;
        this.form.payment_recipient_account = {
          name: account.name,
          no: account.no,
          bank_name: account.bank_name,
          branch_name: account.branch_name
        };
      }

      if (item.contents) {
        this.total = 0;
        item.contents.forEach((item) => {
          this.total += item.amount;
          this.getExpenseDetail(item.content_type_id, item, this.form.content);
        });
      }
      this.loading = false;
    },
    async onSubmitContent() {
      if (this.$v.contentForm.expense.$invalid) {
        await this.$ons.notification.alert("Please select Expense");
        return;
      }
      if (this.$v.contentForm.amount.$invalid) {
        await this.$ons.notification.alert("Please fill Amount");
        return;
      }
      if (this.contentForm.expense.name.toLowerCase() === "transportation") {
        if (this.$v.contentForm.date.$invalid) {
          await this.$ons.notification.alert("Please fill Date");
          return;
        }
        if (this.$v.contentForm.destination.$invalid) {
          await this.$ons.notification.alert("Please fill Destination");
          return;
        }
      }

      if (this.currentIndex !== null) {
        this.form.content = this.form.content.map((item, index) => {
          if (index === this.currentIndex) {
            return this.contentForm;
          } else {
            return item;
          }
        });
      } else {
        let content = { ...this.contentForm };
        this.form.content.push(content);
      }

      this.resetContent();
    },
    onSubmitRelated(relatedForm) {
      //only edit, no add
      if (this.currentRelatedIndex !== null) {
        relatedForm.edited = true;

        this.form.related_items = this.form.related_items.map((item, index) => {
          if (index === this.currentRelatedIndex) {
            return relatedForm;
          } else {
            return item;
          }
        });
      }

      this.resetRelated();
    },
    onContentDelete(index) {
      this.form.content = this.form.content.filter((obj, i) => i !== index);
    },
    onRelatedDelete(index) {
      this.form.related_items = this.form.related_items.filter((obj, i) => i !== index);
    },
    onContentEdit(content, index) {
      this.contentForm = content;
      this.currentIndex = index;
      this.showBottomMenu = true;
    },
    onRelatedEdit(related, index) {
      this.relatedForm = related;
      this.currentRelatedIndex = index;
      this.showRelatedMenu = true;
    },
    resetContent() {
      this.currentIndex = null;
      this.showBottomMenu = false;
      this.contentForm = Object.assign({}, defaultContent);
    },
    resetRelated() {
      this.currentRelatedIndex = null;
      this.showRelatedMenu = false;
      this.relatedForm = {};
    },
    async validate() {
      let options = { title: this.$t("common.notif.alert") };
      if (this.form.payment_information.month !== null && this.form.payment_information.month > 12) {
        await this.$ons.notification.alert(this.$t("app.pay.alert.month"), options);
        return false;
      }

      // if (!this.$v.form.payment_information.month.between) {
      //   await this.$ons.notification.alert(this.$t("app.pay.alert.month"), options);
      //   return false;
      // }
      // if (!this.$v.form.payment_information.month.required) {
      //   await this.$ons.notification.alert(this.$t("app.pay.alert.month"), options);
      //   return false;
      // }
      // if (this.$v.form.payment_information.estimated_payment_date.$invalid) {
      //   await this.$ons.notification.alert(this.$t("app.pay.alert.date"), options);
      //   return false;
      // }
      // if (this.$v.form.page_application.$invalid) {
      //   await this.$ons.notification.alert(this.$t("app.pay.alert.page"), options);
      //   return false;
      // }
      //if auto drawdown no need to show
      if (!this.form.is_drawdown) {
        if (this.$v.form.payment_recipient_account.name.$invalid) {
          await this.$ons.notification.alert(this.$t("app.pay.alert.recipient"), options);
          return false;
        }
        if (this.$v.form.payment_recipient_account.no.$invalid) {
          await this.$ons.notification.alert(this.$t("app.pay.alert.account_no"), options);
          return false;
        }
        if (this.$v.form.payment_recipient_account.bank_name.$invalid) {
          await this.$ons.notification.alert(this.$t("app.pay.alert.bank"), options);
          return false;
        }
        if (this.$v.form.payment_recipient_account.branch_name.$invalid) {
          await this.$ons.notification.alert(this.$t("app.pay.alert.branch"), options);
          return false;
        }
      }
      if (this.form.content.length === 0) {
        await this.$ons.notification.alert(this.$t("app.pay.alert.content"), options);
        return false;
      }

      return true;
    },
    onSubmitPaymentDetail() {
      this.validate().then((valid) => {
        if (valid) {
          let ret = {
            index: this.index,
            total: this.form.content.reduce((sum, obj) => sum + obj.amount, 0),
            page_number: this.form.page_application,
            currency_id: this.selectedCurrency.id,
            company_id: this.form.company.id,
            department_id: this.form.department.id,
            payment_information: this.form.payment_information,
            payment_recipient_account: this.form.payment_recipient_account,
            is_drawdown: this.form.is_drawdown,
            contents: this.form.content
              ? this.form.content.map((object) => {
                  return {
                    amount: object.amount,
                    destination: object.destination,
                    date: object.date,
                    note: object.notes,
                    content_type_id: object.expense.id
                  };
                })
              : [],
            related_approval_id: this.form.related_approval_id,
            related_items: this.form.related_items
              ? this.form.related_items.map((obj) => {
                  return {
                    item_id: obj.id,
                    is_edited: obj.edited ? obj.edited : false,
                    data: obj
                  };
                })
              : []
          };

          this.setParam({ paymentDetail: ret });
          this.pop();
        }
      });
    },
    handleEstimatedPaymentSelect(val) {
      this.form.payment_information.estimated_payment_date = val;
    },
    // handleSupplierSelect() {
    //   searchSupplier("")
    //     .then((response) => {
    //       this.suppliers = response["hydra:member"];
    //     })
    //     .finally(() => (this.showSupplierSelect = true));
    // },
    handleSupplierSearchResult(result, text) {
      this.suppliers = result;

      if (this.suppliers.length === 1) {
        let account = this.suppliers[0];
        this.form.payment_recipient_account = {
          name: account.name,
          no: account.no,
          bank_name: account.bankName,
          branch_name: account.branchName
        };
      } else if (this.suppliers.length > 1) {
        this.showSupplierSelect = true;
      } else {
        this.form.payment_recipient_account.name = text;
      }
    },
    handleSupplierSelectClose() {
      this.showSupplierSelect = false;
    },
    handleSupplierSelectConfirm(account) {
      this.showSupplierSelect = false;
      this.form.payment_recipient_account = {
        name: account.name,
        no: account.no,
        bank_name: account.bankName,
        branch_name: account.branchName
      };
    },
    push(page, key, param) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped></style>
