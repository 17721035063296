<template>
  <form-input-search
    kind="text"
    :required="true"
    :loading="loading"
    :label="$t('app.pay.recipient')"
    :placeholder="$t('app.pay.enter_recipient')"
    @input="onInputChange"
    v-model="supplier"
  />
</template>

<script>
import { search } from "@/api/supplier";

export default {
  name: "supplierInput",
  props: {
    showSupplierSearch: Boolean,
    text: String
  },
  watch: {
    text: function(n) {
      if (n) {
        this.supplier = n;
      }
    }
  },
  data() {
    return {
      loading: false,
      supplier: this.text && this.text.length > 1 ? this.text : "",
      awaitingSearch: false
    };
  },
  methods: {
    onInputChange() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.handleSearch();
          this.awaitingSearch = false;
        }, 1000);
      }
      this.awaitingSearch = true;
    },
    handleSearch() {
      this.loading = true;
      search(this.supplier)
        .then((response) => {
          let result = response["hydra:member"];
          this.$emit("result", result, this.supplier);
          this.loading = false;
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
