<template>
  <v-ons-page @init="handleInit">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>

    <v-ons-card>
      <div class="content p-2 space-y-2">
        <!-- <d-text :label="$t('common.related_approval')" :value="relatedApproval ? relatedApproval.stamp_no : '-'" /> -->
        <d-text :label="$t('app.pay.month')" :value="item.payment_information.month ? item.payment_information.month.toString() : null" />
        <!-- <d-text
          :label="$t('app.pay.estimated_payment_date')"
          :value="
            item.payment_information.estimated_payment_date ? moment(item.payment_information.estimated_payment_date).format('YYYY-MM-DD') : '-'
          "
        /> -->
        <d-text :label="$t('app.page_application')" :value="item.page_number ? item.page_number.toString() : null" />

        <hr />
        <div v-if="!item.is_drawdown">
          <d-text :label="$t('app.account.name')" :value="item.payment_recipient_account.name ? item.payment_recipient_account.name : '-'" />
          <d-text :label="$t('app.account.number')" :value="item.payment_recipient_account.no ? item.payment_recipient_account.no : '-'" />
          <d-text
            :label="$t('app.account.bank')"
            :value="item.payment_recipient_account.bank_name ? item.payment_recipient_account.bank_name : '-'"
          />
          <d-text
            :label="$t('app.account.branch')"
            :value="item.payment_recipient_account.branch_name ? item.payment_recipient_account.branch_name : '-'"
          />
        </div>
        <div class="text-xs space-y-1 text-gray-500" v-else>
          <div class="flex items-center ">
            <div class="bg-green checkbox mr-1" v-if="item.is_drawdown != undefined && item.is_drawdown">
              <Icon name="check" size="12" classes="text-white" />
            </div>
            <!-- <div class="bg-white mr-1">
              <Icon name="circle" size="20" classes="text-gray-500" />
            </div> -->
            <div>
              {{ $t("app.is_drawdown") }}
            </div>
          </div>
        </div>

        <hr />
        <d-subtitle> {{ $t("common.content") }}</d-subtitle>
        <d-item-content v-for="(item, key) in item.contents" :key="key" :data="item" :currency="currency" />

        <hr />
        <!-- related approval -->
        <d-subtitle> {{ $t("common.related_approval_info") }}</d-subtitle>
        <div v-if="noRelations || relatedApproval === null" class="text-gray-400">{{ $t("common.empty") }}</div>
        <component
          v-else
          :is="relatedApproval.type + '-list'"
          v-for="(item, key) in item.related_items"
          :key="'related_approval_' + key"
          :title="$t('app.item') + ' (' + (key + 1) + ')'"
          :data="item"
          :currency="relatedApproval.currency"
        />
      </div>
    </v-ons-card>
  </v-ons-page>
</template>

<script>
import { fetchRelatedContractDetail as fetchContract } from "@/api/related_approval";
//list
import goods from "./component/goods";
import services from "./component/services";
import hr_ from "./component/hr_";
import other from "./component/other";

export default {
  name: "PaymentDetail",
  components: {
    "goods-list": goods,
    "services-list": services,
    "hr-list": hr_,
    "other-list": other
  },
  data() {
    return {
      company: "",
      department: "",
      relatedApproval: null
    };
  },
  computed: {
    item() {
      return this.toolbarInfo.data.item;
    },
    currency() {
      return this.toolbarInfo.data.currency;
    },
    noRelations() {
      return this.item.related_items === null || this.item.related_items.length === 0;
    }
  },
  methods: {
    handleInit() {
      if (this.item.related_approval_id) {
        this.fetchRelatedApproval(this.item.related_approval_id);
      }
    },
    fetchRelatedApproval(id) {
      fetchContract(id).then((response) => {
        this.relatedApproval = {
          stamp_no: response.stamp_no,
          type: response.contract_item_type.code,
          currency: response.currency.code
        };
      });
    }
  }
};
</script>

<style></style>
