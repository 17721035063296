<template>
  <span style="display: flex; justify-content: center; align-items: center">
    <svg
      v-if="name == 'sitemap'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <rect x="3" y="15" width="6" height="6" rx="2" />
      <rect x="15" y="15" width="6" height="6" rx="2" />
      <rect x="9" y="3" width="6" height="6" rx="2" />
      <path d="M6 15v-1a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v1" />
      <line x1="12" y1="9" x2="12" y2="12" />
    </svg>

    <svg
      v-if="name == 'users'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      class="icon list-item__thumbnail text-green"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
      />
    </svg>

    <svg
      v-if="name == 'cog'"
      :width="size"
      :height="size"
      :class="classes"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
      />
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>

    <svg
      v-if="name == 'logout'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
      />
    </svg>

    <svg
      v-if="name == 'edit'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
      <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
      <line x1="16" y1="5" x2="19" y2="8" />
    </svg>

    <svg
      v-if="name == 'trash'"
      :width="size"
      :height="size"
      :class="classes"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        :stroke-width="stroke"
        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
      />
    </svg>

    <svg
      v-if="name == 'book'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18" />
      <line x1="13" y1="8" x2="15" y2="8" />
      <line x1="13" y1="12" x2="15" y2="12" />
    </svg>

    <svg
      v-if="name == 'plus'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <line x1="12" y1="5" x2="12" y2="19" />
      <line x1="5" y1="12" x2="19" y2="12" />
    </svg>

    <svg
      v-if="name == 'plus-circle'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="12" cy="12" r="9" />
      <line x1="9" y1="12" x2="15" y2="12" />
      <line x1="12" y1="9" x2="12" y2="15" />
    </svg>

    <svg
      v-if="name == 'plus-circle-dotted'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="12" cy="12" r="9" stroke-dasharray="1" />
      <line x1="9" y1="12" x2="15" y2="12" />
      <line x1="12" y1="9" x2="12" y2="15" />
    </svg>

    <svg
      v-if="name == 'info'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="12" cy="12" r="9" />
      <line x1="12" y1="8" x2="12.01" y2="8" />
      <polyline points="11 12 12 12 12 16 13 16" />
    </svg>

    <svg
      v-if="name == 'x'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
        clip-rule="evenodd"
      />
    </svg>

    <svg
      v-if="name == 'close'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
    </svg>

    <svg
      v-if="name == 'file-check'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
      />
    </svg>

    <svg
      v-if="name == 'file-list'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
      />
    </svg>

    <svg
      v-if="name == 'paper-plane'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      stroke="currentColor"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.614 26.4407L8 22.4068L40 8M40 8L37.7544 40.8475L25.5 33.5M40 8L18.1053 29.8983V42L22.0351 37.9661"
        stroke="currentColor"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      v-if="name == 'off'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-power"
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M7 6a7.75 7.75 0 1 0 10 0" />
      <line x1="12" y1="4" x2="12" y2="12" />
    </svg>

    <svg
      v-if="name == 'pending'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      stroke="currentColor"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 15.5H29" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
      <path d="M16 23H23" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
      <path
        d="M39 18.5V12C39 9.23858 36.7614 7 34 7H14C11.2386 7 9 9.23858 9 12V39C9 41.7614 11.2386 44 14 44H16.5"
        stroke="currentColor"
        stroke-width="4"
        stroke-linecap="round"
      />
      <path d="M34 30V35.5L37 38.5" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
      <circle cx="34" cy="35" r="10" stroke="currentColor" stroke-width="4" />
    </svg>

    <svg
      v-if="name == 'approved'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      stroke="currentColor"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 14.5H29" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
      <path d="M16 22H23" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
      <path
        d="M39 17.5V11C39 8.23858 36.7614 6 34 6H14C11.2386 6 9 8.23858 9 11V38C9 40.7614 11.2386 43 14 43H16.5"
        stroke="currentColor"
        stroke-width="4"
        stroke-linecap="round"
      />
      <path d="M21 36L29 43L43 26" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg
      v-if="name == 'initiated'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      stroke="currentColor"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 15H30" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
      <path d="M16 22H30" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
      <path d="M16 29H23" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
      <path
        d="M38 17.5V11C38 8.23858 35.7614 6 33 6H13C10.2386 6 8 8.23858 8 11V38C8 40.7614 10.2386 43 13 43H15.5"
        stroke="currentColor"
        stroke-width="4"
        stroke-linecap="round"
      />
      <path d="M35.7143 29V36.5M35.7143 44V36.5M35.7143 36.5H28M35.7143 36.5H43" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
    </svg>

    <svg
      v-if="name == 'check'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      stroke="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
        clip-rule="evenodd"
      />
    </svg>

    <svg
      v-if="name == 'dots'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
      />
    </svg>

    <svg
      v-if="name == 'wallet-duo'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="wallet"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g class="fa-group">
        <path
          class="fa-secondary"
          fill="currentColor"
          d="M416 272a32 32 0 1 0 32 32 32 32 0 0 0-32-32zm16-240H64A64 64 0 0 0 0 96a32 32 0 0 0 32 32h48a16 16 0 0 1 0-32h384a16 16 0 0 0 16-16 48 48 0 0 0-48-48z"
          opacity="0.4"
        ></path>
        <path
          class="fa-primary"
          fill="currentColor"
          d="M461.2 128H32A32 32 0 0 1 0 96v320a64 64 0 0 0 64 64h397.2c28 0 50.8-21.53 50.8-48V176c0-26.47-22.78-48-50.8-48zM416 336a32 32 0 1 1 32-32 32 32 0 0 1-32 32z"
        ></path>
      </g>
    </svg>

    <svg
      v-if="name == 'credit-card-duo'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="credit-card"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <g class="fa-group">
        <path
          class="fa-secondary"
          fill="currentColor"
          d="M0 432a48 48 0 0 0 48 48h480a48 48 0 0 0 48-48V256H0zm192-68a12 12 0 0 1 12-12h136a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12H204a12 12 0 0 1-12-12zm-128 0a12 12 0 0 1 12-12h72a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12H76a12 12 0 0 1-12-12zM528 32H48A48 48 0 0 0 0 80v48h576V80a48 48 0 0 0-48-48z"
          opacity="0.4"
        ></path>
        <path class="fa-primary" fill="currentColor" d="M576 256H0V128h576z"></path>
      </g>
    </svg>

    <svg
      v-if="name == 'exchange-duo'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="exchange"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g class="fa-group">
        <path
          class="fa-secondary"
          fill="currentColor"
          d="M488 384H106l30.47 27.73a24 24 0 0 1 .47 34.4L126.13 457a24 24 0 0 1-33.94 0L9.37 374.63a32 32 0 0 1 0-45.26L92.19 247a24 24 0 0 1 33.94 0L137 257.87a24 24 0 0 1-.47 34.4L106 320h382a24 24 0 0 1 24 24v16a24 24 0 0 1-24 24z"
          opacity="0.4"
        ></path>
        <path
          class="fa-primary"
          fill="currentColor"
          d="M0 168v-16a24 24 0 0 1 24-24h382l-30.5-27.73a24 24 0 0 1-.47-34.4L385.87 55a24 24 0 0 1 33.94 0l82.82 82.34a32 32 0 0 1 0 45.26L419.81 265a24 24 0 0 1-33.94 0L375 254.13a24 24 0 0 1 .47-34.4L406 192H24a24 24 0 0 1-24-24z"
        ></path>
      </g>
    </svg>

    <svg
      v-if="name == 'book-duo'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="book"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <g class="fa-group">
        <path
          class="fa-secondary"
          fill="currentColor"
          d="M96 448c-19.2 0-32-12.8-32-32s16-32 32-32h319.33c-1.93 16.24-1.76 48.38.53 64z"
          opacity="0.4"
        ></path>
        <path
          class="fa-primary"
          fill="currentColor"
          d="M96 384h328a24 24 0 0 0 24-24V32a32 32 0 0 0-32-32H96A96 96 0 0 0 0 96v320a96 96 0 0 0 96 96h328a24 24 0 0 0 24-24v-16a24 24 0 0 0-24-24H96c-19.2 0-32-12.8-32-32s16-32 32-32zm32-250a6 6 0 0 1 6-6h212a6 6 0 0 1 6 6v20a6 6 0 0 1-6 6H134a6 6 0 0 1-6-6zm0 64a6 6 0 0 1 6-6h212a6 6 0 0 1 6 6v20a6 6 0 0 1-6 6H134a6 6 0 0 1-6-6z"
        ></path>
      </g>
    </svg>

    <svg
      v-if="name == 'cube-duo'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="cube"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g class="fa-group">
        <path
          class="fa-secondary"
          fill="currentColor"
          d="M480.85 85.58l-208-78a47.17 47.17 0 0 0-33.7-.1l-208 78a48.06 48.06 0 0 0-31.1 45v225.1a48 48 0 0 0 26.5 42.9l208 104a47.66 47.66 0 0 0 42.9 0l208-104a47.91 47.91 0 0 0 26.5-42.9v-225.1a47.93 47.93 0 0 0-31.1-44.9zM448 345.16l-160 80v-148.9l160-65zm.05-202.48l-192 78-192-78v-1.1l192-72 192 72z"
          opacity="0.4"
        ></path>
        <path class="fa-primary" fill="currentColor" d="M448 345.16l-160 80v-148.9l160-65z"></path>
      </g>
    </svg>

    <svg
      v-if="name == 'user-2-duo'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="user-friends"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <g class="fa-group">
        <path
          class="fa-secondary"
          fill="currentColor"
          d="M480 256a96 96 0 1 0-96-96 96 96 0 0 0 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592a48 48 0 0 0 48-48 111.94 111.94 0 0 0-112-112z"
          opacity="0.4"
        ></path>
        <path
          class="fa-primary"
          fill="currentColor"
          d="M192 256A112 112 0 1 0 80 144a111.94 111.94 0 0 0 112 112zm76.8 32h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 0 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 268.8 288z"
        ></path>
      </g>
    </svg>

    <svg
      v-if="name == 'stamp-duo'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="stamp"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g class="fa-group">
        <path
          class="fa-secondary"
          fill="currentColor"
          d="M480 416H32a32 32 0 0 1-32-32v-32a96 96 0 0 1 96-96h66.56A29.44 29.44 0 0 0 192 226.56v-.07c0-31.79-10-62.06-23.31-90.91A93.86 93.86 0 0 1 160 96 96.06 96.06 0 0 1 273.25 1.5C312 8.25 343.74 40.17 350.51 79a94.83 94.83 0 0 1-9.05 60.42c-12.58 24.26-21.46 50.3-21.46 77.67v9.46A29.44 29.44 0 0 0 349.43 256H416a96 96 0 0 1 96 96v32a32 32 0 0 1-32 32z"
          opacity="0.4"
        ></path>
        <path class="fa-primary" fill="currentColor" d="M480 416v96H32v-96z"></path>
      </g>
    </svg>

    <svg
      v-if="name == 'chart-bar-duo'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="chart-bar"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g class="fa-group">
        <path
          class="fa-secondary"
          fill="currentColor"
          d="M512 400v32a16 16 0 0 1-16 16H32a32 32 0 0 1-32-32V80a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16v304h432a16 16 0 0 1 16 16z"
          opacity="0.4"
        ></path>
        <path
          class="fa-primary"
          fill="currentColor"
          d="M275.2 96h-38.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8h38.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8zm-96 128h-38.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8h38.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8zm288-160h-38.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8zm-96 96h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8h38.4c6.4 0 12.8-6.4 12.8-12.8V172.8c0-6.4-6.4-12.8-12.8-12.8z"
        ></path>
      </g>
    </svg>

    <svg
      v-if="name == 'check-edit-duo'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="money-check-edit"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <g class="fa-group">
        <path
          class="fa-secondary"
          fill="currentColor"
          d="M640 192v288a32 32 0 0 1-32 32H32a32 32 0 0 1-32-32V192a32 32 0 0 1 32-32h171.06L331 288H104a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h259l64 64H104a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h456a16 16 0 0 0 16-16v-74.46a64 64 0 0 0-18.74-45.26L437 160h171a32 32 0 0 1 32 32z"
          opacity="0.4"
        ></path>
        <path
          class="fa-primary"
          fill="currentColor"
          d="M534.64 302.91L303.18 71.47l-71.7 71.7 231.39 231.45a32 32 0 0 0 22.64 9.38H528a16 16 0 0 0 16-16v-42.46a32 32 0 0 0-9.36-22.63zM238.78 7a24.1 24.1 0 0 0-33.9 0L167 44.87a24 24 0 0 0 0 33.8l41.9 41.9 71.7-71.8z"
        ></path>
      </g>
    </svg>

    <svg
      v-if="name == 'user-plus-duo'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="user-plus"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <g class="fa-group">
        <path
          class="fa-secondary"
          fill="currentColor"
          d="M640 224v32a16 16 0 0 1-16 16h-64v64a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-64h-64a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h64v-64a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16v64h64a16 16 0 0 1 16 16z"
          opacity="0.4"
        ></path>
        <path
          class="fa-primary"
          fill="currentColor"
          d="M224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128zm89.6 32h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48v-41.6A134.43 134.43 0 0 0 313.6 288z"
        ></path>
      </g>
    </svg>

    <svg
      v-if="name == 'user-duo'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="user"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <g class="fa-group">
        <path class="fa-secondary" fill="currentColor" d="M352 128A128 128 0 1 1 224 0a128 128 0 0 1 128 128z" opacity="0.4"></path>
        <path
          class="fa-primary"
          fill="currentColor"
          d="M313.6 288h-16.7a174.1 174.1 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48v-41.6A134.43 134.43 0 0 0 313.6 288z"
        ></path>
      </g>
    </svg>

    <svg
      v-if="name == 'briefcase-duo'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="briefcase"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g class="fa-group">
        <path
          class="fa-secondary"
          fill="currentColor"
          d="M320 288h192v144c0 25.6-22.4 48-48 48H48c-25.6 0-48-22.4-48-48V288h192v48a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16z"
          opacity="0.4"
        ></path>
        <path
          class="fa-primary"
          fill="currentColor"
          d="M464 128h-80V80c0-25.6-22.4-48-48-48H176c-25.6 0-48 22.4-48 48v48H48c-25.6 0-48 22.4-48 48v80h512v-80c0-25.6-22.4-48-48-48zm-144 0H192V96h128z"
        ></path>
      </g>
    </svg>

    <svg
      v-if="name == 'off-duo'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="power-off"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g class="fa-group">
        <path
          class="fa-secondary"
          fill="currentColor"
          d="M272 0a23.94 23.94 0 0 1 24 24v240a23.94 23.94 0 0 1-24 24h-32a23.94 23.94 0 0 1-24-24V24a23.94 23.94 0 0 1 24-24z"
          opacity="0.4"
        ></path>
        <path
          class="fa-primary"
          fill="currentColor"
          d="M504 256c0 136.8-110.8 247.7-247.5 248C120 504.3 8.2 393 8 256.4A248 248 0 0 1 111.8 54.2a24.07 24.07 0 0 1 35 7.7L162.6 90a24 24 0 0 1-6.6 31 168 168 0 0 0 100 303c91.6 0 168.6-74.2 168-169.1a168.07 168.07 0 0 0-68.1-134 23.86 23.86 0 0 1-6.5-30.9l15.8-28.1a24 24 0 0 1 34.8-7.8A247.51 247.51 0 0 1 504 256z"
        ></path>
      </g>
    </svg>

    <svg
      v-if="name == 'chevron-down'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
    </svg>

    <svg
      v-if="name == 'chevron-right'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
    </svg>
    <svg
      v-if="name == 'cube-solid'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="cube"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M239.1 6.3l-208 78c-18.7 7-31.1 25-31.1 45v225.1c0 18.2 10.3 34.8 26.5 42.9l208 104c13.5 6.8 29.4 6.8 42.9 0l208-104c16.3-8.1 26.5-24.8 26.5-42.9V129.3c0-20-12.4-37.9-31.1-44.9l-208-78C262 2.2 250 2.2 239.1 6.3zM256 68.4l192 72v1.1l-192 78-192-78v-1.1l192-72zm32 356V275.5l160-65v133.9l-160 80z"
      ></path>
    </svg>

    <svg
      v-if="name == 'user-2-solid'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="user-friends"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <path
        fill="currentColor"
        d="M192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C51.6 288 0 339.6 0 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zM480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592c26.5 0 48-21.5 48-48 0-61.9-50.1-112-112-112z"
      ></path>
    </svg>

    <svg
      v-if="name == 'stamp-solid'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="stamp"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M32 512h448v-64H32v64zm384-256h-66.56c-16.26 0-29.44-13.18-29.44-29.44v-9.46c0-27.37 8.88-53.41 21.46-77.72 9.11-17.61 12.9-38.39 9.05-60.42-6.77-38.78-38.47-70.7-77.26-77.45C212.62-9.04 160 37.33 160 96c0 14.16 3.12 27.54 8.69 39.58C182.02 164.43 192 194.7 192 226.49v.07c0 16.26-13.18 29.44-29.44 29.44H96c-53.02 0-96 42.98-96 96v32c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-32c0-53.02-42.98-96-96-96z"
      ></path>
    </svg>

    <svg
      v-if="name == 'cash'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <rect x="7" y="9" width="14" height="10" rx="2" />
      <circle cx="14" cy="14" r="2" />
      <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" />
    </svg>

    <svg
      v-if="name == 'invoice'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
      <line x1="9" y1="7" x2="10" y2="7" />
      <line x1="9" y1="13" x2="15" y2="13" />
      <line x1="13" y1="17" x2="15" y2="17" />
    </svg>

    <svg
      v-if="name == 'credit-card'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <rect x="3" y="5" width="18" height="14" rx="3" />
      <line x1="3" y1="10" x2="21" y2="10" />
      <line x1="7" y1="15" x2="7.01" y2="15" />
      <line x1="11" y1="15" x2="13" y2="15" />
    </svg>

    <svg
      v-if="name == 'wallet'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />
      <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" />
    </svg>

    <svg
      v-if="name == 'export'"
      :width="size"
      :height="size"
      :class="classes"
      :stroke-width="stroke"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <line x1="17" y1="7" x2="7" y2="17" />
      <polyline points="8 7 17 7 17 16" />
    </svg>

    <svg
      v-if="name == 'mail'"
      :width="size"
      :height="size"
      :class="classes"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        :stroke-width="stroke"
        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
      />
    </svg>

    <svg
      v-if="name == 'lock'"
      :width="size"
      :height="size"
      :class="classes"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        :stroke-width="stroke"
        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
      />
    </svg>

    <svg
      v-if="name == 'help'"
      :width="size"
      :height="size"
      :class="classes"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        :stroke-width="stroke"
        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>

    <svg
      v-if="name == 'circle'"
      :width="size"
      :height="size"
      :class="classes"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      stroke-width="1"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="12" cy="12" r="9" />
    </svg>

    <svg
      v-if="name == 'switch'"
      :width="size"
      :height="size"
      :class="classes"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
    >
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4" />
    </svg>

    <svg
      v-if="name == 'adjustment'"
      :width="size"
      :height="size"
      :class="classes"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
      />
    </svg>
    <svg
      v-if="name == 'sort'"
      :width="size"
      :height="size"
      :class="classes"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="sort"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path
        fill="currentColor"
        d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"
      ></path>
    </svg>
    <svg
      v-if="name == 'arrow-right'"
      :width="size"
      :height="size"
      :class="classes"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
    </svg>

    <svg
      v-if="name == 'upload'"
      :width="size"
      :height="size"
      :class="classes"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
      />
    </svg>
    <svg
      v-if="name == 'document-search'"
      :width="size"
      :height="size"
      :class="classes"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
      />
    </svg>
    <svg
      v-if="name == 'sort-descending'"
      :width="size"
      :height="size"
      :class="classes"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      v-if="name == 'reply'"
      :width="size"
      :height="size"
      :class="classes"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path stroke-linecap="round" stroke-linejoin="round" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
    </svg>
    <svg
      v-if="name == 'check-circle-filled'"
      :width="size"
      :height="size"
      :class="classes"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      class="w-6 h-6"
    >
      <path
        fill-rule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clip-rule="evenodd"
      />
    </svg>
    <svg
      v-if="name == 'users-fill'"
      :width="size"
      :height="size"
      :class="classes"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      class="w-6 h-6"
    >
      <path
        fill="currentColor"
        d="M164.47 195.63a8 8 0 0 1-6.7 12.37H10.23a8 8 0 0 1-6.7-12.37a95.83 95.83 0 0 1 47.22-37.71a60 60 0 1 1 66.5 0a95.83 95.83 0 0 1 47.22 37.71Zm87.91-.15a95.87 95.87 0 0 0-47.13-37.56A60 60 0 0 0 144.7 54.59a4 4 0 0 0-1.33 6a75.83 75.83 0 0 1 3.63 89.94a4 4 0 0 0 1.07 5.53a112.32 112.32 0 0 1 29.85 30.83a23.92 23.92 0 0 1 3.65 16.47a4 4 0 0 0 3.95 4.64h60.3a8 8 0 0 0 7.73-5.93a8.22 8.22 0 0 0-1.17-6.59Z"
      />
    </svg>
    <svg
      v-if="name == 'download'"
      :width="size"
      :height="size"
      :class="classes"
      fill="currentColor"
      class="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
    >
      <path
        fill="currentColor"
        d="M240 136v64a16 16 0 0 1-16 16H32a16 16 0 0 1-16-16v-64a16 16 0 0 1 16-16h40a8 8 0 0 1 0 16H32v64h192v-64h-40a8 8 0 0 1 0-16h40a16 16 0 0 1 16 16Zm-117.66-2.34a8 8 0 0 0 11.32 0l48-48a8 8 0 0 0-11.32-11.32L136 108.69V24a8 8 0 0 0-16 0v84.69L85.66 74.34a8 8 0 0 0-11.32 11.32ZM200 168a12 12 0 1 0-12 12a12 12 0 0 0 12-12Z"
      />
    </svg>
    <svg
      v-if="name == 'file-plus-solid'"
      :width="size"
      :height="size"
      :class="classes"
      fill="currentColor"
      class="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path
        d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zm48 96a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm16 80c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H368c-8.8 0-16 7.2-16 16s7.2 16 16 16h48v48c0 8.8 7.2 16 16 16s16-7.2 16-16V384h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H448V304z"
      />
    </svg>
    <svg
      v-if="name == 'file-check-solid'"
      :width="size"
      :height="size"
      :class="classes"
      fill="currentColor"
      class="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path
        d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM288 368a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm211.3-43.3c-6.2-6.2-16.4-6.2-22.6 0L416 385.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l40 40c6.2 6.2 16.4 6.2 22.6 0l72-72c6.2-6.2 6.2-16.4 0-22.6z"
      />
    </svg>
    <svg
      v-if="name == 'file-exclamation-solid'"
      :width="size"
      :height="size"
      :class="classes"
      fill="currentColor"
      class="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path
        d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zm48 96a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm0 240a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm0-192c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16s16-7.2 16-16V288c0-8.8-7.2-16-16-16z"
      />
    </svg>
    <svg
      v-if="name == 'filter'"
      :width="size"
      :height="size"
      :class="classes"
      fill="currentColor"
      class="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M21 3H5a1 1 0 0 0-1 1v2.59c0 .523.213 1.037.583 1.407L10 13.414V21a1.001 1.001 0 0 0 1.447.895l4-2c.339-.17.553-.516.553-.895v-5.586l5.417-5.417c.37-.37.583-.884.583-1.407V4a1 1 0 0 0-1-1zm-6.707 9.293A.996.996 0 0 0 14 13v5.382l-2 1V13a.996.996 0 0 0-.293-.707L6 6.59V5h14.001l.002 1.583l-5.71 5.71z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: "Icon",
  props: {
    name: {
      type: String
    },
    size: {
      type: [Number, String],
      default: 18
    },
    classes: {
      type: String
    },
    stroke: {
      type: [Number, String],
      default: 1.5
    }
  }
};
</script>

<style></style>
