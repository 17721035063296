export const home = {
  header: { application: "Application" },
  menu: {
    reimbursements: "Reimbursement",
    payment: "Payment",
    collection_report: "Collection Report",
    purchase: "Purchase",
    supplier_contract: "Supplier Contract",
    customer_contract: "Customer Contract",
    corporate_seal: "Corporate Seal",
    stamp_statistic: "Stamp Statistics",
    sales: "Sales",
    sales_registration: "Sales/custom info registration"
  },
  top_menu: {
    pending: "Pending",
    done: "Done",
    initiated: "Initiated",
    forwarded: "Forwarded"
  },
  navigation: {
    home: "Home",
    statistic: "Finance Statistics",
    admin: "Admin"
  }
};
