import { render, staticRenderFns } from "./PurchaseDetailForm.vue?vue&type=template&id=54f8308b&scoped=true&"
import script from "./PurchaseDetailForm.vue?vue&type=script&lang=js&"
export * from "./PurchaseDetailForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54f8308b",
  null
  
)

export default component.exports