<template>
  <v-ons-list-item>
    <div class="center">
      <span class="list-item__title"> &nbsp; {{ label }} </span>
      <span class="list-item__subtitle">
        <div class="w-full flex flex-wrap items-center">
          <div v-for="(user, k) in persons" :key="k" class="flex items-center bg-gray-200 text-black rounded-md text-xs px-2 py-1 mt-1 mr-2">
            {{ user.name }}
            <div @click="$emit('remove', user.id)" v-if="editable">
              <icon name="close" class="cursor-pointer ml-1" />
            </div>
          </div>
        </div>
      </span>
    </div>
    <div class="right list-item__right p-0 flex items-baseline" v-if="editable">
      <ons-button modifier="quiet" @click="$emit('add')">
        <Icon name="plus-circle" size="32" stroke="1" classes="text-gray-400" />
      </ons-button>
    </div>
  </v-ons-list-item>
</template>

<script>
export default {
  name: "form-select-user",
  props: {
    label: {
      type: String
    },
    users: {
      type: Array
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleRemoveUser(id) {
      this.$emit("remove", id);
    }
  },
  computed: {
    persons() {
      return Array.isArray(this.users) ? this.users : [];
    }
  }
};
</script>

<style scoped></style>
