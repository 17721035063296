<template>
  <v-ons-card>
    <div class="title m-0 p-3">{{ $t("common.process") }}</div>
    <div class="content p-4">
      <div style="position: relative; padding-bottom: 20px">
        <span class="vertical-line-md" v-show="data && data.length > 0" />
        <ProcessThumb status="done" :initial="requester.initial_name" :name="requester.name" :process="$t('home.top_menu.initiated')" />
      </div>
      <!-- prospective approvers -->
      <div v-for="(item, key) in data" :key="key" style="position: relative; padding-bottom: 20px">
        <span v-if="key != data.length - 1" class="vertical-line-md" />
        <ProcessThumbMultiple status="process" :item="item" :approvers="item.prospective_approvers" />
      </div>
      <!-- 
      <div v-for="(item, key) in data" :key="key" style="position: relative; padding-bottom: 20px">
        <span v-if="key != data.length - 1" class="vertical-line-md" />
        <ProcessThumb
          v-if="item.status == 1"
          status="done"
          :initial="item.approver.initial_name"
          :name="item.approver.name"
          :process="item.master_process.process.name"
          :approved_at="item.approved_at"
        />
        <ProcessThumb
          v-if="item.status == 2"
          status="rejected"
          :initial="item.approver.initial_name"
          :name="item.approver.name"
          :process="item.master_process.process.name"
          :approved_at="item.approved_at"
        />
        <ProcessThumb
          v-if="item.active"
          status="process"
          :initial="item.approver.initial_name"
          :name="item.approver.name + ' (Pending)'"
          :process="item.master_process.process.name"
          :approved_at="item.approved_at"
        />
        <ProcessThumb
          v-if="item.active === false && item.status == 0"
          status="waiting"
          :initial="item.approver.initial_name"
          :name="item.approver.name"
          :process="item.master_process.process.name"
          :approved_at="item.approved_at"
        />

        <ProcessLog v-for="(log, k) in item.approval_logs" :key="k" :log="log" />
      </div> -->
    </div>
  </v-ons-card>
</template>

<script>
import ProcessThumb from "@/components/ProcessThumb";
// import ProcessLog from "@/components/ProcessLog";
import ProcessThumbMultiple from "@/components/ProcessThumbMultiple.vue";
export default {
  name: "Process",
  props: ["data", "requester"],
  components: {
    ProcessThumb,
    // ProcessLog,
    ProcessThumbMultiple
  }
};
</script>

<style></style>
