<template>
  <v-ons-page @init="handleInit">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>

    <Loading v-if="loading" />
    <div v-show="user">
      <v-ons-card class="flat">
        <div style="text-align: center; margin-bottom: 20px">
          <div class="profile-thumb">{{ user.initial_name }}</div>
          <div>
            {{ user.name }}
            <div v-if="user.is_authorizer">
              <span class="border border-blue-500 ml-2 p-1 rounded-xl text-blue-500" style="font-size:0.5rem;">
                {{ $t("common.company_authorizer") }}
              </span>
            </div>
          </div>
        </div>
      </v-ons-card>

      <section class="mb-20">
        <v-ons-list>
          <d-info :label="$t('admin.user.email')" :sublabel="user.email" />
        </v-ons-list>
        <div class="mt-4 py-2 bg-white">
          <nav-top :tabs="tab" @change-tab="handleChangeTab" :fix="false" />
        </div>
        <v-ons-list v-if="activeTab == 0">
          <d-info :label="$t('admin.user.company')" :sublabel="user.company ? user.company.name : '-'" />
          <d-info :label="$t('admin.user.department')" :sublabel="user.department ? user.department.name : '-'" />
          <d-info :label="$t('admin.user.location')" :sublabel="user.location ? user.location.address : '-'" />
          <d-info :label="$t('admin.user.join_date')" :sublabel="user.join_date ? moment(user.join_date).format('YYYY/MM/DD') : '-'" />
        </v-ons-list>
        <v-ons-list v-if="activeTab == 1">
          <d-info v-for="(item, key) in settings" :key="key" :label="item.title" :sublabel="item.approver ? item.approver.name : '-'" />
        </v-ons-list>
      </section>

      <ons-bottom-toolbar class="fixed z-50">
        <div class="bg-gradient-to-t bottom-0 flex from-white justify-around p-2 to-transparent w-full">
          <circle-button
            icon="trash"
            :label="$t('admin.user.delete_user')"
            @on-click="push(page[0].component, page[0].label, { userId: userId, action: 'DELETE' })"
          />
          <circle-button icon="edit" :label="$t('admin.user.edit_user')" @on-click="push(page[0].component, page[0].label, { userId: userId })" />
        </div>
      </ons-bottom-toolbar>
    </div>
  </v-ons-page>
</template>

<script>
import { fetchUserDetail } from "@/api/user";
import { fetchSettingType } from "@/api/setting_type";
import NavTop from "@/components/NavTop";
import circleButton from "@/components/button/circle";
import UserForm from "@/views/Admin/form/UserForm";

export default {
  name: "UserDetail",
  components: { "nav-top": NavTop, "circle-button": circleButton },
  data() {
    return {
      user: {},
      loading: true,
      submitting: false,
      activeTab: 0,
      settings: []
    };
  },
  computed: {
    userId() {
      return this.toolbarInfo.param.userId;
    },
    tab() {
      return [this.$t("admin.user.company_info"), this.$t("admin.user.setting")];
    },
    page() {
      return [
        {
          component: UserForm,
          label: this.$t("admin.user.edit_user")
        }
      ];
    }
  },
  methods: {
    handleInit() {
      this.loadData();
    },
    handleChangeTab(index) {
      this.activeTab = index;
    },
    async loadData() {
      await this.getUser(this.userId).then((response) => {
        this.user = response;
      });

      await this.getSettings().then((response) => {
        let approvers = response["hydra:member"].map((item) => ({
          id: item.id,
          title: item.name,
          approver: null
        }));

        if (this.user.user_settings) {
          this.user.user_settings.map((obj) => {
            approvers.map((item) => {
              if (item.id === obj.setting_type.id) {
                this.getUser(obj.approver_id).then((res) => {
                  item.approver = { id: res.id, name: res.name };
                });
              }
            });
          });
        }

        this.settings = approvers;
      });

      this.loading = false;
    },
    getUser(userId) {
      return fetchUserDetail(userId);
    },
    getSettings() {
      return fetchSettingType();
    },
    push(page, key, param) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style></style>
