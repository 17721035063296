<template>
  <BottomSheet :title="$t('common.select_currency')" :showing="showing" @on-close="$emit('on-close')">
    <loading-small v-if="loading" class="test" />
    <v-ons-list v-else>
      <v-ons-list-item v-for="(item, key) in currencyList" :key="key" tappable @click="handleChange(item)">
        <label class="left">
          <v-ons-checkbox :input-id="'currency-' + key" :value="item.id" v-model="selectedOption"> </v-ons-checkbox>
        </label>
        <label :for="'currency-' + key" class="center">
          {{ item.code }}
        </label>
      </v-ons-list-item>
    </v-ons-list>
  </BottomSheet>
</template>

<script>
import { getCurrencies } from "@/api/rembursement";
import BottomSheet from "@/components/BottomSheet";

export default {
  name: "CurrencySelect",
  props: ["showing", "includeAll"],
  components: {
    BottomSheet
  },
  data() {
    return {
      loading: true,
      currencyList: [],
      selectedOption: []
    };
  },
  watch: {
    showing: function(newVal) {
      if (newVal) {
        this.fetchList();
      }
    }
  },

  methods: {
    fetchList() {
      this.selectedOption = [];
      getCurrencies().then((response) => {
        this.currencyList = response["hydra:member"];
        if (this.includeAll) {
          this.currencyList = [...this.currencyList, { code: "All", id: null, name: "All" }];
        }
        this.loading = false;
      });
    },
    handleChange(val) {
      this.$emit("on-select", val);
    }
  }
};
</script>

<style></style>
