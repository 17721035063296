<template>
  <v-ons-page>
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <search-filter @on-search="onSearch" @on-reset="onReset" />
    <div class="mt-14">
      <div class="no-result" v-if="contractPartyList.length == 0">{{ $t("common.empty") }}</div>
      <v-ons-list v-else>
        <form-select-icon
          v-for="(obj, key) in contractPartyList"
          :key="key"
          :label="obj.name"
          :sublabel="obj.desc"
          icon="circle"
          iconClass="text-gray-300"
          modifier="none"
          @tap="onSelectContractParty(obj.id)"
        />
      </v-ons-list>
    </div>
    <infinite-loading @infinite="getData" :identifier="infiniteId" />
  </v-ons-page>
</template>

<script>
import { mapMutations } from "vuex";
import { fetchCompanyBackground as fetchList } from "@/api/company";
import SearchFilter from "@/components/SearchFilter.vue";

const defaultFilter = { page: 1, itemsPerPage: 10, name: "" };
export default {
  components: { SearchFilter },
  name: "ContractPartyList",
  data: () => ({
    contractPartyList: [],
    filter: Object.assign({}, defaultFilter),
    infiniteId: +new Date()
  }),
  methods: {
    ...mapMutations("navigator", ["pop", "setParam", "clearParam"]),
    onSelectContractParty(id) {
      let selectedContractParty = this.contractPartyList.filter((approver) => approver.id === id)[0];
      this.setParam({ selectedContractParty: selectedContractParty });
      this.pop();
    },
    forceRerender() {
      this.infiniteId += 1;
    },
    getData($state) {
      fetchList(this.filter).then((response) => {
        let data = response["hydra:member"].map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });

        this.contractPartyList.push(...data);

        if (data.length) {
          this.filter.page += 1;
          $state && $state.loaded();
        } else {
          $state && $state.complete();
        }
      });
    },
    onSearch(filter) {
      this.contractPartyList = [];
      this.filter.page = 1;
      this.filter.name = filter.term;
      this.forceRerender();
    },
    onReset() {
      this.contractPartyList = [];
      this.filter = Object.assign({}, defaultFilter);
      this.forceRerender();
    }
  }
};
</script>

<style scoped></style>
