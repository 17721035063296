<template>
  <v-ons-page @init="loadPage">
    <custom-toolbar v-bind="toolbarInfo">
      <v-ons-toolbar-button slot="right" v-if="selectedApprovers.length > 0" @click="handleAdd()">
        <span class="p-2 text-white bg-blue rounded-md text-sm cursor-pointer">{{ $t("common.add") }} ({{ selectedApprovers.length }})</span>
      </v-ons-toolbar-button>
    </custom-toolbar>
    <!-- <custom-toolbar v-bind="toolbarInfo"></custom-toolbar> -->
    <search-filter @on-search="onSearch" @on-reset="onReset">
      <template v-slot:info>
        <div class="text-left flex items-start pt-1">
          <Icon name="info" classes="h-5 w-5 mr-2" />
          <span class="text-xs text-gray-500">in case you select plural approvers in any step, whichever can approve to pass this step</span>
        </div>
      </template>
    </search-filter>

    <div class="mt-28">
      <div class="no-result" v-if="approverList.length === 0">{{ $t("common.empty") }}</div>
      <v-ons-list v-else>
        <form-select-icon
          v-for="(obj, key) in approverList"
          :key="key"
          :label="obj.name"
          :sublabel="obj.company.name + ' | ' + obj.department.name"
          :badge="obj.is_authorizer ? $t('common.company_authorizer') : null"
          :icon="isSelected(obj.id) ? 'check-circle-filled' : 'circle'"
          :iconClass="isSelected(obj.id) ? 'text-blue-500' : 'text-gray-300'"
          modifier="none"
          @tap="handleSelectApprover(obj.id)"
        />
      </v-ons-list>
    </div>
    <infinite-loading @infinite="getData" :identifier="infiniteId" />
  </v-ons-page>
</template>

<script>
import { mapMutations } from "vuex";
import { fetchList } from "@/api/approver";
import SearchFilter from "@/components/SearchFilter";

const defaultFilter = { page: 1, itemsPerPage: 10, name: "", masterProcessId: null };
export default {
  name: "ApproverListMultiple",
  components: { SearchFilter },
  data: () => ({
    default: [],
    processId: null,
    settingId: null,
    infiniteId: 0,
    approverList: [],
    filter: Object.assign({}, defaultFilter),
    selectedApprovers: []
  }),
  methods: {
    ...mapMutations("navigator", ["pop", "setParam", "clearParam"]),
    handleSelectApprover(id) {
      let selected = this.approverList.filter((approver) => approver.id === id)[0];

      if (!this.selectedApprovers.find((item) => item.id === id)) {
        this.selectedApprovers.push(selected);
      } else {
        this.selectedApprovers = this.selectedApprovers.filter((item) => item.id !== id);
      }
    },
    forceRerender() {
      this.infiniteId += 1;
    },
    isSelected(id) {
      return this.selectedApprovers.find((item) => item.id === id);
    },
    handleAdd() {
      let param = {};
      param = { key: this.processId, value: this.selectedApprovers };
      this.setParam({ approvers: param });
      this.pop();
    },
    loadPage() {
      this.processId = this.toolbarInfo.param.processId;
      this.default = this.toolbarInfo.param.default;
      this.settingId = this.toolbarInfo.param.settingId;
      this.filter.masterProcessId = this.toolbarInfo.param.masterProcessId;
    },
    async getData($state) {
      await fetchList(this.filter).then((response) => {
        let data = response["hydra:member"];

        this.approverList.push(...data);
        this.loadDefaultValue();
        if (data.length) {
          this.filter.page += 1;
          $state && $state.loaded();
        } else {
          $state && $state.complete();
        }
      });
    },
    loadDefaultValue() {
      this.selectedApprovers = this.approverList.filter((approver) => this.default.find((d) => d.id === approver.id));
    },
    onSearch(filter) {
      this.approverList = [];
      this.filter.page = 1;
      this.filter.name = filter.term;
      this.forceRerender();
    },
    onReset() {
      this.approverList = [];
      this.filter = Object.assign({}, defaultFilter);
      this.forceRerender();
    }
  }
};
</script>

<style scoped></style>
