<template>
  <v-ons-toolbar>
    <div class="left">
      <slot name="left">
        <v-ons-back-button v-if="backLabel" @click="resetOption">
          {{ backLabel }}
        </v-ons-back-button>
      </slot>
    </div>
    <div class="center">
      <slot>{{ title }}</slot>
    </div>
    <div class="right"><slot name="right"></slot></div>
  </v-ons-toolbar>
</template>

<script>
export default {
  name: "CustomToolbar",
  props: ["title", "backLabel"],
  methods: {
    resetOption() {
      this.$store.commit("navigator/resetOptions");
    }
  }
};
</script>
