<template>
  <bottom-sheet-action :showing="show" :title="title" @on-close="$emit('cancel')" @on-save="handleSave">
    <form-input kind="text" :required="true" :label="$t('app.sc.item.name')" :placeholder="$t('app.sc.item.enter_name')" v-model="form.name" />
    <form-input :required="true" :label="$t('app.sc.item.budget_tax')" :placeholder="$t('app.sc.item.enter_budget')" v-model.number="form.amount" />
    <form-input :required="true" :label="$t('app.sc.item.unit_price')" :placeholder="$t('app.sc.item.unit_price')" v-model.number="form.unit_price" />
  </bottom-sheet-action>
</template>

<script>
import BottomSheetAction from "@/components/BottomSheetAction";
import { required } from "vuelidate/lib/validators";

const defaultForm = { name: "", amount: null, unit_price: null };
export default {
  name: "RelatedGoodsForm",
  props: ["data", "title", "show"],
  components: { BottomSheetAction },
  data() {
    return {
      form: Object.assign({}, defaultForm)
    };
  },
  watch: {
    data(n) {
      this.form = Object.assign({}, n);
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      amount: {
        required
      },
      unit_price: {
        required
      }
    }
  },
  methods: {
    async validate() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.name.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.item.goods"), options);
        return false;
      }
      if (this.$v.form.amount.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.item.amount"), options);
        return false;
      }
      if (this.$v.form.unit_price.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.item.unit"), options);
        return false;
      }

      return true;
    },
    handleSave() {
      this.validate().then((valid) => {
        if (valid) {
          this.$emit("save", this.form);
        }
      });
    }
  }
};
</script>

<style></style>
