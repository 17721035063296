<template>
  <v-ons-page @show="loadPage">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <search-filter @on-search="onSearch" @on-reset="onReset" />
    <loading-small v-show="loading" />
    <div v-show="!loading" class="mt-14">
      <div class="no-result" v-if="locationList.length === 0">{{ $t("common.empty") }}</div>
      <v-ons-list v-else>
        <form-select-icon
          v-for="(obj, key) in locationList"
          :key="key"
          :label="obj.address"
          :sublabel="''"
          icon="circle"
          iconClass="text-gray-300"
          modifier="none"
          @tap="onSelectLocation(obj.id)"
        />
      </v-ons-list>
    </div>
  </v-ons-page>
</template>

<script>
import { mapMutations } from "vuex";
import { fetchCompanyLocation } from "@/api/company";
import SearchFilter from "@/components/SearchFilter.vue";

export default {
  name: "LocationList",
  components: { SearchFilter },
  data: () => ({
    locationList: [],
    terms: "",
    loading: true
  }),
  methods: {
    ...mapMutations("navigator", ["pop", "setParam"]),
    onSelectLocation(id) {
      let selectedLocation = this.locationList.filter((company) => company.id === id)[0];
      this.setParam({ selectedLocation: selectedLocation });
      this.pop();
    },
    loadPage() {
      let param = this.toolbarInfo.param;
      let companyId = param.companyId;
      fetchCompanyLocation(companyId).then((response) => {
        this.locationList = response["hydra:member"].map((item) => ({
          id: item.id,
          initial_name: "L",
          address: item.address
        }));
        this.loading = false;
      });
    },
    onSearch() {
      console.log("on Search");
    },
    onReset() {
      this.terms = "";
      // this.fetchList();
    }
  }
};
</script>

<style scoped></style>
