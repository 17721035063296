import { fetchRelatedReimbursementDetail as fetch_purchase, fetchRelatedContractDetail as fetch_contract } from "@/api/related_approval";

export function getRelatedApproval(type, id) {
  if (type === "reimbursement") {
    return fetch_purchase(id).then((res) => {
      let item = {
        id: res.id,
        code: res.code,
        currency: res.currency.code,
        data: res.purchase_goods.map((item) => ({
          budget: parseInt(item.budget),
          currency: res.currency.code,
          edited: false,
          goods_name: item.goods_name,
          item_id: item.id,
          unit_price: item.estimated_unit_price
        }))
      };

      return item.data.map((item) => ({
        item_id: item.item_id,
        is_edited: false,
        data: item
      }));
    });
  }
  if (type === "payment") {
    return fetch_contract(id).then((obj) => {
      let data = null;
      switch (obj.contract_item_type.code) {
        case "goods":
          data = obj.contract_item_goods["hydra:member"];
          break;
        case "services":
          data = obj.contract_item_services["hydra:member"];
          break;
        case "hr":
          data = obj.contract_item_hr["hydra:member"];
          break;
        case "other":
          data = obj.contract_item_other["hydra:member"];
          break;
      }
      let item = {
        id: obj.id,
        code: obj.code,
        currency: obj.currency.code,
        data: data,
        module: obj.module.code,
        type: obj.contract_item_type.code,
        contract_name: obj.contract_full_name,
        supplier_account: obj.contract_supplier_account
      };

      return item.data.map((item) => ({
        item_id: item.item_id,
        is_edited: false,
        data: item
      }));
    });
  }
}
