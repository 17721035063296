import { fetchByBufferId as fetchFiles } from "@/api/transaction_files";
import { FILE_TYPE } from "@/utils/constant";

export default {
  name: "InvoiceMixins",
  data() {
    return {
      invoiceFile: {
        e_invoices: [],
        app_papers: []
      }
    };
  },
  methods: {
    loadInvoiceFiles(bufferId) {
      fetchFiles(bufferId).then((r) => {
        let data = r["hydra:member"];
        let a = data.map((i) => ({
          user: i.updater,
          file: i.file,
          date: i.created_at,
          type: i.file.document_type
        }));

        this.invoiceFile.e_invoices = a.filter((i) => i.type == FILE_TYPE.INVOICE && i.user != null);
        this.invoiceFile.app_papers = a.filter((i) => i.type == FILE_TYPE.APP_PAPER && i.user != null);
      });
    }
  }
};
