import request from "@/utils/request";

export function postUpdated(id, data) {
  return request({
    url: `/buffers/${id}/updated`,
    method: "patch",
    data: data
  });
}

export function postHistory(id, data) {
  return request({
    url: `/buffer/${id}/histories`,
    method: "post",
    data: data
  });
}

export function getHistory(id) {
  return request({
    url: `/buffer/${id}/last-history`,
    method: "get"
  });
}
