<template>
  <div class="approval-process">
    <div class="relative">
      <div class="thumb p-0">{{ approver.name.charAt(0) }}</div>

      <div :class="`status ${indicator.class}`">
        <Icon :name="indicator.icon" size="12" />
      </div>
    </div>
    <div class="ml-4 w-full">
      <div class="font-16 text-dark-gray">{{ item.master_process.process.name }}</div>

      <div class="text-black flex justify-between">
        <div>{{ approver.name }}</div>
        <div>{{ item.approved_at ? moment(item.approved_at).format("YYYY/MM/DD HH:mm") : "" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProcessCardView",
  props: ["item", "approver"],
  computed: {
    indicator: function() {
      if (this.item.status === 1) {
        return {
          class: "bg-green",
          icon: "check"
        };
      }
      if (this.item.active) {
        return {
          class: "bg-orange",
          icon: "dots"
        };
      }
      if (this.item.active === false && this.item.status == 0) {
        return {
          class: "bg-gray",
          icon: "dots"
        };
      }
      if (this.item.status === 2) {
        return {
          class: "bg-rose-500",
          icon: "close"
        };
      }

      return {
        class: null,
        icon: null
      };
    }
  }
};
</script>

<style></style>
