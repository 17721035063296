<template>
  <div>
    <Loading v-show="loading" />
    <v-ons-list>
      <v-ons-list-header />
      <form-select
        :label="$t('app.col.related')"
        :placeholder="$t('app.col.select_related')"
        :value="selectedApproval ? selectedApproval.code : null"
        @click="push(page[0].component, page[0].label, { moduleCode: moduleCode })"
      />
      <form-input
        kind="text"
        :label="$t('app.col.customer')"
        :placeholder="$t('app.col.enter_customer')"
        :required="true"
        v-model="form.collection.customer_name"
      />
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-header />
      <form-select
        :label="$t('common.currency')"
        :placeholder="$t('common.select_currency')"
        :required="true"
        @click="cshow.currency = true"
        :value="common.currency ? common.currency.code : null"
      />

      <form-input
        :label="$t('app.col.debt.uncollected')"
        :placeholder="$t('app.col.debt.enter_uncollected')"
        :required="true"
        v-model.number="form.collection.amount"
      />

      <form-calendar
        :label="$t('app.col.debt.payment_date')"
        :placeholder="$t('common.select_date')"
        :required="true"
        mode="single"
        @on-change="handlePaymentDateSelect"
        :value="form.collection.payment_date"
      />
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-header />
      <form-textarea :label="$t('app.col.debt.situation')" :placeholder="$t('app.col.debt.enter_situation')" v-model="form.collection.situation" />

      <form-calendar
        :label="$t('app.col.debt.estimated_date')"
        :placeholder="$t('common.select_date')"
        mode="single"
        @on-change="handleEstimatedDateSelect"
        :value="form.collection.estimated_collection_date"
      />
    </v-ons-list>

    <br />
    <v-ons-list>
      <form-select-user
        :label="$t('app.forward_document')"
        :users="component.finance ? component.finance : []"
        @remove="handleRemoveFinance"
        @add="onClickComponent(COMPONENT.FINANCE, { default: component.finance })"
      />
    </v-ons-list>

    <process-card :module-code="moduleCode">
      <hr />
      <v-ons-list class="bg-transparent mb-5">
        <v-ons-list-item>
          <label class="center">{{ $t("common.remember_approvers") }}</label>
          <div class="right">
            <v-ons-switch v-model="form.save_approvers"> </v-ons-switch>
          </div>
        </v-ons-list-item>
      </v-ons-list>
    </process-card>

    <bottom-submit @submit="handleSubmit" :loading="submitting">
      <template v-slot:additional>
        <div class="flex items-center p-2 space-x-4 w-full text-sm">
          <label class="left">
            <v-ons-checkbox :input-id="'checkbox-email'" v-model="form.send_notification_first_approval"> </v-ons-checkbox>
          </label>
          <label class="center" :for="'checkbox-email'"> {{ $t("app.send_notification_approver") }} </label>
        </div>
      </template>
    </bottom-submit>

    <currency-select :showing="cshow.currency" @on-close="cshow.currency = false" @on-select="onCommonSelect($event, COMMON.CURRENCY)" />
  </div>
</template>

<script>
import ProcessCard from "@/views/common/ProcessCard";
import CurrencySelect from "@/views/common/select/CurrencySelect";
import { required } from "vuelidate/lib/validators";
import { submitDebt } from "@/api/collection_report";
import FormCalendar from "@/components/form/form-calendar";
import RelatedApproval from "@/views/common/list/related_approval/RelatedApproval";
import application from "@/mixins/application";

const defaultForm = {
  payment_date: null,
  situation: "",
  estimated_collection_date: null,
  related_approval_id: null,
  customer_name: "",
  currency_id: null,
  amount: null,
  user_finances: []
};

export default {
  name: "debtForm",
  components: { FormCalendar, CurrencySelect, ProcessCard },
  props: ["show", "init"],
  mixins: [application],
  computed: {
    approver() {
      return this.$store.getters.approvers;
    },
    page() {
      return [
        {
          component: RelatedApproval,
          label: this.$t("common.related_approval")
        }
      ];
    }
  },
  watch: {
    show: function(newVal) {
      if (newVal) {
        this.onShow();
      }
    },
    init: function(newVal) {
      if (newVal) {
        this.onInit("collection");
      }
    },
    "common.currency": function(n) {
      if (n) {
        this.form.collection.currency_id = n.id;
      }
    },
    "component.finance": function(n) {
      if (n) {
        this.form.collection.user_finances = n.map((item) => item.id);
      }
    }
  },
  data() {
    return {
      moduleCode: "collection-debt",
      tempMaster: ["form", "selectedApproval", "common", "component"],
      loading: true,
      submitting: false,
      selectedApproval: null,
      form: {
        send_notification_first_approval: true,
        collection: Object.assign({}, defaultForm),
        save_approvers: true,
        approvals: []
      }
    };
  },
  validations: {
    form: {
      collection: {
        customer_name: {
          required
        },
        currency_id: {
          required
        },
        amount: {
          required
        },
        payment_date: {
          required
        }
      }
    }
  },
  methods: {
    onShow() {
      let param = this.$store.getters.param;
      let selectedFinance = param.selectedFinance;

      if (selectedFinance) {
        this.component[this.COMPONENT.FINANCE] = selectedFinance;
        this.form.collection.user_finances = selectedFinance.map((item) => item.id);
      }

      if (param.selectedApproval) {
        this.selectedApproval = param.selectedApproval;
        this.form.collection.customer_name = this.selectedApproval.contract_name;
        this.form.collection.related_approval_id = this.selectedApproval.id;
      }

      this.$store.commit("navigator/clearParam");
      this.loading = false;
      this.$emit("reloaded");
    },
    handleSubmit() {
      this.isFormValid().then((valid) => {
        if (valid) {
          this.submitting = true;
          submitDebt(this.form)
            .then((res) => {
              this.$ons.notification.alert({
                title: res.code ? res.code : this.$t("common.notif.saved"),
                messageHTML: ` ${this.$t("common.notif.submit")}`
              });
              this.populateDefaultTemp();
              this.$store.commit("navigator/pop");
            })
            .then(() => {
              this.submitting = false;
            });
        }
      });
    },
    handlePaymentDateSelect(val) {
      this.form.collection.payment_date = val;
    },
    handleEstimatedDateSelect(val) {
      this.form.collection.estimated_collection_date = val;
    },
    updateFormCommon(type) {
      if (type === this.COMMON.CURRENCY) {
        this.form.collection.currency_id = this.common.currency.id;
      }
    },
    loadData() {
      if (this.form.collection.user_finances) {
        this.form.collection.user_finances.forEach((item) => {
          this.loadApi(this.COMPONENT.FINANCE, item);
        });
      }
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.collection.customer_name.$invalid) {
        await this.$ons.notification.alert(this.$t("app.col.alert.customer"), options);
        return false;
      }

      if (this.$v.form.collection.currency_id.$invalid) {
        await this.$ons.notification.alert(this.$t("common.alert.currency"), options);
        return false;
      }

      if (this.$v.form.collection.amount.$invalid) {
        await this.$ons.notification.alert(this.$t("app.col.alert.uncollected"), options);
        return false;
      }

      if (this.$v.form.collection.payment_date.$invalid) {
        await this.$ons.notification.alert(this.$t("app.col.alert.payment_date"), options);
        return false;
      }

      if (!this.approverIsValid) {
        await this.$ons.notification.alert(this.$t("common.alert.approver"), options);
        return false;
      }

      return true;
    },
    push(page, key, param) {
      this.$store.commit("navigator/options", {
        animation: "lift-ios"
      });
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    },
    handleReset() {
      this.$ons.notification.confirm(this.$t("common.notif.decide"), { title: this.$t("common.notif.confirm") }).then((response) => {
        if (response === 1) {
          this.form.collection = Object.assign({}, defaultForm);
          this.selectedApproval = null;
          if (this.common.currency) {
            this.form.collection.currency_id = this.common.currency.id;
          }
        }
      });
    }
  }
};
</script>

<style scoped></style>
