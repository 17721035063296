import request from "@/utils/request";

export function fetchApplication(params) {
  return request({
    url: "/buffer/statistic_search",
    method: "get",
    params: params
  });
}

export function fetchFile(params) {
  return request({
    url: "/buffer_files/statistic_search",
    method: "get",
    params: params
  });
}
