<template>
  <v-ons-page @show="fetchList">
    <custom-toolbar v-bind="toolbarInfo">
      <v-ons-toolbar-button slot="right" id="info-button" @click="push(pages[1].component, pages[1].label)">
        <span>{{ $t("common.add_more") }}</span>
      </v-ons-toolbar-button>
    </custom-toolbar>

    <v-ons-progress-bar indeterminate v-if="loading"></v-ons-progress-bar>

    <search-filter @on-search="handleSearch" @on-reset="handleReset" />

    <div class="no-result" v-show="listCount === 0">
      {{ $t("common.empty") }}
    </div>

    <v-ons-list class="mt-14" v-show="listCount > 0">
      <v-ons-list-item v-for="(obj, key) in listData" :key="key">
        <div class="left">
          <div class="user-thumb">{{ obj.initial_name }}</div>
        </div>
        <div class="center">
          <span class="list-item__title">{{ obj.name }}</span>
          <span class="list-item__subtitle">{{ obj.background_companies | formatCompanies }}</span>
        </div>
        <div class="right">
          <v-ons-button modifier="quiet" @click="onEditGroup(obj)">
            <Icon name="edit" classes="icon list-item__thumbnail" />
          </v-ons-button>
        </div>
      </v-ons-list-item>
    </v-ons-list>
  </v-ons-page>
</template>

<script>
import GroupForm from "./form/GroupForm";
import SearchFilter from "@/components/SearchFilter";
import { fetchData } from "@/api/background";

export default {
  name: "Group",
  components: { SearchFilter },
  data() {
    return {
      terms: "",
      listData: {},
      listCount: 0,
      loading: true
    };
  },
  computed: {
    pages() {
      return [
        {
          component: GroupForm,
          label: this.$t("admin.background.edit_group")
        },
        {
          component: GroupForm,
          label: this.$t("admin.background.add_group")
        }
      ];
    }
  },
  filters: {
    formatCompanies(companies) {
      let c = companies.map((item) => {
        return item.company.name;
      });

      let str = c.toString();
      if (str.length > 30) str = str.substring(0, 30) + "...";

      return str;
    }
  },
  methods: {
    fetchList() {
      fetchData({ name: this.terms, itemsPerPage: 99999 }).then((response) => {
        this.listData = response["hydra:member"];
        this.listCount = response["hydra:totalItems"];
        this.loading = false;
      });
    },
    handleSearch(filter) {
      this.terms = filter.term;
      this.fetchList();
    },
    handleReset() {
      this.terms = "";
      this.fetchList();
    },
    onEditGroup(group) {
      this.push(this.pages[0].component, this.pages[0].label, group.id);
    },
    push(page, key, groupId) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              groupId: groupId
            }
          };
        }
      });
    }
  }
};
</script>

<style></style>
