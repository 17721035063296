<template>
  <v-ons-page @init="handleInit">
    <custom-toolbar v-bind="toolbarInfo" />

    <div class="mb-6">
      <v-ons-list>
        <v-ons-list-header />
        <form-input
          :required="true"
          kind="text"
          :label="$t('stat.expense_.name_en')"
          :placeholder="$t('stat.expense_.enter_name')"
          v-model="form.expense_account.name"
          :disabled="isDeleteAction"
        />
        <form-input
          :required="true"
          kind="text"
          :label="$t('stat.expense_.name_jp')"
          :placeholder="$t('stat.expense_.enter_name')"
          v-model="form.expense_account.name_jp"
          :disabled="isDeleteAction"
        />
        <form-input
          :required="true"
          kind="text"
          :label="$t('stat.expense_.name_zh')"
          :placeholder="$t('stat.expense_.enter_name')"
          v-model="form.expense_account.name_zh"
          :disabled="isDeleteAction"
        />

        <v-ons-list-item class="border-b-0">
          <label class="center"> {{ $t("stat.expense_.transport") }} </label>
          <div class="right">
            <v-ons-switch v-model="form.expense_account.is_transportation" :disabled="isDeleteAction" />
          </div>
        </v-ons-list-item>
        <v-ons-list-item>
          <div class="center">
            <span class="list-item__subtitle flex">
              <Icon name="help" :size="18" classes="mr-2" />
              {{ $t("stat.expense_.transport_info") }}
            </span>
          </div>
        </v-ons-list-item>
      </v-ons-list>

      <process-card :module-code="moduleCode">
        <hr />
        <v-ons-list class="bg-transparent mb-5">
          <v-ons-list-item>
            <label class="center">{{ $t("common.remember_approvers") }}</label>
            <div class="right">
              <v-ons-switch v-model="form.save_approvers"> </v-ons-switch>
            </div>
          </v-ons-list-item>
        </v-ons-list>
      </process-card>
    </div>

    <bottom-submit
      @click.native="handleSubmit"
      :label="isDeleteAction ? $t('stat.expense_.delete') : null"
      :classes="isDeleteAction ? 'bg-red-500 active:bg-red-700' : null"
      :loading="submitting"
    />
  </v-ons-page>
</template>

<script>
import ProcessCard from "@/views/common/ProcessCard";
import { required } from "vuelidate/lib/validators";
import { createExpense, submitEdit, submitDelete, fetchExpenseAccountDetail as fetchDetail } from "@/api/expense_account";

export default {
  name: "ExpenseAccountForm",
  components: { ProcessCard },
  computed: {
    approver() {
      return this.$store.getters.approvers;
    },
    param() {
      return this.toolbarInfo ? this.toolbarInfo.param : null;
    },
    id() {
      return this.param ? this.param.id : null;
    },
    action() {
      return this.param ? this.param.action : null;
    },
    company() {
      return this.param ? this.param.company : null;
    },
    module() {
      return this.param ? this.param.module : null;
    },
    isDeleteAction() {
      return this.action === "delete";
    }
  },
  watch: {
    approver(newVal) {
      this.form.approvals = newVal;
    }
  },
  data() {
    return {
      submitting: false,
      moduleCode: "expense-account",
      form: {
        save_approvers: true,
        expense_account: {
          name: "",
          name_jp: "",
          name_zh: "",
          is_transportation: false,
          company_id: null,
          module_type: ""
        },
        approvals: []
      }
    };
  },
  validations: {
    form: {
      expense_account: {
        name: {
          required
        },
        name_jp: {
          required
        },
        name_zh: {
          required
        }
      },
      approvals: {
        $each: {
          approver_id: {
            required
          }
        }
      }
    }
  },
  methods: {
    handleInit() {
      if (this.company) {
        this.form.expense_account.company_id = this.company.id;
      }
      if (this.module) {
        this.form.expense_account.module_type = this.module;
      }

      if (this.id) {
        this.handleLoad();
      }
    },
    handleLoad() {
      fetchDetail(this.id).then((response) => {
        this.form.expense_account = {
          name: response.translations.name.en,
          name_jp: response.translations.name.ja,
          name_zh: response.translations.name.zh,
          is_transportation: response.is_transportation,
          company_id: response.company.id,
          module_type: response.module.code
        };
      });
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.expense_account.name.$invalid) {
        await this.$ons.notification.alert(this.$t("stat.expense_.alert.name_en"), options);
        return false;
      }
      if (this.$v.form.expense_account.name_jp.$invalid) {
        await this.$ons.notification.alert(this.$t("stat.expense_.alert.name_jp"), options);
        return false;
      }
      if (this.$v.form.expense_account.name_zh.$invalid) {
        await this.$ons.notification.alert(this.$t("stat.expense_.alert.name_zh"), options);
        return false;
      }
      if (this.$v.form.approvals.$each.$invalid) {
        await this.$ons.notification.alert(this.$t("common.alert.approver"), options);
        return false;
      }

      return true;
    },
    handleSubmit() {
      this.submitting = true;
      this.isFormValid().then((valid) => {
        if (valid) {
          let submit = null;

          if (this.action === "create") {
            submit = this.handleCreate();
          }
          if (this.action === "edit") {
            submit = this.handleEdit();
          }
          if (this.action === "delete") {
            submit = this.handleDelete();
          }

          if (submit !== null) {
            submit.then(() => {
              this.$ons.notification.toast(this.$t("common.notif.submit"), {
                timeout: 2000,
                animation: "fall"
              });
              this.$store.commit("navigator/pop");
            });
          }
        }
        this.submitting = false;
      });
    },
    async handleCreate() {
      await createExpense(this.form);
    },
    async handleEdit() {
      await submitEdit(this.id, this.form);
    },
    async handleDelete() {
      await submitDelete(this.id, this.form);
    }
  }
};
</script>

<style scoped></style>
