<template>
  <div class="border-l-4 mb-2 bg-gray-50" :class="{ 'border-red-400': data.is_edited }">
    <div class="ml-2">
      <div class="w-full pr-2">
        <div class="mb-1.5">{{ title }}</div>
        <div class="space-y-1 text-xs">
          <div>
            <div>
              <span class="text-gray-500 mr-3">{{ $t("app.sc.item.content") }}</span>
              <span>{{ item.content }}</span>
            </div>
            <div>
              <span class="text-gray-500 mr-3">{{ $t("common.amount") }}</span>
              <span>{{ currency }} {{ item.amount }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "other",
  props: ["data", "title", "currency"],
  computed: {
    item() {
      return this.data.data;
    }
  }
};
</script>

<style></style>
