<template>
  <BottomSheet :title="$t('common.sort_.by')" :showing="showing" @on-close="$emit('on-close')" classes="pb-0">
    <loading-small v-if="loading" class="test" />
    <v-ons-list v-else>
      <div class="p-4 grid grid-cols-2 gap-3 text-sm text-center  border-b border-gray-100">
        <div
          v-for="(item, key) in sorts"
          :key="key"
          class="border border-gray-100 rounded-md py-2 px-2"
          :class="{
            'border border-blue-500 text-blue-500': data.by === item.value,
            'border border-gray-200 text-gray-500': data.by !== item.value
          }"
          @click="onSelectSort(item.value)"
        >
          {{ item.text }}
        </div>
      </div>
      <div class="p-4 grid grid-cols-2 gap-3 text-sm text-center border-b border-gray-100">
        <div
          v-for="(item, key) in order"
          :key="key"
          class="border border-gray-100 rounded-md py-2 px-2"
          :class="{
            'border border-blue-500 text-blue-500': data.order === item.value,
            'border border-gray-200 text-gray-500': data.order !== item.value
          }"
          @click="onSelectOrder(item.value)"
        >
          {{ item.text }}
        </div>
      </div>
      <div class="p-4 grid grid-cols-2 gap-3 text-sm text-center">
        <ons-row class="flex items-center justify-center py-4">
          <v-ons-button modifier="quiet" class="w-full text-center rounded" @click="onReset">
            {{ this.$t("common.sort_.reset") }}
          </v-ons-button>
        </ons-row>
        <ons-row class="flex items-center justify-center py-4">
          <v-ons-button class="w-full text-center rounded" @click="onSubmit">
            {{ $t("common.save") }}
          </v-ons-button>
        </ons-row>
      </div>
    </v-ons-list>
  </BottomSheet>
</template>

<script>
import BottomSheet from "@/components/BottomSheet";

const defaultSort = { by: "createdAt", order: "desc" };

export default {
  name: "SortSelect",
  props: ["showing", "selected"],
  components: {
    BottomSheet
  },
  data() {
    return {
      loading: false,
      sorts: [
        {
          value: "code",
          text: this.$t("common.sort_.app_code")
        },
        {
          value: "createdAt",
          text: this.$t("common.sort_.created_at")
        },
        {
          value: "updatedAt",
          text: this.$t("common.sort_.updated_at")
        }
      ],
      order: [
        {
          value: "asc",
          text: this.$t("common.sort_.ascending")
        },
        {
          value: "desc",
          text: this.$t("common.sort_.descending")
        }
      ],
      data: Object.assign({}, defaultSort)
    };
  },
  methods: {
    onSelectSort(val) {
      this.data.by = val;
    },
    onSelectOrder(val) {
      this.data.order = val;
    },
    onSubmit() {
      this.$emit("on-select", this.data);
    },
    onReset() {
      this.data = Object.assign({}, defaultSort);
      this.onSubmit();
    }
  }
};
</script>

<style></style>
