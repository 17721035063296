<template>
  <div>
    <FileList
      v-for="(item, key) in data"
      :key="'file_' + key"
      :show_action="false"
      :file="item"
      :isEdited="false"
      :history="null"
      :selectable="selectable"
      :isSelected="selection.some((file) => file.id === item.id)"
      @select="handleSelect"
      @on-click="preview"
    />
    <weui-gallery :visible="previewShow" :src="previewImg" :canDelete="false" @onClick="previewShow = false"></weui-gallery>

    <ons-bottom-toolbar class="fixed z-50" v-if="selection.length > 0">
      <div class="bg-white bottom-0 p-2 w-full">
        <slot name="additional" />
        <v-ons-button modifier="outline" class="shadow-md w-10/12" @click="handleDownloadSelection">
          <div class="flex items-center justify-center">
            <Icon name="download" classes="h-6 w-6 mr-2" />
            <span>Download</span>
          </div>
        </v-ons-button>
      </div>
    </ons-bottom-toolbar>
  </div>
</template>

<script>
import FileList from "@/components/list/FileList";
import multiDownload from "multi-download";

export default {
  name: "FileListData",
  components: { FileList },
  props: {
    data: Array,
    selectable: Boolean
  },
  data() {
    return {
      previewImg: "",
      previewShow: false,
      selection: []
    };
  },
  computed: {
    topbar() {
      return document.getElementsByTagName("ons-toolbar")[1];
    }
  },
  watch: {
    selectable: function(n) {
      if (n === false) {
        this.selection = [];
      }
    }
  },
  methods: {
    handleSelect(id) {
      let selected = this.data.filter((file) => file.id === id)[0];

      if (!this.selection.find((item) => item.id === id)) {
        this.selection.push(selected);
      } else {
        this.selection = this.selection.filter((item) => item.id !== id);
      }
    },
    preview(view, url) {
      if (view) {
        this.previewImg = url;
        this.previewShow = true;

        if (this.topbar) {
          this.topbar.style.zIndex = "0";
        }
        if (this.bottomBar) {
          this.bottomBar.style.zIndex = "0";
        }

        return;
      }

      window.open(url, "_blank");
    },
    async handleDownloadSelection() {
      let files = this.selection.map((item) => ({
        url: item.url,
        name: item.formated_name
      }));

      await multiDownload(
        files.map((u) => u.url),
        {
          rename: ({ url, index, urls }) => {
            console.log(url, urls);
            return files[index].name;
          }
        }
      );

      this.$toast.success("Downloaded");
    }
  }
};
</script>
