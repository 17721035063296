export default {
  name: "AdminMixins",
  data() {
    return {
      mandatoryApproval: []
    };
  },
  watch: {
    approver: {
      deep: true,
      handler(n) {
        this.form.approvals = n.map((item) => ({
          approver_id: item.approver_id,
          master_process_id: item.master_process_id
        }));

        this.mandatoryApproval = n.filter((item) => item.optional == false);
      }
    }
  },
  computed: {
    approver() {
      return this.$store.getters.approvers;
    },
    approverIsValid() {
      let any = this.mandatoryApproval.filter((item) => item.approver_id == null);
      return any.length == 0;
    }
  }
};
