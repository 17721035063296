<template>
  <v-ons-page @show="handleShow" @init="handleInit">
    <Loading v-if="loading" />
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>

    <search-filter
      :showSort="false"
      placeholder="Search by code, applicant,customer, vendor, or recipient name"
      @on-search="onSearch"
      @on-reset="resetSearch"
      @on-sort="onSort"
      class="fixed w-full"
    />
    <filter-box @on-sort="onSort" @on-filter="onFilter" :param="param" :periodPrefix="periodPrefix" />
    <div class="no-result" v-if="listData.length === 0">
      <Icon name="pending" classes="text-gray-300" size="82" />
      <span class="text-gray-500 mt-4">{{ $t("common.empty") }}</span>
    </div>

    <div v-else class="pt-20 pb-12">
      <card-list
        v-for="(item, key) in listData"
        :key="key"
        :title="item.title"
        :color="item.color"
        :code="item.code"
        :process="{ status: item.status, process: item.process }"
        :applicant="item.applicant"
        :datas="item.info"
        :activeStatus="item.last_status"
        @click.native="push(pages[0].component, item.code, item.data, !isDone, isDone)"
      />
    </div>
    <infinite-loading @infinite="getApprovalData" :identifier="infiniteId" />
  </v-ons-page>
</template>

<script>
import { fetchList } from "@/api/approval";
import Detail from "./Detail";
import SearchFilter from "@/components/SearchFilter";
import { mapInfo } from "@/utils/application_mapper";
import CardList from "@/components/CardList";
import FilterBox from "./component/filter/FilterBox.vue";
import { fetchAll as fetchExpenses } from "@/api/expense_account";

const defaultFilter = { page: 1, itemsPerPage: 5, search: null, "sort[createdAt]": "desc" };
export default {
  name: "Pending",
  components: { CardList, SearchFilter, FilterBox },
  data() {
    return {
      loading: true,
      listData: [],
      pages: [
        {
          component: Detail,
          label: "Detail"
        }
      ],
      infiniteId: +new Date(),
      filter: Object.assign({}, defaultFilter),
      totalItems: 0,
      param: [
        {
          key: "company.id",
          prefix: "buffer",
          alias: "company",
          value: null
        },
        {
          key: "requester.id",
          prefix: "buffer",
          alias: "requester",
          value: []
        },
        {
          key: "stamp_no",
          prefix: "buffer",
          alias: "stamp_no",
          value: null
        },
        {
          key: "status",
          prefix: "buffer",
          alias: "status",
          value: null
        },
        {
          key: "module.code",
          prefix: "buffer",
          alias: "module",
          value: []
        }
      ],
      periodPrefix: "buffer"
    };
  },
  computed: {
    isDone() {
      return this.toolbarInfo.param ? this.toolbarInfo.param.function === "done" : false;
    }
  },
  methods: {
    handleInit() {
      this.handleGetContentType();
    },
    handleShow() {
      if (this.$store.getters.isSubmit) {
        this.handleResetAll();
      }
      this.loading = false;
    },
    async handleGetContentType() {
      let expenses = this.$store.getters.expenses;
      let datalang = this.$store.getters.datalang;
      if (expenses.length > 0 && datalang == this.$i18n.locale) {
        return;
      } else {
        let res = await fetchExpenses({
          moduleCode: "reimbursement"
          // companyId: this.$store.getters.profile.company.id
        });
        this.$store.commit("user/SET_EXPENSES", res["hydra:member"]);
        this.$store.commit("user/SET_DATALANG", this.$i18n.locale);
      }
    },
    forceRerender() {
      this.infiniteId += 1;
    },
    handleResetAll() {
      this.listData = [];
      this.$store.commit("navigator/resetSubmit");
      this.filter = Object.assign({}, defaultFilter);
      this.forceRerender();
    },
    async getApprovalData($state) {
      if (this.filter.page === 1) {
        this.listData = [];
      }

      if (this.isDone) {
        this.filter.isDone = 1;
      }

      await fetchList(this.filter).then((response) => {
        let data = response["hydra:member"];

        let responseData = data.map((item) => {
          let obj = {
            id: item.id,
            code: item.buffer.code,
            status: item.buffer.status,
            process: item.master_process.process.name,
            applicant: item.buffer.requester,
            title: this.$t("common." + item.buffer.action + "['" + item.master_process.module.code + "']"),
            color: item.buffer.action === "delete" ? "text-red-500" : "",
            last_status: item.buffer.last_approval ? item.buffer.last_approval.process.name : null,
            data: item
          };

          obj.info = mapInfo(item.master_process.module.code, item.buffer.info, item.buffer.data);
          return obj;
        });

        this.listData.push(...responseData);

        if (data.length) {
          this.filter.page += 1;
          $state && $state.loaded();
        } else {
          $state && $state.complete();
        }
      });
    },
    push(page, key, item, showAction, showTopEdit) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              data: item,
              showAction: showAction,
              showTopEdit: showTopEdit ?? false
            }
          };
        }
      });
    },
    onSearch(filters) {
      this.resetSort();
      this.filter["search"] = filters.term;
      this.forceRerender();
    },
    onFilter(filters) {
      this.resetFilter();
      this.filter = Object.assign(this.filter, filters);
      this.forceRerender();
    },
    onSort(filters) {
      this.resetSort();
      this.filter[filters.sort_by] = filters.sort;
      this.forceRerender();
    },
    async resetSort() {
      this.listData = [];
      this.filter.page = 1;
      delete this.filter["sort[code]"];
      delete this.filter["sort[createdAt]"];
      delete this.filter["sort[updatedAt]"];
    },
    async resetFilter() {
      this.filter = Object.assign({}, defaultFilter);
      this.listData = [];
      this.filter.page = 1;
    },
    async resetSearch() {
      this.listData = [];
      this.filter.page = 1;
      delete this.filter["search"];
      this.forceRerender();
    }
  }
};
</script>

<style></style>
