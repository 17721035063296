<template>
  <v-ons-page @init="onInitPage" @show="onShowPage">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>

    <div class="mb-28">
      <v-ons-list>
        <v-ons-list-header />
        <form-input
          kind="text"
          :label="$t('admin.background.group_name')"
          :required="true"
          :placeholder="$t('admin.background.enter_group_name')"
          v-model="form.background.name"
        />
        <!-- company -->
        <ons-list-item>
          <div class="list-item__title required">
            <span class="ml-1">{{ $t("admin.background.company") }}</span>
          </div>
          <span class="list-item__subtitle">
            <label class="center">
              <div
                v-for="(item, key) in companies"
                :key="key"
                class="border-b list-item list-item--chevron list-item__subtitle px-0 py-3 text-base"
                @click="push(page[1].component, page[1].label, { key: key, exclude: companies, hasBackground: false })"
              >
                <span v-if="!item.name"> {{ $t("admin.background.select_company") }}</span>
                <span class="text-gray-900"> {{ item.name }}</span>
              </div>
            </label>
          </span>
        </ons-list-item>
      </v-ons-list>
      <v-ons-list>
        <v-ons-list-item :modifier="md ? 'nodivider' : ''">
          <div class="center" style="justify-content: center; padding: 6px">
            <v-ons-button modifier="quiet" style="justify-content: center" @click="addCompany">
              {{ $t("common.add_more") }}
            </v-ons-button>
          </div>
        </v-ons-list-item>
      </v-ons-list>
      <br />
      <!-- PIC -->
      <v-ons-list>
        <v-ons-list-header>{{ $t("admin.background.pic_help") }}</v-ons-list-header>

        <form-select-remove
          v-for="item in pic"
          :key="item.id"
          :label="item.title"
          :placeholder="item.desc"
          :value="item.selectedItem ? item.selectedItem.name : null"
          @click="onClickPic(item)"
          @remove="onRemovePic(item)"
        />
      </v-ons-list>

      <process-card module-code="background">
        <hr />
        <v-ons-list class="bg-transparent mb-5">
          <v-ons-list-item>
            <label class="center">{{ $t("common.remember_approvers") }}</label>
            <div class="right">
              <v-ons-switch v-model="form.save_approvers"> </v-ons-switch>
            </div>
          </v-ons-list-item>
        </v-ons-list>
      </process-card>

      <bottom-submit :loading="submitting" @submit="handleSubmit">
        <template v-slot:additional>
          <div class="flex items-center p-2 space-x-4 w-full text-sm">
            <label class="left">
              <v-ons-checkbox :input-id="'checkbox-email'" v-model="form.send_notification_first_approval"> </v-ons-checkbox>
            </label>
            <label class="center" :for="'checkbox-email'"> {{ $t("app.send_notification_approver") }} </label>
          </div>
        </template>
      </bottom-submit>
    </div>
  </v-ons-page>
</template>

<script>
import { createBackground, fetchBackgroundDetail, fetchPic, updateBackground } from "@/api/background";
import { mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";

import PicList from "@/views/common/list/PicList";
import CompanyList from "@/views/common/list/CompanyList";
import ApproverList from "@/views/common/list/ApproverList";
import ProcessCard from "@/views/common/ProcessCard";

import Admin from "@/mixins/admin";

export default {
  name: "GroupForm",
  components: { ProcessCard },
  mixins: [Admin],
  validations: {
    form: {
      background: {
        name: {
          required
        }
      }
    }
  },
  computed: {
    page() {
      return [
        {
          component: PicList,
          label: this.$t("common.select")
        },
        {
          component: CompanyList,
          label: this.$t("admin.user.select_company")
        },
        {
          component: ApproverList,
          label: this.$t("common.select")
        }
      ];
    }
  },
  data() {
    return {
      form: {
        background: {
          name: "",
          companies: [],
          background_pics: []
        },
        send_notification_first_approval: true,
        save_approvers: true,
        approvals: []
      },
      selectedCompany: null,
      companies: [{ name: null }],
      pic: [],
      submitting: false
    };
  },
  methods: {
    ...mapMutations("navigator", ["setParam", "clearParam", "pop"]),
    onInitPage() {
      fetchPic().then((response) => {
        this.pic = response["hydra:member"].map((item) => {
          return {
            id: item.id,
            title: item.name,
            desc: this.$t("common.select"),
            selectedItem: null,
            selected: false
          };
        });
        if (this.toolbarInfo.groupId) {
          this.loadGroupDetail();
        }
      });
    },
    loadGroupDetail() {
      fetchBackgroundDetail(this.toolbarInfo.groupId).then((response) => {
        this.selectedCompany = response.company;
        // load companies
        this.companies = response.background_companies.map((item) => ({
          id: item.company.id,
          name: item.company.name
        }));
        this.form.background.companies = response.background_companies.map((item) => {
          return item.company.id;
        });
        this.form.background.name = response.name;
        response.background_pics.forEach((background_pic) => {
          this.pic.forEach((pic) => {
            if (pic.id === background_pic.pic_type.id) {
              pic.selectedItem = background_pic.pic;
            }
          });
        });

        let loadedPics = this.pic.map((pic) => ({
          pic_type_id: pic.id,
          pic_id: pic.selectedItem?.id
        }));

        this.form.background.background_pics = loadedPics.filter((item) => item.pic_id); //filter by pic
      });
    },
    onShowPage() {
      let param = this.$store.getters["navigator/getParam"];
      let pic = param.pic;
      let selectedCompany = param.selectedCompany;

      if (selectedCompany) {
        // this.companies[selectedCompany.index] = selectedCompany.selected; //not reactive
        this.companies.splice(selectedCompany.index, 1, selectedCompany.selected); //reactive,  see https://vuejs.org/v2/guide/reactivity.html#For-Arrays
        this.form.background.companies[selectedCompany.index] = this.companies[selectedCompany.index].id;
      }

      if (pic) {
        this.pic.forEach((item) => {
          if (item.id === pic.key) {
            item.selectedItem = pic.value;
          }
        });

        let pics = this.pic.filter((item) => item.selectedItem); //filter by selected item

        this.form.background.background_pics = pics.map((pic) => ({
          pic_type_id: pic.id,
          pic_id: pic.selectedItem?.id
        }));
      }

      this.clearParam();
    },
    onClickPic(pic) {
      this.push(this.page[0].component, pic.desc, { picId: pic.id });
    },
    onRemovePic(pic) {
      this.pic.forEach((item) => {
        if (item.id === pic.id) {
          item.selectedItem = null;
        }
      });
      this.form.background.background_pics = this.form.background.background_pics.filter((item) => item.pic_type_id !== pic.id);
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.background.name.$invalid) {
        await this.$ons.notification.alert(this.$t("admin.background.enter_group_name"), options);
        return false;
      }
      if (this.form.background.companies.length === 0) {
        await this.$ons.notification.alert(this.$t("common.alert.company"), options);
        return false;
      }
      if (!this.approverIsValid) {
        await this.$ons.notification.alert(this.$t("common.alert.approver"), options);
        return false;
      }

      return true;
    },
    handleSubmit() {
      this.isFormValid().then((valid) => {
        if (valid) {
          this.submitting = true;
          if (this.toolbarInfo.groupId) {
            this.handleUpdateGroup();
          } else {
            this.handleCreateGroup();
          }
          this.submitting = false;
        }
      });
    },
    async handleCreateGroup() {
      await createBackground(this.form).then((res) => {
        this.$ons.notification.alert({
          title: res.code ? res.code : this.$t("common.notif.saved"),
          messageHTML: ` ${this.$t("common.notif.submit")}`
        });
        this.pop();
      });
    },
    async handleUpdateGroup() {
      await updateBackground(this.form, this.toolbarInfo.groupId).then((res) => {
        this.$ons.notification.alert({
          title: res.code ? res.code : this.$t("common.notif.saved"),
          messageHTML: ` ${this.$t("common.notif.submit")}`
        });
        this.pop();
      });
    },
    addCompany() {
      this.companies.push({ name: null });
    },
    push(page, key, param) {
      this.$store.commit("navigator/options", {
        animation: "lift-ios"
      });
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped></style>
