<template>
  <div class="space-y-6 mb-16">
    <v-ons-list>
      <v-ons-list-header />
      <form-input
        kind="text"
        :label="$t('app.cc.item.name')"
        :placeholder="$t('app.cc.item.enter_name')"
        :required="true"
        v-model="item_goods.name"
      />
      <form-input
        kind="text"
        :label="$t('app.cc.item.brand')"
        :placeholder="$t('app.cc.item.enter_brand')"
        :required="true"
        v-model="item_goods.brand"
      />
    </v-ons-list>

    <v-ons-list>
      <form-input :label="$t('app.cc.item.qty')" :placeholder="$t('app.enter_quantity')" :required="true" v-model.number="item_goods.quantity" />
      <form-input :label="$t('common.amount')" :placeholder="$t('common.enter_amount')" :required="true" v-model.number="item_goods.amount" />
      <form-input
        :label="$t('app.cc.item.unit')"
        :placeholder="$t('app.cc.item.enter_unit')"
        :required="true"
        v-model.number="item_goods.unit_price"
      />
    </v-ons-list>

    <v-ons-list>
      <form-input
        :label="$t('app.cc.item.freq')"
        :placeholder="$t('app.cc.item.enter_freq')"
        :required="true"
        v-model.number="item_goods.frequency"
      />
      <form-input :label="$t('app.cc.item.ratio')" :placeholder="$t('app.cc.item.enter_ratio')" v-model.number="item_goods.ratio" />
    </v-ons-list>

    <v-ons-list>
      <form-textarea :label="$t('common.notes')" :placeholder="$t('common.enter_notes')" v-model="item_goods.note" />
    </v-ons-list>

    <bottom-submit :label="$t('common.next')" @click.native="handleSubmit" classes="bg-blue-500" />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "customerDetailPurchase",
  props: ["param"],
  mounted() {
    if (this.param.item.item) {
      this.item_goods = this.param.item.item;
    } else if (this.param.item) {
      this.item_goods = this.param.item;
    }
  },
  watch: {
    item_goods: {
      deep: true,
      handler(n) {
        n.total = n.unit_price * n.quantity;
        this.total = n.unit_price * n.quantity;
      }
    }
  },
  data() {
    return {
      item_goods: {
        name: "",
        brand: "",
        quantity: null,
        unit_price: null,
        amount: null,
        note: "",
        frequency: "",
        ratio: null
      },
      total: 0
    };
  },
  validations: {
    item_goods: {
      name: {
        required
      },
      brand: {
        required
      },
      quantity: {
        required
      },
      amount: {
        required
      },
      unit_price: {
        required
      },
      frequency: {
        required
      }
    }
  },
  methods: {
    handleSubmit() {
      this.isFormValid().then((valid) => {
        if (valid) {
          this.$store.commit("app/SET_DATA", {
            index: this.param.item.index,
            total: this.total,
            name: "item_goods",
            item: this.item_goods
          });

          this.$store.commit("navigator/pop");
        }
      });
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.item_goods.name.$invalid) {
        await this.$ons.notification.alert(this.$t("app.cc.alert.item.name"), options);
        return false;
      }

      if (this.$v.item_goods.brand.$invalid) {
        await this.$ons.notification.alert(this.$t("app.cc.alert.item.brand"), options);
        return false;
      }

      if (this.$v.item_goods.quantity.$invalid) {
        await this.$ons.notification.alert(this.$t("app.cc.alert.item.qty"), options);
        return false;
      }

      if (this.$v.item_goods.amount.$invalid) {
        await this.$ons.notification.alert(this.$t("app.cc.alert.item.amount"), options);
        return false;
      }

      if (this.$v.item_goods.unit_price.$invalid) {
        await this.$ons.notification.alert(this.$t("app.cc.alert.item.unit"), options);
        return false;
      }

      if (this.$v.item_goods.frequency.$invalid) {
        await this.$ons.notification.alert(this.$t("app.cc.alert.item.amount_tax"), options);
        return false;
      }

      return true;
    }
  }
};
</script>

<style scoped></style>
