<template>
  <div>
    <ons-list>
      <ons-list-header />
      <form-select
        :label="$t('common.currency')"
        :required="true"
        modifier="quiet"
        :placeholder="$t('common.select_currency')"
        :value="currencyCode"
      />
      <form-select
        :required="true"
        :label="$t('common.company')"
        modifier="quiet"
        :placeholder="$t('common.select_company')"
        :value="local.company ? local.company.name : null"
      />
      <form-select
        :required="true"
        :label="$t('common.department')"
        modifier="quiet"
        :placeholder="$t('common.select_department')"
        :value="local.department ? local.department.name : null"
      />
    </ons-list>

    <ons-list class="mt-6">
      <ons-list-header>{{ $t("app.purc.goods_detail") }}</ons-list-header>
      <form-select
        v-for="(item, key) in local.items"
        :key="key"
        :label="$t('app.purc.goods_detail') + '(' + ++key + ')'"
        @click="HandleDetailClick(item)"
        :value="currencyCode + ' ' + Round(item.budget, currencyCode)"
      />

      <v-ons-list-item>
        <div class="center list-item__center text-gray-500 text-xs">
          {{ $t("app.purc.add_more_info") }}
        </div>
      </v-ons-list-item>
    </ons-list>
    <v-ons-list>
      <v-ons-list-item class="p-0">
        <div class="center flex justify-center items-center p-0">
          <v-ons-button modifier="quiet" style="justify-content: center" @click="addItem">
            <ons-icon icon="md-plus"> </ons-icon>
            {{ $t("common.add_more") }}
          </v-ons-button>
        </div>
      </v-ons-list-item>
    </v-ons-list>
    <br />
    <v-ons-list>
      <v-ons-list-item class="py-4">
        <div class="left">
          {{ $t("app.purc.total_estimated") }}
          {{ currencyCode ? "(" + currencyCode + ")" : "" }}
        </div>
        <div class="center" style="margin-left: 10px">
          <span class="text-orange"> {{ Round(formObj.total_estimated, currencyCode) }} </span>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <br />
    <v-ons-list>
      <form-select-user :label="$t('app.forward_document')" :users="formObj.user_finances ? formObj.user_finances : []" :editable="false" />
    </v-ons-list>

    <bottom-submit @click.native="handleSubmit" />
  </div>
</template>

<script>
import _ from "lodash";
//api
import { fetchCompanyDetail as fetch_company } from "@/api/company";
import { fetchDepartmentDetail as fetch_department } from "@/api/department";
//mixins
import ApplicationEdit from "@/mixins/application_edit";

export default {
  name: "PurchaseEdit",
  mixins: [ApplicationEdit],
  data() {
    return {
      local: {
        moduleCode: "purchase",
        items: [],
        company: null,
        department: null
      }
    };
  },
  computed: {
    currencyCode() {
      return this.formObj.currency_id ? this.formObj.currency_id.code : null;
    }
  },
  watch: {
    formObj: {
      deep: true,
      handler(n) {
        //set form
        let form = {
          total_estimated: n.total_estimated,
          total_approved: n.total_approved,
          purchase_goods: n.purchase_goods,
          currency_id: n.currency_id ? n.currency_id.id : null
        };
        this.form = Object.assign({}, form);
      }
    },
    "local.items": function(n) {
      if (n) {
        this.formObj.total_estimated = n.reduce((sum, obj) => sum + obj.budget, 0);
      }
    }
  },
  methods: {
    setDefaultLocalData() {
      this.formObj.purchase_goods = this.formObj.purchase_goods.map((item, key) => ({
        ...item,
        index: key
      }));

      this.defaultForm = _.cloneDeep(this.formObj);
      this.local.items = this.formObj.purchase_goods;

      if (this.local.items.length > 0) {
        if (this.local.items[0].company_id) {
          fetch_company(this.local.items[0].company_id).then((r) => (this.local.company = r));
        }
        if (this.local.items[0].department_id) {
          fetch_department(this.local.items[0].department_id).then((r) => (this.local.department = r));
        }
      }
    },
    addItem() {
      // this.local.items = [...this.local.items, { index: this.local.items.length + 1, budget: 0, estimated_unit_price: 0, qty: 0 }];
      this.local.items = [
        ...this.local.items,
        {
          index: this.local.items.length + 1,
          estimated_unit_price: 0,
          qty: 0,
          budget: 0,
          company_id: this.local.company.id,
          department_id: this.local.department.id
        }
      ];
    },
    handleShow() {
      let param = this.$store.getters["navigator/getParam"];
      let purchaseDetail = param?.purchaseDetail;

      if (purchaseDetail) {
        this.local.items = this.local.items.map((obj) => {
          if (obj.index === purchaseDetail.index) {
            return { ...purchaseDetail };
          } else {
            return obj;
          }
        });

        this.formObj.purchase_goods = this.local.items;
      }
    }
  }
};
</script>

<style></style>
