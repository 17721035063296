<template>
  <v-ons-navigator
    swipeable
    swipe-target-width="150px"
    :page-stack="pageStack"
    :pop-page="storePop"
    :options="options"
    :swipe-threshold="0"
    :class="{ 'border-radius': borderRadius }"
  ></v-ons-navigator>
</template>

<script>
import AppSplitter from "./AppSplitter.vue";
import preventBack from "vue-prevent-browser-back";
import { checkCredentials } from "@/permission";
import Login from "@/views/Security/Login";
import SplashScreen from "@/views/Security/SplashScreen";

export default {
  mixins: [preventBack],
  async beforeCreate() {
    this.$store.commit("navigator/push", SplashScreen);
    await checkCredentials()
      .then((valid) => {
        if (valid) {
          this.$store.commit("navigator/reset", AppSplitter);
        } else {
          this.$store.dispatch("user/LogOut");
          this.$store.commit("navigator/reset", Login);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  },
  data() {
    return {
      shutUp: this.md
    };
  },
  computed: {
    pageStack() {
      return this.$store.state.navigator.stack;
    },
    options() {
      return this.$store.state.navigator.options;
    },
    borderRadius() {
      return new URL(window.location).searchParams.get("borderradius") !== null;
    }
  },
  methods: {
    storePop() {
      this.$store.commit("navigator/pop");
    }
  },
  created() {
    window.addEventListener("popstate", this.storePop, false);
  }
};
</script>

<style></style>
