<template>
  <v-ons-page @init="handleInit">
    <custom-toolbar v-bind="toolbarInfo" />

    <div class="mb-6">
      <v-ons-list>
        <v-ons-list-header />
        <form-input
          kind="text"
          :required="true"
          :label="$t('stat.bank_.name')"
          :placeholder="$t('stat.bank_.enter_name')"
          v-model="form.company_bank_account.bank_name"
          :disabled="isDeleteAction"
        />
        <form-input
          kind="text"
          :required="true"
          :label="$t('stat.bank_.branch')"
          :placeholder="$t('stat.bank_.enter_branch')"
          v-model="form.company_bank_account.branch"
          :disabled="isDeleteAction"
        />
        <form-input
          kind="text"
          :required="true"
          :label="$t('stat.bank_.no')"
          :placeholder="$t('stat.bank_.enter_no')"
          v-model="form.company_bank_account.number"
          :disabled="isDeleteAction"
        />
        <form-input
          kind="text"
          :required="true"
          :label="$t('stat.bank_.type')"
          :placeholder="$t('stat.bank_.enter_type')"
          v-model="form.company_bank_account.type"
          :disabled="isDeleteAction"
        />
      </v-ons-list>

      <process-card :module-code="moduleCode">
        <hr />
        <v-ons-list class="bg-transparent mb-5">
          <v-ons-list-item>
            <label class="center">{{ $t("common.remember_approvers") }}</label>
            <div class="right">
              <v-ons-switch v-model="form.save_approvers"> </v-ons-switch>
            </div>
          </v-ons-list-item>
        </v-ons-list>
      </process-card>
    </div>

    <bottom-submit
      @click.native="handleSubmit"
      :loading="submitting"
      :label="isDeleteAction ? $t(`common.delete['bank-account']`) : null"
      :classes="isDeleteAction ? 'bg-red-500 active:bg-red-700' : null"
    />
  </v-ons-page>
</template>

<script>
import ProcessCard from "@/views/common/ProcessCard";
import { required } from "vuelidate/lib/validators";
import { submit, submitEdit, submitDelete } from "@/api/bank_account";

export default {
  name: "BankAccountForm",
  components: { ProcessCard },
  computed: {
    approver() {
      return this.$store.getters.approvers;
    },
    param() {
      return this.toolbarInfo ? this.toolbarInfo.param : null;
    },
    item() {
      return this.param ? this.param.item : null;
    },
    action() {
      return this.param ? this.param.action : null;
    },
    company() {
      return this.param ? this.param.company : null;
    },
    isDeleteAction() {
      return this.action === "delete";
    }
  },
  watch: {
    approver(newVal) {
      this.form.approvals = newVal;
    }
  },
  data() {
    return {
      submitting: false,
      moduleCode: "bank-account",
      form: {
        save_approvers: true,
        company_bank_account: {
          company_id: null,
          bank_name: "",
          branch: "",
          number: "",
          type: ""
        },
        approvals: []
      },
      itemId: null
    };
  },
  validations: {
    form: {
      company_bank_account: {
        bank_name: {
          required
        },
        branch: {
          required
        },
        number: {
          required
        },
        type: {
          required
        }
      },
      approvals: {
        $each: {
          approver_id: {
            required
          }
        }
      }
    }
  },
  methods: {
    handleInit() {
      if (this.item && Object.keys(this.item).length !== 0) {
        this.form.company_bank_account = {
          bank_name: this.item.bank_name,
          branch: this.item.branch,
          number: this.item.number,
          type: this.item.type
        };

        this.itemId = this.item.id;
      }

      if (this.company) {
        this.form.company_bank_account.company_id = this.company;
      }
    },
    handleSubmit() {
      this.submitting = true;
      this.isFormValid().then((valid) => {
        if (valid) {
          let submit = null;

          if (this.action === "create") {
            submit = this.handleCreate();
          }
          if (this.action === "edit") {
            submit = this.handleEdit();
          }
          if (this.action === "delete") {
            submit = this.handleDelete();
          }

          if (submit !== null) {
            submit.then(() => {
              this.$ons.notification.toast(this.$t("common.notif.submit"), {
                timeout: 1500,
                animation: "fall"
              });
              this.$store.commit("navigator/pop");
            });
          }
        }
        this.submitting = false;
      });
    },
    async handleCreate() {
      await submit(this.form);
    },
    async handleEdit() {
      await submitEdit(this.itemId, this.form);
    },
    async handleDelete() {
      await submitDelete(this.itemId, this.form);
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.company_bank_account.bank_name.$invalid) {
        await this.$ons.notification.alert(this.$t("stat.bank_.alert.name"), options);
        return false;
      }

      if (this.$v.form.company_bank_account.branch.$invalid) {
        await this.$ons.notification.alert(this.$t("stat.bank_.alert.branch"), options);
        return false;
      }

      if (this.$v.form.company_bank_account.number.$invalid) {
        await this.$ons.notification.alert(this.$t("stat.bank_.alert.no"), options);
        return false;
      }

      if (this.$v.form.company_bank_account.type.$invalid) {
        await this.$ons.notification.alert(this.$t("stat.bank_.alert.type"), options);
        return false;
      }

      if (this.$v.form.approvals.$each.$invalid) {
        await this.$ons.notification.alert(this.$t("common.alert.approver"), options);
        return false;
      }

      return true;
    }
  }
};
</script>

<style scoped></style>
