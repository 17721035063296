<template>
  <div>
    <div class="bg-white flex text-sm relative border-b border-gray-100">
      <div
        class="absolute ease-in-out transition-transform duration-200 bottom-0 left-0 w-1/3 flex justify-center"
        :style="{ transform: `translateX(calc(100% * ${activeTab}))` }"
      >
        <span class="bg-blue-500 h-0.5 w-1/4" />
      </div>
      <div
        v-for="(item, index) in tabs"
        :key="index"
        class="flex justify-center flex-1"
        @click="handleChange(index)"
      >
        <div class="flex flex-col items-center ">
          <div
            class="text-xs text-xs mb-3 mt-1"
            :class="
              index == activeTab ? 'text-gray-900 font-medium' : 'text-gray-400'
            "
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopBar",
  props: ["tabs"],
  data() {
    return {
      activeTab: 0,
    };
  },
  methods: {
    handleChange(index) {
      let val = parseInt(index);
      if (this.activeTab === val) {
        return;
      }
      this.activeTab = val;
      this.$emit("change-tab", index);
    },
  },
};
</script>

<style></style>
