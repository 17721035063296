<template>
  <div>
    <div class="font-16 text-dark-gray">{{ label }}</div>
    <div class="flex justify-between items-center">
      <div class="font-18" :class="{ 'text-red-500 w-1/2': edited }">{{ value ? value : " - " }}</div>
      <div v-if="history">
        <div class="p-1 rounded flex items-center " style="font-size:10px">
          <Icon name="edit" classes="text-gray-500 mr-1" :size="12" />
          <span class="mr-1">{{ history.user.name }} </span>
          <span class="italic">{{ history.date ? moment(history.date).format("YYYY/MM/DD") : "" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "d-text",
  props: {
    label: {
      type: String
    },
    value: {
      type: [String, Number]
    },
    history: {
      type: Object,
      default: null
    }
  },
  computed: {
    edited() {
      return this.history != null;
    }
  }
};
</script>

<style scoped></style>
