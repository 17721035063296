export const admin = {
  menu: {
    my_organization: "My Organization",
    external_companies: "External Companies",
    background: "Background",
    create_company: "Create Company",
    create_user: "Create User",
    log_out: "Log out"
  },
  background: {
    add_group: "Add Group",
    edit_group: "Edit Group",
    group_name: "Group name",
    enter_group_name: "Enter group name",
    company: "Company",
    select_company: "Select company",
    pic_help: "Registration of Allastarsea PIC",
    finance: "Finance",
    select_finance: "Select Finance",
    treasury: "Treasury",
    select_treasury: "Select Treasury",
    hr: "HR",
    select_hr: "Select HR",
    ga: "General Affair",
    select_ga: "Select General Affair",
    select_pic: "Please Select PIC"
  },
  company: {
    create_company: "Create Company",
    edit_company: "Edit Company",
    company: "Company",
    enter_company: "Enter company name",
    location: "Location",
    enter_location: "Enter location",
    department: "Department",
    enter_department: "Enter department"
  },
  user: {
    create_user: "Create User",
    edit_user: "Edit User",
    delete_user: "Delete User",
    company_info: "Company Information",
    company: "Company",
    select_company: "Select company",
    department: "Department",
    select_department: "Select department",
    location: "Location",
    select_location: "Select location",
    join_date: "Join date",
    select_join_date: "Select join date",
    personal_info: "Personal Information",
    name: "Name",
    enter_name: "Enter fullname",
    email: "Email",
    enter_email: "Enter email",
    company_auth: "Company Authorizer",
    company_finance: "Company Finance / Treasury",
    company_treasury: "Company Internal Auditor",
    company_legal: "Company Legal",
    role: "User role",
    setting: "Setting"
  }
};
