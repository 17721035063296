<template>
  <BottomSheet :title="$t('stat.proj.filter')" :showing="showing" @on-close="$emit('on-close')">
    <div class="p-4 grid grid-cols-2 gap-3 text-sm text-center">
      <div
        v-for="(item, key) in list"
        :key="key"
        class="border border-gray-100 rounded-md py-2 px-2"
        :class="{
          'bg-blue-500 text-white': selectedList === item.value,
          'border border-gray-200 text-gray-500': selectedList !== item.value
        }"
        @click="handleSelect(item)"
      >
        {{ item.text }}
      </div>
    </div>
  </BottomSheet>
</template>

<script>
import BottomSheet from "@/components/BottomSheet";

export default {
  name: "periodSelect",
  props: ["showing", "selected"],
  components: {
    BottomSheet
  },
  data() {
    return {
      selectedList: null
    };
  },
  computed: {
    list() {
      return [
        {
          value: "passed",
          text: this.$t("stat.proj.passed")
        },
        {
          value: "today",
          text: this.$t("stat.proj.today")
        },
        {
          value: "upToToday",
          text: this.$t("stat.proj.upto")
        },
        {
          value: "tomorrow1Week",
          text: this.$t("stat.proj.one")
        },
        {
          value: "next2week",
          text: this.$t("stat.proj.two")
        },
        {
          value: "next3week",
          text: this.$t("stat.proj.three")
        }
      ];
    }
  },
  watch: {
    showing: function(newVal) {
      if (newVal) {
        this.selectedList = this.selected.value;
      }
    }
  },
  methods: {
    handleSelect(item) {
      this.selectedList = item.value;
      this.$emit("on-select", item);
    }
  }
};
</script>

<style></style>
