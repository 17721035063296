<template>
  <div
    style="
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2001;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    <div
      style="
        height: 100px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;
        flex-direction: column;
        border-radius: 10px;
      "
    >
      <img src="@/assets/images/loading.gif" style="width: 38px !important" />
      <span style="font-size: 12px; color: white">Uploading</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Uploading",
};
</script>

<style></style>
