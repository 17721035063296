const tabbar = {
  strict: true,
  namespaced: true,
  state: {
    index: 0
  },
  mutations: {
    set(state, index) {
      state.index = index;
    },
    resetIndex(state) {
      state.index = 0;
    }
  }
};

export default tabbar;
