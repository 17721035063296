import request from "@/utils/request";

export function submitPurchaseBuffer(data) {
  return request({
    url: "/buffer/purchase",
    method: "post",
    data: data
  });
}

export function fetchDetail(id) {
  return request({
    url: "/purchases/" + id,
    method: "get"
  });
}
