const lang_ = "lang_";
const defaultLang = "ja";

export function getLang() {
  if (!localStorage.getItem(lang_)) {
    localStorage.setItem(lang_, defaultLang);
  }
  return localStorage.getItem(lang_);
}

export function setLang(lang) {
  return localStorage.setItem(lang_, lang);
}

export function removeLang() {
  return localStorage.removeItem(lang_);
}
