export const FILE_TYPE = {
  INVOICE: "e-invoice",
  APP_PAPER: "app-paper",
  DEAL_SLIP: "deal-slip",
  COL_DEAL_SLIP: "collection-deal-slip",
  CONTRACT_PLAN: "contract-plan",
  CONTRACT_SIGNED: "contract-signed",
  CORPORATE_SEAL: "corporate-seal-plan",
  CORPORATE_SEAL_SIGNED: "corporate-seal-signed",
  PURCHASE: "purchase-photo",
  SALES_EVIDENCE: "sales-evidence"
};

export const COMPONENT = {
  FINANCE: "finance",
  BANK_ACCOUNT: "bank_account",
  COMPANY: "company",
  DEPARTMENT: "department",
  RELATED_APPROVAL: "related_approval",
  USER: "user"
};

export const COMMON = {
  CURRENCY: "currency",
  COMPANY: "company",
  DEPARTMENT: "department"
};
