import request from "@/utils/request";

export function submit(data) {
  return request({
    url: "/buffer/contract-customer",
    method: "post",
    data: data
  });
}

export function fetchRelatedList(params) {
  return request({
    url: "/contracts/customer_contract_related_approvals",
    method: "get",
    params: params
  });
}
