<template>
  <div>
    <v-ons-list>
      <v-ons-list-item v-for="(item, key) in data" :key="key" class="p-0">
        <div class="left list-item__left mr-2 p-0">
          <div
            class="h-full m-0 p-0 w-1.5"
            :class="{
              'bg-gray-300': !item.edited,
              'bg-red-400': item.edited
            }"
          ></div>
        </div>
        <div class="center list-item__center">
          <div class="w-full pr-2">
            <div class="mb-1.5">{{ $t("app.item") }} ({{ key + 1 }})</div>
            <div class="space-y-1 text-sm">
              <component :is="type + '_'" :item="item" :currency="currency" />
            </div>
          </div>
        </div>

        <!-- <div class="right">
          <v-ons-button modifier="quiet" @click="onDelete(key)">
            <Icon name="trash" stroke-width="1.5" classes="icon list-item__thumbnail" />
          </v-ons-button>
          <v-ons-button modifier="quiet" @click="onEdit(item, key)">
            <Icon name="edit" classes="icon list-item__thumbnail" />
          </v-ons-button>
        </div> -->
      </v-ons-list-item>
    </v-ons-list>
  </div>
</template>

<script>
import RelatedGoods from "./list/RelatedGoods";
import RelatedServices from "./list/RelatedServices";
import RelatedHr from "./list/RelatedHr";
import RelatedOther from "./list/RelatedOther";

export default {
  name: "RelatedList",
  props: ["type", "data", "currency"],
  components: {
    goods_: RelatedGoods,
    services_: RelatedServices,
    hr_: RelatedHr, //hr is reserved
    other_: RelatedOther
  },
  methods: {
    onDelete(index) {
      this.$emit("delete", index);
    },
    onEdit(item, index) {
      this.$emit("edit", item, index);
    }
  }
};
</script>

<style></style>
