import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home/Home.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: () => import("../views/Login/Index.vue")
  // },
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/statistic",
    name: "Statistic",
    component: () => import("../views/Statistic")
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/Admin/Admin.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
