<template>
  <div class="border-l-4 mb-2 bg-gray-50" :class="{ 'border-red-400': data.is_edited }">
    <div class="ml-2">
      <div class="w-full pr-2">
        <div class="mb-1.5">{{ title }}</div>
        <div class="space-y-1 text-xs">
          <div>
            <div class="flex">
              <div class="text-gray-500 mr-3">{{ $t("app.purc.goods_name") }}</div>
              <div>{{ item.goods_name }}</div>
            </div>
            <div class="flex">
              <div class="text-gray-500 mr-3">{{ $t("app.purc.budget_tax") }}</div>
              <div>{{ item.currency }} {{ item.budget }}</div>
            </div>
            <div class="flex">
              <div class="text-gray-500 mr-3">{{ $t("app.purc.estimated_unit_price") }}</div>
              <div>{{ item.currency }} {{ item.unit_price }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "d-item-related",
  props: ["data", "title"],
  computed: {
    item() {
      return this.data.data;
    }
  }
};
</script>

<style></style>
