<template>
  <bottom-sheet :title="detail.title" :showing="detail.show" @on-close="$emit('on-close')">
    <component :is="comp" :data="detail.data" :relation="detail.item"></component>
  </bottom-sheet>
</template>

<script>
import BottomSheet from "@/components/BottomSheet";
import InfoPurchase from "./InfoPurchase";
import InfoContract from "./InfoContract";

export default {
  name: "Info",
  components: {
    "bottom-sheet": BottomSheet,
    purchases: InfoPurchase,
    contract: InfoContract
  },
  props: ["detail", "comp"]
};
</script>

<style></style>
