<template>
  <div class="bg-white border-gray-500 flex h-full items-center justify-center mx-2 rounded-2xl whitespace-nowrap" @click="$emit('on-click')">
    <div class="mr-1">
      <Icon :name="icon" :classes="icon_class ? icon_class : 'text-gray-600'" :size="size" stroke="1" />
    </div>
    <div>
      <span :class="label_class">{{ label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "button-default",
  props: {
    icon: {
      type: String
    },
    size: {
      type: Number,
      default: 22
    },
    label: {
      type: String
    },
    label_class: {
      type: String,
      default: "text-sm"
    },
    icon_class: {
      type: String,
      default: ""
    }
  }
};
</script>

<style></style>
