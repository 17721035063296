<template>
  <ons-list-item :modifier="mod" class="relative">
    <div class="center" @click="$emit('click')">
      <div class="list-item__title" :class="{ required: required }">
        {{ label }}
      </div>
      <div class="list-item__subtitle py-1 text-base">
        <span v-if="showPlaceholder"> {{ placeholder }}</span>
        <span v-else class="text-gray-900"> {{ value }}</span>
      </div>
      <!-- </v-ons-button> -->
    </div>
    <div class="right">
      <ons-button v-if="value" modifier="quiet" class="absolute right-0 " @click="$emit('remove')">
        <div class="status bg-black rounded-full">
          <Icon name="close" size="18" stroke="0.5" classes="text-white p-0.5" />
        </div>
      </ons-button>
    </div>
  </ons-list-item>
</template>

<script>
export default {
  name: "form-select-remove",
  props: {
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    value: {
      type: String
    },
    modifier: {
      type: String,
      default: "chevron"
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showPlaceholder() {
      return this.value ? false : true;
    },
    mod() {
      return this.value ? "" : this.modifier;
    }
  }
};
</script>

<style scoped></style>
