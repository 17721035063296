import { render, staticRenderFns } from "./customerDetailPurchase.vue?vue&type=template&id=e231fd7e&scoped=true&"
import script from "./customerDetailPurchase.vue?vue&type=script&lang=js&"
export * from "./customerDetailPurchase.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e231fd7e",
  null
  
)

export default component.exports