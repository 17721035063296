<template>
  <v-ons-page @show="handleShow">
    <custom-toolbar v-bind="toolbarInfo">
      <v-ons-toolbar-button slot="right" id="info-button" @click="handleAdd">
        <span class="mr-2 text-blue">{{ $t("stat.register") }}</span>
      </v-ons-toolbar-button>
    </custom-toolbar>

    <nav-top :tabs="ttabs" @change-tab="handleChangeTab" />

    <div class="pt-6">
      <search-filter @on-search="handleSearch" @on-reset="handleReset" class="fixed w-full z-10 border-b border-gray-100" />
      <div class="pt-20">
        <v-ons-list>
          <v-ons-list-header>{{ $t("stat.inv.info") }}</v-ons-list-header>
          <invoice-select v-for="(item, key) in list" :key="key" :id="item.id" :data="item" @input="handleSelect" @onUpload="handleUpload" />
        </v-ons-list>
      </div>
      <infinite-loading @infinite="getData" :identifier="infiniteId" />
    </div>

    <invoice-action @tap="handleSubmit()" :submitting="submitting" :selected-count="param.selectedCount" />
  </v-ons-page>
</template>

<script>
import InvoiceSelect from "@/views/Statistic/InvoiceManagement/component/invoiceSelect";
import InvoiceAction from "@/views/Statistic/InvoiceManagement/component/invoiceAction";
import InvoiceForm from "@/views/Statistic/InvoiceManagement/component/invoiceForm";
import InvoiceRegisterList from "@/views/Statistic/InvoiceManagement/component/InvoiceRegisterList";

import NavTop from "@/components/NavTop";
import SearchFilter from "@/components/SearchFilter";
import { fetchList, approve } from "@/api/invoice_management";
import _ from "lodash";

const defaultTab = ["reimbursement", "payment"];
const defaultFilter = { page: 1, itemsPerPage: 5, moduleCode: "reimbursement" };

const defaultParam = { selected: [], selectedEdit: [], eInvoices: [], appPapers: [], selectedCount: 0 };
export default {
  name: "InvoiceManagementIndex",
  components: { NavTop, InvoiceSelect, InvoiceAction, SearchFilter },
  data() {
    return {
      tabs: defaultTab,
      activeTab: 0,
      list: [],
      listCount: [],
      param: _.cloneDeep(defaultParam),
      filter: Object.assign({}, defaultFilter),
      page: [InvoiceForm, InvoiceRegisterList],
      infiniteId: 1,
      submitting: false
    };
  },
  beforeDestroy() {
    this.param = Object.assign({}, defaultParam);
  },
  watch: {
    activeTab(n) {
      this.filter.moduleCode = this.tabs[n];
      this.filter.page = 1;
    }
  },
  computed: {
    ttabs() {
      return [this.$t("stat.inv.reimbursement"), this.$t("stat.inv.payment")];
    }
  },
  methods: {
    forceRerender() {
      this.infiniteId += 1;
    },
    handleShow() {
      if (this.$store.getters.isSubmit) {
        this.$store.commit("navigator/resetSubmit");
        this.param = Object.assign({}, defaultParam);
        this.list = [];
        this.listCount = 0;
        this.resetFilter();
        this.forceRerender();
      }
    },
    handleSelect(val) {
      const index = this.param.selected.indexOf(val);
      if (index > -1) {
        this.param.selected.splice(index, 1);
      } else {
        this.param.selected.push(val);
      }

      this.param.selectedCount = this.param.selected.length;
    },
    handleChangeTab(index) {
      this.activeTab = index;
      this.list = [];
      this.resetParam();
      this.forceRerender();
    },
    resetParam() {
      this.param.selected = [];
      this.param.selectedCount = 0;
    },
    async getData($state) {
      let data = [];
      await fetchList(this.filter).then((response) => {
        data = response["hydra:member"];

        this.listCount = response["hydra:totalItems"];
        let dataMap = data.map((item) => {
          let application_papers = item.application_papers.map((file) => ({
            ...file.file
          }));
          let e_invoices = item.e_invoices.map((file) => ({
            ...file.file
          }));

          return { ...item, application_papers, e_invoices };
        });

        this.list.push(...dataMap);
        if (data.length) {
          this.filter.page += 1;
          $state && $state.loaded();
        } else {
          $state && $state.complete();
        }
      });
    },
    handleUpload(data) {
      this.param.selectedEdit = [];
      this.param.selectedEdit.push(data.id);
      this.param.eInvoices = data.e_invoices;
      this.param.appPapers = data.application_papers;
      this.push(this.page[0], this.$t("stat.invoice"), this.param);
    },
    handleAdd() {
      this.push(this.page[1], this.$t("stat.register"), { moduleCode: this.filter.moduleCode });
    },
    handleSubmit() {
      this.$ons.notification.confirm(this.$t("common.notif.decide"), { title: this.$t("common.notif.confirm") }).then((response) => {
        if (response === 1) {
          this.submitting = true;
          approve({ transactions: this.param.selected }).then(() => {
            this.$ons.notification
              .toast(this.$t("common.notif.submit"), {
                timeout: 1000,
                animation: "fall"
              })
              .then(() => {
                this.submitting = false;
                this.list = [];
                this.filter.page = 1;
                this.resetParam();
                this.forceRerender();
              });
          });
        }
      });
    },
    handleSearch(data) {
      if (data.term.length == 0) {
        return;
      }
      this.resetFilter();
      this.filter.applicationCode = data.term;
      this.forceRerender();
    },
    handleReset() {
      this.resetFilter();
      this.forceRerender();
    },
    resetFilter() {
      this.list = [];
      this.filter.page = 1;
      this.filter.applicationCode = "";
    },
    push(page, key, param) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped></style>
