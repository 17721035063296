<template>
  <v-ons-page @init="handleInit">
    <custom-toolbar v-bind="toolbarInfo">
      <v-ons-toolbar-button slot="right" v-if="selectedList.length > 0" @click="handleAdd()">
        <span class="p-2 text-white bg-blue rounded-md text-sm cursor-pointer">{{ $t("common.add") }} ({{ selectedList.length }})</span>
      </v-ons-toolbar-button>
    </custom-toolbar>

    <search-filter @on-search="handleSearch" @on-reset="handleReset" />
    <div class="mt-16">
      <div class="no-result" v-if="list.length === 0">{{ $t("common.empty") }}</div>
      <v-ons-list v-else>
        <invoice-select v-for="(item, key) in list" :key="key" :id="item.id" :data="item" @input="handleSelect" :showUpload="false" />
      </v-ons-list>
    </div>
    <infinite-loading @infinite="getData" :identifier="infiniteId" />
  </v-ons-page>
</template>

<script>
import { fetchList, register } from "@/api/invoice_management";
import SearchFilter from "@/components/SearchFilter";
import InvoiceSelect from "@/views/Statistic/InvoiceManagement/component/invoiceSelect";

export default {
  name: "InvoiceRegisterList",
  components: { SearchFilter, InvoiceSelect },
  data() {
    return {
      list: [],
      listCount: 0,
      infiniteId: 0,
      filter: { page: 1, itemsPerPage: 10, moduleCode: this.module, obtained: true, applicationCode: null },
      selectedList: []
    };
  },
  computed: {
    module() {
      return this.toolbarInfo.param.moduleCode ? this.toolbarInfo.param.moduleCode : "reimbursement";
    }
  },
  methods: {
    handleInit() {
      this.filter.moduleCode = this.module;
    },
    forceRerender() {
      this.infiniteId += 1;
    },
    handleSearch(filter) {
      this.list = [];
      this.filter.page = 1;
      this.filter.applicationCode = filter.term;
      this.forceRerender();
    },
    handleReset() {
      this.list = [];
      this.filter = { page: 1, itemsPerPage: 10, moduleCode: this.module, obtained: true, applicationCode: null };
      this.forceRerender();
    },
    async getData($state) {
      let data = [];
      await fetchList(this.filter).then((response) => {
        data = response["hydra:member"];

        this.listCount = response["hydra:totalItems"];
        this.list.push(...data);
        if (data.length) {
          this.filter.page += 1;
          $state && $state.loaded();
        } else {
          $state && $state.complete();
        }
      });
    },
    handleSelect(val) {
      const index = this.selectedList.indexOf(val);
      if (index > -1) {
        this.selectedList.splice(index, 1);
      } else {
        this.selectedList.push(val);
      }
    },
    handleAdd() {
      this.$ons.notification.confirm(this.$t("common.notif.decide"), { title: this.$t("common.notif.confirm") }).then((response) => {
        if (response === 1) {
          register({ transactions: this.selectedList }).then(() => {
            this.$ons.notification.toast(this.$t("common.notif.submit"), {
              timeout: 500,
              animation: "fall"
            });
          });
          this.$store.commit("navigator/popSubmit");
        }
      });
    }
  }
};
</script>
