<template>
  <div>
    <Loading v-show="loading" />
    <v-ons-list>
      <v-ons-list-header />
      <form-select
        :label="$t('app.cc.contract_party')"
        :placeholder="$t('app.cc.select_party')"
        :required="true"
        @click="push(page[1].component, page[1].label)"
        :value="companyObj ? companyObj.name : null"
      />

      <form-select
        v-if="companyObj !== null"
        :label="$t('common.department')"
        :placeholder="$t('common.select_department')"
        :required="false"
        @click="
          push(page[2].component, page[2].label, {
            companyId: companyObj.id
          })
        "
        :value="departmentObj ? departmentObj.name : null"
      />

      <form-calendar
        :required="false"
        :label="$t('app.estimated_approval_date')"
        mode="single"
        @on-change="handleEstimatedApprovalDeadline"
        :placeholder="$t('common.select_date')"
        :value="form.contract.estimated_approval_date ? moment(form.contract.estimated_approval_date).format('YYYY-MM-DD') : null"
      />
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-header />
      <form-select
        :label="$t('app.cc.contract_type')"
        :placeholder="$t('app.cc.select_type')"
        :required="true"
        @click="contractSelectShow = true"
        :value="contractTypeObj ? contractTypeObj.name : null"
      />

      <form-select
        :label="$t('common.currency')"
        :placeholder="$t('common.select_currency')"
        :required="true"
        @click="cshow.currency = true"
        :value="common.currency ? common.currency.code : null"
      />

      <form-input
        kind="text"
        :label="$t('app.cc.contract_fullname')"
        :placeholder="$t('app.cc.enter_fullname')"
        :required="true"
        v-model="form.contract.contract_full_name"
      />

      <form-input-mask
        kind="text"
        :required="false"
        :label="$t('app.cc.contract_period')"
        :placeholder="'YYYY-MM-DD'"
        :mask="{ mask: '9999-99-99', placeholder: 'YYYY-MM-DD' }"
        v-model="form.contract.contract_period"
      />
    </v-ons-list>

    <br />
    <ons-list v-show="common.currency && contractTypeObj">
      <ons-list-header>{{ $t("app.cc.items") }}</ons-list-header>
      <form-select
        v-for="item in items"
        :key="item.index"
        @click="onItemClick(item)"
        :label="$t('app.cc.items') + ' (' + item.index + ')'"
        :value="(common.currency ? common.currency.code : '') + ' ' + Round(item.total, common.currency ? common.currency.code : null)"
      />
      <v-ons-list-item>
        <div class="center" style="font-size: 13px; color: rgba(0, 0, 0, 0.6)">
          {{ $t("app.cc.add_more_info") }}
        </div>
      </v-ons-list-item>
    </ons-list>
    <v-ons-list v-show="common.currency && contractTypeObj" class="mb-6">
      <v-ons-list-item class="p-0">
        <div class="center flex justify-center items-center p-0">
          <v-ons-button modifier="quiet" style="justify-content: center" @click="onItemAdd">
            <ons-icon icon="md-plus"> </ons-icon>
            {{ $t("common.add_more") }}
          </v-ons-button>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <br />
    <v-ons-list class="border-b border ">
      <v-ons-list-item>
        <div class="left">{{ $t("common.total") }}</div>
        <div class="center text-xl" style="margin-left: 10px">
          {{ common.currency ? common.currency.code : "" }}
          <span class="text-orange ml-2 font-bold">
            {{ Round(form.contract.amount, common.currency ? common.currency.code : null) }}
          </span>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-header />
      <form-uploader
        :label="$t('app.cc.plan')"
        :sublabel="$t('app.cc.plan_info')"
        :multiple="true"
        :required="false"
        :documentType="FILE_TYPE.CONTRACT_PLAN"
        :existing="this.files[FILE_TYPE.CONTRACT_PLAN]"
        @on-success="onUpload($event, FILE_TYPE.CONTRACT_PLAN)"
      />
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-header />
      <v-ons-list-item modifier="nodivider">
        <v-ons-col width="30%">
          <label>{{ $t("app.cc.stamp") }}</label>
        </v-ons-col>
        <v-ons-col style="margin-left: 10px"> {{ nextStampNo }} </v-ons-col>
      </v-ons-list-item>
      <!-- <form-uploader
        :label="$t('app.cc.signed')"
        :sublabel="$t('app.cc.signed_info')"
        :multiple="true"
        :documentType="FILE_TYPE.CONTRACT_SIGNED"
        :existing="this.files[FILE_TYPE.CONTRACT_SIGNED]"
        @on-success="onUpload($event, FILE_TYPE.CONTRACT_SIGNED)"
      /> -->
    </v-ons-list>

    <br />
    <v-ons-list>
      <form-select-user
        :label="$t('app.forward_document')"
        :users="component.finance ? component.finance : []"
        @remove="handleRemoveFinance"
        @add="onClickComponent(COMPONENT.FINANCE, { default: component.finance })"
      />
    </v-ons-list>

    <process-card :required="false" :module-code="moduleCode">
      <hr />
      <v-ons-list class="bg-transparent mb-5">
        <v-ons-list-item>
          <label class="center">{{ $t("common.remember_approvers") }}</label>
          <div class="right">
            <v-ons-switch v-model="form.save_approvers"> </v-ons-switch>
          </div>
        </v-ons-list-item>
      </v-ons-list>
    </process-card>

    <bottom-submit @submit="handleSubmit" :loading="submitting">
      <template v-slot:additional>
        <div class="flex items-center p-2 space-x-4 w-full text-sm">
          <label class="left">
            <v-ons-checkbox :input-id="'checkbox-email'" v-model="form.send_notification_first_approval"> </v-ons-checkbox>
          </label>
          <label class="center" :for="'checkbox-email'"> {{ $t("app.send_notification_approver") }} </label>
        </div>
      </template>
    </bottom-submit>

    <currency-select :showing="cshow.currency" @on-close="cshow.currency = false" @on-select="onCommonSelect($event, COMMON.CURRENCY)" />

    <contract-type-select :showing="contractSelectShow" modul="customer" @on-close="contractSelectShow = false" @on-select="handleContractSelect" />
  </div>
</template>

<script>
import CurrencySelect from "@/views/common/select/CurrencySelect";
import ContractPartyList from "@/views/common/list/ContractPartyList";
import DepartmentList from "@/views/common/list/DepartmentList";
import ContractTypeSelect from "@/views/common/select/ContractTypeSelect";
import ProcessCard from "@/views/common/ProcessCard";
import customerDetailForm from "@/views/Application/CustomerContract/customerDetailForm";
import { required } from "vuelidate/lib/validators";
import { submit } from "@/api/contract_customer";

import application from "@/mixins/application";

const defaultForm = {
  company_id: null,
  department_id: null,
  currency_id: null,
  contract_item_type_id: null,
  contract_full_name: null,
  contract_plan_files: [],
  signed_contract_files: [],
  contract_period: null,
  estimated_approval_date: null,
  user_finances: [],
  amount: 0
};

const defaultItem = [
  {
    index: 1,
    total: 0
  }
];

export default {
  name: "customerContractForm",
  components: { ContractTypeSelect, CurrencySelect, ProcessCard },
  props: ["show", "init"],
  mixins: [application],
  data() {
    return {
      moduleCode: "contract-customer",
      tempMaster: ["form", "items", "companyObj", "departmentObj", "contractTypeObj", "itemName", "common", "files", "component"],
      defaultTemp: ["common", "companyObj", "departmentObj"],
      loading: true,
      submitting: false,
      contractSelectShow: false,

      companyObj: null,
      departmentObj: null,
      contractTypeObj: null,

      form: {
        send_notification_first_approval: true,
        contract: Object.assign({}, defaultForm),
        save_approvers: true,
        approvals: []
      },
      items: defaultItem,
      itemName: undefined
    };
  },
  validations: {
    form: {
      contract: {
        currency_id: {
          required
        },
        company_id: {
          required
        },
        contract_item_type_id: {
          required
        },
        contract_full_name: {
          required
        }
      }
    }
  },
  computed: {
    approver() {
      return this.$store.getters.approvers;
    },
    page() {
      return [
        {
          component: customerDetailForm,
          label: "Item"
        },
        {
          component: ContractPartyList,
          label: this.$t("app.cc.select_party")
        },
        {
          component: DepartmentList,
          label: this.$t("common.select_department")
        }
      ];
    }
  },
  watch: {
    show: function(newVal) {
      if (newVal) {
        this.onShow();
      }
    },
    init: function(newVal) {
      if (newVal) {
        this.onInit("contract");
      }
    },
    companyObj: {
      handler(n) {
        if (n) {
          this.form.contract.company_id = n.id;
        }
      },
      deep: true
    },
    departmentObj: {
      handler(n) {
        if (n) {
          this.form.contract.department_id = n.id;
        }
      },
      deep: true
    },
    "common.currency": {
      handler(n) {
        if (n) {
          this.form.contract.currency_id = n.id;
        }
      },
      deep: true
    },
    "form.contract.contract_period": {
      handler(n) {
        if (n == "") {
          this.form.contract.contract_period = null;
        }
      }
    },
    items: function(n) {
      if (n) {
        this.form.contract.amount = n.reduce((sum, obj) => sum + obj.total, 0);
      }
    },
    "component.finance": function(n) {
      if (n) {
        this.form.contract.user_finances = n.map((item) => item.id);
      }
    }
  },
  methods: {
    onShow() {
      this.loading = true;
      let param = this.$store.getters.param;

      let selectedContractParty = param.selectedContractParty;
      let selectedDepartment = param.selectedDepartment;
      let selectedFinance = param.selectedFinance;

      if (selectedFinance) {
        this.component[this.COMPONENT.FINANCE] = selectedFinance;
        this.form.contract.user_finances = selectedFinance.map((item) => item.id);
      }

      if (selectedContractParty) {
        this.companyObj = selectedContractParty;
        this.form.contract.company_id = selectedContractParty.id;
      }

      if (selectedDepartment) {
        this.departmentObj = selectedDepartment;
        this.form.contract.department_id = selectedDepartment.id;
      }

      this.populateItem();

      this.$store.commit("navigator/clearParam");
      this.loading = false;
      this.$emit("reloaded");
    },
    loadData() {
      if (this.form.contract.user_finances) {
        this.form.contract.user_finances.forEach((item) => {
          this.loadApi(this.COMPONENT.FINANCE, item);
        });
      }
    },
    onItemClick(item) {
      this.push(this.page[0].component, this.$t("app.cc.items") + " (" + item.index + ")", {
        item: item,
        currencyObj: this.common.currency,
        contractTypeObj: this.contractTypeObj
      });
    },
    onItemAdd() {
      this.items = [...this.items, { index: this.items.length + 1, total: 0 }];
    },
    populateItem() {
      let contractItem = this.$store.getters.data;

      this.itemName = contractItem.name;
      if (contractItem) {
        this.items = this.items.map((obj) => {
          if (obj.index === contractItem.index) {
            return { ...contractItem };
          } else {
            return obj;
          }
        });

        if (this.itemName) {
          this.form.contract[this.itemName] = this.items.map((item) => item.item);
        }
      }

      this.$store.commit("app/SET_DATA", {});
    },
    handleContractSelect(val) {
      this.contractSelectShow = false;
      this.contractTypeObj = val;
      this.form.contract.contract_item_type_id = this.contractTypeObj.id;
    },
    handleEstimatedApprovalDeadline(val) {
      this.form.contract.estimated_approval_date = val;
    },
    handleSubmit() {
      this.isFormValid().then((valid) => {
        //remove approver if null
        this.form.approvals = this.form.approvals.filter((item) => item.approver_id !== null);

        if (valid) {
          this.submitting = true;
          submit(this.form)
            .then((res) => {
              this.$ons.notification.alert({
                title: res.code ? res.code : this.$t("common.notif.saved"),
                messageHTML: ` ${this.$t("common.notif.submit")}`
              });
              this.populateDefaultTemp();
              this.$store.commit("navigator/pop");
            })
            .then(() => {
              this.submitting = false;
            });
        }
      });
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.contract.company_id.$invalid) {
        await this.$ons.notification.alert(this.$t("app.cc.alert.party"), options);
        return false;
      }

      if (this.$v.form.contract.contract_item_type_id.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.type"), options);
        return false;
      }

      if (this.$v.form.contract.currency_id.$invalid) {
        await this.$ons.notification.alert(this.$t("common.alert.currency"), options);
        return false;
      }

      if (this.$v.form.contract.contract_full_name.$invalid) {
        await this.$ons.notification.alert(this.$t("app.cc.alert.fullname"), options);
        return false;
      }

      if (this.form.contract.contract_period != null && !this.$moment(this.form.contract.contract_period)._isValid) {
        await this.$ons.notification.alert(this.$t("app.sc.alert.contract_period"), options);
        return false;
      }

      if (
        this.form.contract.item_goods === undefined &&
        this.form.contract.item_services === undefined &&
        this.form.contract.item_other === undefined
      ) {
        await this.$ons.notification.alert(this.$t("app.cc.alert.items"), options);
        return false;
      }

      if (!this.approverIsValid) {
        await this.$ons.notification.alert(this.$t("common.alert.approver"), options);
        return false;
      }

      return true;
    },
    updateFormCommon(type) {
      if (type === this.COMMON.CURRENCY) {
        this.form.contract.currency_id = this.common.currency.id;
      }
    },
    updateFormFile(type) {
      if (type === this.FILE_TYPE.CONTRACT_PLAN) {
        this.form.contract.contract_plan_files = this.files[this.FILE_TYPE.CONTRACT_PLAN].map((file) => file.id);
      }
      if (type === this.FILE_TYPE.CONTRACT_SIGNED) {
        this.form.contract.signed_contract_files = this.files[this.FILE_TYPE.CONTRACT_SIGNED].map((file) => file.id);
      }
    },
    handleReset() {
      this.$ons.notification.confirm(this.$t("common.notif.decide"), { title: this.$t("common.notif.confirm") }).then((response) => {
        if (response === 1) {
          this.form.contract = Object.assign({}, defaultForm);
          this.items = defaultItem;
          this.contractTypeObj = null;
          this.companyObj = null;
          this.departmentObj = null;

          this.files = {};
          if (this.common.currency) {
            this.form.contract.currency_id = this.common.currency.id;
          }
        }
      });
    }
  }
};
</script>

<style scoped></style>
