<template>
  <div class="space-y-2">
    <d-text :label="$t('app.sc.item.name')" :value="item.content ? item.content : '-'" />
    <hr />
    <d-text :label="$t('app.sc.item.type1')" :value="item.type1 ? item.type1 : '-'" />
    <d-text :label="$t('app.sc.item.type2')" :value="item.type2 ? item.type2 : '-'" />
    <d-text :label="$t('common.amount')" :value="amount" />
    <hr />
    <d-text :label="$t('common.notes')" :value="item.note ? item.note : '-'" />
  </div>
</template>

<script>
import { Round } from "../../../../utils/number";
export default {
  name: "SupplierContractItemOther",
  props: ["data"],
  computed: {
    item() {
      return this.data.item;
    },
    currency() {
      return this.data.currency;
    },
    amount() {
      return this.currency + " " + (this.item.amount ? Round(this.item.amount, this.currency) : "0");
    }
  }
};
</script>

<style></style>
