<template>
  <v-ons-page @init="handleInit">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>

    <ons-list>
      <form-select
        :label="$t('stat.export_.type')"
        :placeholder="$t('stat.export_.select_type')"
        @click="moduleShow = true"
        :value="selectedModule ? selectedModule.label : null"
      />
      <form-select
        :label="$t('stat.export_.file_type')"
        :placeholder="$t('stat.export_.select_file_type')"
        @click="fileTypeShow = true"
        :value="selectedFileType ? selectedFileType : null"
      />
    </ons-list>

    <form-button :loading="submitting" classes="py-6" @click.native="handleExport">
      <icon name="export" :size="22" classes="mr-2" />
      {{ submitting ? "Loading..." : $t("stat.export_.export") }}
    </form-button>

    <select-single
      :title="$t('stat.export_.select_type')"
      :show="moduleShow"
      :option="modules"
      :value="selectedModule ? selectedModule.value : null"
      @close="handleClose('moduleShow')"
      @select="handleModuleSelect"
    />
    <file-type-select :showing="fileTypeShow" :value="selectedFileType" @on-close="handleClose('fileTypeShow')" @on-select="handleFileTypeSelect" />
  </v-ons-page>
</template>
<script>
import fileTypeSelect from "@/views/Statistic/Export/components/fileTypeSelect";
import SelectSingle from "@/components/SelectSingle.vue";
import { exportStampStatistic } from "@/api/stamp_statistic";

export default {
  name: "ExportStampStatistic",
  components: { fileTypeSelect, SelectSingle },
  data() {
    return {
      submitting: false,
      fileTypeShow: false,
      selectedFileType: "pdf",
      moduleShow: false,
      selectedModule: null,
      filter: {
        type: "pdf",
        "module.code": null
      }
    };
  },
  watch: {
    selectedFileType: function(n) {
      if (n) {
        this.filter.type = n;
      }
    },
    selectedModule: function(n) {
      if (n) {
        this.filter["module.code"] = n.value;
      }
    }
  },
  computed: {
    modules() {
      return this.toolbarInfo.param.modules ? this.toolbarInfo.param.modules.map((item) => ({ value: item.module, label: item.name })) : [];
    },
    moduleCode() {
      return this.toolbarInfo.param.module ? this.toolbarInfo.param.module : null;
    }
  },
  methods: {
    handleInit() {
      let y = this.modules.find((item) => item.value == this.moduleCode);
      if (y) {
        this.selectedModule = y;
      }
    },
    handleExport() {
      this.submitting = true;
      exportStampStatistic(this.filter).then(() => {
        this.$ons.notification.toast(this.$t("common.notif.email"), {
          timeout: 2500,
          animation: "fall"
        });
        this.$store.commit("navigator/pop");
      });

      this.submitting = false;
    },
    handleClose(type) {
      this[type] = false;
    },
    handleFileTypeSelect(val) {
      this.selectedFileType = val;
      this.fileTypeShow = false;
    },
    handleModuleSelect(val) {
      this.selectedModule = val;
      this.moduleShow = false;
    }
  }
};
</script>
