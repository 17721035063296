<template>
  <div class="space-y-2">
    <d-text :label="$t('app.cc.item.name')" :value="item.name ? item.name : '-'" />
    <d-text :label="$t('app.cc.item.brand')" :value="item.brand ? item.brand : '-'" />
    <hr />
    <d-text :label="$t('app.cc.item.qty')" :value="item.quantity ? item.quantity : '-'" />
    <d-text :label="$t('common.amount')" :value="currency + ' ' + (item.amount ? item.amount : '-')" />
    <d-text :label="$t('app.cc.item.unit')" :value="item.unit_price ? item.unit_price : '-'" />
    <hr />
    <d-text :label="$t('app.cc.item.freq')" :value="item.frequency ? item.frequency : '-'" />
    <d-text :label="$t('app.cc.item.ratio')" :value="item.ratio ? item.ratio : '-'" />
    <hr />
    <d-text :label="$t('common.notes')" :value="item.note ? item.note : '-'" />
  </div>
</template>

<script>
export default {
  name: "itemGoods",
  props: ["data"],
  computed: {
    currency() {
      return this.data.currency;
    },
    item() {
      return this.data.item;
    }
  }
};
</script>

<style></style>
