<template>
  <v-ons-page @show="handleInit">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <search-filter />

    <div class="mt-14">
      <div class="no-result" v-show="listCount === 0">
        {{ $t("common.empty") }}
      </div>

      <v-ons-list>
        <form-select-icon
          v-for="(item, key) in list"
          :key="key"
          :label="item.bank_name + ' - ' + item.branch"
          :sublabel="item.type ? item.type : ''"
          icon="circle"
          iconClass="text-gray-300"
          modifier="none"
          @tap="handleSelectAccount(item)"
        />
      </v-ons-list>
    </div>
  </v-ons-page>
</template>

<script>
import SearchFilter from "@/components/SearchFilter";
import { fetchListByCompany as fetchList } from "@/api/bank_account";

export default {
  name: "BankAccountList",
  components: { SearchFilter },
  data() {
    return {
      list: []
    };
  },
  computed: {
    listCount() {
      return this.list.length;
    },
    company() {
      if (this.toolbarInfo.param && this.toolbarInfo.param.company) {
        return this.toolbarInfo.param.company;
      } else {
        return this.$store.getters.profile.company.id;
      }
    }
  },
  methods: {
    handleInit() {
      if (this.company) {
        this.getData();
      }
    },
    async getData() {
      await fetchList(this.company).then((response) => {
        this.list = response["hydra:member"];
      });
    },
    handleSelectAccount(item) {
      this.$store.commit("navigator/setParam", { selectedAccount: item });
      this.$store.commit("navigator/pop");
    }
  }
};
</script>

<style></style>
