<template>
  <div class="bg-white fixed px-3 py-4 rounded-0 w-full text-center z-20">
    <div class=" flex items-center">
      <div class="relative w-full">
        <v-ons-search-input
          maxlength="20"
          :placeholder="placeholder ? placeholder : $t('common.search')"
          style="width: 100%"
          v-model="filters.term"
          @focus="showCancel = true"
        >
        </v-ons-search-input>
        <span
          style="
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
          "
          @click="handleReset"
        >
          <Icon v-if="filters.term.length > 0" name="x" classes="h-5 w-5 list-item__thumbnail text-gray-500" />
        </span>
      </div>

      <div class="w-1/6 whitespace-nowrap ml-2" v-show="showCancel">
        <btn-default :label="$t('common.cancel')" label_class="text-sm text-blue-800" @on-click="handleReset" />
      </div>
      <div class="border py-1 ml-2 rounded" v-show="!showCancel" v-if="showSort">
        <btn-default @on-click="sortShow = true" :label="$t('common.sort')" icon="sort" :size="18" />
      </div>
    </div>
    <sort-select :showing="sortShow" :selected="filters.by" @on-close="sortShow = false" @on-select="handleSortSelect" />
    <slot name="info" />
  </div>
</template>

<script>
import SortSelect from "@/views/common/select/SortSelect";

const defaultFilter = { term: "", sort_by: "", by: "", sort: "asc" };
export default {
  name: "SearchFilter",
  props: {
    placeholder: {
      type: String
    },
    showSort: {
      type: Boolean,
      default: false
    }
  },
  components: { SortSelect },
  watch: {
    "filters.term": function() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.handleSearch();
          this.awaitingSearch = false;
        }, 800);
      }
      this.awaitingSearch = true;
    }
  },
  data() {
    return {
      showCancel: false,
      awaitingSearch: false,
      sortShow: false,
      sortBy: "",
      filters: Object.assign({}, defaultFilter)
    };
  },
  methods: {
    handleSearch() {
      this.$emit("on-search", this.filters);
    },
    handleReset() {
      this.filters = Object.assign({}, defaultFilter);
      this.showCancel = false;
      this.$emit("on-reset", this.filters);
    },
    handleSortSelect(sort) {
      this.resetSort();
      this.filters.sort_by = "sort[" + sort.by + "]";
      this.filters.by = sort.by;
      this.filters.sort = sort.order;
      this.sortShow = false;
      this.$emit("on-sort", this.filters);
    },
    resetSort() {
      delete this.filters.by;
      delete this.filters.sort_by;
    }
  }
};
</script>

<style scoped></style>
