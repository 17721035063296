<template>
  <v-ons-page @init="handleInit">
    <custom-toolbar v-bind="toolbarInfo">
      <v-ons-toolbar-button slot="right" id="info-button" @click="handleCreate">
        <span>{{ $t("common.add") }}</span>
      </v-ons-toolbar-button>
    </custom-toolbar>

    <nav-top :tabs="ttabs" @change-tab="handleChangeTab" />

    <loading-small v-show="loading" class="pt-10" />
    <div v-show="!loading" class="pt-6">
      <search-filter @on-search="handleSearch" @on-reset="handleReset" class="fixed w-full z-10 border-b border-gray-100" />
      <div class="pt-16">
        <v-ons-list v-show="listCount > 0">
          <v-ons-list-item v-for="(item, key) in list" :key="key">
            <div class="center">
              <span class="list-item__title">{{ item.name }}</span>
            </div>
            <div class="right">
              <v-ons-button modifier="quiet" @click="handleDelete(item)">
                <Icon name="trash" classes="icon list-item__thumbnail" />
              </v-ons-button>
              <v-ons-button modifier="quiet" @click="handleEdit(item)">
                <Icon name="edit" classes="icon list-item__thumbnail" />
              </v-ons-button>
            </div>
          </v-ons-list-item>
        </v-ons-list>
      </div>
    </div>
  </v-ons-page>
</template>

<script>
import NavTop from "@/components/NavTop";
import ExpenseAccountForm from "@/views/Statistic/ExpenseAccount/ExpenseAccountForm";
import SearchFilter from "@/components/SearchFilter";
import { fetchList } from "@/api/expense_account";

const defaultTab = ["Reimbursement", "Payment"];

const defaultFilter = { page: 1, itemsPerPage: 100, moduleCode: "reimbursement", companyId: null };

export default {
  name: "ExpenseAccountList",
  components: { NavTop, SearchFilter },
  data() {
    return {
      filter: Object.assign({}, defaultFilter),
      loading: true,
      tab: defaultTab,
      activeTab: 0,
      list: [],
      listCount: 0,
      page: [ExpenseAccountForm]
    };
  },
  computed: {
    ttabs() {
      return [this.$t("stat.expense_.reimbursement"), this.$t("stat.expense_.payment")];
    }
  },
  methods: {
    handleInit() {
      this.filter.companyId = this.toolbarInfo.param.id;
      this.getList();
    },
    handleCreate() {
      this.push(this.page[0], this.$t("stat.expense_.create"), {
        company: this.toolbarInfo.param,
        module: this.filter.moduleCode,
        action: "create",
        item: {}
      });
    },
    handleEdit(item) {
      this.push(this.page[0], this.$t("stat.expense_.edit"), {
        company: this.toolbarInfo.param,
        module: this.filter.moduleCode,
        action: "edit",
        id: item.id
      });
    },
    handleDelete(item) {
      this.push(this.page[0], this.$t("stat.expense_.delete"), {
        company: this.toolbarInfo.param,
        module: this.filter.moduleCode,
        action: "delete",
        id: item.id
      });
    },
    handleChangeTab(index) {
      this.activeTab = index;
      this.filter.moduleCode = defaultTab[index].toLocaleLowerCase();
      this.getList();
    },
    async getList() {
      this.loading = true;
      await fetchList(this.filter).then((response) => {
        this.list = response["hydra:member"];
        this.listCount = response["hydra:totalItems"];
      });
      this.loading = false;
    },
    handleSearch(filter) {
      this.list = this.list.filter((item) => item.name.toLocaleLowerCase().includes(filter.term.toLocaleLowerCase()));
    },
    handleReset() {
      this.getList();
    },
    push(page, key, param) {
      this.$store.commit("navigator/options", {
        animation: "lift-ios"
      });
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style scoped></style>
