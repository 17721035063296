<template>
  <div>
    <Loading v-show="loading" />
    <v-ons-list>
      <v-ons-list-header />
      <form-select
        :label="$t('app.col.related')"
        :placeholder="$t('app.col.select_related')"
        :value="approvalObj ? approvalObj.code : null"
        @click="push(page[2].component, page[2].label, { moduleCode: moduleCode })"
      />

      <form-input
        kind="text"
        :label="$t('app.col.customer')"
        :placeholder="$t('app.col.enter_customer')"
        :required="true"
        v-model="form.collection.customer_name"
      />

      <form-select
        :label="$t('common.company')"
        :placeholder="$t('common.select_company')"
        :required="true"
        :value="companyObj ? companyObj.name : null"
        @click="push(page[0].component, page[0].label)"
      />

      <form-select
        v-show="companyObj"
        :label="$t('common.department')"
        :placeholder="$t('common.select_department')"
        :required="true"
        @click="push(page[1].component, page[1].label, { companyId: companyObj.id })"
        :value="departmentObj ? departmentObj.name : null"
      />
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-header />
      <form-select
        :label="$t('common.currency')"
        :placeholder="$t('common.select_currency')"
        :required="true"
        @click="cshow.currency = true"
        :value="common.currency ? common.currency.code : null"
      />

      <form-input
        :label="$t('app.col.sales.amount')"
        :placeholder="$t('app.col.sales.amount_enter')"
        :required="true"
        v-model.number="form.collection.amount"
      />

      <!-- <form-select
        :label="$t('app.col.sales.evidence_type')"
        :placeholder="$t('app.col.sales.evidence_type_select')"
        :required="true"
        @click="
          push(page[2].component, page[2].label, {
            company: companyObj ? companyObj.id : null
          })
        "
        :value="accountObj && accountObj.bank_name ? accountObj.bank_name + ' - ' + accountObj.branch : null"
      /> -->
      <form-select
        :required="true"
        :label="$t('app.col.sales.evidence_type')"
        :placeholder="$t('app.col.sales.evidence_type_select')"
        @click="typeShow = true"
        :value="evidenceType ? evidenceType.name : null"
      />
      <form-calendar
        :label="$t('app.col.sales.delivery_date')"
        :placeholder="$t('common.select_date')"
        mode="single"
        @on-change="handleSalesDateSelect"
        :value="form.collection.sales_date"
      />

      <form-uploader
        :label="$t('app.col.sales.evidence')"
        :sublabel="$t('app.col.sales.evidence_upload')"
        :multiple="true"
        :documentType="FILE_TYPE.SALES_EVIDENCE"
        :existing="this.files[FILE_TYPE.SALES_EVIDENCE]"
        @on-success="onUpload($event, FILE_TYPE.SALES_EVIDENCE)"
      />
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-header />
      <form-textarea :label="$t('common.notes')" :placeholder="$t('common.enter_notes')" v-model="form.collection.notes" />
    </v-ons-list>

    <br />
    <v-ons-list>
      <form-select-user
        :label="$t('app.forward_document')"
        :users="component.finance ? component.finance : []"
        @remove="handleRemoveFinance"
        @add="onClickComponent(COMPONENT.FINANCE, { default: component.finance })"
      />
    </v-ons-list>

    <process-card :module-code="moduleCode">
      <hr />
      <v-ons-list class="bg-transparent mb-5">
        <v-ons-list-item>
          <label class="center">{{ $t("common.remember_approvers") }}</label>
          <div class="right">
            <v-ons-switch v-model="form.save_approvers"> </v-ons-switch>
          </div>
        </v-ons-list-item>
      </v-ons-list>
    </process-card>

    <bottom-submit @submit="handleSubmit" :loading="submitting">
      <template v-slot:additional>
        <div class="flex items-center p-2 space-x-4 w-full text-sm">
          <label class="left">
            <v-ons-checkbox :input-id="'checkbox-email'" v-model="form.send_notification_first_approval"> </v-ons-checkbox>
          </label>
          <label class="center" :for="'checkbox-email'"> {{ $t("app.send_notification_approver") }} </label>
        </div>
      </template>
    </bottom-submit>

    <currency-select :showing="cshow.currency" @on-close="cshow.currency = false" @on-select="onCommonSelect($event, COMMON.CURRENCY)" />
    <evidence-type-select
      :showing="typeShow"
      :value="evidenceType ? evidenceType.id : null"
      @on-close="typeShow = false"
      @on-select="handleEvidenceTypeSelect($event)"
    />
  </div>
</template>

<script>
import ProcessCard from "@/views/common/ProcessCard";
import CurrencySelect from "@/views/common/select/CurrencySelect";
import { required } from "vuelidate/lib/validators";
import { submitSales } from "@/api/collection_report";
import ContractPartyList from "@/views/common/list/ContractPartyList";
import DepartmentList from "@/views/common/list/DepartmentList";
import RelatedApproval from "@/views/common/list/related_approval/RelatedApproval";
import application from "@/mixins/application";
import evidenceTypeSelect from "./component/evidenceTypeSelect.vue";

const defaultForm = {
  company_id: null,
  department_id: null,
  sales_date: null,
  sales_evidences: [],
  evidence_type_id: null,
  notes: "",
  related_approval_id: null,
  customer_name: "",
  currency_id: null,
  amount: null,
  user_finances: []
};

export default {
  name: "salesForm",
  components: { CurrencySelect, ProcessCard, evidenceTypeSelect },
  mixins: [application],
  props: ["show", "init"],
  computed: {
    approver() {
      return this.$store.getters.approvers;
    },
    page() {
      return [
        {
          component: ContractPartyList,
          label: this.$t("common.select_company")
        },
        {
          component: DepartmentList,
          label: this.$t("common.select_department")
        },
        {
          component: RelatedApproval,
          label: this.$t("common.related_approval")
        }
      ];
    }
  },
  watch: {
    show: function(newVal) {
      if (newVal) {
        this.onShow();
      }
    },
    init: function(newVal) {
      if (newVal) {
        this.onInit("collection");
      }
    },
    companyObj: function(n) {
      if (n) {
        this.form.collection.company_id = n.id;
      }
    },
    departmentObj: function(n) {
      if (n) {
        this.form.collection.department_id = n.id;
      }
    },
    "common.currency": function(n) {
      if (n) {
        this.form.collection.currency_id = n.id;
      }
    },
    "component.finance": function(n) {
      if (n) {
        this.form.collection.user_finances = n.map((item) => item.id);
      }
    },
    evidenceType: function(n) {
      if (n) {
        this.form.collection.evidence_type_id = n.id;
      }
    }
  },

  data() {
    return {
      moduleCode: "collection-sales",
      tempMaster: ["form", "companyObj", "departmentObj", "approvalObj", "accountObj", "common", "files", "component"],
      defaultTemp: ["common", "companyObj", "departmentObj"],
      loading: true,
      submitting: false,
      companyObj: null,
      accountObj: null,
      departmentObj: null,
      approvalObj: null,
      form: {
        send_notification_first_approval: true,
        collection: Object.assign({}, defaultForm),
        save_approvers: true,
        approvals: []
      },
      evidenceType: null,
      typeShow: false
    };
  },
  validations: {
    form: {
      collection: {
        company_id: {
          required
        },
        department_id: {
          required
        },
        customer_name: {
          required
        },
        currency_id: {
          required
        },
        amount: {
          required
        },
        evidence_type_id: {
          required
        }
      }
    }
  },
  methods: {
    onShow() {
      let param = this.$store.getters.param;
      let selectedFinance = param.selectedFinance;

      if (selectedFinance) {
        this.component[this.COMPONENT.FINANCE] = selectedFinance;
        this.form.collection.user_finances = selectedFinance.map((item) => item.id);
      }

      if (param.selectedContractParty) {
        let obj = param.selectedContractParty;

        this.companyObj = obj;
        this.form.collection.company_id = obj.id;
      }

      if (param.selectedDepartment) {
        let obj = param.selectedDepartment;

        this.departmentObj = obj;
        this.form.collection.department_id = obj.id;
      }

      if (param.selectedApproval) {
        let obj = param.selectedApproval;
        this.approvalObj = obj;
        this.form.collection.customer_name = obj.contract_name;
        this.form.collection.related_approval_id = obj.id;
      }

      this.$store.commit("navigator/clearParam");
      this.loading = false;
      this.$emit("reloaded");
    },
    loadData() {
      if (this.form.collection.user_finances) {
        this.form.collection.user_finances.forEach((item) => {
          this.loadApi(this.COMPONENT.FINANCE, item);
        });
      }
    },
    handleSubmit() {
      this.isFormValid().then((valid) => {
        if (valid) {
          this.submitting = true;
          submitSales(this.form)
            .then((res) => {
              this.$ons.notification.alert({
                title: res.code ? res.code : this.$t("common.notif.saved"),
                messageHTML: ` ${this.$t("common.notif.submit")}`
              });
              this.populateDefaultTemp();
              this.$store.commit("navigator/pop");
            })
            .then(() => {
              this.submitting = false;
            });
        }
      });
    },
    handleSalesDateSelect(val) {
      this.form.collection.sales_date = val;
    },
    updateFormFile(type) {
      if (type === this.FILE_TYPE.SALES_EVIDENCE) {
        this.form.collection.sales_evidences = this.files[this.FILE_TYPE.SALES_EVIDENCE].map((file) => file.id);
      }
    },
    updateFormCommon(type) {
      if (type === this.COMMON.CURRENCY) {
        this.form.collection.currency_id = this.common.currency.id;
      }
    },
    handleEvidenceTypeSelect(list) {
      this.evidenceType = list;
      this.typeShow = false;
    },
    async isFormValid() {
      let options = { title: this.$t("common.notif.alert") };

      if (this.$v.form.collection.company_id.$invalid) {
        await this.$ons.notification.alert(this.$t("common.alert.company"), options);
        return false;
      }

      if (this.$v.form.collection.department_id.$invalid) {
        await this.$ons.notification.alert(this.$t("common.alert.department"), options);
        return false;
      }

      if (this.$v.form.collection.customer_name.$invalid) {
        await this.$ons.notification.alert(this.$t("app.col.alert.customer"), options);
        return false;
      }

      if (this.$v.form.collection.currency_id.$invalid) {
        await this.$ons.notification.alert(this.$t("common.alert.currency"), options);
        return false;
      }

      if (this.$v.form.collection.amount.$invalid) {
        await this.$ons.notification.alert(this.$t("app.col.alert.collected"), options);
        return false;
      }

      if (this.$v.form.collection.amount.$invalid) {
        await this.$ons.notification.alert(this.$t("app.sales.alert.evidence"), options);
        return false;
      }

      if (!this.approverIsValid) {
        await this.$ons.notification.alert(this.$t("common.alert.approver"), options);
        return false;
      }

      return true;
    },
    handleReset() {
      this.$ons.notification.confirm(this.$t("common.notif.decide"), { title: this.$t("common.notif.confirm") }).then((response) => {
        if (response === 1) {
          this.form.collection = Object.assign({}, defaultForm);
          this.files = {};
          this.approvalObj = null;
          this.accountObj = null;
          this.companyObj = null;
          this.departmentObj = null;

          if (this.common.currency) {
            this.form.collection.currency_id = this.common.currency.id;
          }
        }
      });
    }
  }
};
</script>

<style scoped></style>
