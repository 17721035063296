<template>
  <BottomSheet :title="$t('app.sc.contract_type')" :showing="showing" @on-close="$emit('on-close')">
    <loading-small v-if="loading" />
    <v-ons-list v-else class="pb-16">
      <v-ons-list-item v-for="(item, key) in contractList" :key="key" tappable @click="handleChange(item)">
        <label class="left">
          <v-ons-checkbox :input-id="'contract-' + key" :value="item.id" v-model="selectedOption"> </v-ons-checkbox>
        </label>
        <label :for="'contract-' + key" class="center">
          {{ item.name }}
        </label>
      </v-ons-list-item>
    </v-ons-list>
  </BottomSheet>
</template>

<script>
import { getContractTypes } from "@/api/contractSupplier";
import BottomSheet from "@/components/BottomSheet";

export default {
  name: "ContractTypeSelect",
  props: {
    showing: {
      type: Boolean,
      default: false
    },
    modul: {
      type: String,
      default: "supplier"
    }
  },
  components: {
    BottomSheet
  },
  data() {
    return {
      loading: true,
      contractList: [],
      selectedOption: []
    };
  },
  watch: {
    showing: function(newVal) {
      if (newVal) {
        this.fetchList();
      }
    }
  },

  methods: {
    fetchList() {
      this.selectedOption = [];
      getContractTypes(this.modul).then((response) => {
        this.contractList = response["hydra:member"].map((item) => ({
          name: item.name,
          code: item.code,
          id: item.id
        }));
        this.loading = false;
      });
    },
    handleChange(val) {
      this.$emit("on-select", val);
    }
  }
};
</script>

<style></style>
