<template>
  <v-ons-page @show="onShow" @init="handleInit">
    <Loading v-if="loading" />
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>

    <search-filter
      :showSort="false"
      placeholder="Search by code, applicant,customer, vendor, or recipient name"
      @on-search="onSearch"
      @on-reset="resetSearch"
      @on-sort="onSort"
      class="fixed w-full"
    />
    <filter-box @on-sort="onSort" @on-filter="onFilter" :param="param" :periodPrefix="periodPrefix" />
    <div class="no-result" v-if="listData.length === 0">
      <Icon name="approved" classes="text-gray-300" size="82" />
      <span class="text-gray-500 mt-4">{{ $t("common.empty") }}</span>
    </div>

    <div v-else class="pt-20">
      <card-list
        v-for="(item, key) in listData"
        :key="key"
        :title="item.title"
        :code="item.code"
        :color="item.color"
        :process="{ status: item.status, process: null }"
        :applicant="item.applicant"
        :datas="item.info"
        @click.native="push(pages[0], item.code, item)"
      />
    </div>
    <infinite-loading @infinite="getApprovalData" :identifier="infiniteId" />
  </v-ons-page>
</template>

<script>
import { fetchMyBuffers as fetchList } from "@/api/buffer";
import { fetchForwarded as fetchForwardedList } from "@/api/buffer";
import SearchFilter from "@/components/SearchFilter";
import { mapInfo } from "@/utils/application_mapper";
import CardList from "@/components/CardList";
import ApplicationDataDetail from "@/views/Application/component/ApplicationDataDetail";
import { fetchAll as fetchExpenses } from "@/api/expense_account";

import FilterBox from "./component/filter/FilterBox.vue";

const defaultFilter = { page: 1, itemsPerPage: 5, search: "" };
export default {
  name: "Done",
  components: { CardList, SearchFilter, FilterBox },
  data() {
    return {
      loading: true,
      terms: "",
      listData: [],
      pages: [ApplicationDataDetail],
      filter: Object.assign({}, defaultFilter),
      totalItems: 0,
      infiniteId: this.func + new Date(),
      param: [
        {
          key: "company.id",
          prefix: null,
          alias: "company",
          value: null
        },
        {
          key: "requester.id",
          prefix: null,
          alias: "requester",
          value: []
        },
        {
          key: "stamp_no",
          prefix: null,
          alias: "stamp_no",
          value: null
        },
        {
          key: "status",
          prefix: null,
          alias: "status",
          value: null
        },
        {
          key: "module.code",
          prefix: null,
          alias: "module",
          value: []
        }
      ],
      periodPrefix: "buffer"
    };
  },
  computed: {
    func() {
      return this.toolbarInfo.param.function;
    },
    status() {
      return this.toolbarInfo.param.status;
    }
  },
  methods: {
    handleInit() {
      this.handleGetContentType();
    },
    onShow() {
      this.loading = false;
    },
    async handleGetContentType() {
      let expenses = this.$store.getters.expenses;
      let datalang = this.$store.getters.datalang;
      if (expenses.length > 0 && datalang == this.$i18n.locale) {
        return;
      } else {
        let res = await fetchExpenses({
          moduleCode: "reimbursement"
          // companyId: this.$store.getters.profile.company.id
        });
        this.$store.commit("user/SET_EXPENSES", res["hydra:member"]);
        this.$store.commit("user/SET_DATALANG", this.$i18n.locale);
      }
    },
    forceRerender() {
      this.infiniteId += 1;
    },
    async getApprovalData($state) {
      let data = [];
      if (this.func === "initiated") {
        data = await fetchList(this.filter).then((response) => {
          this.totalItems = response["hydra:totalItems"];
          return response["hydra:member"];
        });
      } else if (this.func === "forwarded") {
        data = await fetchForwardedList(this.filter).then((response) => {
          this.totalItems = response["hydra:totalItems"];
          return response["hydra:member"];
        });
      } else {
        return;
      }

      let responseData = data.map((item) => {
        let obj = {
          buffer_id: item.id,
          code: item.code,
          status: item.status,
          title: this.$t("common." + item.action + "['" + item.module.code + "']"),
          color: item.action === "delete" ? "text-red-500" : "",
          applicant: item.requester,
          data: item.data,
          module: item.module.code
        };

        obj.info = mapInfo(item.module.code, item.info, item.data);
        return obj;
      });

      this.listData.push(...responseData);

      if (data.length) {
        this.filter.page += 1;
        $state && $state.loaded();
      } else {
        $state && $state.complete();
      }
    },
    onSearch(filters) {
      this.resetSort();
      this.filter.search = filters.term;
      this.forceRerender();
    },
    onSort(filters) {
      this.resetSort();
      this.filter[filters.sort_by] = filters.sort;
      this.forceRerender();
    },
    async resetSort() {
      this.listData = [];
      this.filter.page = 1;
      delete this.filter["sort[code]"];
      delete this.filter["sort[createdAt]"];
      delete this.filter["sort[updatedAt]"];
    },
    onFilter(filters) {
      this.resetFilter();
      this.filter = Object.assign(this.filter, filters);
      this.forceRerender();
    },
    async resetFilter() {
      this.filter = Object.assign({}, defaultFilter);
      this.listData = [];
      this.filter.page = 1;
    },
    async resetSearch() {
      this.listData = [];
      this.filter.page = 1;
      delete this.filter["search"];
      this.forceRerender();
    },
    onReset() {
      this.listData = [];
      this.filter = Object.assign({}, defaultFilter);
      this.forceRerender();
    },
    push(page, key, param) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>

<style></style>
