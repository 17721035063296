export const stat = {
  select_one: "以下から一つを選択してください",
  projection: "申請済支払予測",
  projection_info: "申請済支払予測の統計",
  invoice: "発票管理",
  invoice_info: "発票管理の設定",
  bank: "自社口座情報管理",
  bank_info: "自社口座情報の設定",
  expense: "費目の管理",
  expense_info: "費目の設定",
  export: "エクスポート",
  export_info: "入出金申請書 ・報告書と添付のエクスポート",
  document_search: "承認記録・ファイル検索",
  register: "Register",

  proj: {
    filter: "絞り込み",
    passed: "過去",
    today: "当日",
    upto: "当日以前",
    one: "明日〜1週間以内",
    two: "8〜14日以内",
    three: "15〜30日以内",
    pending: "保留中の支払"
  },
  inv: {
    reimbursement: "精算",
    payment: "支払",
    info: "未入手発票一覧",
    selected: "選択済み",
    items: "件",
    select: "選択してください",
    ok: "完了",
    upload: "アップロード",
    einvoice: "電子発票",
    einvoice_info: "発票のOFD・PDFファイル、写真を10件までアップロードできます",
    paper: "請求書等",
    paper_info: "請求書等のファイル、写真を10件までアップロードできます",
    alert: {
      upload: "電子発票 /支払申請書をアップロードしてください"
    }
  },
  bank_: {
    account: "自社口座情報管理",
    name: "銀行名",
    enter_name: "銀行名の入力",
    branch: "支店",
    enter_branch: "支店名の入力",
    no: "口座番号",
    enter_no: "口座番号の入力",
    type: "種類",
    enter_type: "種類の入力",
    alert: {
      name: "銀行名を入力してください",
      branch: "支店を入力してください",
      no: "口座番号を入力してください",
      type: "種類を入力してください"
    }
  },
  expense_: {
    account: "費用科目",
    reimbursement: "精算",
    payment: "支払",
    create: "費目の作成",
    edit: "費目の編集",
    delete: "費目の削除",
    name: "名称",
    name_en: "名称 (EN)",
    name_jp: "名称 (JP)",
    name_zh: "名称 (ZH)",
    enter_name: "費目の種類の入力",
    transport: "交通費に属する科目ですか？",
    transport_info: "交通費科目の場合、目的地と起点到達点の入力項目が申請書上増加します。",
    alert: {
      name_en: "費目の種類を入力してください (EN)",
      name_jp: "費目の種類を入力してください (JP)",
      name_zh: "費目の種類を入力してください (ZH)"
    }
  },
  export_: {
    export: "エクスポート",
    type: "エクスポートの形式",
    select_type: "エクスポートの形式を選択",
    file_type: "File Type",
    select_file_type: "File file type",
    period: "期間",
    select_period: "期間の選択",
    alert: {
      type: "エクスポートの形式を選択",
      period: "期間の選択"
    },
    date_type: "日付タイプ",
    date_type_: {
      completion: "完了日",
      payment: "支払完了日"
    }
  },
  search_: {
    desc: "過去申請やファイルを検索",
    app: "申請別",
    doc: "ファイル別",
    change: "条件変更"
  }
};
