<template>
  <v-ons-card v-if="data">
    <div class="content p-2 space-y-2">
      <d-text :label="$t('common.app_code')" :value="data.code" />
      <d-text :label="$t('common.company')" :value="company" />
      <hr />
      <d-text :label="$t('stat.bank_.name')" :value="obj.bank_name" />
      <d-text :label="$t('stat.bank_.branch')" :value="obj.branch" />
      <d-text :label="$t('stat.bank_.no')" :value="obj.number" />
      <d-text :label="$t('stat.bank_.type')" :value="obj.type" />
    </div>
  </v-ons-card>
</template>

<script>
import buffer from "@/mixins/buffer";

export default {
  name: "BankAccount",
  props: ["data"],
  mixins: [buffer],
  computed: {
    obj() {
      return this.data.data;
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(n, o) {
        if (n != o) {
          this.fetchCompany(this.data.data.company_id);
        }
      }
    }
  }
};
</script>

<style></style>
