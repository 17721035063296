<template>
  <v-ons-page @show="handleShow" @init="handleInit">
    <custom-toolbar v-bind="toolbarInfo">
      <!-- <v-ons-toolbar-button v-if="activeTab == 1" slot="right" id="info-button" class="cursor-pointer" @click="handleDownloadFile">
        <Icon name="download" classes="h-6 w-6" />
      </v-ons-toolbar-button> -->
    </custom-toolbar>

    <Loading v-if="loading" />
    <NavTop :tabs="tabs" @change-tab="handleChangeTab" />
    <!-- <FilterDescription :filter="filter" @show="handleShowFilter" /> -->
    <FilterDescription
      :filter="filter"
      :showAction="activeComp !== 'ApplicationList'"
      :showSelect="showSelect"
      @show="handleShowFilter"
      @action="handleShowSelect"
    />
    <!-- content -->
    <div>
      <Empty icon="document-search" v-if="componentData.length === 0" />
      <component v-else class="z-50" :is="activeComp" :data="componentData" :selectable="showSelect" />
    </div>
    <infinite-loading @infinite="loadData" :identifier="infiniteId" />
    <!-- end content -->
    <FilterBox @filter="handleShowFilter" @sort="handleShowSort" />

    <FilterSort
      :showing="showSort"
      :sorts="activeTab === 0 ? sort.ApplBy : sort.FileBy"
      :order="sort.order"
      @select="handleSort"
      @reset="handleResetSort"
      @close="showSort = false"
    />
    <FilterForm :defaultFilter="filter" :showing="showFilter" @close="showFilter = false" @search="handleSearch" />
  </v-ons-page>
</template>

<script>
import NavTop from "@/components/NavTop";
import Empty from "@/components/Empty";
import FilterBox from "@/views/Statistic/DocumentSearch/component/FilterBox";
import FilterDescription from "@/views/Statistic/DocumentSearch/component/FilterDescription";
import FilterForm from "@/views/Statistic/DocumentSearch/component/FilterForm";
import FilterSort from "@/views/Statistic/DocumentSearch/component/FilterSort";
import ApplicationList from "@/views/Statistic/DocumentSearch/component/ApplicationList";
import FileListData from "@/views/Statistic/DocumentSearch/component/FileListData";
import { fetchApplication, fetchFile } from "@/api/document_search";
import { mapInfo } from "@/utils/application_mapper";
import multiDownload from "multi-download";

export default {
  name: "DocumentSearch",
  components: { NavTop, Empty, FilterBox, FilterDescription, FilterForm, FilterSort, ApplicationList, FileListData },
  data() {
    return {
      activeTab: 0,
      loading: true,
      showFilter: true,
      showSort: false,
      applicationData: [],
      fileData: [],
      infiniteId: 0,
      showSelect: false,
      filter: Object.assign(
        {},
        {
          page: 1,
          itemsPerPage: 5,
          companyName: this.$store.getters.profile.company.name,
          currency: "",
          inputAmount: 0,
          amount: null,
          condition: null,
          vendor: null,
          completionDate: null,
          estPaymentDate: null,
          createdAt: null
        }
      )
    };
  },
  computed: {
    tabs() {
      return [this.$t("stat.search_.app"), this.$t("stat.search_.doc")];
    },
    sort() {
      return {
        ApplBy: [{ value: "sort[date]", text: this.$t("common.date") }],
        FileBy: [
          { value: "sort[date]", text: this.$t("common.date") },
          { value: "sort[amount]", text: this.$t("common.amount") }
        ],
        order: [
          { value: "asc", text: this.$t("common.ascending") },
          { value: "desc", text: this.$t("common.descending") }
        ]
      };
    },
    activeComp() {
      return this.activeTab === 0 ? "ApplicationList" : "FileListData";
    },
    componentData() {
      return this.activeTab === 0 ? this.applicationData : this.fileData;
    }
  },
  methods: {
    handleInit() {
      let format = "YYYY-MM-DD";
      let today = this.moment().format(format);

      this.filter.createdAt = `${today}to${today}`;
    },
    handleShowSelect() {
      this.showSelect = !this.showSelect;
    },
    forceRerender() {
      this.infiniteId += 1;
    },
    handleShow() {
      this.loading = false;
    },
    handleChangeTab(value) {
      this.activeTab = value;
      this.resetAndLoadData();
    },
    handleShowFilter() {
      this.showFilter = true;
    },
    handleShowSort() {
      this.showSort = true;
    },
    handleSort(sort) {
      this.resetData();
      this.filter[sort.by] = sort.order;

      this.showSort = false;
      this.forceRerender();
    },
    handleResetSort() {
      this.filter.sort = null;
      this.showSort = false;
      this.resetAndLoadData();
    },
    handleSearch(data) {
      this.filter = data;
      this.showFilter = false;
      this.resetAndLoadData();
    },
    handleDownloadFile() {
      let files = this.fileData.map((item) => ({
        url: item.url,
        name: item.formated_name
      }));

      multiDownload(
        files.map((u) => u.url),
        {
          rename: ({ url, index, urls }) => {
            console.log(url, urls);
            return files[index].name;
          }
        }
      );

      this.$toast.success("Downloaded");
    },
    resetData() {
      this.applicationData = [];
      this.fileData = [];
      this.filter.page = 1;

      delete this.filter["sort[date]"];
      delete this.filter["sort[amount]"];
    },
    resetAndLoadData() {
      this.resetData();

      this.forceRerender();
    },
    loadData($state) {
      let getMethod = this.activeTab === 0 ? fetchApplication : fetchFile;
      let param = Object.assign({}, this.filter);
      delete param.inputAmount;
      delete param.condition;

      getMethod(param).then((res) => {
        let data = res["hydra:member"];
        if (this.activeTab === 0) {
          let responseData = data.map((item) => {
            let obj = {
              buffer_id: item.id,
              code: item.code,
              status: item.status,
              title: this.$t("common." + item.action + "['" + item.module.code + "']"),
              applicant: item.requester,
              data: item.data,
              module: item.module.code,
              stamp: item.stamp_no
            };

            obj.info = mapInfo(item.module.code, item.info, item.data);
            return obj;
          });

          this.applicationData.push(...responseData);
        } else {
          let files = data.map((item) => {
            return {
              ...item.files,
              id: item.id,
              formated_name: item.formatted_name
            }
          })
          this.fileData.push(...files);
        }

        if (data.length) {
          this.filter.page += 1;
          $state && $state.loaded();
        } else {
          $state && $state.complete();
        }
      });
    }
  }
};
</script>

<style scoped>
.toolbar-tittle-custom {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.2;
}
.page /deep/ .page__content {
  background-color: white !important;
  bottom: 48px;
}
.page /deep/ .page__background {
  background-color: white !important;
}
.infinite-loading-container /deep/ .infinite-status-prompt {
  background-color: white !important;
}
</style>
