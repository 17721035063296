<template>
  <v-ons-page @init="loadPage">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <search-filter @on-search="onSearch" @on-reset="onReset" />
    <loading-small v-show="loading" />
    <div v-show="!loading" class="mt-14">
      <div class="no-result" v-if="departmentList.length === 0">{{ $t("common.empty") }}</div>
      <v-ons-list v-else>
        <form-select-icon
          v-for="(obj, key) in departmentList"
          :key="key"
          :label="obj.name"
          :sublabel="obj.subtitle"
          icon="circle"
          iconClass="text-gray-300"
          modifier="none"
          @tap="onSelectDepartment(obj.id)"
        />
      </v-ons-list>
    </div>
  </v-ons-page>
</template>

<script>
import { mapMutations } from "vuex";
import { fetchCompanyDepartment } from "@/api/company";
import SearchFilter from "@/components/SearchFilter.vue";

export default {
  components: { SearchFilter },
  name: "DepartementList",
  data: () => ({
    departmentList: [],
    terms: "",
    loading: true
  }),
  methods: {
    ...mapMutations("navigator", ["pop", "setParam"]),
    onSelectDepartment(id) {
      let selectedDepartment = this.departmentList.filter((company) => company.id === id)[0];
      this.setParam({ selectedDepartment: selectedDepartment });
      this.pop();
    },
    loadPage() {
      let param = this.toolbarInfo.param;
      let companyId = param.companyId;

      fetchCompanyDepartment(companyId).then((response) => {
        this.departmentList = response["hydra:member"].map((item) => ({
          id: item.id,
          initial_name: "D",
          name: item.name,
          subtitle: "Department of " + item.name
        }));
        this.loading = false;
      });
    },
    onSearch(filter) {
      console.log(filter);
    },
    onReset() {
      this.terms = "";
      // this.fetchList();
    }
  }
};
</script>

<style scoped></style>
