export const common = {
  yes: "是的",
  no: "不",
  back: "返回",
  cancel: "取消",
  add_more: "追加",
  submit: "提交",
  next: "下一步",
  view_data: "查看数据",
  process: "审批流程",
  applicant: "Applicant",
  // app
  currency: "币种",
  select_currency: "选择币种",
  amount: "金额",
  enter_amount: "输入金额",
  total: "合计金额",
  date: "日期",
  select_date: "选择日期",
  destination: "费用明细",
  enter_destination: "报销明细之追加",
  notes: "备注",
  enter_notes: "输入备注",
  company: "公司",
  select_company: "选择公司名",
  department: "部门",
  select_department: "选择部门",
  // related_approval: "相关已审批报销申请",
  related_approval: "相关已审批合同",
  related_approval_info: "相关已审批报销申请信息",
  select: "选择",
  enter_number: "输入1以上的数",
  content: "内容",
  //other
  select_finance: "财务负责人之选择",
  //payment
  bank_name: "银行",
  enter_bank_name: "输入银行名",
  branch_name: "分行",
  enter_branch_name: "输入分行号码",
  colleague: "同事",
  search: "搜索",
  filter: "筛选",
  change: "改变",
  sort: "排序",
  ascending: "升序",
  descending: "降序",
  reset: "重置",
  greater_than: "最低金额",
  less_than: "最高金额",
  equal_to: "等于",
  bypass: "记录系统（不走审批流程）快捷",
  remember_approvers: "记忆这次设定的流程上的人",
  uploaded_at: "上转日期",
  confirm: "选择",
  vendor: "小贩",
  enter_vendor: "输入供应商名称",
  ok: "完了",
  empty: "暂无数据",
  add: "追加",
  save: "保存",
  select_languange: "请选择一种语言",
  alert: {
    currency: "请选择币种",
    approver: "请选择审批人",
    company: "请选择公司名",
    department: "请选择部门"
  },
  notif: {
    submit: "已提交申请",
    approve: "已审批申请",
    reject: "不通过申请",
    alert: "警告",
    confirm: "确认",
    decide: "确定吗？",
    saved: "已保存",
    email: "已发送数据，请确认您的邮箱"
  },
  create: {
    user: "使用者之作成",
    company: "公司之作成",
    background: "群组之作成",
    reimbursement: "报销申请",
    payment: "付款申请",
    purchase: "购买申请",
    "collection-debt": "债权回收",
    "collection-report": "汇款报告",
    "collection-sales": "Create Sales/Custom Info Registration",
    "contract-supplier": "购入・供应商合同缔结",
    "contract-customer": "客户合同缔结",
    "corporate-seal": "证件资料盖章申请",
    "expense-account": "费用项目",
    "bank-account": "自家公司帐号情报管理之作成"
  },
  edit: {
    user: "使用者之编辑",
    company: "公司之编辑",
    background: "群组之编辑",
    reimbursement: "报销申请",
    payment: "付款申请",
    purchase: "购买申请",
    "collection-debt": "债权回收",
    "collection-report": "汇款报告",
    "contract-supplier": "购入・供应商合同缔结",
    "contract-customer": "客户合同缔结",
    "corporate-seal": "证件资料盖章申请",
    "expense-account": "费用项目",
    "bank-account": "自家公司帐号情报管理之编辑"
  },
  delete: {
    user: "使用者之作成",
    "bank-account": "自家公司帐号情报管理之删除",
    "expense-account": "费用项目之删除"
  },
  waiting: "等待{type}",
  app_code: "申请代码",
  type: "类型",
  approve: "审批",
  reject: "不通过",
  approved: "流程完成",
  rejected: "被否决",
  withdrawn: "撤销",
  onprocess: "在过程中",
  detail: "详细",
  requested_by: "申请者： ",
  company_authorizer: "公司责任者",
  sort_: {
    by: "排序",
    app_code: "申请代码",
    created_at: "申请日期",
    updated_at: "更新日期",
    ascending: "升序",
    descending: "降序",
    reset: "重置"
  },
  app_detail_: {
    more: "更多功能",
    more_opt: "更多功能",
    submit_again: "使用此记录重新提交",
    cancel: "取消"
  }
};
