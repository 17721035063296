<template>
  <BottomSheet :title="$t('stat.export_.file_type')" :showing="showing" @on-close="$emit('on-close')">
    <v-ons-list class="pb-16">
      <v-ons-list-item v-for="(item, key) in list" :key="key" tappable @click="handleChange(item)">
        <label class="left">
          <v-ons-checkbox :input-id="'export_file_type_select-' + key" :value="item" v-model="selectedOption"> </v-ons-checkbox>
        </label>
        <label :for="'export_file_type_select-' + key" class="center">
          {{ item }}
        </label>
      </v-ons-list-item>
    </v-ons-list>
  </BottomSheet>
</template>

<script>
import BottomSheet from "@/components/BottomSheet";

export default {
  name: "fileTypeSelect",
  props: {
    showing: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    }
  },
  components: {
    BottomSheet
  },
  watch: {
    showing: function(newVal) {
      if (newVal) {
        if (this.value.length > 0) {
          this.selectedOption = this.list.filter((item) => item == this.value);
        }
      }
    }
  },
  data() {
    return {
      list: ["pdf", "xlsx"],
      selectedOption: []
    };
  },
  methods: {
    handleChange(val) {
      this.$emit("on-select", val);
    }
  }
};
</script>
