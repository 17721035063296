<template>
  <v-ons-list-item>
    <span class="list-item__title" :class="{ required: required }">
      {{ label }}
    </span>
    <span class="list-item__subtitle">
      <input :class="'text-input w-full ' + classes" :type="kind" :placeholder="placeholder" :value="value" @input="handleInput" />
      <weui-loading v-if="loading" type="brand"></weui-loading>
    </span>
  </v-ons-list-item>
</template>

<script>
export default {
  name: "form-input-search",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    kind: {
      type: String,
      default: "number"
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    value: {
      type: [String, Number]
    },
    required: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: ""
    }
  },
  methods: {
    handleInput(val) {
      this.$emit("input", val.target.value);
    }
  }
};
</script>

<style scoped></style>
