<template>
  <v-ons-action-sheet :visible="showing" @posthide="handleClose" cancelable class="action-sheet-form">
    <div class="action-sheet-title">
      <div class="left" @click="handleClose">
        <Icon name="chevron-down" classes="h-6 w-6" />
      </div>
      <div>{{ title }}</div>
    </div>

    <!-- <div style="max-height:80vh;overflow: auto;padding-bottom:28px;"> -->
    <div :style="`${min ? 'min-height:' + min + 'vh;' : ''}max-height:90vh;overflow: auto;padding-bottom:28px;`">
      <slot />
    </div>
  </v-ons-action-sheet>
</template>

<script>
export default {
  name: "BottomSheet",
  props: ["title", "showing", "min"],
  methods: {
    handleClose() {
      this.$emit("on-close");
    }
  }
};
</script>

<style></style>
