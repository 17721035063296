import request from "@/utils/request";

export function fetchList(params) {
  return request({
    url: "/users/approver",
    method: "get",
    params: params
  });
}
export function fetchDetail(id) {
  return request({
    url: `/users/${id}`,
    method: "get"
  });
}
