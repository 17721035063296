<template>
  <div>
    <div class="font-18">
      <span class="font-semibold">{{ expense ? expense.name : "-" }} </span>
      <span class="text-blue-500">{{ currency }} {{ Round(data.amount, currency) }}</span>
    </div>
    <slot />
    <div class="font-16 text-gray-500">{{ $t("common.notes") }} : {{ data.note ? data.note : "-" }}</div>
  </div>
</template>

<script>
import { fetchExpenseAccountDetail as getExpense } from "@/api/expense_account";
import { Round } from "../../utils/number";

export default {
  name: "d-item-content",
  props: ["data", "currency"],
  data() {
    return {
      expense: undefined
    };
  },
  mounted() {
    this.fetchExpense(this.data.content_type_id);
  },
  methods: {
    Round,
    fetchExpense(id) {
      getExpense(id).then((response) => {
        this.expense = response;
      });
    }
  }
};
</script>

<style></style>
