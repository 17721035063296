<template>
  <v-ons-list>
    <component v-if="relation" :is="relation.module + '_' + relation.type.code" :data="data" :currency="relation.currency_code" />
  </v-ons-list>
</template>

<script>
import SupplierGoods from "./components/SupplierGoods";
import SupplierService from "./components/SupplierService";
import SupplierHr from "./components/SupplierHr";
import SupplierOther from "./components/SupplierOther";
import CustomerGoods from "./components/CustomerGoods";
import CustomerService from "./components/CustomerService";
import CustomerOther from "./components/CustomerOther";

export default {
  name: "InfoContract",
  props: ["data", "relation"],
  components: {
    "contract-supplier_goods": SupplierGoods,
    "contract-supplier_services": SupplierService,
    "contract-supplier_hr": SupplierHr,
    "contract-supplier_other": SupplierOther,
    "contract-customer_goods": CustomerGoods,
    "contract-customer_services": CustomerService,
    "contract-customer_other": CustomerOther
  }
};
</script>

<style></style>
