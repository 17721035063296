<template>
  <v-ons-page @show="showPage" @init="initPage">
    <custom-toolbar v-bind="toolbarInfo"></custom-toolbar>
    <v-ons-progress-bar indeterminate v-if="loading"></v-ons-progress-bar>
    <div class="pb-16">
      <v-ons-list>
        <v-ons-list-header>
          <br />
          {{ $t("app.reimb.reimbursement") }} ({{ index }})
        </v-ons-list-header>

        <!-- <form-select
          :label="$t('common.related_approval')"
          @click="
            push(page[2].component, page[2].label, {
              moduleCode: 'reimbursement'
            })
          "
          :placeholder="$t('common.select')"
          :value="form.approval ? form.approval.code : null"
        /> -->

        <form-input
          :required="false"
          :label="$t('app.page_application')"
          :placeholder="$t('app.enter_page_application')"
          v-model.number="page_application"
        />

        <!-- <form-select
          :required="true"
          :label="$t('common.company')"
          @click="push(page[0].component, page[0].label, null)"
          :placeholder="$t('common.select_company')"
          :value="form.company ? form.company.name : null"
        />

        <form-select
          :required="true"
          v-show="form.company"
          :label="$t('common.department')"
          @click="
            push(page[1].component, page[1].label, {
              companyId: form.company.id
            })
          "
          :placeholder="$t('common.select_department')"
          :value="form.department ? form.department.name : null"
        /> -->
      </v-ons-list>

      <!-- content -->
      <v-ons-list>
        <v-ons-list-header />
        <v-ons-list-item>
          <div class="right">
            <ons-button modifier="quiet" @click="addReimbursementContent">
              <Icon name="plus-circle" size="25" stroke="1" classes="text-blue" />
            </ons-button>
          </div>
          <div class="center ">
            <span class="list-item__title required">
              {{ $t("common.content") }}
            </span>
            <span class="list-item__subtitle">
              {{ $t("app.reimb.reimbursement_content") }}
            </span>
          </div>
        </v-ons-list-item>

        <content-list
          v-if="form.content.length > 0"
          :content="form.content"
          :selectedCurrency="selectedCurrency"
          @delete="onContentDelete"
          @edit="onContentEdit"
        />
      </v-ons-list>

      <!-- related approval -->
      <v-ons-list>
        <v-ons-list-header>
          <br />
          {{ $t("common.related_approval_info") }}
        </v-ons-list-header>
        <related-list v-if="form.related_items.length > 0" :data="form.related_items" @edit="onRelatedEdit" @delete="onRelatedDelete" />
        <div v-else class="text-gray-300 p-5">{{ $t("common.empty") }}</div>
      </v-ons-list>

      <!-- form content -->
      <reimbursement-content
        @onExpenseChange="onExpenseChange"
        @onSubmitContent="onSubmitContent"
        @closeBottomMenu="onCancelContent"
        v-model="showBottomMenu"
        :expense-list="expenseList"
        :content-form="contentForm"
        :selected-currency="selectedCurrency"
      />

      <!-- form related -->
      <related-form
        :show="showRelatedMenu"
        :title="$t('app.purc.goods_detail') + ' (' + (currentRelatedIndex + 1) + ')'"
        :data="relatedForm"
        @cancel="onCancelRelated"
        @save="onSubmitRelated"
      />

      <bottom-submit :label="$t('common.next')" @click.native="onSubmitReimbursementDetail" classes="bg-blue-500" :loading="loading" />
    </div>
  </v-ons-page>
</template>

<script>
import applicationDetail from "@/mixins/application_detail";
import { mapMutations } from "vuex";
// import { minValue, required } from "vuelidate/lib/validators";
import { required } from "vuelidate/lib/validators";
import { fetchAll as fetchExpenseList } from "@/api/expense_account";

import ContractPartyList from "@/views/common/list/ContractPartyList";
import DepartmentList from "@/views/common/list/DepartmentList";
import RelatedApproval from "@/views/common/list/related_approval/RelatedApproval";
import ExpenseDetailList from "@/views/common/list/ExpenseDetailList";
import ReimbursementContent from "@/views/Application/Reimbursement/component/ReimbursementContent";
import ContentList from "@/views/Application/Reimbursement/component/ContentList";
import RelatedList from "@/views/Application/Reimbursement/component/RelatedList";
import RelatedForm from "@/views/Application/Reimbursement/component/RelatedForm";

const defaultContent = {
  expense: null,
  amount: null,
  date: null,
  notes: null,
  destination: "",
  from_to: ""
};

const defaultRelated = {
  item_id: null,
  goods_name: "",
  budget: 0,
  unit_price: 0,
  edited: false
};

export default {
  components: {
    ReimbursementContent,
    ContentList,
    RelatedList,
    RelatedForm
  },
  name: "ReimbursementDetailForm",
  mixins: [applicationDetail],
  watch: {
    "form.company": function(n) {
      if (n) {
        this.expenseFilter.companyId = n.id;
        this.loadExpenses();
      }
    },
    page_application: function(n) {
      this.form.page_application = n > 0 ? n : null;
    }
  },
  data: () => ({
    showBottomMenu: false,
    showRelatedMenu: false,
    loading: true,
    expenseList: [],
    index: null,
    currentIndex: null,
    currentRelatedIndex: null,
    selectedCurrency: null,
    form: {
      page_application: null,
      company: null,
      department: null,
      approval: null,
      content: [],
      related_approval_id: null,
      related_items: []
    },
    contentForm: Object.assign({}, defaultContent),
    relatedForm: Object.assign({}, defaultRelated),
    expenseFilter: {
      moduleCode: "reimbursement",
      companyId: null
    },
    page_application: null,
    total: 0
  }),
  validations: {
    form: {
      // page_application: {
      //   required,
      //   minValue: minValue(1)
      // },
      company: {
        required
      },
      department: {
        required
      }
    },
    contentForm: {
      expense: {
        required
      },
      amount: {
        required
      },
      date: {
        required
      },
      destination: {
        required
      }
    }
  },
  computed: {
    page() {
      return [
        {
          component: ContractPartyList,
          label: this.$t("common.select_company")
        },
        {
          component: DepartmentList,
          label: this.$t("common.select_department")
        },
        {
          component: RelatedApproval,
          label: this.$t("common.related_approval")
        },
        {
          component: ExpenseDetailList,
          label: this.$t("app.reimb.select_expense")
        }
      ];
    }
  },
  methods: {
    ...mapMutations("navigator", ["clearParam", "setParam", "pop"]),
    onExpenseChange() {
      this.contentForm.date = null;
      this.contentForm.destination = null;
    },
    addReimbursementContent() {
      this.showBottomMenu = !this.showBottomMenu;
    },
    showPage() {
      let param = this.$store.getters["navigator/getParam"];
      let selectedCompany = param?.selectedContractParty;
      if (selectedCompany) {
        this.expenseFilter.companyId = selectedCompany.id;
        this.loadExpenses();

        this.form.company = selectedCompany;
        this.form.department = null;
      }
      let selectedDepartment = param?.selectedDepartment;
      if (selectedDepartment) {
        this.form.department = selectedDepartment;
      }

      //on select related approval
      let selectedApproval = param.selectedApproval;
      if (selectedApproval) {
        this.form.approval = selectedApproval;
        this.form.related_items = selectedApproval.data;
        this.form.related_approval_id = selectedApproval.id;
      }
      this.clearParam();
    },
    loadExpenses() {
      fetchExpenseList(this.expenseFilter).then((response) => {
        this.expenseList = response["hydra:member"].map((item) => ({
          id: item.id,
          name: item.name,
          is_transportation: item.is_transportation
        }));
      });
    },
    initPage() {
      let param = this.toolbarInfo.param;

      if (param.currency) {
        this.selectedCurrency = param.currency;
      }

      let item = param.item;
      this.index = item.index;
      if (item.company_id) {
        this.getCompany(item.company_id);
      }
      if (item.department_id) {
        this.getDepartment(item.department_id);
      }
      if (item.page_number) {
        this.form.page_application = item.page_number;
      }
      if (item.related_approval_id) {
        this.form.related_approval_id = item.related_approval_id;
        this.getRelatedApproval("reimbursement", item.related_approval_id);
      }
      if (item.related_items) {
        this.form.related_items = item.related_items.map((obj) => ({
          ...obj.data
        }));
      }
      if (item.contents) {
        this.total = 0;
        item.contents.forEach((item) => {
          this.total += item.amount;
          this.getExpenseDetail(item.content_type_id, item, this.form.content);
        });
      }
      this.loading = false;
    },
    async onSubmitContent() {
      if (this.$v.contentForm.expense.$invalid) {
        await this.$ons.notification.alert("Please select Expense");
        return;
      }
      if (this.$v.contentForm.amount.$invalid) {
        await this.$ons.notification.alert("Please fill Amount");
        return;
      }
      if (this.contentForm.expense.is_transportation) {
        if (this.$v.contentForm.date.$invalid) {
          await this.$ons.notification.alert("Please fill Date");
          return;
        }
        if (this.$v.contentForm.destination.$invalid) {
          await this.$ons.notification.alert("Please fill Destination");
          return;
        }
      }

      if (this.currentIndex !== null) {
        //edit
        this.form.content = this.form.content.map((item, index) => {
          if (index === this.currentIndex) {
            return this.contentForm;
          } else {
            return item;
          }
        });
      } else {
        //add
        let content = { ...this.contentForm };
        this.form.content.push(content);
      }

      //calculate total
      this.total = 0;
      this.form.content.forEach((item) => {
        this.total += item.amount;
      });

      this.resetContent();
    },
    onSubmitRelated(relatedForm) {
      //only edit, no add
      if (this.currentRelatedIndex !== null) {
        relatedForm.edited = true;

        this.form.related_items = this.form.related_items.map((item, index) => {
          if (index === this.currentRelatedIndex) {
            return relatedForm;
          } else {
            return item;
          }
        });
      }

      this.resetRelated();
    },
    onContentDelete(index) {
      this.form.content = this.form.content.filter((obj, i) => i !== index);
    },
    onRelatedDelete(index) {
      this.form.related_items = this.form.related_items.filter((obj, i) => i !== index);
    },
    onContentEdit(content, index) {
      this.contentForm = content;
      this.currentIndex = index;
      this.showBottomMenu = true;
    },
    onRelatedEdit(related, index) {
      this.relatedForm = related;
      this.currentRelatedIndex = index;
      this.showRelatedMenu = true;
    },
    onCancelContent() {
      this.resetContent();
    },
    onCancelRelated() {
      this.resetRelated();
    },
    resetContent() {
      this.currentIndex = null;
      this.showBottomMenu = false;
      this.contentForm = Object.assign({}, defaultContent);
    },
    resetRelated() {
      this.currentRelatedIndex = null;
      this.showRelatedMenu = false;
      this.relatedForm = Object.assign({}, defaultRelated);
    },
    async validate() {
      let options = { title: this.$t("common.notif.alert") };

      // if (this.$v.form.page_application.$invalid) {
      //   await this.$ons.notification.alert(this.$t("app.reimb.alert.page"), options);
      //   return false;
      // }
      if (this.$v.form.company.$invalid) {
        await this.$ons.notification.alert(this.$t("common.alert.company"), options);
        return false;
      }
      if (this.$v.form.department.$invalid) {
        await this.$ons.notification.alert(this.$t("common.alert.department"), options);
        return false;
      }
      if (this.form.content.length === 0) {
        await this.$ons.notification.alert(this.$t("app.reimb.alert.content"), options);
        return false;
      }
      return true;
    },
    onSubmitReimbursementDetail() {
      this.validate().then((valid) => {
        if (valid) {
          let ret = {
            total: this.total,
            index: this.index,
            page_number: this.form.page_application,
            currency_id: this.selectedCurrency.id,
            company_id: this.form.company.id,
            department_id: this.form.department.id,
            contents: this.form.content
              ? this.form.content.map((object) => ({
                  amount: object.amount,
                  destination: object.destination,
                  date: object.date,
                  note: object.notes,
                  content_type_id: object.expense.id,
                  from_to: object.from_to
                }))
              : [],
            related_approval_id: this.form.related_approval_id,
            related_items: this.form.related_items
              ? this.form.related_items.map((item) => ({
                  item_id: item.item_id,
                  is_edited: item.edited,
                  data: item
                }))
              : []
          };

          this.setParam({ reimbursementDetail: ret });
          this.pop();
        }
      });
    },
    push(page, key, param) {
      this.$store.commit("navigator/push", {
        extends: page,
        data() {
          return {
            toolbarInfo: {
              backLabel: this.$t("common.back"),
              title: key,
              param: param
            }
          };
        }
      });
    }
  }
};
</script>
