<template>
  <BottomSheet :title="$t('common.sort')" :showing="showing" @on-close="$emit('close')" classes="pb-0">
    <loading-small v-if="loading" class="test" />
    <v-ons-list v-else>
      <div class="p-4 grid grid-cols-2 gap-3 text-sm text-center  border-b border-gray-100">
        <div
          v-for="(item, key) in sorts"
          :key="key"
          class="border border-gray-100 rounded-md py-2 px-2"
          :class="{
            'border border-blue-500 text-blue-500': data.by === item.value,
            'border border-gray-200 text-gray-500': data.by !== item.value
          }"
          @click="handleSelectSort(item.value)"
        >
          {{ item.text }}
        </div>
      </div>
      <div class="p-4 grid grid-cols-2 gap-3 text-sm text-center border-b border-gray-100">
        <div
          v-for="(item, key) in order"
          :key="key"
          class="border border-gray-100 rounded-md py-2 px-2"
          :class="{
            'border border-blue-500 text-blue-500': data.order === item.value,
            'border border-gray-200 text-gray-500': data.order !== item.value
          }"
          @click="handleSelectOrder(item.value)"
        >
          {{ item.text }}
        </div>
      </div>
      <div class="p-4 grid grid-cols-2 gap-3 text-sm text-center">
        <ons-row class="flex items-center justify-center py-4">
          <v-ons-button modifier="quiet" class="w-full text-center rounded" @click="handleReset">
            {{ $t("common.reset") }}
          </v-ons-button>
        </ons-row>
        <ons-row class="flex items-center justify-center py-4">
          <v-ons-button class="w-full text-center rounded" @click="handleSubmit">
            {{ $t("common.save") }}
          </v-ons-button>
        </ons-row>
      </div>
    </v-ons-list>
  </BottomSheet>
</template>

<script>
import BottomSheet from "@/components/BottomSheet";

const defaultFilter = {
  by: null,
  order: "desc"
};

export default {
  name: "FilterSort",
  components: { BottomSheet },
  props: {
    showing: Boolean,
    sorts: Array,
    order: Array
  },
  data() {
    return {
      loading: false,
      data: Object.assign({}, defaultFilter)
    };
  },
  methods: {
    handleReset() {
      this.$emit("reset");
    },
    handleSelectSort(value) {
      this.data.by = value;
    },
    handleSelectOrder(value) {
      this.data.order = value;
    },
    handleSubmit() {
      this.$emit("select", this.data);
    }
  }
};
</script>
