<template>
  <v-ons-page @init="loadPage">
    <custom-toolbar v-bind="toolbarInfo">
      <v-ons-toolbar-button slot="right" v-if="selectedApprovers.length > 0" @click="handleAdd()">
        <span class="p-2 text-white bg-blue rounded-md text-sm cursor-pointer">{{ $t("common.add") }} ({{ selectedApprovers.length }})</span>
      </v-ons-toolbar-button>
    </custom-toolbar>
    <search-filter @on-search="onSearch" @on-reset="onReset" />
    <div class="mt-14">
      <div class="no-result" v-if="approverList.length == 0">{{ $t("common.empty") }}</div>
      <v-ons-list v-else>
        <form-select-icon
          v-for="(obj, key) in approverList"
          :key="key"
          :label="obj.name"
          :sublabel="obj.company.name + ' | ' + obj.department.name"
          :badge="obj.is_authorizer ? $t('common.company_authorizer') : null"
          :icon="isSelected(obj.id) ? 'check-circle-filled' : 'circle'"
          :iconClass="isSelected(obj.id) ? 'text-blue-500' : 'text-gray-300'"
          modifier="none"
          @tap="handleSelectApprover(obj.id)"
        />
      </v-ons-list>
    </div>
    <infinite-loading @infinite="getData" />
  </v-ons-page>
</template>

<script>
import { mapMutations } from "vuex";
import { fetchList } from "@/api/approver";
import SearchFilter from "@/components/SearchFilter.vue";

const defaultFilter = { page: 1, itemsPerPage: 10, name: "" };

export default {
  name: "FinanceListMultiple",
  components: { SearchFilter },
  data: () => ({
    default: [],
    approverList: [],
    filter: Object.assign({}, defaultFilter),
    selectedApprovers: []
  }),
  methods: {
    ...mapMutations("navigator", ["pop", "setParam", "clearParam"]),
    handleSelectApprover(id) {
      let selected = this.approverList.filter((approver) => approver.id === id)[0];

      if (!this.selectedApprovers.find((item) => item.id === id)) {
        this.selectedApprovers.push(selected);
      } else {
        this.selectedApprovers = this.selectedApprovers.filter((item) => item.id !== id);
      }
    },
    isSelected(id) {
      return this.selectedApprovers.find((item) => item.id === id);
    },
    handleAdd() {
      this.setParam({ selectedFinance: this.selectedApprovers });
      this.pop();
    },
    loadPage() {
      this.default = this.toolbarInfo.param.default ?? [];
    },
    getData($state) {
      fetchList(this.filter).then((response) => {
        let data = response["hydra:member"];

        this.approverList.push(...data);
        this.loadDefaultValue();
        if (data.length) {
          this.filter.page += 1;
          $state && $state.loaded();
        } else {
          $state && $state.complete();
        }
      });
    },
    loadDefaultValue() {
      this.selectedApprovers = this.approverList.filter((approver) => this.default.find((d) => d.id === approver.id));
    },
    onSearch(filter) {
      this.approverList = [];
      this.filter.page = 1;
      this.filter.name = filter.term;
      this.getData();
    },
    onReset() {
      this.approverList = [];
      this.filter = Object.assign({}, defaultFilter);
      this.getData();
    }
  }
};
</script>

<style scoped></style>
