// import router from "./router";
import { getToken } from "./utils/auth";
import store from "./store";
import { getInfo } from "@/api/login";

function checkCredentials() {
  return new Promise((resolve) => {
    if (getToken()) {
      getInfo()
        .then((profile) => {
          store.commit("user/SET_PROFILE", profile);
          store.commit("user/SET_ROLES", profile.roles["hydra:member"]);

          resolve(true);
        })
        .catch(() => {
          console.log("JWT not valid, please relogin");
          resolve(false);
        });
      resolve(true);
    } else {
      resolve(false);
    }
  });
}

export { checkCredentials };
