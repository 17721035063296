export const stat = {
  select_one: "请从下列选项选择",
  projection: "预测已申请完成付款",
  projection_info: "预测已申请完成付款的统计",
  invoice: "发票管理",
  invoice_info: "发票管理设定",
  bank: "本公司银行帐号管理",
  bank_info: "本公司帐号银行管理设定",
  expense: "费用项目管理",
  expense_info: "费用项目设定",
  export: "导出",
  export_info: "导出汇入汇款申请书/报告书及附件",
  document_search: "申请记录/文档搜索",
  register: "Register",

  proj: {
    filter: "范围",
    passed: "过去",
    today: "当天",
    upto: "至今",
    one: "明天～一週内",
    two: "8〜14天内",
    three: "15〜30天内",
    pending: "保留中之付款"
  },
  inv: {
    reimbursement: "报销",
    payment: "付款",
    info: "未获得发票一览",
    selected: "已选择",
    items: "件",
    select: "请选择",
    ok: "完了",
    upload: "上传",
    einvoice: "电子发票",
    einvoice_info: "发票最多可上传10张",
    paper: "报销凭证",
    paper_info: "报销凭证最多可上传10张",
    alert: {
      upload: "请上传电子发票／付款申请书"
    }
  },
  bank_: {
    account: "本公司银行帐号管理",
    name: "银行名",
    enter_name: "输入银行名",
    branch: "分行",
    enter_branch: "输入分行",
    no: "帐户号码",
    enter_no: "输入帐户号码",
    type: "种类",
    enter_type: "输入种类",
    alert: {
      name: "请输入银行名",
      branch: "请输入分行",
      no: "请输入帐户号码",
      type: "请输入种类"
    }
  },
  expense_: {
    account: "费用项目",
    reimbursement: "报销",
    payment: "付款",
    create: "添加费用项目",
    edit: "编辑费用项目",
    delete: "删除费用项目",
    name: "名称",
    name_en: "名称 (EN)",
    name_jp: "名称 (JP)",
    name_zh: "名称 (ZH)",
    enter_name: "输入费用项目种类",
    transport: "是否属于交通费科目？",
    transport_info: "若是，则添加起点和目的地的输入",
    alert: {
      name_en: "请输入费用项目种类 (EN)",
      name_jp: "请输入费用项目种类 (JP)",
      name_zh: "请输入费用项目种类 (ZH)"
    }
  },
  export_: {
    export: "输出",
    type: "导出形式",
    select_type: "选择导出形式",
    file_type: "File Type",
    select_file_type: "File file type",
    period: "期间",
    select_period: "选择期间",
    alert: {
      type: "选择输出之形式",
      period: "期间之选择"
    },
    date_type: "日期类型",
    date_type_: {
      completion: "完成日期",
      payment: "付款完了日"
    }
  },
  search_: {
    desc: "搜索已申请的记录或已上转的文档",
    app: "申请单子",
    doc: "文档",
    change: "条件更新"
  }
};
