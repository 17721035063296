<template>
  <div>
    <search-filter />

    <v-ons-list class="pt-14">
      <v-ons-list-item v-for="(item, key) in list" :key="key">
        <div class="left">
          <div class="approval-icon bg-transparent">
            <Icon name="circle" classes="text-gray-300" :size="32" />
          </div>
        </div>

        <div class="center" @click="handleSelect(item)">
          <div class="list-item__title">
            <div class="flex items-center">
              <div class="mr-2">{{ item.code ? item.code : " - " }}</div>
              <div>
                <span class="border border-blue-500 text-blue-500 text-xs p-0.5 rounded-md">{{ item.type.name }}</span>
              </div>
            </div>
          </div>
          <div class="list-item__subtitle flex justify-between">
            <div>{{ item.contract_name }}</div>
            <div></div>
          </div>
        </div>

        <div class="right" @click="handleShowInfo(item)">
          <v-ons-button modifier="quiet">
            <Icon name="info" classes="icon list-item__thumbnail" />
          </v-ons-button>
        </div>
      </v-ons-list-item>
    </v-ons-list>
  </div>
</template>

<script>
import SearchFilter from "@/components/SearchFilter";
import { fetchRelatedContract as fetchList } from "@/api/related_approval";
import { fetchRelatedContractDetail as fetchDetail } from "@/api/related_approval";

const defaultFilter = { moduleCode: "", code: "", type: null };
export default {
  name: "RelatedContract",
  props: ["related"],
  components: {
    "search-filter": SearchFilter
  },
  data() {
    return {
      filter: Object.assign({}, defaultFilter),
      list: []
    };
  },
  created() {
    this.filter.moduleCode = this.related;
    this.getList();
  },
  methods: {
    getList() {
      fetchList(this.filter).then((response) => {
        let data = response["hydra:member"];

        this.list = data.map((item) => ({
          id: item.id,
          code: item.code,
          contract_name: item.contract_full_name,
          stamp: item.stamp_no,
          type: item.contract_item_type,
          module: this.related,
          currency_code: item.currency.code,
          supplier_account: item.contract_supplier_account ? item.contract_supplier_account : null
        }));
      });
    },
    handleShowInfo(item) {
      this.getDetail(item.id).then((obj) => {
        this.$emit("detail", item, obj);
      });
    },
    handleSelect(item) {
      this.getDetail(item.id).then((obj) => {
        this.$emit("select", item, obj);
      });
    },
    async getDetail(id) {
      let obj = [];
      await fetchDetail(id).then((response) => {
        switch (response.contract_item_type.code) {
          case "goods":
            obj = response.contract_item_goods["hydra:member"];
            break;
          case "services":
            obj = response.contract_item_services["hydra:member"];
            break;
          case "hr":
            obj = response.contract_item_hr["hydra:member"];
            break;
          case "other":
            obj = response.contract_item_other["hydra:member"];
            break;
        }

        return obj;
      });

      return obj;
    }
  }
};
</script>

<style></style>
