import request from "@/utils/request";

// export function fetchRelatedReimbursement() {
//   return request({
//     url: "/transactions/reimbursement",
//     method: "get"
//   });
// }

// export function fetchRelatedPayment() {
//   return request({
//     url: "/transactions/payment",
//     method: "get"
//   });
// }

export function fetchDetail(id) {
  return request({
    url: "/transactions/" + id,
    method: "get"
  });
}

export function fetchRelatedReimbursement(param) {
  return request({
    url: "/purchases/list",
    method: "get",
    params: param
  });
}
export function fetchRelatedReimbursementDetail(id) {
  return request({
    url: "/purchases/" + id,
    method: "get"
  });
}

export function fetchRelatedContract(param) {
  return request({
    url: "/contracts/list",
    method: "get",
    params: param
  });
}

export function fetchRelatedContractDetail(id) {
  return request({
    url: "/contracts/" + id,
    method: "get"
  });
}
