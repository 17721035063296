<template>
  <div class="approval-process mt-4 pl-3">
    <div class="relative">
      <div class="log"></div>
    </div>
    <div class="ml-7 w-full">
      <div class="font-16"></div>
      <div class="text-dark-gray flex justify-between">
        <div>{{ log.log_text }}</div>
        <div>{{ log.created_at ? moment(log.created_at).format("YYYY/MM/DD HH:mm") : "" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProcessLog",
  props: ["log"]
};
</script>

<style></style>
